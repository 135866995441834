<template>
  <v-container style="min-height: 70vh">
    <v-row class="my-15" justify="center">
      <v-col xs="12" sm="12" md="12" lg="9" xl="6">
        <base-card class="pt-10 pb-15">
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            Privacy Policy
          </p>
          <div
            :class="{
              'mx-15': $vuetify.display.mdAndUp,
              'mx-5': $vuetify.display.smAndDown,
              'text text-body-2': true,
            }"
            style="font-size: 16px"
          >
            <p>
              <strong>NETWORKTAL</strong> ("we" "us" or "our") is committed to
              protecting the privacy of our users ("you" or "your"). This
              Privacy Policy describes the types of information we collect from
              and about you when you use our platform (the "Platform") including
              our website and how we collect use disclose and protect that
              information.
            </p>

            <h2>Contact details</h2>

            <p>
              NetworkTal, LLC <br />
              131 Continental Dr, Suite 305 <br />
              19713 Newark, Delaware <br />
              United States of America <br />
              <br />
              <a href="https://www.networktal.com">www.networktal.com</a> <br />
              <a href="mailto:support@networktal.com">support@networktal.com</a>
            </p>

            <h2>Information We Collect</h2>
            <p>
              We collect a variety of information to provide and improve the
              Platform for our users. Here's a breakdown of the information we
              collect and how we use it:
            </p>
            <ul>
              <li>
                <strong>Account Information:</strong> When you create a
                NetworkTal account we collect personal information such as your
                name, email address, and password. We use this information to
                identify you, create your account, and provide customer service.
              </li>
              <li>
                <strong>Profile Information:</strong> You may choose to provide
                additional information to your profile such as your location,
                place of origin, areas of interest (for explorers) or areas of
                expertise, a description of your professional experience,
                certifications, and a presentation video (for trainers) when
                registering as a trainer. This information helps you connect
                with other users and personalize your experience on the
                Platform.
              </li>
              <li>
                <strong>Transaction Information:</strong> When you book a
                session on the Platform we collect information about the
                transaction such as the type of session, the trainer you booked
                it with, the date and time of the session, and the amount you
                paid. We use this information to process your payment, fulfill
                your booking, and maintain accurate financial records.
              </li>
              <li>
                <strong>Usage Data:</strong> We collect information about how
                you access and use the Platform such as the type of device you
                use, the pages you visit, the time and date of your visit, your
                search queries, and your interactions with trainers and
                explorers. We use this information to understand how users
                interact with the Platform, improve its functionality, and
                personalize your experience.
              </li>
              <li>
                <strong>Device Information:</strong> We collect information
                about the device you use to access the Platform such as the
                device type, operating system, IP address, and browser type. We
                use this information to ensure the Platform functions properly
                on your device and for security purposes.
              </li>
              <li>
                <strong>Cookies and Other Tracking Technologies:</strong> We use
                cookies and other tracking technologies such as web beacons and
                pixel tags to collect information about your activity on the
                Platform. These technologies may store information locally on
                your device or in the web server logs. We use this information
                to:
                <ul>
                  <li>Remember your preferences and settings.</li>
                  <li>Track user behavior and trends on the Platform.</li>
                  <li>Target advertising to you.</li>
                </ul>
              </li>
            </ul>

            <h2>How We Use Your Information</h2>
            <p>
              We use the information we collect for a variety of purposes
              including:
            </p>
            <ul>
              <li>To provide and operate the Platform</li>
              <li>To process your transactions</li>
              <li>
                To send you marketing and promotional communications (with your
                consent)
              </li>
              <li>To respond to your inquiries and requests</li>
              <li>
                To improve the Platform and develop new features and services
              </li>
              <li>To personalize your experience on the Platform</li>
              <li>To analyze user behavior and trends</li>
              <li>To prevent fraud or other illegal activity</li>
              <li>To comply with legal and regulatory obligations</li>
            </ul>

            <h2>Sharing Your Information</h2>
            <p>
              We may share your information with third-party service providers
              who help us operate the Platform. These providers may have access
              to your information only to perform specific tasks on our behalf
              and are obligated to protect your information. Here are some
              examples of third-party service providers we may share your
              information with:
            </p>
            <ul>
              <li>
                <strong>Payment processors:</strong> To process your
                transactions.
              </li>
              <li>
                <strong>Cloud providers:</strong> To store and process your
                data.
              </li>
              <li>
                <strong>Marketing platforms:</strong> To send you marketing
                communications.
              </li>
              <li>
                <strong>Data analytics providers:</strong> To analyze user
                behavior and trends.
              </li>
            </ul>
            <p>
              We may also disclose your information if required by law or in the
              good faith belief that such disclosure is necessary to:
            </p>
            <ul>
              <li>Comply with a legal obligation</li>
              <li>Protect the rights or safety of you, us, or others</li>
              <li>Prevent or investigate fraud or other wrongdoing</li>
            </ul>

            <h2>Your Choices</h2>
            <p>You have certain choices regarding your information:</p>
            <ul>
              <li>
                <strong>Account Information:</strong> You can access and update
                your account information through your profile settings.
              </li>
              <li>
                <strong>Profile Information:</strong> You can edit or delete
                your profile information at any time.
              </li>
              <li>
                <strong>Transaction Information:</strong> You can view your
                transaction (session booking) history and booking details in
                your account settings.
              </li>
              <!-- <li>
                <strong>Marketing Communications:</strong> You can opt out of
                receiving marketing communications from us by following the
                unsubscribe instructions in those communications or by adjusting
                your communication preferences in your account settings.
              </li> -->
              <li>
                <strong>Cookies and Other Tracking Technologies:</strong> You
                can control cookies and other tracking technologies through your
                browser settings. Please note that disabling cookies may limit
                your ability to use certain features of the Platform.
              </li>
            </ul>

            <h2>Data Security</h2>
            <p>
              We take reasonable steps to protect your information from
              unauthorized access, disclosure, alteration, or destruction. These
              steps include:
            </p>
            <ul>
              <li>Secure storage of your information</li>
              <li>Regular security audits</li>
              <li>Use of encryption technologies</li>
            </ul>
            <p>
              However, no internet transmission or electronic storage is ever
              completely secure. We cannot guarantee the security of your
              information.
            </p>

            <h2>Data Retention</h2>
            <p>
              We will retain your information for as long as your account is
              active or as needed to provide you with services. We may also
              retain your information for a longer period to:
            </p>
            <ul>
              <li>Comply with legal and regulatory obligations</li>
              <li>Resolve disputes</li>
              <li>Enforce our agreements</li>
              <li>Maintain backups in case of system failures</li>
            </ul>
            <p>
              When we no longer need to retain your information, we will delete
              it securely.
            </p>

            <h2>Children's Privacy</h2>
            <p>
              Our Platform is not directed to children under the age of 13. We
              do not knowingly collect personal information from children under
              13. If you are a parent or guardian and you believe your child has
              provided us with personal information, please contact us. We will
              take steps to remove the information from our systems.
            </p>

            <h2>International Data Transfers</h2>
            <p>
              NetworkTal is a global platform and your information may be
              transferred to and processed in countries other than your own.
              These countries may have different data protection laws than your
              own country. When we transfer your information to other countries,
              we will take steps to protect your information in accordance with
              this Privacy Policy and applicable laws.
            </p>

            <h2>Your California Privacy Rights</h2>
            <p>
              If you are a California resident, you have certain rights
              regarding your information under the California Consumer Privacy
              Act (CCPA). You can request to know what information we collect,
              use, and disclose about you. You can also request to delete your
              information, subject to certain exceptions.
            </p>
            <p>
              To exercise your rights under the CCPA, you can contact us at
              <a href="mailto:support@networktal.com">support@networktal.com</a
              >.
            </p>

            <h2>Legal Basis for Processing (GDPR)</h2>
            <p>
              We process your information based on the following legal grounds
              under the General Data Protection Regulation (GDPR):
            </p>
            <ul>
              <li>
                <strong>Consent:</strong> In some cases, we may ask for your
                explicit consent to process your information for a specific
                purpose, such as sending you marketing communications. You can
                withdraw your consent at any time by contacting us or following
                the unsubscribe instructions in those communications.
              </li>
              <li>
                <strong>Contract:</strong> We need to process your information
                to fulfill our contractual obligations with you, such as
                processing payments for bookings and providing customer service.
              </li>
              <li>
                <strong>Legitimate Interests:</strong> We may also process your
                information for our legitimate interests, provided these
                interests are not overridden by your rights and freedoms. These
                legitimate interests include:
                <ul>
                  <li>Operating and improving the Platform</li>
                  <li>
                    Analyzing user behavior to personalize your experience
                  </li>
                  <li>Preventing fraud or other illegal activity</li>
                  <li>Complying with legal and regulatory obligations</li>
                </ul>
              </li>
            </ul>

            <h2>Data Subject Rights (GDPR)</h2>
            <p>
              If you are located in the European Union (EU), you have certain
              rights under the GDPR regarding your personal data. These rights
              include:
            </p>
            <ul>
              <li>
                <strong>Right to access:</strong> You have the right to request
                a copy of the information we hold about you.
              </li>
              <li>
                <strong>Right to rectification:</strong> You have the right to
                request that we correct any inaccurate or incomplete information
                we hold about you.
              </li>
              <li>
                <strong>Right to erasure:</strong> You have the right to request
                that we erase your personal data, subject to certain exceptions.
              </li>
              <li>
                <strong>Right to restriction of processing:</strong> You have
                the right to request that we restrict the processing of your
                personal data.
              </li>
              <li>
                <strong>Right to data portability:</strong> You have the right
                to request that we transfer your personal data to another
                controller.
              </li>
              <li>
                <strong>Right to object:</strong> You have the right to object
                to the processing of your personal data for marketing purposes
                or on grounds relating to your particular situation.
              </li>
            </ul>
            <p>
              To exercise any of these rights, please contact us at
              <a href="mailto:support@networktal.com">support@networktal.com</a
              >. We will respond to your request within a reasonable timeframe.
            </p>

            <h2>Data Breach Notification</h2>
            <p>
              In the event of a data breach that is likely to result in a risk
              to your rights and freedoms, we will notify you without undue
              delay. The notification will describe the nature of the data
              breach, the categories of data affected, and the measures we have
              taken to address the breach.
            </p>

            <h2>Changes to This Privacy Policy</h2>
            <p>
              This privacy policy was updated for the last time on
              <strong>June 8th 2024</strong>.
            </p>

            <p>
              We may update this Policy from time to time. We will notify you of
              any material changes by posting the updated Policy on the
              Platform. We recommend that you review this Privacy Policy
              periodically to stay informed about our practices.
            </p>

            <h2>Contact Us</h2>

            <p>
              If you have any questions about this Policy, please contact us at
              <a href="mailto:support@networktal.com">support@networktal.com</a>
              .
            </p>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { setMetaTags } from "../../utils/utils.js";

export default {
  created() {
    setMetaTags(
      "NetworkTal - Privacy Policy",
      "Learn more about our Privacy Policy"
    );
  },
};
</script>

<style scoped>
.text {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text h2 {
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 20px;
}

.text p {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text ul {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.text a {
  color: #3f51b5;
  text-decoration: none;
}

.text a:hover {
  text-decoration: underline;
}
</style>
