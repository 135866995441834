<template>
  <v-container
    style="min-width: 90%; min-height: 70vh"
    fluid
    class="ma-0 pa-0 px-2"
  >
    <base-card
      v-if="!currentUserTrainerProfile"
      class="ma-15"
      style="text-align: center"
    >
      <base-loader></base-loader>
    </base-card>
    <v-row v-else class="my-15 ma-0 pa-0" justify="center">
      <v-col
        cols="12"
        sm="10"
        md="6"
        lg="6"
        xl="5"
        :class="{
          'pr-5': $vuetify.display.mdAndUp,
          'pa-2': $vuetify.display.smAndDown,
          'pa-0': true,
        }"
      >
        <trainer-availability-calendar
          v-if="currentUserTrainerProfile.id"
          :trainerId="currentUserTrainerProfile.id"
          :loading="loading"
        ></trainer-availability-calendar>
      </v-col>
      <v-col
        cols="12"
        sm="10"
        md="6"
        lg="6"
        xl="4"
        style="min-width: 200px"
        order="first"
        order-md="last"
        order-lg="last"
        order-xl="last"
        order-xxl="last"
        :class="{
          'pr-5': $vuetify.display.mdAndUp,
          'pa-2': $vuetify.display.smAndDown,
          'pa-0': true,
        }"
      >
        <upcoming-availability
          :currentUserTrainerProfile="currentUserTrainerProfile"
          :trainerAvailability="futureTrainerAvailability"
          :loading="loading"
        ></upcoming-availability>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import trainerAvailabilityCalendar from "../../../components/trainer/settings/availability/availabilitycalendar.vue";
import upcomingAvailability from "../../../components/trainer/settings/availability/upcomingavailability.vue";
import { mapActions, mapGetters } from "vuex";
import {
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
  setMetaTags,
} from "../../../utils/utils.js";

export default {
  inject: ["eventBus"],
  data() {
    return {
      futureTrainerAvailability: "",
    };
  },
  components: {
    trainerAvailabilityCalendar,
    upcomingAvailability,
  },
  async created() {
    this.eventBus.on("new-availability-added", async () => {
      await this.loadFutureTrainerAvailability(
        this.currentUserTrainerProfile.id
      );
    });
    this.eventBus.on("new-event-added", async () => {
      await this.loadFutureTrainerAvailability(
        this.currentUserTrainerProfile.id
      );
    });
    this.eventBus.on("availability-removed", async () => {
      await this.loadFutureTrainerAvailability(
        this.currentUserTrainerProfile.id
      );
    });
    setMetaTags(
      "NetworkTal - Trainer Availability Settings",
      "Trainer Availability Settings"
    );
    setTimeout(async () => {
      if (this.currentUserTrainerProfile) {
        await this.loadFutureTrainerAvailability(
          this.currentUserTrainerProfile.id
        );
      }
    }, 300);
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
      userId: "auth/userId",
      currentUser: "users/currentUser",
    }),
    loading() {
      if (!this.futureTrainerAvailability) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      getTrainerByUserId: "trainers/getTrainerByUserId",
      getTrainerAvailability: "trainerAvailability/getTrainerAvailability",
    }),
    async loadFutureTrainerAvailability(trainerId) {
      let trainerAvailability = await this.getTrainerAvailability({
        trainerId: trainerId,
      });

      this.futureTrainerAvailability =
        this.getFutureTrainerAvailability(trainerAvailability);
    },
    getFutureTrainerAvailability(trainerAvailability) {
      return trainerAvailability.filter((availability) => {
        // Get current time in user's timezone
        let currentTimeInUserTimezone = getCurrentTimeInUserTimezone(
          this.currentUser.timezone
        );
        // Get availability end time in user's timezone
        let availabilityEndTime = convertUTCDateTimeToUserTimezoneNoFormat(
          availability.endTime,
          this.currentUser.timezone
        );
        return availabilityEndTime.isAfter(currentTimeInUserTimezone);
      });
    },
  },
};
</script>

<style scoped></style>
