<template>
  <base-card :loading="true" style="width: 100%">
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="12" sm="3">
        <v-skeleton-loader class="px-5" type="avatar"></v-skeleton-loader>
      </v-col>
      <v-col class="ma-0 pa-0 px-5">
        <v-skeleton-loader type="heading"></v-skeleton-loader>
        <v-skeleton-loader type="text"></v-skeleton-loader>
        <v-skeleton-loader type="text"></v-skeleton-loader>
        <v-skeleton-loader type="text"></v-skeleton-loader>
        <v-row justify="center">
          <v-col cols="4">
            <v-skeleton-loader type="chip"></v-skeleton-loader>
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader type="chip"></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-skeleton-loader type="button"></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader type="button"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
export default {};
</script>

<style scoped>
div :deep() .v-skeleton-loader__avatar {
  margin-top: 50px;
  min-height: 120px;
  min-width: 120px;
  /* align center horizontally */
  margin-left: auto;
  margin-right: auto;
}

div :deep() .v-skeleton-loader__heading {
  min-height: 50px;
}

div :deep() .v-skeleton-loader__chip {
  min-height: 40px;
  min-width: 100px !important;
}

div :deep() .v-skeleton-loader__button {
  min-height: 40px;
  min-width: 100px !important;
}
</style>
