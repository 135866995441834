// src/composables/useGlobalFunctions.js
// import { ref } from "vue";
import { useStore } from "vuex";

export function useGlobalFunctions() {
  // Get the store
  const store = useStore();

  // Trainers functions
  const fetchTrainersInRandomOrder = async () => {
    let trainers;
    await store.dispatch("trainers/loadTrainers", {
      forceRefresh: false,
    });
    trainers = shuffleTrainers(store.getters["trainers/trainers"]);
    return trainers;
  };

  const fetchTrainersSessionsInRandomOrder = async () => {
    let trainerSessions = [];
    let trainersInRandomOrder = await fetchTrainersInRandomOrder();

    trainersInRandomOrder.forEach((trainer) => {
      trainer.sessions.forEach((session) => {
        trainerSessions.push({
          ...session,
          trainerProfile: {
            id: trainer.id,
            displayName: trainer.displayName,
            photoURL: trainer.photoURL,
            areas: trainer.areas,
          },
        });
      });
    });

    let trainersSessionsInRandomOrder = shuffleTrainers(trainerSessions);

    return trainersSessionsInRandomOrder;
  };

  const shuffleTrainers = (trainers) => {
    for (let i = trainers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [trainers[i], trainers[j]] = [trainers[j], trainers[i]];
    }
    return trainers;
  };

  const createTrainerProfileDisplayNamePath = (displayName) => {
    return displayName.split(" ").join("-").toLowerCase();
  };

  const getTrainerIdFromTrainerProfileFullPath = (trainerProfileFullPath) => {
    let trainerProfileSubPath = trainerProfileFullPath.split("/")[2];
    let trainerProfileId = getTrainerIdFromTrainerProfileSubPath(
      trainerProfileSubPath
    );
    return trainerProfileId;
  };

  const getTrainerIdFromTrainerProfileSubPath = (trainerProfilePath) => {
    let trainerProfileId =
      trainerProfilePath.split("-")[trainerProfilePath.split("-").length - 1];
    return trainerProfileId;
  };

  // Picture operations functions
  const isPictureValid = (picture) => {
    let isPictureValid = false;

    if (picture) {
      isPictureValid =
        picture.size < 2000000 &&
        ["image/jpeg", "image/png", "image/bmp"].includes(picture.type);
    }
    return isPictureValid;
  };

  const validateAndUploadPicture = async (picture, userId) => {
    if (picture && isPictureValid(picture)) {
      await store.dispatch("data/uploadProfilePicture", {
        userId: userId,
        image: picture,
      });
    } else {
      store.dispatch("data/clearState");
    }
  };

  // Return the functions to make them available for other components
  return {
    createTrainerProfileDisplayNamePath,
    fetchTrainersInRandomOrder,
    fetchTrainersSessionsInRandomOrder,
    getTrainerIdFromTrainerProfileFullPath,
    getTrainerIdFromTrainerProfileSubPath,
    isPictureValid,
    validateAndUploadPicture,
  };
}
