<template>
  <div style="height: 100%">
    <base-loader v-if="!toCompleteSessions || !disputedSessions"></base-loader>
    <div v-else class="text-caption" style="height: 100% !important">
      <v-row class="ma-0 pa-0 py-1">
        <v-col class="ma-0 pa-0 pt-2 font-weight-medium">Action Required</v-col>
      </v-row>
      <v-row
        v-if="toCompleteSessions.length === 0 && disputedSessions.length === 0"
        class="ma-0 pa-0"
        align="center"
        style="min-height: 100px"
      >
        <v-col
          align="center"
          class="ma-0 pa-0 text-caption text-grey"
          style="height: 100% !important; font-style: italic"
        >
          No action required
        </v-col>
      </v-row>
      <div v-else>
        <router-link to="/settings/user/mysessions">
          <v-row class="ma-0 pa-0">
            <v-col v-if="toCompleteSessions.length > 0" class="ma-0 pa-0">
              <v-row class="ma-0 pa-0">
                <v-col align="center" class="ma-0 pa-0 pt-3 pb-1 text-h4">
                  {{ toCompleteSessions.length }}
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col align="center" class="ma-0 pa-0 pb-2">
                  To Complete
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="disputedSessions.length > 0" class="ma-0 pa-0">
              <v-row class="ma-0 pa-0">
                <v-col align="center" class="ma-0 pa-0 pt-3 pb-1 text-h4">
                  {{ disputedSessions.length }}
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col align="center" class="ma-0 pa-0 pb-2"> Disputed </v-col>
              </v-row>
            </v-col>
          </v-row>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toCompleteSessions: {
      required: true,
    },
    disputedSessions: {
      required: true,
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
</style>
