<template>
  <v-container style="min-height: 70vh">
    <v-row class="my-15" justify="center">
      <v-col xs="12" sm="12" md="12" lg="9" xl="6">
        <base-card class="pt-10 pb-15">
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            Terms and Conditions
          </p>
          <div
            :class="{
              'mx-15': $vuetify.display.mdAndUp,
              'mx-5': $vuetify.display.smAndDown,
              'text text-body-2': true,
            }"
            style="font-size: 16px"
          >
            <h2>Article 1: General</h2>
            <p>
              1.1 These terms and conditions govern your access to and use of
              the <strong>NETWORKTAL</strong> website and/or mobile application
              (hereinafter: the “platform”), including any content,
              functionality and services offered on or through the platform and
              are applicable to every offer, agreement or any resulting
              agreement(s) between <strong>NETWORKTAL</strong>, operated by the
              company NetworkTal, LLC, incorporated under the laws of Delaware
              with a principal place of business at 131 Continental Dr, Suite
              305, 19713 Newark Delaware (hereinafter: “we”) and a user of our
              platform (hereinafter: “user”).
            </p>
            <p>
              1.2 PLEASE REVIEW THESE TERMS AND POLICY CAREFULLY. YOUR ACCESS TO
              AND/OR USE OF THE SITE CONSTITUTES YOUR ACCEPTANCE OF, AND CONSENT
              TO BE BOUND BY, THESE TERMS AND POLICY. IF YOU DO NOT ACCEPT AND
              CONSENT TO BE BOUND BY THESE TERMS AND POLICY, THEN YOU MUST NOT
              USE OR ACCESS THE SITE.
            </p>
            <p>
              1.3 We may change these Terms and/or Policy from time to time by
              notifying you of such changes by any reasonable means, including
              by posting revised Terms or Policy through the Site. Any such
              changes will not apply to any dispute between you and us arising
              prior to the date on which we posted the revised Terms or Policy
              incorporating such changes, or otherwise notified you of such
              changes.
            </p>
            <p>
              1.4 Any discrepancies to these terms and conditions are solely
              applicable as far as agreed between parties in explicit written
              form, and only apply to that agreement to which these
              discrepancies are declared applicable.
            </p>
            <p>
              1.5 The applicability of any other discrepancies to these terms
              and conditions from a user is explicitly excluded.
            </p>
            <p>
              1.6 In case one or more conditions, partly or as a whole, in these
              terms and conditions shall be null or subject to annulment, then
              the remaining conditions will stay fully applicable. Parties will
              then discuss the situation in order to agree on new conditions in
              substitution of the conditions which are null or subject to
              annulment, in which the objective and effect of the original
              conditions will be leading.
            </p>
            <p>
              1.7 In case there is any uncertainty about the explanation of one
              or more conditions of these terms and conditions, or in case of a
              situation that is not captured in these terms and conditions, then
              the explanation or situation will be assessed in accordance with
              the spirit of these terms and conditions.
            </p>

            <h2>Article 2: Definitions</h2>
            <p>2.1 The following definitions are applicable:</p>
            <ul>
              <li>
                <u>Platform</u> the NetworkTal website and/or mobile
                application, under authority of NetworkTal, LLC
              </li>
              <li>
                <u>User</u>: every user of the platform, with or without account
              </li>
              <li>
                <u>Session</u>: training, guidance or feedback sessions provided
                on or through the platform
              </li>
              <li>
                <u>Trainer</u>: user with a trainer account, who may provide
                sessions on or through the platform
              </li>
              <li>
                <u>Explorer</u>: user with an account, who may book sessions on
                or through the platform
              </li>
            </ul>

            <h2>Article 3: Use of the platform</h2>
            <p>
              3.1 Only users with an account may provide and/or take training
              sessions on our platform. Creating an account is free. When
              creating an account, you agree to provide us with accurate,
              complete and up-to-date information and to not create an account
              for fraudulent or misleading purposes. You are solely responsible
              for any activity on your account and for maintaining the
              confidentiality and security of your password. We are not liable
              for any acts or omissions by you in connection with your account.
              You agree to notify us immediately in case of any unauthorized use
              of your account.
            </p>
            <p>
              3.2 On this platform, training sessions provided by a trainer can
              be booked by an explorer. We act solely as an intermediary in
              facilitating the training sessions and engagements between a
              trainer and an explorer.
            </p>
            <p>
              3.3 This platform maintains a safe, friendly and community
              spirited environment. Users should keep to that spirit while
              participating in any communications or services on this platform.
              Rude, abusive, violent, discriminating and/or improper language or
              behavior will not be tolerated and may result in account warning
              or suspension/prohibition of entire access to the platform. To
              report a violation or any inappropriate behavior or activities on
              this platform, please contact us via
              <a href="mailto:support@networktal.com">support@networktal.com</a
              >.
            </p>
            <p>
              3.4 We grant you a non-exclusive, non-transferable, revocable
              license to access and use the platform for your personal,
              non-commercial use, subject to these terms and conditions. You may
              not:
            </p>
            <ul>
              <li>use the platform for any commercial purposes</li>
              <li>
                copy, modify or create derivative works of any part of the
                platform
              </li>
              <li>
                reverse engineer, decompile or disassemble any part of the
                platform
              </li>
              <li>remove any proprietary notices from the platform</li>
              <li>
                use any robot, spider or other automated device to access the
                platform
              </li>
              <li>
                use any automated process to collect data from the platform
                without prior written consent
              </li>
            </ul>
            <p>
              3.5 You are solely responsible for all content that you submit or
              post on the platform. We have the right to remove any content that
              we determine, in our sole discretion, violates these terms and
              conditions or does not meet our appropriate standards. You agree
              that your content will not:
            </p>
            <ul>
              <li>be false, inaccurate or misleading</li>
              <li>be defamatory, libelous or slanderous</li>
              <li>be obscene, vulgar or offensive</li>
              <li>be malicious, harmful or threatening</li>
              <li>
                infringe any intellectual property rights or other rights of any
                third party
              </li>
              <li>violate any appliable law or regulation</li>
            </ul>
            <p>
              3.6 This platform may contain links to third-party websites, web
              applications or services. We are not responsible for the practices
              or the content on any of these websites, web applications or
              services.
            </p>
            <p>
              3.7 Users are responsible for scanning all transferred files and
              content for viruses and malware. The platform cannot be held
              responsible or liable for any damages which might occur due to
              platform usage, use of content or files transferred.
            </p>
            <p>
              3.8 Violation of these terms and conditions or not meeting our
              appropriate standards may get your account disabled permanently,
              without prior notice. Upon termination, you may no longer be able
              to have access to the platform. In this event, the user may
              contact us via
              <a href="mailto:support@networktal.com">support@networktal.com</a>
              for more information surrounding the violation and the status of
              the account.
            </p>
            <p>
              3.9 You may not link to the platform without our prior written
              consent. You can file a request via
              <a href="mailto:support@networktal.com">support@networktal.com</a
              >.
            </p>

            <h2>Article 4: Trainers</h2>
            <p>
              4.1 Trainers create, offer and provide services, including but not
              limited to training sessions, in their area of expertise. The
              trainer is solely responsible and liable for their representation,
              performance and/or results of services and all content published
              on the platform.
            </p>
            <p>
              4.2 By offering a service, the trainer undertakes that it has
              sufficient expertise and sufficient permissions, certificates,
              rights (including but not limited to intellectual property rights)
              and/or licenses (including but not limited to intellectual
              property licenses), to offer, provide and/or sell the service and
              content on our platform and is solely responsible and liable for
              this.
            </p>
            <p>
              4.3 Trainers set up a development plan, based on which they help
              explorers acquire new skills, expand their knowledge and achieve
              their goals through (one-to-one) meetings.
            </p>
            <p>
              4.4 Trainers may set their own rates and we will take a commission
              of 15% on each booked session. Each session sold and successfully
              completed, accredits your account with a revenue equal to 85% of
              the purchase amount. We partner with Stripe to process payouts.
              Trainers can access their Stripe account to manage payouts via
              their account.
            </p>
            <p>
              4.5 Trainers may not contact and/or offer services to explorers
              outside of the platform. Trainers may not offer explorers or any
              other users of the platform to pay or make payment using any
              method other than through the platform.
            </p>
            <p>
              4.6 All content posted by trainers are user generated content.
              Trainers are solely responsible and liable for all content
              (including but not limited to: their profile, communications and
              training sessions) posted on the platform, including mistakes and
              (typing) errors.
            </p>
            <p>
              4.7 Trainers may not promote their services on the platform
              through activities that are prohibited by any laws, regulations
              and/or third parties’ terms and conditions, as well as through
              marketing activity that negatively affects our relationship with
              our users or partners.
            </p>
            <p>
              4.8 Content posted by trainers and/or accounts of trainers may be
              removed and disabled from the platform in case of violation with
              these terms and conditions and/or appropriate standards. In this
              event, the platform is not obliged to pay the trainer any eventual
              outstanding remaining amount.
            </p>
            <p>
              4.9 Trainers are responsible for obtaining a general liability
              insurance policy with coverage amounts that are sufficient to
              cover all risk associated with the performance of their services.
            </p>
            <p>
              4.10 Promotion of Trainer Profiles: By creating a trainer profile
              on our platform, Trainers agree that Networktal may use publicly
              available information from their trainer profiles, including but
              not limited to name, profile picture, video presentations, "About
              Me", "My Approach" sections, and professional details, for
              promotional purposes across Networktal's social media channels.
              This promotion is intended to enhance the visibility of Trainers
              and attract potential users to their sessions.
            </p>

            <h2>Article 5: Explorers</h2>
            <p>
              5.1 Explorers may book sessions offered by trainers. Explorers are
              motivated and interested in learning new skills, improving
              existing skills, developing knowledge and building their network
              with the help of trainers.
            </p>
            <p>
              5.2 Explorers are responsible for providing full, correct and
              accurate information on their profile and in their further
              communications and sessions on the platform.
            </p>
            <p>
              5.3 Explorers acknowledge that trainers are solely responsible and
              liable for their representation, performance and/or results of
              services. Explorers cannot hold the platform responsible or liable
              for the (content of) services created, offered and/or provided by
              trainers. Furthermore, explorers acknowledge that all content
              posted by trainers are user generated content and that trainers
              are solely responsible and liable for all information and content
              (including but not limited to information and content regarding
              their profile, communications and training sessions) posted on the
              platform, including mistakes and (typing) errors.
            </p>
            <p>
              5.4 Explorers are solely responsible and liable for attending and
              following their booked sessions. In case the explorer missed a
              session, for any reason whatsoever, the platform or the trainer
              cannot be held responsible or liable for replacement of (any part
              of) the session.
            </p>
            <p>
              5.5 The information and/or content provided in the sessions are
              meant for personal use of the explorer only. Explorers may not
              share or use this for any other purposes.
            </p>
            <p>
              5.6 Explorers are responsible and liable for the sufficient full
              payment of the booked sessions or any other services purchased,
              including the fee for the platform. Booking a service involves a
              payment obligation for the explorer.
            </p>
            <p>
              5.7 In case the explorer could not realize a successful payment,
              for any reason whatsoever, then the explorer shall be in default
              by operation of law. The explorer will then be responsible for
              payment of the legal interest, calculated over the outstanding
              amount on the moment the explorer was in default until the moment
              of fulfilling the amount. All eventual further reasonable costs,
              including collection costs and/or legal costs, in order to claim
              the payment, are at risk and account of the explorer.
            </p>
            <p>
              5.8 Explorers can use a variety of payment methods offered at the
              platform, including CreditCard.
            </p>
            <p>
              5.9 Explorers may not offer direct payments to trainers using
              payment systems outside of the platform.
            </p>

            <h2>Article 6: Bookings, cancellations and refunds</h2>
            <p>
              6.1 Sessions can be booked by explorers in the availability
              timeframe of the trainer, after which the trainer has the
              possibility to either accept or decline the booking of the session
              within three days.
            </p>
            <p>
              6.2 Refund Policy - In the event of a service cancellation and
              subsequent refund request, the original payment processing fee
              charged by our payment processor (Stripe) will be deducted from
              the refund amount. By agreeing to these terms and conditions, the
              explorer acknowledges and accepts this deduction.
            </p>
            <p>
              6.3 In case booking of the session is declined or has not been
              accepted within three days, the booking of the session will
              automatically be cancelled. In this event, a refund (see refund
              Policy at 6.2) to the explorer will automatically be processed via
              the payment method that was used by the explorer.
            </p>
            <p>
              6.4 There is no possibility of rescheduling a booked session. In
              case the trainer and/or the explorer would like to reschedule a
              session, it is necessary to cancel the booked session, bearing in
              mind the terms and conditions for cancellation as laid down in
              this article, and to book a new session.
            </p>
            <p>
              6.5 Sessions may be cancelled by the trainer or the explorer until
              24 hours before the starting time of the session. In the event of
              cancellation, a refund (see refund Policy at 6.2) to the explorer
              will automatically be processed via the payment method that was
              used by the explorer.
            </p>
            <p>
              6.6 After a session has ended, the explorer needs to mark the
              session as completed in its account, after which the financial
              compensation will be transferred to the trainer. In case the
              session has not been marked as completed within three days after
              the session has ended, the session will automatically be marked as
              completed.
            </p>

            <h2>Article 7: Arbitration</h2>
            <p>
              At our election, any dispute arising out of or relating to these
              Terms and Conditions or your use of the Platform may be resolved
              by binding arbitration in accordance with the Arbitration Rules of
              the American Arbitration Association. The arbitration shall be
              conducted in New Castle, Delaware. Each party shall bear its own
              costs of arbitration, except that the prevailing party shall be
              entitled to recover from the non- prevailing party all reasonable
              costs and expenses of arbitration, including attorneys' fees.
            </p>

            <h2>Article 8: Reviews</h2>
            <p>
              8.1 After a service is completed, the explorer can leave a review
              about the booked service.
            </p>
            <p>
              8.2 Users are responsible and liable for legitimate and truthful
              reviews.
            </p>
            <p>
              8.3 Reviews will not be removed by the platform, unless there is a
              clear violation of our terms and conditions and/or it does not
              meet our appropriate standards.
            </p>

            <h2>Article 9: Limitations of liability</h2>
            <p>
              9.1 The use, in the broadest sense possible, of the platform, its
              content and any (results of) services obtained through the
              platform is at own risk, account and liability of the users.
            </p>
            <p>
              9.2 In no event will the (company behind the) platform, its
              affiliates or their licensors, service providers, employees,
              agents, officers or directors be liable for damages of any kind,
              under any legal theory, arising out of or in connection with your
              use, or inability to use, the platform, any websites or web
              applications linked to it, any content on the platform or such
              other websites or web applications, or any services or items
              obtained through the platform or such other websites or web
              applications, including direct, indirect, special, incidental,
              consequential or punitive damages, including but not limited to
              personal injury, pain and suffering, emotional distress, loss of
              revenue, loss of profits, loss of business or anticipated savings,
              loss of use, loss of goodwill, loss of data, and whether caused by
              tort (including negligence), breach of contract or otherwise, even
              if foreseeable.
            </p>
            <p>
              9.3 This platform is not liable for any damages arising as
              consequence of any inaccurate and/or incomplete information
              provided by users.
            </p>
            <p>
              9.4 In case the platform shall be liable for damages, of any kind
              and from any party, then this liability is always explicitly
              restricted to the legal liability and what is concluded in these
              terms and conditions.
            </p>
            <p>
              9.5 In case the platform shall be liable for damages, the platform
              is exclusively liable for direct damages. ‘Direct damages’ in
              these terms and conditions can exclusively be understood as the
              reasonable costs of establishing the cause and extent of the
              damages, insofar the establishment concerns the damages considered
              in these terms and conditions, the eventual reasonable costs to
              let the platform fulfill any neglected obligations, insofar this
              can be considered at the account of the platform, and the
              reasonable costs to prevent or limit the damages, insofar the user
              provides sufficient evidence that these costs actually led to
              limitation of the direct damages as meant in these terms and
              conditions. In no case, the platform is liable for indirect
              damages, including consequential damages.
            </p>
            <p>
              9.6 In any case, the liability of the platform shall be limited to
              the amount covered under the professional liability insurance.
            </p>
            <p>
              9.7 Damages claims need to be submitted within twelve months after
              the user started suffering damages, after which period the
              eventual right to claim damages is expired.
            </p>
            <p>
              9.8 The limitations of liability which are concluded in this
              article, do not apply insofar as the damages are caused
              intentionally or the platform is grossly at fault.
            </p>
            <p>
              9.9 The foregoing does not affect any liability which cannot be
              excluded or limited under applicable law.
            </p>

            <h2>Article 10: Disclaimer</h2>
            <p>
              10.1 This platform is provided ‘as is’ and ‘as available’. We
              disclaim all warranties, express or implied, including but not
              limited to the implied warranties of merchantability, fitness for
              a particular purpose and non-infringement. We do not warrant that
              the platform will be error-free, free of viruses or other harmful
              components, or that the. Platform will continue to be available.
            </p>
            <p>
              10.2 The user grants the platform indemnity against any claims or
              demands from the user or third parties that are based on or have
              any connection with damages in relation with the use of the
              platform.
            </p>
            <p>
              10.3 In case the platform receives a claim in such matters, it’s
              the user’s duty to aid the platform both in and out of court and
              immediately do all what in those circumstances is expected from
              the user. In case the user is failing to take any adequate and
              appropriate measures, then the platform has the right to take
              these measures itself without any notice of default. All costs and
              damages on the side of the platform and third parties caused by
              this, are integrally at the expense and risk of the user.
            </p>

            <h2>Article 11: Intellectual property</h2>
            <p>
              11.1 We reserve all rights and powers for which we are entitled to
              under copyright law and other intellectual property laws and
              regulations, regarding this platform, including its general
              layout, look and feel, design, logos, images, trademarks, text,
              information, content and any materials available thereon.
            </p>
            <p>
              11.2 Users have no right to copy, transfer, adapt, modify,
              multiply, distribute, transmit, display, create derivative works,
              make public or reproduce any (parts of) content from or deriving
              from the platform.
            </p>
            <p>
              11.3 For reporting claims of intellectual property infringement,
              please contact
              <a href="mailto:support@networktal.com">support@networktal.com</a
              >. Note that it is our policy in appropriate circumstances to
              disable and/or terminate the accounts of users who are repeat
              infringers.
            </p>

            <h2>Article 12: Confidentiality</h2>
            <p>
              12.1 Users acknowledge that there might be need for other users to
              disclose certain confidential information and that this
              confidential information should be protected from unauthorized use
              and disclosure. Therefore, users agree to treat any information
              from other users as highly sensitive, secret and classified
              material. Users agree to maintain all such information in strict
              confidence, not disclose this information to any third parties,
              not use the information for any purpose except for providing the
              agreed obligations and not copy or reproduce any of the
              information without the user’s permission.
            </p>

            <h2>Article 13: Applicable law</h2>
            <p>
              13.1 These Terms and Conditions and any disputes arising out of or
              relating to these Terms and Conditions or your use of the Platform
              shall be governed by and construed in accordance with the laws of
              the State of Delaware, without regard to its conflict of laws
              provisions. You agree to the exclusive jurisdiction of the courts
              located in New Castle, Delaware to resolve any legal disputes
              arising out of or relating to these Terms and Conditions or your
              use of the Platform. Notwithstanding the foregoing, we may seek
              injunctive or other equitable relief in any court of competent
              jurisdiction to protect our intellectual property rights.
            </p>

            <h2>Article 14: User Responsibilities</h2>
            <p>
              14.1 As a user of our Platform, you are obliged to adhere to the
              following responsibilities:
            </p>
            <ul>
              <li>
                Ensure that any content you contribute is lawful and does not
                pose a threat to the security of the website or the well-being
                of other users.
              </li>
              <li>
                Commit to complying with all applicable laws, contracts, and
                intellectual property rights, assuming full responsibility for
                your actions while accessing or utilizing the website.
              </li>
              <li>
                Abide by the terms outlined herein, refraining from engaging in
                any behavior that could be construed as harassing, threatening,
                or intimidating to others.
              </li>
              <li>
                Refrain from attempting to access another user's account without
                explicit authorization from the account holder and our platform.
              </li>
              <li>
                Avoid engaging in any activities that could disrupt the
                functionality of the website or impede other users' ability to
                fully enjoy its features.
              </li>
              <li>
                Abstain from attempting to bypass or circumvent any security
                measures implemented to protect the website or its content.
              </li>
              <li>
                Refrain from accessing any features or areas of the website to
                which you do not have explicit authorization.
              </li>
              <li>
                Do not employ any automated tools or interfaces to access the
                website without prior authorization from us.
              </li>
              <li>
                Refrain from soliciting information from other users for illegal
                activities, purposes that could cause harm to individuals or
                property, or fraudulent schemes.
              </li>
            </ul>

            <h2>Article 15: Exclusion of Liability for External Links</h2>
            <p>
              15.1 Our website may provide access to third-party content,
              services, or information via hyperlinks or other means. It is
              important to understand that we do not exercise control over these
              third-party materials, and as such, we cannot endorse or guarantee
              the accuracy, relevance, or safety of such content. Your decision
              to access or utilize third-party materials is done so at your own
              risk.
            </p>

            <h2>
              Article 16: Assignment of the Contractual Rights and Obligations
            </h2>
            <p>
              16.1 As users of the platform, you agree and acknowledge that we
              may exercise at our sole discretion and we reserve the right to
              freely assign and/or transfer any or all of the rights and
              obligations described in this Agreement in connection to providing
              the service to you. You may not assign any of your rights and
              duties without our prior consent. Notwithstanding the foregoing
              provisions, this agreement shall be binding upon and inure to the
              benefit of the parties hereto and their respective successors and
              assigns.
            </p>

            <h2>Article 17: Amendments of terms and conditions</h2>
            <p>
              17.1 We may change these terms and conditions from time to time
              and without any prior notification. These amended terms and
              conditions are applicable to the subsequent legal relationships
              with the user.
            </p>

            <p>
              These terms and conditions were updated for the last time on
              <strong>August 5th 2024</strong>.
            </p>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { setMetaTags } from "../../utils/utils.js";

export default {
  created() {
    setMetaTags(
      "NetworkTal - Terms and Conditions",
      "Read the Terms and Conditions of NetworkTal"
    );
  },
};
</script>

<style scoped>
.text {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text h2 {
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 20px;
}

.text p {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 25px;
  text-indent: -25px;
}

.text ul {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 45px;
}

.text a {
  color: #3f51b5;
  text-decoration: none;
}

.text a:hover {
  text-decoration: underline;
}
</style>
