<template>
  <div class="text-caption ma-2">
    <v-row
      @click="selectSession(session)"
      :class="
        selectedSession.id === session.id
          ? 'ma-0 pa-0 card-session card-session-active'
          : 'ma-0 pa-0 card-session'
      "
      :key="session.id"
      elevation="0"
      align="center"
    >
      <v-col cols="5" sm="6">
        {{ session.title }}
      </v-col>
      <v-col cols="4" sm="3">
        <v-icon size="15">mdi-clock-outline</v-icon>
        {{ session.duration }} min
      </v-col>
      <v-col cols="3">
        <v-icon size="15">mdi-cash</v-icon>
        {{
          session.price === 0 ? "Free" : session.price + " " + currency.symbol
        }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  inject: ["eventBus"],
  data: () => ({}),
  props: {
    session: {
      type: Object,
      required: true,
    },
    selectedSession: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currency: "websiteData/currency",
    }),
  },
  methods: {
    selectSession(session) {
      this.eventBus.emit("select-session", session);
    },
  },
};
</script>

<style scoped>
.card-session {
  background-color: rgb(var(--v-theme-background1));
  color: rgb(var(--v-theme-textPrimary));
  min-height: 70px;
  border-radius: 10px !important;
}

.card-session-active {
  border: 2px solid rgb(var(--v-theme-colorSpecial1));
  background-color: rgba(0, 0, 0, 0.1);
}

.card-session:hover {
  background-color: rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
}
</style>
