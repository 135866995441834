import db from "../../../firebase-init";

export default {
  async addAvailability(context, payload) {
    // console.log('Add or Update Availability triggered with payload: ' + JSON.stringify(payload))

    await db.collection("trainer_availability").add({
      trainerId: payload.trainerId,
      startTime: payload.startTime,
      endTime: payload.endTime,
    });
  },

  async getTrainerAvailability(context, payload) {
    // console.log('Fetch Trainer Availability triggered with payload: ' + JSON.stringify(payload))

    const trainerAvailability = [];
    const snapshot = await db
      .collection("trainer_availability")
      .where("trainerId", "==", payload.trainerId)
      .get();

    snapshot.forEach((doc) => {
      const data = doc.data();
      data.id = doc.id;
      trainerAvailability.push(data);
    });

    // order availability by start time
    trainerAvailability.sort((a, b) => {
      return new Date(a.startTime) - new Date(b.startTime);
    });

    return trainerAvailability;
  },

  async removeAvailability(context, payload) {
    // console.log('Remove Availability triggered with payload: ' + JSON.stringify(payload))

    await db.collection("trainer_availability").doc(payload.id).delete();
  },

  setAddAvailabilityDialog(context, payload) {
    context.commit("setAddAvailabilityDialog", payload);
  },
};
