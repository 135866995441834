<template>
  <div v-if="!isTrainer">
    <base-card class="pa-5 pt-4" style="text-align: center">
      <v-row class="ma-0 pa-0" align="center">
        <v-col
          class="ma-0 pa-0 text-body-2 font-weight-medium"
          cols="10"
          align="start"
        >
          <v-icon size="small" class="pr-2">mdi-plus</v-icon>
          Register as a trainer?</v-col
        >
      </v-row>
      <v-row class="ma-0 pa-0 py-5">
        <v-col align="center" class="ma-0 pa-0 py-3 text-caption">
          <base-button
            customHeight="27px"
            customFontSize="10px"
            customWidth="95px"
            toPath="/becometrainer/register"
            >Become a trainer</base-button
          >
        </v-col>
      </v-row>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isTrainer: "auth/isTrainer",
    }),
  },
};
</script>

<style scoped></style>
