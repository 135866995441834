<template>
  <div>
    <base-dialog :model="messageTrainerDialog">
      <v-toolbar class="text-white" color="colorSpecial5" elevation="1">
        <v-row align="center" class="text-h5 font-weight-medium ma-0 pa-0">
          <v-col cols="10">Send a message</v-col>
          <v-col cols="2" align="end">
            <v-btn icon @click="triggerCloseMessageDialog()">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-container>
        <v-form @submit.prevent="submitEvent">
          <base-error-success-message
            class="ma-2"
            :errorMessage="errorMessage"
            :successMessage="successMessage"
          ></base-error-success-message>
          <v-textarea
            class="my-5 mx-5"
            rows="7"
            v-model="message"
            :error-messages="messageErrors"
            clearable
            :counter="500"
          >
            <template v-slot:label>
              <div>Write your message</div>
            </template>
          </v-textarea>
          <div class="mx-5 mb-2">
            <base-button class="mr-4" @click="submitEvent()"
              >Send Message</base-button
            >
            <base-button
              customColor="error"
              @click="triggerCloseMessageDialog()"
            >
              Close
            </base-button>
          </div>
        </v-form>
      </v-container>
    </base-dialog>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { mapGetters, mapActions } from "vuex";
import { required, maxLength } from "@vuelidate/validators";

export default {
  inject: ["eventBus"],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      message: { required, maxLength: maxLength(500) },
    };
  },
  props: {
    trainerId: {
      type: String,
      required: false,
    },
    targetUserId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      message: "",
      errorMessage: "",
      successMessage: "",
      selectedTrainer: "",
    };
  },
  created() {
    this.fetchTrainer();
    this.eventBus.on("set-base-dialog", (payload) => {
      this.setMessageTrainerDialog(payload);
    });
  },
  unmounted() {
    this.eventBus.off("set-base-dialog");
  },
  watch: {
    dialog(newValue) {
      if (newValue === true) {
        this.fetchTrainer();
      }
    },
  },
  computed: {
    ...mapGetters({
      messageTrainerDialog: "trainers/messageTrainerDialog",
      userId: "auth/userId",
      activeRoom: "room/activeRoomId",
    }),
    messageErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "message") {
          if (error.$validator === "required") {
            errors.push("Message is required");
          } else if (error.$validator === "maxLength") {
            errors.push("Message cannot be longer than 500 characters");
          }
        }
      });
      return errors;
    },
  },
  methods: {
    ...mapActions({
      getTrainerById: "trainers/getTrainerById",
      setMessageTrainerDialog: "trainers/setMessageTrainerDialog",
      sendMessage: "room/sendMessage",
      clearRoom: "room/clearRoom",
      selectRoom: "room/selectRoom",
    }),
    async submitEvent() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        try {
          if (this.message.length > 0) {
            const { message } = this;

            this.clearRoom();

            let targetUserId = this.targetUserId;
            if (targetUserId === null) {
              targetUserId = this.selectedTrainer.userId;
            }

            if (targetUserId === this.userId) {
              throw new Error("You cannot send a message to yourself");
            }

            await this.selectRoom({
              targetUser: targetUserId,
              currentUser: this.userId,
            });

            await this.sendMessage({
              message: message,
              senderId: this.userId,
            });

            this.successMessage = "Message sent successfully!";
            this.clearData();
            this.v$.$reset();

            setTimeout(() => {
              this.successMessage = "";
              this.triggerCloseMessageDialog();
            }, 1000);
          }
        } catch (error) {
          this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        }
      } else {
        this.errorMessage = "Please fill in all the required information";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },
    async fetchTrainer() {
      this.selectedTrainer = await this.getTrainerById({
        id: this.trainerId,
      });
    },
    triggerCloseMessageDialog() {
      this.setMessageTrainerDialog(false);
      this.clearData();
      this.v$.$reset();
    },
    clearData() {
      this.message = "";
    },
  },
};
</script>

<style scoped></style>
