<template>
  <v-container id="home-section-1" fluid>
    <v-row class="ma-0 pa-0 py-15 px-10 text-center" justify="center">
      <v-col cols="12" md="9">
        <div :style="'font-size: ' + titleSize + ';'">
          <strong>Trainer</strong> registration is now open!
        </div>
        <div class="my-10">
          <base-button
            class="my-2"
            toPath="/becometrainer/register"
            customColor="colorSpecial1"
          >
            Become a trainer
          </base-button>
          <base-button
            class="my-2"
            toPath="/trainers"
            customColor="colorSpecial4"
          >
            View all trainers
          </base-button>
        </div>
        <div :style="'font-size: ' + descriptionSize + ';'">
          Transform <strong>passion</strong> into <strong>profession</strong>.
          Share your expertise and inspire learners around the world!
        </div>
        <div class="mt-10" :style="'font-size: ' + descriptionSize + ';'">
          See
          <strong>
            <router-link
              to="/faq"
              style="
                text-decoration: none;
                color: rgb(var(--v-theme-primary)) !important;
              "
              >FAQ</router-link
            >
          </strong>
          for more details
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "@/assets/css/global.css";

export default {
  computed: {
    titleSize() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "40px";
        case "sm":
          return "45px";
        case "md":
          return "50px";
        case "lg":
          return "65px";
        case "xl":
          return "75px";
        default:
          return "50px";
      }
    },
    descriptionSize() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "20px";
        case "sm":
          return "25px";
        case "md":
          return "30px";
        case "lg":
          return "35px";
        case "xl":
          return "35px";
        default:
          return "35px";
      }
    },
  },
  methods: {
    learnMore() {
      const element = document.querySelector("#home-section-2");
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
#home-section-1 {
  background-color: rgb(var(--v-theme-background3));
  color: rgb(var(--v-theme-textPrimary));
  /* border: 2px solid black; */
  min-height: 25vh;
  margin: 0px;
  padding: 0px;
}
</style>
