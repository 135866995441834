<template>
  <div class="session-card text-caption ma-3">
    <base-loader v-if="!session"></base-loader>
    <router-link
      v-else
      class="font-weight-medium ma-0 pa-0"
      :to="'/settings/trainer/mysessions/' + session.id"
      style="text-decoration: none"
    >
      <v-row
        align="center"
        v-if="userProfile"
        justify="space-around"
        class="ma-0 pa-0 pa-1"
      >
        <v-col cols="5" sm="4" align="center">
          <v-row class="ma-0 pa-0">
            <v-col
              cols="12"
              sm="4"
              class="ma-0 pa-0 pr-2"
              :align="photoAlignment"
            >
              <v-avatar size="35"> <v-img :src="getPhotoURL"></v-img> </v-avatar
            ></v-col>
            <v-col
              cols="12"
              sm="7"
              class="ma-0 pa-0"
              :style="{
                'justify-content': nameAlignment,
                display: 'flex',
                'align-items': 'center',
              }"
            >
              <strong>{{ userProfile.displayName }}</strong>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          sm="4"
          md="4"
          lg="3"
          xl="3"
          class="ma-0 pa-0 px-2"
          style="font-size: 10px"
        >
          <v-icon size="small" class="mr-2">mdi-human-male-board</v-icon>
          {{ session.sessionTitle }}
          <br />
          <v-icon size="small" class="mr-2">mdi-clock-outline</v-icon>
          {{ getSessionStartTime() }}
        </v-col>
        <v-col align="center" class="ma-0 pa-0">
          <div v-if="newSessionCardMessage !== null">
            <v-chip
              size="small"
              :color="chipColorNewCardMessage"
              class="text-white"
              variant="outlined"
              label
              style="font-size: 10px"
            >
              <v-icon size="small" start>mdi-label</v-icon>
              {{ newSessionCardMessage }}
            </v-chip>
            <v-icon>mdi-question</v-icon>
          </div>
          <div v-else>
            <v-chip
              size="small"
              :color="chipColor"
              class="text-white"
              variant="outlined"
              label
              style="font-size: 10px"
            >
              <v-icon size="small" start>mdi-label</v-icon>
              {{ sessionCardMessage }}
            </v-chip>
          </div>
        </v-col>
      </v-row>
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
} from "@/utils/utils.js";

export default {
  inject: ["eventBus"],
  data() {
    return {
      userProfile: null,
    };
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    sessionCardMessage: {
      type: String,
      required: false,
      default: "",
    },
  },
  created() {
    this.getUserProfileDetails();
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
      userPhotoDefault: "websiteData/userPhotoDefault",
    }),
    getPhotoURL() {
      if (this.userProfile) {
        if (this.userProfile.photoURL) {
          return this.userProfile.photoURL;
        } else {
          return this.userPhotoDefault;
        }
      } else {
        return this.userPhotoDefault;
      }
    },
    chipColor() {
      switch (this.sessionCardMessage) {
        case "UPCOMING":
          return "info";
        case "COMPLETED":
          return "success";
        case "DECLINED":
          return "error";
        case "PENDING SYSTEM ACTION":
          return "warning";
        case "CANCELLED":
          return "error";
        case "DISPUTED":
          return "warning";
        default:
          return "info";
      }
    },
    chipColorNewCardMessage() {
      switch (this.newSessionCardMessage) {
        case "PENDING CONFIRMATION":
          return "info";
        case "ACCEPT OR DECLINE":
          return "warning";
        default:
          return "info";
      }
    },
    newSessionCardMessage() {
      let lastStatus =
        this.session.statusLogs[this.session.statusLogs.length - 1];
      let sessionEndDateInUserTimezone =
        convertUTCDateTimeToUserTimezoneNoFormat(
          this.session.end,
          this.currentUser.timezone
        );
      let currentTimeInUserTimezone = getCurrentTimeInUserTimezone(
        this.currentUser.timezone
      );

      if (lastStatus.status === "accepted") {
        // if start date is in the past
        if (sessionEndDateInUserTimezone.isBefore(currentTimeInUserTimezone)) {
          return "PENDING EXPLORER COMPLETION";
        } else {
          return "UPCOMING";
        }
      } else if (lastStatus.status === "new") {
        if (sessionEndDateInUserTimezone.isBefore(currentTimeInUserTimezone)) {
          return "PENDING SYSTEM ACTION";
        } else {
          return "ACCEPT OR DECLINE";
        }
      } else {
        return null;
      }
    },
    nameAlignment() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "center";
        case "sm":
          return "center";
        case "md":
          return "start";
        case "lg":
          return "start";
        case "xl":
          return "start";
        default:
          return "start";
      }
    },
    photoAlignment() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "center";
        case "sm":
          return "center";
        case "md":
          return "end";
        case "lg":
          return "end";
        case "xl":
          return "end";
        default:
          return "end";
      }
    },
  },
  methods: {
    ...mapActions({
      getUserByUserId: "users/getUserByUserId",
    }),
    getSessionStartTime() {
      return convertUTCDateTimeToUserTimezoneNoFormat(
        this.session.start,
        this.currentUser.timezone
      ).format("MMM D, YYYY, HH:mm");
    },
    async getUserProfileDetails() {
      this.userProfile = await this.getUserByUserId(this.session.userId);
    },
  },
};
</script>

<style scoped>
strong {
  font-weight: 450;
}

.session-card {
  background-color: rgb(var(--v-theme-background1));
  color: rgb(var(--v-theme-textPrimary));
  border-radius: 10px;
  font-size: 12px;
  border: 2px solid rgb(var(--v-theme-background1));
}

.session-card:hover {
  /* add border */
  border: 2px solid rgb(var(--v-theme-colorSpecial1));
}

a {
  text-decoration: none;
  color: rgb(var(--v-theme-textPrimary));
}
</style>
