<template>
  <div v-if="communicationTool">
    <v-row class="mx-1">
      <v-col cols="1" class="ma-0 pa-0">
        <v-checkbox v-model="communicationTool.enabled"></v-checkbox>
      </v-col>
      <v-col
        cols="4"
        class="mt-1 font-weight-medium"
        :class="{ 'disabled-text': !communicationTool.enabled }"
      >
        {{ communicationTool.name }}
      </v-col>
      <v-col cols="7" class="ma-0 pa-0">
        <v-text-field
          v-model="communicationTool.toolId"
          :label="communicationTool.name + ' ID'"
          :error-messages="toolIdErrors"
          clearable
          :disabled="communicationTool.enabled === false"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";

export default {
  inject: ["eventBus"],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      communicationTool: {
        toolId: {
          required: requiredIf(function () {
            return this.communicationTool.enabled;
          }),
        },
      },
    };
  },
  props: {
    communicationTool: {
      type: Object,
    },
  },
  async created() {
    this.eventBus.on("validate-communication-tools", async () => {
      await this.triggerValidation();
    });
  },
  computed: {
    toolIdErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "communicationTool.toolId") {
          errors.push("Communication Tool ID is required.");
        }
      });
      return errors;
    },
  },
  methods: {
    async triggerValidation() {
      await this.v$.$validate();
    },
  },
};
</script>

<style scoped>
.disabled-text {
  color: rgb(var(--v-theme-disabled));
}
</style>
