<template>
  <div>
    <v-expansion-panels class="expansion-panels">
      <v-expansion-panel v-for="FAQ in FAQs" :key="FAQ.question">
        <v-expansion-panel-title class="question-header">
          {{ FAQ.question }}
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          {{ FAQ.answer }}
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  data() {
    return {
      FAQs: [
        {
          question: "How does NetworkTal work?",
          answer:
            "We provide a platform where users (explorers) can connect with trainers for personalized learning sessions. Users can browse through trainer profiles and book sessions with trainers that align with their learning goals.",
        },
        {
          question: "How do I create an account on NetworkTal?",
          answer:
            'Creating an account on NetworkTal is simple and takes just a few seconds. Simply click on the "Sign Up" button and follow the on-screen instructions. You will need to provide your first name, last name and email address. In your user account you can then add more details to your profile such as your interest training areas. Once you have completed the process, you will be able to browse through trainer profiles and book sessions.',
        },
        {
          question:
            "What types of training categories are available on NetworkTal?",
          answer:
            "NetworkTal offers a wide variety of training categories in a variety of areas, including: business and leadership, technology and software, personal development, health and fitness, language learning, and much more! We are constantly adding new training categories, so be sure to check back frequently for the latest offerings.",
        },
        {
          question: "How can I find a trainer that's right for me?",
          answer:
            "There are a few ways to find a trainer that's right for you. You can browse through trainer profiles, see reviews, filter your search by skill or name and contact trainers directly to ask questions.",
        },
        {
          question: "How do I book a session with a trainer?",
          answer:
            "Once you've found a trainer you're interested in, you can book a session directly on our platform. Simply click on the trainer's profile and click on the book session button.",
        },
        {
          question: "How do I pay for my sessions?",
          answer:
            "We offer a variety of payment methods to accommodate your preferences, including credit card.",
        },
        {
          question: "How do I contact customer support?",
          answer:
            "We're always here to help! You can contact our customer support team by email. Go to our Contact page at the bottom of the page for more details.",
        },
      ],
    };
  },
  components: {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
.expansion-panels {
  /* remove elevation */
  box-shadow: none !important;
}

.question-header {
  font-weight: 450;
  color: rgb(var(--v-theme-textPrimary));
}
</style>
