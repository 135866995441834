<template>
  <v-container class="text-subtitle-1">
    <base-error-success-message
      :errorMessage="errorMessage"
      :successMessage="successMessage"
    ></base-error-success-message>

    <v-row>
      <v-col class="font-weight-medium" cols="5">Full Name:</v-col>
      <v-col cols="7">
        <v-text-field
          v-model="updatedUserProfile.displayName"
          :error-messages="displayNameErrors"
          label="Full Name"
          required
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="font-weight-medium" cols="5">Email Address:</v-col>
      <v-col cols="7">
        <v-text-field
          v-model="updatedUserProfile.email"
          label="Email Address"
          disabled
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="font-weight-medium" cols="5">From:</v-col>
      <v-col cols="12" sm="7">
        <v-row>
          <v-col cols="6">
            <v-select
              :items="countries"
              v-model="updatedUserProfile.from.country"
              item-title="countryName"
              item-value="countryName"
              label="Country"
              :return-object="false"
              @update:model-value="updatedUserProfile.from.region = ''"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="fromRegionList"
              v-model="updatedUserProfile.from.region"
              item-title="name"
              item-value="name"
              label="Region"
              :return-object="false"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="font-weight-medium" cols="5">Living in:</v-col>
      <v-col cols="12" sm="7">
        <v-row>
          <v-col cols="6">
            <v-select
              :items="countries"
              v-model="updatedUserProfile.livingIn.country"
              item-title="countryName"
              item-value="countryName"
              label="Country"
              :return-object="false"
              @update:model-value="updatedUserProfile.livingIn.region = ''"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="livingInRegionList"
              v-model="updatedUserProfile.livingIn.region"
              item-title="name"
              item-value="name"
              label="Region"
              :return-object="false"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <base-button @click="submit">
        Save
        <base-icon>mdi-content-save</base-icon>
      </base-button>
      <base-button customColor="error" @click="cancel">
        Cancel
        <base-icon>mdi-close</base-icon>
      </base-button>
    </v-row>
  </v-container>
</template>

<script>
import countries from "country-region-data/data.json";
import { mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      updatedUserProfile: {
        displayName: { required, maxLength: maxLength(100) },
      },
    };
  },
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      countries,
      updatedUserProfile: {
        displayName: "",
        email: "",
        from: {
          country: "",
          region: "",
        },
        livingIn: {
          country: "",
          region: "",
        },
      },
    };
  },
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.fillInUserProfileInformation();
  },
  methods: {
    ...mapActions({
      createOrUpdateUser: "users/createOrUpdateUser",
    }),
    cancel() {
      this.$emit("cancelEdit");
    },
    async submit() {
      const isValid = await this.v$.$validate();

      if (isValid) {
        try {
          this.createOrUpdateUser({
            id: this.userProfile.id,
            data: {
              ...this.userProfile,
              displayName: this.updatedUserProfile.displayName,
              from: {
                country: this.updatedUserProfile.from.country,
                region: this.updatedUserProfile.from.region,
              },
              livingIn: {
                country: this.updatedUserProfile.livingIn.country,
                region: this.updatedUserProfile.livingIn.region,
              },
            },
          });

          this.$emit("editBasicInformationSuccessful");
        } catch (error) {
          this.errorMessage = "Unknown error occurred. Please try again later!";
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        }
      } else {
        this.errorMessage = "Please fill in all the required information";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },
    fillInUserProfileInformation() {
      this.updatedUserProfile = {
        displayName: this.userProfile.displayName,
        email: this.userProfile.email,
        from: {
          country: this.userProfile.from.country,
          region: this.userProfile.from.region,
        },
        livingIn: {
          country: this.userProfile.livingIn.country,
          region: this.userProfile.livingIn.region,
        },
      };
    },
  },
  computed: {
    fromRegionList() {
      return this?.updatedUserProfile?.from?.country
        ? this.countries.find(
            (country) =>
              country.countryName === this.updatedUserProfile.from.country
          )?.regions || []
        : [];
    },
    livingInRegionList() {
      return this?.updatedUserProfile?.livingIn?.country
        ? this.countries.find(
            (country) =>
              country.countryName === this.updatedUserProfile.livingIn.country
          )?.regions || []
        : [];
    },
    displayNameErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "displayName") {
          if (error.$validator === "required") {
            errors.push("Full name is required");
          } else if (error.$validator === "maxLength") {
            errors.push("Full name cannot be longer than 100 characters");
          }
        }
      });
      return errors;
    },
  },
};
</script>

<style scoped></style>
