<template>
  <v-container id="home-section-3" fluid>
    <v-row class="font-weight-bold py-10 px-10 text-center" justify="center">
      <v-col
        cols="12"
        md="9"
        lg="9"
        xl="9"
        :style="'font-size: ' + titleSize + ';'"
      >
        What you can expect
      </v-col>
    </v-row>
    <v-row class="pb-10" align="center" justify="center">
      <v-col class="pb-10" align="center" cols="12" md="3" lg="3" xl="3">
        <div class="pt-7 feature-card">
          <v-img
            :src="require('@/assets/home/home-feature-01.png')"
            :height="imageHeight"
          ></v-img>
          <p class="text-h5 font-weight-bold mt-7 mx-5">EXPERTS NETWORK</p>
          <p class="text-h6 font-weight-regular px-10">
            Gain access to a wide network of experts from different fields
          </p>
        </div>
      </v-col>
      <v-col class="pb-10" align="center" cols="12" md="3" lg="3" xl="3">
        <div class="pt-7 feature-card">
          <v-img
            :src="require('@/assets/home/home-feature-02.png')"
            :height="imageHeight"
          ></v-img>
          <p class="text-h5 font-weight-bold mt-7 mx-5">DIRECT INSIGHTS</p>
          <p class="text-h6 font-weight-regular px-10">
            Find information directly from people that you admire
          </p>
        </div>
      </v-col>
      <v-col class="pb-10" align="center" cols="12" md="3" lg="3" xl="3">
        <div class="pt-7 feature-card">
          <v-img
            :src="require('@/assets/home/home-feature-03.png')"
            :height="imageHeight"
          ></v-img>
          <p class="text-h5 font-weight-bold mt-7 mx-5">EASY & CONVENIENT</p>
          <p class="text-h6 font-weight-regular px-10">
            Connect with trainers from the comfort of your home
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    imageHeight() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "150px";
        case "sm":
          return "150px";
        case "md":
          return "200px";
        case "lg":
          return "200px";
        case "xl":
          return "220px";
        default:
          return "200px";
      }
    },
    titleSize() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "40px";
        case "sm":
          return "45px";
        case "md":
          return "50px";
        case "lg":
          return "65px";
        case "xl":
          return "75px";
        default:
          return "50px";
      }
    },
  },
};
</script>

<style scoped>
#home-section-3 {
  /* border: 2px solid black; */
  background-color: rgb(var(--v-theme-background2));
  color: rgb(var(--v-theme-textSecondary));
  min-height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.feature-card {
  background-color: rgb(var(--v-theme-background1));
  color: rgb(var(--v-theme-textPrimary));
  border-radius: 40px;
  max-width: 500px;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 80%;
  height: 100%;
}

.row {
  margin: 0px;
}
</style>
