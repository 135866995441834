<template>
  <div>
    <v-container fluid style="min-height: 80vh" class="fill-height">
      <v-row justify="center">
        <v-col cols="12" sm="8" md="6" lg="4" xl="3">
          <password-reset-form></password-reset-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PasswordResetForm from "../../components/auth/passwordresetform.vue";
import { setMetaTags } from "../../utils/utils.js";

export default {
  components: {
    PasswordResetForm,
  },
  created() {
    setMetaTags(
      "NetworkTal - Reset Password",
      "Reset your password to NetworkTal. Find a trainer today!"
    );
  },
};
</script>

<style scoped></style>
