<template>
  <base-card
    v-if="userProfile"
    class="my-3 mx-5 student-card"
    customColor="background"
  >
    <v-row justify="center" align="center" class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" align="center" cols="2">
        <v-avatar size="40" class="mr-2">
          <v-img :src="getPhotoURL"></v-img>
        </v-avatar>
      </v-col>
      <v-col
        :class="$vuetify.display.smAndDown ? 'ma-0 pa-0 pl-3' : 'ma-0 pa-0'"
        cols="5"
      >
        {{ userProfile.displayName }}
      </v-col>
      <v-col
        :align="userButtonsAlign"
        :class="
          $vuetify.display.smAndDown ? 'ma-0 pa-0 py-2' : 'ma-0 pa-0 pr-10'
        "
        cols="8"
        sm="5"
      >
        <base-button
          @click="triggerSendMessageToUser()"
          customHeight="25px"
          customFontSize="10px"
        >
          Send Message
        </base-button>
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  inject: ["eventBus"],
  data() {
    return {
      userProfile: null,
    };
  },
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.getUserProfileDetails();
  },
  computed: {
    ...mapGetters({
      userPhotoDefault: "websiteData/userPhotoDefault",
    }),
    getPhotoURL() {
      if (this.userProfile) {
        if (this.userProfile.photoURL) {
          return this.userProfile.photoURL;
        } else {
          return this.userPhotoDefault;
        }
      } else {
        return this.userPhotoDefault;
      }
    },
    userDataAlign() {
      if (this.$vuetify.display.mdAndUp) {
        return "start";
      } else {
        return "center";
      }
    },
    userButtonsAlign() {
      if (this.$vuetify.display.mdAndUp) {
        return "end";
      } else {
        return "center";
      }
    },
  },
  methods: {
    ...mapActions({
      getUserByUserId: "users/getUserByUserId",
      setMessageTrainerDialog: "trainers/setMessageTrainerDialog",
    }),
    async getUserProfileDetails() {
      this.userProfile = await this.getUserByUserId(this.student.userId);
    },
    triggerSendMessageToUser() {
      this.eventBus.emit("send-message-to-user", {
        userId: this.student.userId,
      });
    },
  },
};
</script>

<style scoped>
.student-card {
  text-decoration: none;
  font-size: 12px;
  font-weight: 450;
}
</style>
