<template>
  <div>
    <v-skeleton-loader
      v-if="!isLoaded"
      ref="skeleton"
      type="list-item-avatar-two-line"
      class="mx-auto"
    ></v-skeleton-loader>
    <Room
      v-for="room in sortedRooms"
      :key="room.id"
      :room="room"
      :active="room.id === roomID"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Room from "./Room";

export default {
  name: "RoomList",
  components: {
    Room,
  },
  data() {
    return {
      isLoaded: false,
      sortedRooms: [],
      latestTimestamps: [],
    };
  },
  props: {
    filterByName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      roomID: "room/activeRoomId",
      userPhotoDefault: "websiteData/userPhotoDefault",
    }),
  },
  created() {
    this.getRoomsAsync();
  },
  methods: {
    ...mapActions({
      getRooms: "room/getRooms",
      getUserByUserId: "users/getUserByUserId",
    }),
    async getRoomsAsync() {
      let roomsResult = await this.getRooms({
        userId: this.userId,
      });
      if (roomsResult) {
        this.sortRooms(roomsResult);
      }
      this.isLoaded = true;
    },
    async sortRooms(rooms) {
      for (var i = 0; i < rooms.length; i++) {
        var room = rooms[i];

        let chatPartnerUserId = room.users.filter(
          (userId) => userId != this.userId
        );
        let chatPartnerProfile = await this.getUserByUserId(
          chatPartnerUserId[0]
        );

        if (chatPartnerProfile) {
          room.name = chatPartnerProfile.displayName;
          if (chatPartnerProfile.photoURL !== "") {
            room.photoUrl = chatPartnerProfile.photoURL;
          } else {
            room.photoUrl = this.userPhotoDefault;
          }
        }

        this.sortedRooms.push(room);
      }
    },
  },
  watch: {
    filterByName(newValue) {
      // filter the sorted rooms after the search input. if the input is empty, show all rooms
      if (newValue) {
        this.sortedRooms = this.sortedRooms.filter((room) => {
          return room.name.toLowerCase().includes(newValue.toLowerCase());
        });
      } else {
        this.sortedRooms = [];
        this.getRoomsAsync();
      }
    },
  },
};
</script>

<style scoped></style>
