<template>
  <div>
    <base-dialog-confirm
      v-if="confirmDialog"
      :displayText="sessionActionConfirmationDisplayText"
      eventName="confirm-status-update-session"
      :payload="sessionActionConfirmationPayload"
      :toolbarText="sessionActionConfirmationToolbarText"
    ></base-dialog-confirm>

    <report-session-dialog
      v-if="reportSessionDialog"
      :session="session"
      initiator="trainer"
    ></report-session-dialog>

    <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
      <v-row justify="center">
        <v-col cols="12" sm="11" md="11" lg="9" xl="7">
          <base-error-success-message
            :errorMessage="errorMessage"
            :successMessage="successMessage"
          ></base-error-success-message>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="7" md="7" lg="6" xl="5">
          <session-overview
            :session="session"
            :trainerProfile="trainerProfile"
          ></session-overview>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="3" xl="2">
          <user-overview
            :trainerProfile="trainerProfile"
            :userProfile="userProfile"
            class="mb-6"
          ></user-overview>
          <session-status :session="session" class="mb-6"></session-status>
          <session-actions
            :session="session"
            :trainerProfile="trainerProfile"
            :userProfile="userProfile"
            class="mb-6"
          ></session-actions>
          <report-session
            v-if="displayReportProblem"
            :session="session"
          ></report-session>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReportSession from "../../../components/trainer/settings/mysessions/session/reportsession.vue";
import ReportSessionDialog from "../../../components/trainer/settings/mysessions/session/reportsessiondialog.vue";
import SessionActions from "../../../components/trainer/settings/mysessions/session/sessionactions.vue";
import SessionOverview from "../../../components/trainer/settings/mysessions/session/sessionoverview.vue";
import SessionStatus from "../../../components/trainer/settings/mysessions/session/sessionstatus.vue";
import UserOverview from "../../../components/trainer/settings/mysessions/session/useroverview.vue";
import { setMetaTags } from "../../../utils/utils.js";
import {
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
} from "@/utils/utils.js";

export default {
  inject: ["eventBus"],
  data() {
    return {
      session: "",
      trainerProfile: "",
      userProfile: "",
      successMessage: "",
      errorMessage: "",
      sessionActionConfirmationPayload: null,
      sessionActionConfirmationDisplayText: "",
      sessionActionConfirmationToolbarText: "",
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    ReportSession,
    ReportSessionDialog,
    SessionActions,
    SessionOverview,
    SessionStatus,
    UserOverview,
  },
  async created() {
    await this.fetchSession();
    await this.fetchTrainerProfile();
    await this.fetchUserProfile();
    this.eventBus.on("confirm-status-update-session", async (payload) => {
      // console.log(
      //     'confirm-status-update-session triggered with payload:',
      //     JSON.stringify(payload)
      // )

      const status =
        payload.data.statusLogs[payload.data.statusLogs.length - 1].status;

      try {
        switch (status) {
          case "accepted":
            // update session status
            await this.updateEvent({
              ...payload,
            });

            await this.sendSessionAcceptedEmailToUser({
              ...payload.data,
            });

            break;
          case "declined":
            // update session status
            await this.updateEvent({
              ...payload,
            }).then(async () => {
              // refund user session amount if not free trial
              if (payload.data.sessionIsFreeTrial !== true) {
                await this.createRefund({
                  paymentIntentId: payload.data.stripe.paymentIntentId,
                });
              }
            });

            await this.sendSessionDeclinedEmailToUser({
              ...payload.data,
            });

            break;
          case "cancelled":
            // update session status
            await this.updateEvent({
              ...payload,
            }).then(async () => {
              // refund user session amount if not free trial
              if (payload.data.sessionIsFreeTrial !== true) {
                await this.createRefund({
                  paymentIntentId: payload.data.stripe.paymentIntentId,
                });
              }
            });

            await this.sendSessionCancelledEmailToUser({
              ...payload.data,
            });
            break;
          default:
            break;
        }

        // Display success message
        this.successMessage = `Session ${status} successfully`;
        setTimeout(() => {
          this.successMessage = "";
        }, 5000);
      } catch (error) {
        this.errorMessage =
          "An unexpected error occurred. Please try again later or contact support.";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }

      await this.fetchSession();
    });
    this.eventBus.on("trigger-accept-session-confirmation", (payload) => {
      this.triggerSessionConfirmationDialog(payload, "accept");
    });
    this.eventBus.on("trigger-decline-session-confirmation", (payload) => {
      this.triggerSessionConfirmationDialog(payload, "decline");
    });
    this.eventBus.on("trigger-cancel-session-confirmation", (payload) => {
      this.triggerSessionConfirmationDialog(payload, "cancel");
    });
    this.eventBus.on("session-reported", () => {
      setTimeout(async () => {
        await this.fetchSession();
        await this.fetchTrainerProfile();
      }, 1000);
    });
    setMetaTags(
      "NetworkTal - Trainer Session Details",
      "View the details of your session, manage the session status, and more."
    );
  },
  unmounted() {
    this.eventBus.off("confirm-status-update-session");
    this.eventBus.off("trigger-accept-session-confirmation");
    this.eventBus.off("trigger-decline-session-confirmation");
    this.eventBus.off("trigger-cancel-session-confirmation");
    this.eventBus.off("session-reported");
  },
  computed: {
    ...mapGetters({
      confirmDialog: "websiteData/confirmDialog",
      currentUser: "users/currentUser",
      reportSessionDialog: "sessions/reportSessionDialog",
      trainerReviewDialog: "reviews/trainerReviewDialog",
    }),
    displayReportProblem() {
      if (this.session) {
        let latestStatus =
          this.session.statusLogs[this.session.statusLogs.length - 1];
        if (latestStatus.status === "accepted") {
          return convertUTCDateTimeToUserTimezoneNoFormat(
            this.session.end,
            this.currentUser.timezone
          ).isBefore(getCurrentTimeInUserTimezone(this.currentUser.timezone));
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      createRefund: "payments/createRefund",
      getSessionById: "sessions/getSessionById",
      getTrainerById: "trainers/getTrainerById",
      getUserByUserId: "users/getUserByUserId",
      sendSessionAcceptedEmailToUser: "email/sendSessionAcceptedEmailToUser",
      sendSessionCancelledEmailToUser: "email/sendSessionCancelledEmailToUser",
      sendSessionDeclinedEmailToUser: "email/sendSessionDeclinedEmailToUser",
      setConfirmDialog: "websiteData/setConfirmDialog",
      updateEvent: "calendar/updateEvent",
    }),
    async fetchSession() {
      try {
        this.session = await this.getSessionById(this.id);
      } catch (error) {
        // redirect to page not found
        this.$router.push({ name: "PageNotFound", params: { pathMatch: "*" } });
      }
    },
    async fetchTrainerProfile() {
      if (this.session) {
        this.trainerProfile = await this.getTrainerById({
          id: this.session.trainerId,
        });
      }
    },
    async fetchUserProfile() {
      if (this.session) {
        this.userProfile = await this.getUserByUserId(this.session.userId);
      }
    },
    triggerSessionConfirmationDialog(payload, statusDisplayText) {
      this.sessionActionConfirmationPayload = {
        id: payload.id,
        data: payload.data,
      };
      this.sessionActionConfirmationDisplayText = `${statusDisplayText} the session with ${
        payload.userDisplayName
      } on ${convertUTCDateTimeToUserTimezoneNoFormat(
        payload.data.start,
        this.currentUser.timezone
      ).format("MMMM D, YYYY [at] HH:mm")}`;
      this.sessionActionConfirmationToolbarText = "Confirmation";

      this.setConfirmDialog(true);
    },
  },
};
</script>

<style scoped></style>
