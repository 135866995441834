<template>
  <div>
    <base-card class="pa-5 pt-2" style="text-align: center">
      <base-loader v-if="!currentUserTrainerProfile"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0" align="center">
          <v-col
            class="ma-0 pa-0 text-body-2 font-weight-medium"
            cols="10"
            align="start"
          >
            <v-icon size="small" class="pr-2">mdi-credit-card</v-icon>
            My Payments
          </v-col>
          <v-col class="ma-0 pa-0" align="right">
            <router-link to="/settings/trainer/mypayments">
              <v-icon size="15" color="grey">mdi-eye</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <router-link to="/settings/trainer/mypayments">
          <v-row class="ma-0 pa-0 py-2">
            <v-col align="center" class="ma-0 pa-0 text-caption">
              <v-badge
                v-if="
                  !isStripeTrainerAccountOnboarded &&
                  verifyStripeAccountOnboardingCompleted
                "
                color="warning"
                icon="mdi-alert-outline"
                location="top end"
              >
                <v-icon size="80" color="primary">mdi-bank-circle</v-icon>
              </v-badge>
              <v-icon v-else size="80" color="primary">mdi-bank-circle</v-icon>
            </v-col>
          </v-row>
        </router-link>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
      isStripeTrainerAccountOnboarded:
        "payments/isStripeTrainerAccountOnboarded",
      verifyStripeAccountOnboardingCompleted:
        "payments/verifyStripeAccountOnboardingCompleted",
    }),
  },
};
</script>

<style scoped></style>
