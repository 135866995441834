<template>
  <div>
    <base-dialog :model="addEventDialog" :customWidth="dialogCustomWidth">
      <v-container
        :class="{
          'pa-5': $vuetify.display.mdAndUp,
          'pa-0': $vuetify.display.smAndDown,
          'ma-0': true,
        }"
        fluid
      >
        <v-stepper
          class="stepper-card"
          v-model="e1"
          elevation="0"
          rounded="10"
          alt-labels
        >
          <v-row
            class="text-h5 font-weight-medium ma-0 pa-2 pl-5"
            align="center"
          >
            <v-col align="start" cols="10" class="ma-0 pa-0"
              >Book a session</v-col
            >
            <v-col align="end" class="ma-0 pa-0">
              <v-btn icon @click="closeDialog" elevation="0">
                <v-icon size="small">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <base-error-success-message
            class="ma-2"
            :errorMessage="errorMessage"
            :successMessage="successMessage"
          ></base-error-success-message>

          <v-stepper-header
            class="elevation-0 mx-5 text-caption ma-0 pa-0"
            style="min-height: 110px"
          >
            <base-tooltip-info
              text="Choose a session type that you want to book with your trainer."
              :custom="true"
            >
              <v-stepper-item
                title="Session Type"
                color="colorSpecial1"
                :complete="e1 > 0"
                value="1"
                class="ma-0 pa-0"
              >
              </v-stepper-item>
            </base-tooltip-info>

            <v-divider class="mx-0"></v-divider>

            <base-tooltip-info
              text="Choose a date and time for your session. You can choose a time slot from your trainer's availability."
              :custom="true"
            >
              <v-stepper-item
                title="Session Date&Time"
                color="colorSpecial1"
                :complete="e1 > 1"
                value="2"
                class="ma-0 pa-0"
              >
              </v-stepper-item>
            </base-tooltip-info>

            <v-divider class="mx-0"></v-divider>

            <base-tooltip-info
              text="Choose a communication tool that you want to use for your session. You can contact trainer via this tool."
              :custom="true"
            >
              <v-stepper-item
                title="Communication Tool"
                color="colorSpecial1"
                :complete="e1 > 2"
                value="3"
                class="ma-0 pa-0"
              >
              </v-stepper-item>
            </base-tooltip-info>

            <v-divider class="mx-0"></v-divider>

            <base-tooltip-info
              text="Check your session details and confirm your booking."
              :custom="true"
            >
              <v-stepper-item
                title="Session Summary"
                color="colorSpecial1"
                value="4"
                class="ma-0 pa-0"
              >
              </v-stepper-item>
            </base-tooltip-info>
          </v-stepper-header>

          <v-stepper-window class="ma-0 pa-0">
            <v-stepper-window-item value="1" class="ma-0 pa-0">
              <session-types
                :sessions="trainer.sessions"
                :selectedSession="selectedSession"
              ></session-types>

              <v-row class="ma-0 pa-0">
                <v-col align="end">
                  <base-button @click="continueToNextStep()">
                    Continue
                  </base-button>
                </v-col>
              </v-row>
            </v-stepper-window-item>

            <v-stepper-window-item value="2">
              <date-time
                :selectedSession="selectedSession"
                :availability="trainerAvailability"
                :trainerId="trainer.id"
              ></date-time>

              <v-row class="mt-10 mb-2">
                <v-col :align="backButtonAlign">
                  <base-button @click="e1 = 0">Back</base-button>
                </v-col>
                <v-col :align="continueButtonAlign">
                  <base-button
                    @click="continueToNextStep()"
                    :disabled="!isSelectedTimeframeValid"
                  >
                    Continue
                  </base-button>
                </v-col>
              </v-row>
            </v-stepper-window-item>

            <v-stepper-window-item value="3">
              <communication-tools
                :communicationTools="getCommunicationTools()"
                :selectedCommunicationTool="selectedCommunicationTool"
              ></communication-tools>

              <v-row class="mt-10 mb-2 ma-0 pa-0">
                <v-col :align="backButtonAlign">
                  <base-button @click="e1 = 1">Back</base-button>
                </v-col>
                <v-col :align="continueButtonAlign">
                  <base-button @click="continueToNextStep()">
                    Continue
                  </base-button>
                </v-col>
              </v-row>
            </v-stepper-window-item>

            <v-stepper-window-item value="4">
              <summary-info
                v-if="
                  selectedSession &&
                  selectedStartTimeUTC &&
                  selectedEndTimeUTC &&
                  selectedCommunicationTool
                "
                :selectedSession="selectedSession"
                :selectedStartTimeUTC="selectedStartTimeUTC"
                :selectedEndTimeUTC="selectedEndTimeUTC"
                :selectedCommunicationTool="selectedCommunicationTool"
              ></summary-info>

              <v-row class="mt-10 mb-2 ma-0 pa-0">
                <v-col class="ma-0 pa-0" :align="backButtonAlign">
                  <base-button @click="e1 = 2">Back</base-button>
                </v-col>
                <v-col class="ma-0 pa-0" :align="continueButtonAlign">
                  <base-button
                    customColor="colorSpecial1"
                    :customLoading="submitButtonLoading"
                    @click="submitEvent()"
                  >
                    {{
                      isSelectedSessionFreeTrial ? "Submit" : "Go to payment"
                    }}
                  </base-button>
                </v-col>
              </v-row>
            </v-stepper-window-item>
          </v-stepper-window>
        </v-stepper>
      </v-container>
    </base-dialog>
  </div>
</template>

<script>
import SessionTypes from "./components/sessiontypes.vue";
import DateTime from "./components/datetime.vue";
import CommunicationTools from "./components/communicationtools.vue";
import SummaryInfo from "./components/summaryinfo.vue";
import { mapGetters, mapActions } from "vuex";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";

export default {
  inject: ["eventBus"],
  setup() {
    const { getTrainerIdFromTrainerProfileFullPath } = useGlobalFunctions();
    return { getTrainerIdFromTrainerProfileFullPath };
  },
  props: {
    trainer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedSession: null,
      selectedStartTimeUTC: null,
      selectedEndTimeUTC: null,
      isSelectedTimeframeValid: false,
      selectedCommunicationTool: null,
      e1: 0,
      errorMessage: null,
      successMessage: null,
      trainerUserProfile: null,
      trainerAvailability: [],
      submitButtonLoading: false,
    };
  },
  components: {
    SessionTypes,
    DateTime,
    CommunicationTools,
    SummaryInfo,
  },
  async created() {
    // set selected session if query parameter is present
    if (this.$route.query.bookSession) {
      this.setAddEventDialog(true);
      this.selectedSession = this.trainer.sessions.find(
        (session) => session.id === this.$route.query.bookSession
      );
    } else {
      this.selectedSession = this.trainer.sessions[0];
    }

    this.selectedCommunicationTool = this.getCommunicationTools()[0];

    // get trainer availability
    this.trainerAvailability = await this.getTrainerAvailability({
      trainerId: this.trainer.id,
    });

    // get trainer user profile
    this.trainerUserProfile = await this.getUserByUserId(this.trainer.userId);

    this.eventBus.on("set-base-dialog", (value) => {
      this.setAddEventDialog(value);
    });

    this.eventBus.on("select-session", (session) => {
      this.selectedSession = session;
    });

    this.eventBus.on("book-session", (session) => {
      this.setAddEventDialog(true);
      this.selectedSession = session;
    });

    this.eventBus.on("select-communication-tool", (communicationTool) => {
      this.selectedCommunicationTool = communicationTool;
    });

    this.eventBus.on("selected-timeframe-updated", (payload) => {
      this.selectedStartTimeUTC = payload.selectedStartTimeUTC;
      this.selectedEndTimeUTC = payload.selectedEndTimeUTC;
      this.isSelectedTimeframeValid = payload.isSelectedTimeframeValid;
    });
  },
  unmounted() {
    this.eventBus.off("set-base-dialog");
  },
  computed: {
    ...mapGetters({
      addEventDialog: "calendar/addEventDialog",
      currentUser: "users/currentUser",
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
      meetingColor: "calendar/meetingColor",
      userId: "auth/userId",
    }),
    isSelectedSessionFreeTrial() {
      return (
        this.selectedSession.isTrial === true &&
        this.selectedSession.price === 0
      );
    },
    dialogCustomWidth() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "100vw";
        case "sm":
          return "100vw";
        case "md":
          return "80vw";
        case "lg":
          return "70vw";
        case "xl":
          return "60vw";
        default:
          return "60vw";
      }
    },
    continueButtonAlign() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "center";
        case "sm":
          return "end";
        case "md":
          return "end";
        case "lg":
          return "end";
        case "xl":
          return "end";
        default:
          return "end";
      }
    },
    backButtonAlign() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "center";
        case "sm":
          return "start";
        case "md":
          return "start";
        case "lg":
          return "start";
        case "xl":
          return "start";
        default:
          return "start";
      }
    },
  },
  methods: {
    ...mapActions({
      createEvent: "calendar/createEvent",
      createPaymentIntent: "payments/createPaymentIntent",
      getTrainerAvailability: "trainerAvailability/getTrainerAvailability",
      getUserByUserId: "users/getUserByUserId",
      setAddEventDialog: "calendar/setAddEventDialog",
    }),
    clearData() {
      this.selectedStartTimeUTC = null;
      this.selectedEndTimeUTC = null;
      this.isSelectedTimeframeValid = false;
      this.errorMessage = null;
      this.successMessage = null;
      this.submitButtonLoading = false;
    },
    closeDialog() {
      this.setAddEventDialog(false);
    },
    continueToNextStep() {
      // check if user is trying to book a session with himself otherwise continue to next step
      if (
        this.currentUserTrainerProfile &&
        this.currentUserTrainerProfile.id ===
          this.getTrainerIdFromTrainerProfileFullPath(this.$route.path)
      ) {
        this.errorMessage = "You can't book a session with yourself.";
        setTimeout(() => {
          this.errorMessage = null;
        }, 5000);
      } else {
        this.e1++;
      }
    },
    getCommunicationTools() {
      return this.trainer.communicationTools.filter(
        (communicationTool) => communicationTool.toolId !== null
      );
    },
    async submitEvent() {
      try {
        // show loading spinner
        this.submitButtonLoading = true;

        const sessionPayloadBase = {
          color: this.meetingColor,
          communicationToolId: this.selectedCommunicationTool.id,
          creationTimestamp: new Date().toISOString(),
          end: this.selectedEndTimeUTC,
          platformURL: process.env.VUE_APP_NETWORKTAL_PLATFORM_URL,
          sessionDescription: this.selectedSession.description,
          sessionId: this.selectedSession.id,
          sessionIsTrial: this.selectedSession.isTrial,
          sessionIsFreeTrial: false,
          sessionTitle: this.selectedSession.title,
          start: this.selectedStartTimeUTC,
          trainerDisplayName: this.trainer.displayName,
          trainerEmail: this.trainer.email,
          trainerId: this.trainer.id,
          trainerPhotoURL: this.trainer.photoURL,
          trainerTimezone: this.trainerUserProfile.timezone,
          trainerStripeAccountId: this.trainer.stripeAccountId,
          trainerUserId: this.trainer.userId,
          userDisplayName: this.currentUser.displayName,
          userEmail: this.currentUser.email,
          userId: this.currentUser.userId,
          userTimezone: this.currentUser.timezone,
        };

        if (!this.isSelectedSessionFreeTrial) {
          // if session is not free trial, create payment intent and redirect to payment page
          await this.createPaymentIntent({
            price: this.selectedSession.price,
            currency: this.currentUser.currency.code.toLowerCase(),
            email: this.currentUser.email,
            productData: {
              ...sessionPayloadBase,
            },
          });

          this.$router.push({
            path: "/trainer/sessionbooking/payment",
          });
        } else {
          // if session is free trial, create event and redirect to session status page
          let event = await this.createEvent({
            ...sessionPayloadBase,
            sessionIsFreeTrial: true,
            statusLogs: [
              {
                timestamp: new Date().toISOString(),
                status: "new",
                initiator: "user",
              },
            ],
          });

          setTimeout(() => {
            this.$router.push(
              event
                ? "/trainer/sessionbooking/sessionstatus?session_id=" + event.id
                : "/settings/user/mysessions"
            );
          }, 500);
        }
      } catch (error) {
        // this.errorMessage = error.message;

        // show error message and hide loading spinner
        this.submitButtonLoading = false;
        setTimeout(() => {
          this.errorMessage = "Something went wrong. Please try again later.";
        }, 5000);
      }
    },
  },
};
</script>

<style scoped>
.stepper-card {
  background-color: rgb(var(--v-theme-card)) !important;
  /* min-height: 80vh; */
}
</style>
