<template>
  <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col
        :class="{
          'pr-5': $vuetify.display.mdAndUp,
          'py-5': $vuetify.display.smAndDown,
          'pa-0': true,
        }"
        cols="12"
        md="10"
        lg="6"
        xl="6"
      >
        <base-card :loading="loading" style="min-height: 300px">
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            My Explorer Sessions
          </p>
          <base-loader v-if="loading"></base-loader>
          <div
            :class="{
              'ma-10': $vuetify.display.mdAndUp,
              'ma-2': $vuetify.display.smAndDown,
            }"
            v-else
          >
            <v-row v-if="isUserEventsNotEmpty">
              <v-col>
                <my-sessions :sessions="sessionsUser" />
              </v-col>
            </v-row>
            <div v-if="isUserEventsEmpty">
              <v-row class="ma-10" style="font-size: 16px">
                <v-col>No upcoming meetings scheduled. Schedule one now!</v-col>
              </v-row>
              <v-row class="ma-10">
                <v-col>
                  <base-button toPath="/settings/user/mytrainers">
                    My trainers
                  </base-button>
                </v-col>
              </v-row>
            </div>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MySessions from "@/components/user/mysessions/mysessions.vue";
import { mapActions, mapGetters } from "vuex";
import { setMetaTags } from "../../../utils/utils.js";

export default {
  inject: ["eventBus"],
  data() {
    return {};
  },
  components: {
    MySessions,
  },
  async created() {
    await this.loadEvents();
    setMetaTags("NetworkTal - My Explorer Sessions", "My Explorer Sessions");
  },
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      sessionsUser: "calendar/sessionsUser",
      currentUser: "users/currentUser",
    }),
    loading() {
      if (this.sessionsUser) {
        return false;
      } else {
        return true;
      }
    },
    isUserEventsEmpty() {
      if (this.sessionsUser) {
        if (this.sessionsUser.length === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isUserEventsNotEmpty() {
      if (this.sessionsUser) {
        if (this.sessionsUser.length !== 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      loadUserEvents: "calendar/loadUserEvents",
    }),
    async loadEvents() {
      if (this.currentUser && this.currentUser.userId) {
        await this.loadUserEvents({
          userId: this.currentUser.userId,
        });
      }
    },
  },
  watch: {
    currentUser() {
      this.loadEvents();
    },
  },
};
</script>

<style scoped></style>
