<template>
  <div style="width: 100%">
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col cols="7" class="text-h5 font-weight-medium pl-5">General</v-col>
      <v-col align="end">
        <base-button v-if="!inEditMode" @click="toggleInEditMode">
          Edit
          <base-icon>mdi-pencil</base-icon>
        </base-button>
      </v-col>
    </v-row>
    <base-card
      :class="{
        'pa-10': $vuetify.display.mdAndUp,
        'pa-2': $vuetify.display.smAndDown,
      }"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <base-error-success-message
        :successMessage="successMessage"
      ></base-error-success-message>
      <settings-data
        v-if="!inEditMode"
        :userProfile="userProfile"
      ></settings-data>
      <edit-settings-data
        v-if="inEditMode"
        @cancelEdit="toggleInEditMode()"
        @editGeneralSettingsSuccessful="editGeneralSettingsSuccessful()"
        :userProfile="userProfile"
      ></edit-settings-data>
    </base-card>
  </div>
</template>

<script>
import settingsData from "./settingsdata.vue";
import editSettingsData from "./editsettingsdata.vue";

export default {
  inject: ["eventBus"],
  data() {
    return {
      inEditMode: false,
      successMessage: "",
    };
  },
  components: {
    settingsData,
    editSettingsData,
  },
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toggleInEditMode() {
      this.inEditMode = !this.inEditMode;
    },
    editGeneralSettingsSuccessful() {
      this.eventBus.emit("general-settings-edited");
      this.toggleInEditMode();
      this.successMessage = "Operation successful!";
      setTimeout(() => {
        this.successMessage = "";
      }, 5000);
    },
  },
};
</script>

<style scoped></style>
