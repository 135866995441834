<template>
  <div class="hidden-sm-and-down">
    <v-btn
      class="text-white mx-1"
      style="height: 45px"
      to="/trainers"
      variant="outlined"
      rounded="xl"
    >
      <v-icon size="small" start>mdi-account-search</v-icon>
      Trainers
    </v-btn>
    <v-btn style="height: 45px" to="/sessions" variant="outlined" rounded="xl">
      <v-icon size="small" start>mdi-clipboard-text-search-outline</v-icon>
      Sessions
    </v-btn>
    <v-btn class="text-white mx-1" style="height: 45px" to="/blog" rounded="xl">
      <v-icon size="small" start>mdi-post-outline</v-icon>
      Blog
    </v-btn>
    <v-btn
      v-if="!isTrainer"
      class="text-white mx-1"
      to="/becometrainer/register"
      variant="text"
      rounded="xl"
      style="height: 45px"
    >
      <v-icon size="small" start>mdi-plus</v-icon>
      Become a trainer
    </v-btn>
    <v-btn
      v-if="isAuthenticated"
      class="text-white mx-1"
      to="/messages"
      variant="text"
      rounded="xl"
      style="height: 45px"
    >
      <template v-slot:prepend>
        <v-badge
          v-if="newMessageNotificationsCount > 0"
          :content="newMessageNotificationsCount"
          :value="newMessageNotificationsCount"
          color="red"
          location="top start"
          floating
        >
        </v-badge>
      </template>
      <v-icon size="small" start>mdi-message-text-outline</v-icon>
      Chat
    </v-btn>
    <v-menu v-if="isTrainer && isAuthenticated" open-on-hover class="offset-y">
      <template v-slot:activator="{ props }">
        <v-btn
          class="text-white mx-1"
          variant="text"
          v-bind="props"
          style="height: 45px"
          rounded="xl"
        >
          <template v-slot:prepend>
            <v-badge
              v-if="sessionUpdateNotificationsCount > 0"
              :content="sessionUpdateNotificationsCount"
              :value="sessionUpdateNotificationsCount"
              color="red"
              location="top start"
              floating
            >
            </v-badge>
            <v-badge
              v-if="
                !isStripeTrainerAccountOnboarded &&
                verifyStripeAccountOnboardingCompleted
              "
              color="warning"
              icon="mdi-exclamation"
              location="top start"
              floating
            >
            </v-badge>
          </template>
          <v-icon start size="small">mdi-account-tie</v-icon>
          Trainer
          <v-icon end>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-btn
            class="mx-2"
            to="/settings/trainer/dashboard"
            variant="text"
            rounded="xl"
          >
            <template v-slot:prepend>
              <v-badge
                v-if="
                  !isStripeTrainerAccountOnboarded &&
                  verifyStripeAccountOnboardingCompleted
                "
                color="warning"
                icon="mdi-exclamation"
                location="top start"
                floating
              >
              </v-badge>
            </template>
            <v-icon size="small" start>mdi-view-dashboard</v-icon>
            Trainer Dashboard
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="mx-2"
            to="/settings/trainer/mysessions"
            variant="text"
            @click="deleteSessionUpdateNotifications"
            rounded="xl"
          >
            <template v-slot:prepend>
              <v-badge
                v-if="sessionUpdateNotificationsCount > 0"
                :content="sessionUpdateNotificationsCount"
                :value="sessionUpdateNotificationsCount"
                color="red"
                location="top start"
                floating
              >
              </v-badge>
              <v-icon size="small">mdi-human-male-board</v-icon>
            </template>
            My Sessions
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="mx-2"
            to="/settings/trainer/profile/information"
            variant="text"
            rounded="xl"
          >
            <v-icon size="small" start>mdi-account-cog</v-icon>
            Trainer Profile Settings
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="mx-2"
            to="/settings/trainer/sessions"
            variant="text"
            rounded="xl"
          >
            <v-icon size="small" start>mdi-clipboard-text</v-icon>
            Session Types Management
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="mx-2"
            to="/settings/trainer/availability"
            variant="text"
            rounded="xl"
          >
            <v-icon size="small" start>mdi-calendar-clock</v-icon>
            Availability Management
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="mx-2"
            :to="trainerProfileURL()"
            variant="text"
            rounded="xl"
          >
            <v-icon size="small" start>mdi-account-tie</v-icon>
            My Trainer Profile
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="mx-2"
            to="/settings/trainer/myexplorers"
            variant="text"
            rounded="xl"
          >
            <v-icon size="small" start>mdi-account-school</v-icon>
            My Explorers
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="mx-2"
            to="/settings/trainer/mypayments"
            variant="text"
            rounded="xl"
          >
            <v-icon size="small" start>mdi-credit-card</v-icon>
            My Payments
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      v-if="isAuthenticated"
      class="user-menu offset-y"
      open-on-hover
      z-index="15"
    >
      <template v-slot:activator="{ props }">
        <v-avatar
          size="40"
          class="mx-3 mt-1 elevation-10"
          v-bind="props"
          elevation-10
          style="border: 2px solid white"
        >
          <v-img :src="getPhotoPath"></v-img>
        </v-avatar>
      </template>
      <v-list>
        <v-card v-if="userProfile" class="user-account-card" elevation="0">
          <p class="mt-5 ml-5 mb-5">
            <router-link to="/settings/user/dashboard">
              <v-avatar size="40" class="mr-5">
                <v-img :src="getPhotoPath"></v-img>
              </v-avatar>
            </router-link>
            <router-link to="/settings/user/dashboard">
              {{ userProfile.displayName }}
            </router-link>
          </p>
        </v-card>
        <v-list-item>
          <v-btn
            class="mx-2"
            to="/settings/user/dashboard"
            variant="text"
            rounded="xl"
          >
            <v-icon size="small" start>mdi-view-dashboard</v-icon>
            Explorer Dashboard
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="mx-2"
            to="/settings/user/profile/basicinformation"
            variant="text"
            rounded="xl"
          >
            <v-icon size="small" start>mdi-account-cog</v-icon>
            Explorer Profile Settings
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="mx-2"
            to="/settings/user/mytrainers"
            variant="text"
            rounded="xl"
          >
            <v-icon size="small" start>mdi-account-tie</v-icon>
            my trainers
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="mx-2"
            to="/settings/user/mysessions"
            variant="text"
            rounded="xl"
          >
            <v-icon size="small" start>mdi-calendar</v-icon>
            my explorer sessions
          </v-btn>
        </v-list-item>
        <v-list-item v-if="!isTrainer">
          <v-btn
            class="mx-2"
            to="/becometrainer/register"
            variant="text"
            rounded="xl"
          >
            <v-icon size="small" start>mdi-plus</v-icon>
            become a trainer
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="mx-2"
            to="/settings/user/general"
            variant="text"
            rounded="xl"
          >
            <v-icon size="small" start>mdi-cog</v-icon>
            settings
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn class="mx-2" @click="logoutUser" variant="text" rounded="xl">
            <v-icon size="small" start>mdi-logout</v-icon>
            logout
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn
      v-if="!isAuthenticated"
      class="text-white mx-1"
      to="/login"
      variant="text"
      style="height: 45px"
      rounded="xl"
    >
      <v-icon size="small" start>mdi-login</v-icon>
      login
    </v-btn>
    <v-btn
      v-if="!isAuthenticated"
      class="text-white mx-1"
      to="/signup"
      variant="text"
      style="height: 45px"
      rounded="xl"
    >
      <v-icon size="small" start>mdi-account-plus</v-icon>
      signup
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { messaging } from "@/firebase-init.js";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";

export default {
  inject: ["eventBus"],
  setup() {
    const { createTrainerProfileDisplayNamePath } = useGlobalFunctions();
    return { createTrainerProfileDisplayNamePath };
  },
  data() {
    return {
      userProfile: null,
      trainerProfile: null,
    };
  },
  created() {
    this.getUserProfileDetails();
    this.eventBus.on("profile-picture-updated", () => {
      this.getUserProfileDetails();
    });
  },
  mounted() {
    // Listen to messages sent from the firebase-messaging-sw.js inside the service worker from onBackgroundMessage function
    const channel = new BroadcastChannel("sw-messages");
    channel.addEventListener("message", () => {
      // Process the received message data
      // console.log('From background in vuejs:' + JSON.stringify(event.data))
      this.getUserNotifications(this.userId);
    });
    // Listen to messages sent from the firebase-messaging-sw.js inside the service worker from onMessage function
    messaging.onMessage(() => {
      // console.log('Message received. ', payload)

      setTimeout(() => {
        this.getUserNotifications(this.userId);
      }, 1000);
    });
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
      isAuthenticated: "auth/isAuthenticated",
      isStripeTrainerAccountOnboarded:
        "payments/isStripeTrainerAccountOnboarded",
      isTrainer: "auth/isTrainer",
      isTrainerRegistrationApproved: "auth/isTrainerRegistrationApproved",
      newMessageNotificationsCount:
        "notifications/newMessageNotificationsCount",
      notifications: "notifications/notifications",
      sessionUpdateNotificationsCount:
        "notifications/sessionUpdateNotificationsCount",
      userId: "auth/userId",
      userPhotoDefault: "websiteData/userPhotoDefault",
      verifyStripeAccountOnboardingCompleted:
        "payments/verifyStripeAccountOnboardingCompleted",
    }),
    getPhotoPath() {
      if (this.userProfile && this.userProfile.photoURL) {
        return this.userProfile.photoURL;
      } else {
        return this.userPhotoDefault;
      }
    },
  },
  methods: {
    ...mapActions({
      deleteNotification: "notifications/deleteNotification",
      getUserByUserId: "users/getUserByUserId",
      getUserNotifications: "notifications/getUserNotifications",
      logout: "auth/logout",
      verifyStripeTrainerAccountOnboarding:
        "payments/verifyStripeTrainerAccountOnboarding",
    }),
    async deleteSessionUpdateNotifications() {
      // console.log('Deleting session update notifications')
      let userNotifications = this.getUserSessionUpdateNotifications();
      if (userNotifications.length > 0) {
        userNotifications.forEach((notification) => {
          this.deleteNotification({
            userId: this.userId,
            notificationId: notification.id,
          });
        });
      }
      this.getUserNotifications(this.userId);
    },
    getUserSessionUpdateNotifications() {
      // filter notifications that have as sender the roomUserId
      return this.notifications.filter(
        (notification) =>
          notification.data.receiverId == this.userId &&
          notification.data.type == "session-update"
      );
    },
    async getUserProfileDetails() {
      this.userProfile = await this.getUserByUserId(this.userId);
    },
    logoutUser() {
      this.logout();
      if (this.$route.name !== "Home") {
        this.$router.replace("/");
      }
    },
    trainerProfileURL() {
      if (this.currentUserTrainerProfile !== null) {
        return (
          "/trainer/" +
          this.createTrainerProfileDisplayNamePath(
            this.currentUserTrainerProfile.displayName
          ) +
          "-" +
          this.currentUserTrainerProfile.id
        );
      } else {
        return "/trainers";
      }
    },
  },
  watch: {
    async userId() {
      if (this.userId !== null) {
        this.getUserProfileDetails();
        this.getUserNotifications(this.userId);
        setTimeout(async () => {
          if (this.isTrainer && !this.isTrainerRegistrationApproved) {
            await this.verifyStripeTrainerAccountOnboarding(this.userId);
          }
        }, 1000);
      }
    },
    userProfile() {
      if (this.userProfile === null) {
        this.getUserProfileDetails();
      }
    },
    isAuthenticated() {
      if (this.isAuthenticated === true) {
        this.getUserProfileDetails();
      }
    },
  },
};
</script>

<style scoped>
.user-account-card {
  border-radius: 10px;
  background-color: rgb(var(--v-theme-background1)) !important;
  color: rgb(var(--v-theme-textPrimary));
  border-radius: 20px !important;
  padding: 1px !important;
  font-size: 11px;
  text-decoration: none;
  margin: 15px;
}

.user-account-card a {
  color: rgb(var(--v-theme-textPrimary));
  text-decoration: none;
}
</style>
