<template>
  <v-row
    class="certification-card text-subtitle-2 ma-0 pa-0 py-3"
    align="center"
    justify="center"
  >
    <v-col cols="3" sm="2" class="ma-0 pa-0">
      <v-row class="ma-0 pa-0">
        <v-col align="center" class="ma-0 pa-0">
          <v-avatar tile size="50" class="ma-2">
            <v-img :src="getPhotoURL"></v-img>
          </v-avatar>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="5" sm="5" class="ma-0 pa-0">
      <p class="font-weight-medium text-subtitle-2 ma-0 pa-0">
        {{ certification.name }}
      </p>
      <p class="text-caption ma-0 pa-0">{{ certification.description }}</p>
    </v-col>
    <v-col cols="4" sm="2" class="certification-card-insights ma-0 pa-0">
      <p><strong>Provider:</strong> {{ certification.provider }}</p>
      <p v-if="certification.number">
        <strong>Number:</strong> {{ certification.number }}
      </p>
      <a v-if="certification.link" target="_blank" :href="certification.link">
        View certification link
      </a>
    </v-col>
    <v-col align="center" cols="12" sm="3" class="ma-0 pa-0">
      <v-icon
        :class="{
          'mx-4': $vuetify.display.mdAndUp,
          'my-5': $vuetify.display.mdAndUp,
          'ma-5': $vuetify.display.mdAndDown,
        }"
        color="darkgray"
        @click="triggerUpdateCertification()"
        size="small"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        :class="{
          'mx-4': $vuetify.display.mdAndUp,
          'my-5': $vuetify.display.mdAndUp,
          'ma-5': $vuetify.display.mdAndDown,
        }"
        color="darkgray"
        @click="triggerRemoveCertificationConfirmation()"
        size="small"
      >
        mdi-delete
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  inject: ["eventBus"],
  props: {
    trainerId: {
      required: true,
    },
    certification: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      certificationPhotoDefault: "websiteData/certificationPhotoDefault",
    }),
    getPhotoURL() {
      return this.certification.photoURL
        ? this.certification.photoURL
        : this.certificationPhotoDefault;
    },
  },
  methods: {
    ...mapActions({
      setAddOrUpdateCertificationDialog:
        "trainers/setAddOrUpdateCertificationDialog",
      removeCertification: "trainers/removeCertification",
    }),
    triggerUpdateCertification() {
      this.eventBus.emit("update-certification", {
        certificationId: this.certification.id,
        certificationName: this.certification.name,
        certificationDescription: this.certification.description,
        certificationPhotoURL: this.certification.photoURL,
        certificationProvider: this.certification.provider,
        certificationNumber: this.certification.number,
        certificationLink: this.certification.link,
      });
      this.setAddOrUpdateCertificationDialog(true);
    },
    // triggerRemoveCertification() {
    //     this.removeCertification({
    //         trainerId: this.trainerId,
    //         id: this.certification.id,
    //     })
    //     this.eventBus.emit('certification-removed')
    // },
    triggerRemoveCertificationConfirmation() {
      this.eventBus.emit("trigger-remove-certification-confirmation", {
        trainerId: this.trainerId,
        id: this.certification.id,
        certificationName: this.certification.name,
      });
    },
  },
};
</script>

<style scoped>
.certification-card {
  background-color: rgb(var(--v-theme-background1));
  color: rgb(var(--v-theme-textPrimary));
  border-radius: 10px;
}
.certification-card-insights {
  color: rgb(var(--v-theme-textQuaternary));
  font-size: 9px;
}

.certification-card-insights a {
  text-decoration: none;
}

.certification-card-insights a:hover {
  text-decoration: underline;
}
</style>
