<template>
  <div>
    <v-container fluid style="min-height: 80vh" class="fill-height">
      <v-row justify="center">
        <v-col cols="12" sm="8" md="6" lg="4" xl="3">
          <verify-email></verify-email>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VerifyEmail from "../../components/auth/verifyemail.vue";
import { setMetaTags } from "../../utils/utils.js";

export default {
  components: {
    VerifyEmail,
  },
  created() {
    setMetaTags(
      "NetworkTal - Verify Email",
      "Verify your email to NetworkTal. Find a trainer today!"
    );
  },
};
</script>

<style scoped></style>
