import dayjs from "dayjs";

// Set Web Page Meta Tags
export function setMetaTags(title, description) {
  document.title = title;
  updateMetaTag('name="description"', description);
  updateMetaTag('property="og:title"', title);
  updateMetaTag('property="og:description"', description);
}

function updateMetaTag(findStr, content) {
  const tag = document.head.querySelector(`[${findStr}]`);
  if (tag) {
    tag.content = content;
  } else {
    const meta = document.createElement("meta");
    const [attr, value] = findStr.split("=");
    meta.setAttribute(attr, value.replace(/"/g, ""));
    meta.content = content;
    document.head.appendChild(meta);
  }
}

// Convert UTC Datetime to User Timezone Datetime
export function convertUserTimezoneDateTimeToUTC(date, timezone) {
  return dayjs.tz(date, timezone).utc().format("YYYY-MM-DD HH:mm");
}

// Convert UTC Datetime to User Timezone Datetime
export function convertUTCDateTimeToUserTimezoneNoFormat(
  datetimeUTC,
  timezone
) {
  return dayjs.utc(datetimeUTC).tz(timezone);
}

// Get Current Date Time in User Timezone
export function getCurrentTimeInUserTimezone(timezone) {
  return dayjs.utc().tz(timezone);
}

// Get UTC value of the timezone
export function getUTCValueOfTimezone(timezone) {
  if (!timezone) {
    return "";
  }

  // Set the timezone
  const now = dayjs.utc().tz(timezone);

  // Get the UTC offset in hours
  const offset = now.utcOffset() / 60;
  const formattedOffset = offset >= 0 ? `UTC+${offset}` : `UTC${offset}`;

  return `(${formattedOffset})`;
}
