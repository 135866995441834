<template>
  <v-container>
    <base-error-success-message
      :errorMessage="errorMessage"
      :successMessage="successMessage"
    ></base-error-success-message>
    <v-row class="general-settings-data">
      <v-col cols="5">Timezone:</v-col>
      <v-col>
        <v-select
          :items="timezones"
          item-title="text"
          v-model="updatedUserProfile.timezone"
          label="Timezone"
          return-object
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="general-settings-data">
      <v-col cols="5">Currency:</v-col>
      <v-col>
        <v-select
          :items="currencies"
          item-title="name"
          v-model="updatedUserProfile.currency"
          label="Currency"
          return-object
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <base-button @click="submit">
        Save
        <base-icon>mdi-content-save</base-icon>
      </base-button>
      <base-button customColor="error" @click="cancel">
        Cancel
        <base-icon>mdi-close</base-icon>
      </base-button>
    </v-row>
  </v-container>
</template>

<script>
import currencies from "../../../../assets/general/currencies.json";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      timezones: [],
      currencies,
      updatedUserProfile: {
        currency: {},
        timezone: "",
      },
    };
  },
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.getTimezones();
    this.fillUserProfileInformation();
  },
  methods: {
    ...mapActions({
      createOrUpdateUser: "users/createOrUpdateUser",
    }),
    fillUserProfileInformation() {
      let timezone = this.userProfile.timezone;
      this.updatedUserProfile.timezone = this.timezones.find(
        (t) => t == timezone
      );
      let currency = this.userProfile.currency;
      this.updatedUserProfile.currency = this.currencies.find(
        (c) => c.name == currency.name
      );
    },
    submit() {
      try {
        let userId = this.userProfile.id;
        let userProfileOriginal = this.userProfile;
        delete userProfileOriginal.id;
        this.createOrUpdateUser({
          id: userId,
          data: {
            ...userProfileOriginal,
            timezone: this.updatedUserProfile.timezone,
            currency: this.updatedUserProfile.currency,
          },
        });
        this.$emit("editGeneralSettingsSuccessful");
      } catch (error) {
        this.errorMessage = "Unknown error occurred. Please try again later!";
        // this.errorMessage = 'An error occurred. Error: ' + error.message
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },
    cancel() {
      this.$emit("cancelEdit");
    },
    getTimezones() {
      for (const timeZone of Intl.supportedValuesOf("timeZone")) {
        this.timezones.push(timeZone);
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.general-settings-data {
  font-size: 17px !important;
}
</style>
