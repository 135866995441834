<template>
  <div>
    <home-section-01></home-section-01>
    <home-section-11></home-section-11>
    <!-- <home-section-10></home-section-10> -->
    <!-- <home-section-09></home-section-09> -->
    <!-- <home-section-02></home-section-02> -->
    <home-section-03></home-section-03>
    <home-section-04></home-section-04>
    <!-- <home-section-05></home-section-05> -->
    <!-- <home-section-06></home-section-06> -->
    <home-section-07></home-section-07>
    <home-section-08></home-section-08>
    <home-section-12></home-section-12>
  </div>
</template>

<script>
import HomeSection01 from "@/components/home/HomeSection01.vue";
// import HomeSection02 from "@/components/home/HomeSection02.vue";
import HomeSection03 from "@/components/home/HomeSection03.vue";
import HomeSection04 from "@/components/home/HomeSection04.vue";
// import HomeSection05 from "@/components/home/HomeSection05.vue";
// import HomeSection06 from "@/components/home/HomeSection06.vue";
import HomeSection07 from "@/components/home/HomeSection07.vue";
import HomeSection08 from "@/components/home/HomeSection08.vue";
// import HomeSection09 from "@/components/home/HomeSection09.vue";
// import HomeSection10 from "@/components/home/HomeSection10.vue";
import HomeSection11 from "@/components/home/HomeSection11.vue";
import HomeSection12 from "@/components/home/HomeSection12.vue";
import { setMetaTags } from "../utils/utils.js";

export default {
  components: {
    HomeSection01,
    // HomeSection02,
    HomeSection03,
    HomeSection04,
    // HomeSection05,
    // HomeSection06,
    HomeSection07,
    HomeSection08,
    // HomeSection09,
    // HomeSection10,
    HomeSection11,
    HomeSection12,
  },
  created() {
    setMetaTags(
      "NetworkTal - Connect with skilled professionals and learn new skills",
      "NetworkTal is a platform that connects skilled professionals with individuals that want to learn new skills through personalized one-to-one meetings. Find a trainer today!"
    );
  },
  mounted() {
    // scroll to section if query parameter is present
    if (this.$route.query.section) {
      const element = document.querySelector("#" + this.$route.query.section);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 1000);
      }
    }
  },
};
</script>
