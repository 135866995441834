<template>
  <v-container id="newsletter" fluid>
    <v-row class="ma-0 pa-0 py-15 text-center text-white" justify="center">
      <v-col class="ma-0 pa-0" cols="11" md="9">
        <div
          :class="{
            'ma-0 pa-0 ma-5 text-h4': $vuetify.display.mdAndUp,
            'ma-0 pa-0 ma-5 text-h5': $vuetify.display.mdAndDown,
          }"
        >
          <v-icon size="small" class="mr-2">mdi-bell-outline</v-icon>
          <strong>Subscribe now</strong> and get your exclusive guide
          <strong
            >Master Your Career - Essential skills and strategies for
            2024</strong
          >!
        </div>
        <v-row class="ma-0 pa-0 mt-5" justify="center">
          <v-col class="ma-0 pa-0" cols="12" sm="8" md="7" lg="4" align="start">
            <base-error-success-message
              :errorMessage="errorMessage"
              :successMessage="successMessage"
            ></base-error-success-message>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" align="center" justify="center">
          <v-col class="ma-0 pa-0" cols="8" sm="6" md="5" lg="3">
            <v-text-field
              class="ma-0 pa-0 pt-5"
              v-model="state.email"
              bg-color="white"
              color="primary"
              :error-messages="v$.email.$errors.map((e) => e.$message)"
              flat
              placeholder="Enter your email"
              required
              rounded="xl"
              variant="solo"
            ></v-text-field
          ></v-col>
          <v-col class="ma-0 pa-0 pl-2" cols="4" sm="2" md="2" lg="1">
            <base-button
              class="ma-0 pa-0"
              customColor="primary"
              customWidth="110"
              customHeight="55"
              @click="submit"
            >
              Submit</base-button
            >
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" align="center" justify="center">
          <v-col class="ma-0 pa-0">
            <div class="text-caption px-1" align="center">
              By subscribing, you acknowledge and agree to our
              <router-link
                to="/terms-and-conditions"
                target="_blank"
                class="text-caption text-decoration-underline text-white"
              >
                Terms and Conditions
              </router-link>
              and our
              <router-link
                to="/privacy-policy"
                target="_blank"
                class="text-caption text-decoration-underline text-white"
              >
                Privacy Policy
              </router-link>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "@/assets/css/global.css";
import { ref, reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, helpers, required } from "@vuelidate/validators";
import db from "../../firebase-init.js";

export default {
  setup() {
    let successMessage = ref("");
    let errorMessage = ref("");
    const state = reactive({
      email: "",
    });

    const rules = {
      email: {
        required: helpers.withMessage("Email address is required", required),
        email: helpers.withMessage("Email address is not valid", email),
      },
    };

    const v$ = useVuelidate(rules, state);

    const submit = () => {
      v$.value.$touch();
      if (!v$.value.$invalid) {
        db.collection("subscribers")
          .add({
            email: state.email,
            date: new Date().toISOString(),
          })
          .then(() => {
            successMessage.value = "You have successfully subscribed!";
            errorMessage.value = "";
            state.email = "";
            v$.value.$reset(); // Reset the validation state
            setTimeout(() => {
              successMessage.value = "";
            }, 5000);
          })
          .catch(() => {
            errorMessage.value = "An error occurred. Please try again later.";
            successMessage.value = "";
            v$.value.$reset(); // Reset the validation state
            setTimeout(() => {
              errorMessage.value = "";
            }, 5000);
          });
      }
    };

    return { state, v$, submit, successMessage, errorMessage };
  },
};
</script>

<style scoped>
#newsletter {
  background-color: rgb(var(--v-theme-background2));
  color: rgb(var(--v-theme-textPrimary));
  /* border: 2px solid black; */
  min-height: 25vh;
  margin: 0px;
  padding: 0px;
}
</style>
