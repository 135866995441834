<template>
  <div>
    <message-trainer-dialog
      v-if="trainerProfile"
      :trainerId="trainerProfile.id"
      :targetUserId="userProfile.userId"
    ></message-trainer-dialog>

    <base-card class="pa-5" style="text-align: center">
      <base-loader v-if="!userProfile"></base-loader>
      <div v-else>
        <v-avatar size="60">
          <v-img :src="getPhotoURL"></v-img>
        </v-avatar>
        <router-link
          :to="
            '/trainer/' +
            createTrainerProfileDisplayNamePath(trainerProfile.displayName) +
            '-' +
            trainerProfile.id
          "
        >
          <p class="text-subtitle-1 font-weight-medium pt-5">
            {{ userProfile.displayName }}
          </p>
        </router-link>
        <p class="text-caption font-weight-regular text-grey">Explorer</p>
        <v-row class="ma-0 pa-0 mt-3" justify="center">
          <v-col :align="messageButtonAlignment" class="ma-0 pa-0">
            <base-button
              customHeight="27px"
              customFontSize="10px"
              customWidth="95px"
              @click="setMessageTrainerDialog(true)"
              >Message</base-button
            ></v-col
          >
        </v-row>
      </div>
    </base-card>
  </div>
</template>

<script>
import MessageTrainerDialog from "@/components/trainer/profile/overview/messagetrainerdialog/messagetrainerdialog.vue";
import { mapActions, mapGetters } from "vuex";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";

export default {
  setup() {
    const { createTrainerProfileDisplayNamePath } = useGlobalFunctions();
    return { createTrainerProfileDisplayNamePath };
  },
  components: {
    MessageTrainerDialog,
  },
  props: {
    userProfile: {
      required: true,
    },
    trainerProfile: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      userPhotoDefault: "websiteData/userPhotoDefault",
    }),
    getPhotoURL() {
      if (this.userProfile) {
        if (this.userProfile.photoURL) {
          return this.userProfile.photoURL;
        } else {
          return this.userPhotoDefault;
        }
      } else {
        return this.userPhotoDefault;
      }
    },
    viewButtonAlignment() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "right";
        case "sm":
          return "center";
        case "md":
          return "center";
        case "lg":
          return "center";
        case "xl":
          return "center";
        default:
          return "center";
      }
    },
    messageButtonAlignment() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "left";
        case "sm":
          return "center";
        case "md":
          return "center";
        case "lg":
          return "center";
        case "xl":
          return "center";
        default:
          return "center";
      }
    },
  },
  methods: {
    ...mapActions({
      setMessageTrainerDialog: "trainers/setMessageTrainerDialog",
    }),
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
</style>
