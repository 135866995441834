<template>
  <v-card
    class="d-flex align-start flex-column"
    flat
    rounded="0"
    color="chatBackground"
  >
    <ChatList />
    <ChatInput />
  </v-card>
</template>

<script>
import ChatInput from "./ChatInput";
import ChatList from "./ChatList";

export default {
  name: "ChatContainer",
  components: {
    ChatInput,
    ChatList,
  },
};
</script>

<style scoped></style>
