<template>
  <div>
    <base-card class="pa-5 pt-2" style="text-align: center">
      <base-loader v-if="!currentUserTrainerProfile"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0" align="center">
          <v-col
            class="ma-0 pa-0 text-body-2 font-weight-medium"
            cols="10"
            align="start"
          >
            <v-icon size="small" class="pr-2">mdi-clipboard-text</v-icon>
            Session Types Overview</v-col
          >
          <v-col class="ma-0 pa-0" align="right">
            <router-link to="/settings/trainer/sessions">
              <v-icon size="15" color="grey">mdi-pencil</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 text-caption py-5" justify="center">
          <v-col align="center" class="ma-0 pa-0" cols="5">
            <v-row class="ma-0 pa-0">
              <v-col align="center" class="ma-0 pa-0 pt-3 pb-1 text-h4">
                {{ getNonTrialSessionTypes().length }}
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col align="center" class="ma-0 pa-0 pb-2">
                Session Types
              </v-col>
            </v-row>
          </v-col>
          <v-col align="center" class="ma-0 pa-0" cols="5">
            <v-row
              v-if="getTrialSession().length === 1"
              class="ma-0 pa-0 text-success"
            >
              <v-col align="center" class="ma-0 pa-0 pt-3 pb-1 text-h4">
                {{ getTrialSession().length === 1 ? "Yes" : "No" }}
              </v-col>
            </v-row>
            <v-row
              v-if="getTrialSession().length === 0"
              class="ma-0 pa-0 text-warning"
            >
              <v-col align="center" class="ma-0 pa-0 pt-3 pb-1 text-h4">
                {{ getTrialSession().length === 1 ? "Yes" : "No" }}
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col align="center" class="ma-0 pa-0 pb-2">
                Trial Session
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
    }),
  },
  methods: {
    getNonTrialSessionTypes() {
      let allSessionTypes = [];
      let nonTrialSessionTypes = [];
      allSessionTypes = this.currentUserTrainerProfile.sessions;
      nonTrialSessionTypes = allSessionTypes.filter(
        (session) => session.isTrial === false
      );

      return nonTrialSessionTypes;
    },
    getTrialSession() {
      let allSessionTypes = [];
      let trialSession = [];
      allSessionTypes = this.currentUserTrainerProfile.sessions;
      trialSession = allSessionTypes.filter(
        (session) => session.isTrial === true
      );

      return trialSession;
    },
  },
};
</script>

<style scoped></style>
