<template>
  <v-container style="min-height: 70vh">
    <v-row class="my-15" justify="center">
      <v-col xs="12" sm="12" md="12" lg="9" xl="6">
        <base-card class="pt-10 pb-15">
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            Contact US
          </p>
          <div
            :class="{
              'mx-15': $vuetify.display.mdAndUp,
              'mx-5': $vuetify.display.smAndDown,
              'text text-body-2': true,
            }"
            style="font-size: 16px"
          >
            <p class="mt-10">
              Have questions or need assistance? Before reaching out, please
              visit our
              <router-link to="/faq" class="text-decoration-underline">
                Frequently Asked Questions
              </router-link>
              page. If you still need help, feel free to contact us.
            </p>
            <div>
              <h2>Email</h2>
              <p>
                <a href="mailto:contact@networktal.com"
                  >contact@networktal.com</a
                >
              </p>
            </div>
            <div>
              <h2>Social Media</h2>
              <ul>
                <li>
                  <a :href="socialMediaProfiles.linkedin" target="_blank"
                    >LinkedIn</a
                  >
                </li>
                <li>
                  <a :href="socialMediaProfiles.x" target="_blank">X</a>
                </li>
                <li>
                  <a :href="socialMediaProfiles.facebook" target="_blank"
                    >Facebook</a
                  >
                </li>
                <li>
                  <a :href="socialMediaProfiles.instagram" target="_blank"
                    >Instagram</a
                  >
                </li>
              </ul>
            </div>

            <p class="mt-10" style="font-weight: 450">
              We look forward to hearing from you!
            </p>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { setMetaTags } from "../../utils/utils.js";

export default {
  computed: {
    ...mapGetters({
      socialMediaProfiles: "websiteData/socialMediaProfiles",
    }),
  },
  created() {
    setMetaTags(
      "NetworkTal - Contact Us",
      "Contact NetworkTal for any questions or concerns"
    );
  },
};
</script>

<style scoped>
.text {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text h2 {
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 20px;
}
.text ul {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

a {
  color: inherit;
  text-decoration: none;
  font-weight: 420;
}
</style>
