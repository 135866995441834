<template>
  <v-container>
    <v-row>
      <v-col class="font-weight-medium" cols="5">Full Name:</v-col>
      <v-col>{{ getTrainerDisplayName }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="5" class="font-weight-medium">Date of birth:</v-col>
      <v-col>{{ getDateOfBirth }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="5" class="font-weight-medium">Gender:</v-col>
      <v-col>{{ getGender }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="5" class="font-weight-medium">From:</v-col>
      <v-col>{{ getFrom }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="5" class="font-weight-medium">Living in:</v-col>
      <v-col>{{ getLivingIn }}</v-col>
    </v-row>
    <v-row>
      <v-col class="font-weight-medium" cols="5">Spoken Languages:</v-col>
      <v-col>
        {{ getTrainerLanguages }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="font-weight-medium" cols="5">About me:</v-col>
      <v-col>{{ getTrainerAboutMe }}</v-col>
    </v-row>
    <v-row>
      <v-col class="font-weight-medium" cols="5">My Approach:</v-col>
      <v-col>{{ getTrainerMyApproach }}</v-col>
    </v-row>
    <v-row>
      <v-col class="font-weight-medium" cols="5">Training Areas:</v-col>
      <v-col>
        <v-chip
          class="ma-1"
          v-for="interestArea in getTrainerInterestAreas"
          :key="interestArea"
          :ripple="false"
        >
          {{ interestArea }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="font-weight-medium" cols="5">Video Presentation:</v-col>
      <v-col cols="7">{{ trainerProfile.videoPresentationURL }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    trainerProfile: {
      required: true,
    },
  },
  computed: {
    getTrainerDisplayName() {
      if (this.isTrainerProfileInformationUpdated()) {
        let trainerProfileInfoUpdateRequest =
          this.getTrainerProfileInfoUpdateRequest();
        return trainerProfileInfoUpdateRequest.displayName;
      } else {
        return this.trainerProfile.displayName;
      }
    },
    getTrainerLanguages() {
      if (this.isTrainerProfileInformationUpdated()) {
        let trainerProfileInfoUpdateRequest =
          this.getTrainerProfileInfoUpdateRequest();
        return this.formatTrainerLanguages(
          trainerProfileInfoUpdateRequest.languages
        );
      } else {
        return this.formatTrainerLanguages(this.trainerProfile.languages);
      }
    },
    getDateOfBirth() {
      if (this.isTrainerProfileInformationUpdated()) {
        let trainerProfileInfoUpdateRequest =
          this.getTrainerProfileInfoUpdateRequest();
        return trainerProfileInfoUpdateRequest.dateOfBirth;
      } else {
        return this.trainerProfile.dateOfBirth;
      }
    },
    getGender() {
      if (this.isTrainerProfileInformationUpdated()) {
        let trainerProfileInfoUpdateRequest =
          this.getTrainerProfileInfoUpdateRequest();
        return trainerProfileInfoUpdateRequest.gender;
      } else {
        return this.trainerProfile.gender;
      }
    },
    getFrom() {
      if (this.isTrainerProfileInformationUpdated()) {
        let trainerProfileInfoUpdateRequest =
          this.getTrainerProfileInfoUpdateRequest();
        return `${trainerProfileInfoUpdateRequest.from.country}, ${trainerProfileInfoUpdateRequest.from.region}`;
      } else {
        return `${this.trainerProfile.from.country}, ${this.trainerProfile.from.region}`;
      }
    },
    getLivingIn() {
      if (this.isTrainerProfileInformationUpdated()) {
        let trainerProfileInfoUpdateRequest =
          this.getTrainerProfileInfoUpdateRequest();
        return `${trainerProfileInfoUpdateRequest.livingIn.country}, ${trainerProfileInfoUpdateRequest.livingIn.region}`;
      } else {
        return `${this.trainerProfile.livingIn.country}, ${this.trainerProfile.livingIn.region}`;
      }
    },
    getTrainerAboutMe() {
      if (this.isTrainerProfileInformationUpdated()) {
        let trainerProfileInfoUpdateRequest =
          this.getTrainerProfileInfoUpdateRequest();
        return trainerProfileInfoUpdateRequest.aboutMe;
      } else {
        return this.trainerProfile.aboutMe;
      }
    },
    getTrainerMyApproach() {
      if (this.isTrainerProfileInformationUpdated()) {
        let trainerProfileInfoUpdateRequest =
          this.getTrainerProfileInfoUpdateRequest();
        return trainerProfileInfoUpdateRequest.myApproach;
      } else {
        return this.trainerProfile.myApproach;
      }
    },
    getTrainerInterestAreas() {
      if (this.isTrainerProfileInformationUpdated()) {
        let trainerProfileInfoUpdateRequest =
          this.getTrainerProfileInfoUpdateRequest();
        return trainerProfileInfoUpdateRequest.areas;
      } else {
        return this.trainerProfile.areas;
      }
    },
  },
  methods: {
    formatTrainerLanguages(trainerLanguages) {
      let languages = "";
      trainerLanguages.forEach((language) => {
        languages += language.name + ", ";
      });
      return languages.slice(0, -2);
    },
    getTrainerProfileInfoUpdateRequest() {
      let trainerProfileInfoUpdateRequest = null;

      this.trainerProfile.trainerProfileUpdateRequests.forEach(
        (updateRequest) => {
          if (
            updateRequest.type === "profile-information" &&
            updateRequest.status === "pending"
          ) {
            trainerProfileInfoUpdateRequest = updateRequest;
          }
        }
      );
      return trainerProfileInfoUpdateRequest;
    },
    isTrainerProfileInformationUpdated() {
      let isTrainerProfileInformationUpdated = false;

      if (
        this.trainerProfile &&
        this.trainerProfile.trainerProfileUpdateRequests &&
        this.trainerProfile.trainerProfileUpdateRequests.length > 0
      ) {
        // check if any trainer profile Update Requests are of type profile-information and status is pending
        this.trainerProfile.trainerProfileUpdateRequests.forEach(
          (updateRequest) => {
            if (
              updateRequest.type === "profile-information" &&
              updateRequest.status === "pending"
            ) {
              isTrainerProfileInformationUpdated = true;
            }
          }
        );
      }

      // If the trainer profile is not approved, do not show the notification
      if (this.trainerProfile.registrationRequestStatus !== "approved") {
        isTrainerProfileInformationUpdated = false;
      }

      return isTrainerProfileInformationUpdated;
    },
  },
};
</script>

<style scoped></style>
