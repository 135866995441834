<template>
  <v-container>
    <base-error-success-message
      :errorMessage="errorMessage"
      :successMessage="successMessage"
    ></base-error-success-message>

    <v-row>
      <v-col class="font-weight-medium" cols="5">Full Name:</v-col>
      <v-col>
        <v-text-field
          v-model="updatedTrainerProfile.displayName"
          :error-messages="displayNameErrors"
          label="Full Name"
          required
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="5" class="font-weight-medium"> Date of birth: </v-col>

      <v-col>
        <v-menu
          v-model="menu"
          class="offset-y"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ props }">
            <v-text-field
              v-model="updatedTrainerProfile.dateOfBirth"
              label="Date of birth"
              placeholder="YYYY-MM-DD"
              readonly
              v-bind="props"
              :error-messages="dateOfBirthErrors"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            show-adjacent-months
            v-model="date"
            @update:modelValue="saveDateOfBirth"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="5" class="font-weight-medium"> Gender </v-col>

      <v-col>
        <v-select
          v-model="updatedTrainerProfile.gender"
          :items="['Not given', 'Male', 'Female']"
          label="Gender"
          :error-messages="genderErrors"
        ></v-select>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="4" class="font-weight-medium"> From </v-col>
      <v-col cols="12" sm="8">
        <v-row>
          <v-col cols="6">
            <v-select
              :items="countries"
              v-model="updatedTrainerProfile.from.country"
              item-title="countryName"
              item-value="countryName"
              label="Country"
              :error-messages="fromCountryErrors"
              :return-object="false"
              @update:model-value="updatedTrainerProfile.from.region = ''"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="fromCountryRegions"
              v-model="updatedTrainerProfile.from.region"
              item-title="name"
              item-value="name"
              label="Region"
              :error-messages="fromRegionErrors"
              :return-object="false"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="4" class="font-weight-medium"> Living In </v-col>

      <v-col cols="12" sm="8">
        <v-row>
          <v-col cols="6">
            <v-select
              :items="countries"
              v-model="updatedTrainerProfile.livingIn.country"
              item-title="countryName"
              item-value="countryName"
              label="Country"
              :error-messages="livingInCountryErrors"
              :return-object="false"
              @update:model-value="updatedTrainerProfile.livingIn.region = ''"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="livingInCountryRegions"
              v-model="updatedTrainerProfile.livingIn.region"
              item-title="name"
              item-value="name"
              label="Region"
              :error-messages="livingInRegionErrors"
              :return-object="false"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="5" class="font-weight-medium"> Spoken languages </v-col>
      <v-col>
        <v-select
          v-model="updatedTrainerProfile.languages"
          :items="filteredLanguages"
          item-title="name"
          label="Languages"
          hint="Languages that you speak"
          multiple
          :error-messages="languagesErrors"
          return-object
        >
          <template v-slot:prepend-item>
            <v-text-field
              class="mx-5"
              v-model="searchLanguageInput"
              placeholder="Search Area"
              @update:modelValue="searchLanguage"
            ></v-text-field>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="font-weight-medium" cols="5">About Me:</v-col>
      <v-col>
        <v-textarea
          v-model="updatedTrainerProfile.aboutMe"
          :error-messages="aboutMeErrors"
          :counter="500"
        >
          <template v-slot:label>
            <div>
              About me:
              <small>(mandatory)</small>
            </div>
          </template>
        </v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="font-weight-medium" cols="5">My approach:</v-col>
      <v-col>
        <v-textarea
          v-model="updatedTrainerProfile.myApproach"
          :error-messages="myApproachErrors"
          :counter="500"
        >
          <template v-slot:label>
            <div>
              My approach:
              <small>(mandatory)</small>
            </div>
          </template>
        </v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="font-weight-medium" cols="5">Training Areas:</v-col>
      <v-col>
        <v-select
          v-model="updatedTrainerProfile.areas"
          :items="trainerAreas"
          :error-messages="areasErrors"
          chips
          closable-chips
          label="Training Areas"
          multiple
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="font-weight-medium" cols="12" sm="5">
        <base-tooltip-info
          :text="videoPresentationURLGuidance"
        ></base-tooltip-info
        >Video Presentation:</v-col
      >
      <v-col cols="12" sm="7">
        <v-text-field
          v-model="updatedTrainerProfile.videoPresentationURL"
          :error-messages="videoPresentationURLErrors"
          prepend-icon="mdi-video"
          label="Presentation Video URL"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="updatedTrainerProfile.videoPresentationURL">
      <v-col cols="12" sm="5">
        <base-tooltip-info
          :text="videoPresentationURLPreviewGuidance"
        ></base-tooltip-info>
        Video presentation preview
      </v-col>
      <v-col cols="12" sm="7">
        <video-card
          class="video-preview"
          :src="updatedTrainerProfile.videoPresentationURL"
        ></video-card>
      </v-col>
    </v-row>

    <v-row>
      <base-button @click="submit">
        Save
        <base-icon>mdi-content-save</base-icon>
      </base-button>
      <base-button customColor="error" @click="cancel">
        Cancel
        <base-icon>mdi-close</base-icon>
      </base-button>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, minLength, url } from "@vuelidate/validators";
import languages from "@/assets/general/languages.json";
import countries from "country-region-data/data.json";

export default {
  inject: ["eventBus"],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      updatedTrainerProfile: {
        displayName: { required, maxLength: maxLength(100) },
        languages: {
          required,
          minLength: minLength(1),
          containsEnglish: (value) =>
            value.some((item) => item.name === "English"),
        },
        dateOfBirth: {
          required,
          min18YearsOld: (value) =>
            new Date(value) < new Date(Date.now() - 18 * 31557600000),
        },
        gender: { required },
        from: {
          country: {
            required,
          },
          region: {
            required,
          },
        },
        livingIn: {
          country: {
            required,
          },
          region: {
            required,
          },
        },
        aboutMe: { required, maxLength: maxLength(500) },
        myApproach: { required, maxLength: maxLength(500) },
        areas: { required, maxLength: maxLength(3), minLength: minLength(1) },
        videoPresentationURL: {
          required,
          url,
          isLinkYoutubeOrVimeo: (value) => {
            const youtubeRegex =
              /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/g;
            const vimeoRegex =
              /^(https?:\/\/)?(www\.vimeo\.com|vimeo\.?com)\/.+$/g;
            return youtubeRegex.test(value) || vimeoRegex.test(value);
          },
        },
      },
    };
  },
  data() {
    return {
      activePicker: null,
      date: null,
      menu: false,
      countries,
      filteredLanguages: [],
      errorMessage: "",
      successMessage: "",
      updatedTrainerProfile: {
        displayName: "",
        languages: [],
        dateOfBirth: "",
        gender: "",
        from: {
          country: "",
          region: "",
        },
        livingIn: {
          country: "",
          region: "",
        },
        aboutMe: "",
        myApproach: "",
        areas: "",
        videoPresentationURL: "",
      },
      languages,
      searchLanguageInput: "",
      videoPresentationURLGuidance: `
            <div class="text-caption"
              <p class="mb-2">The trainer presentation video is a great way to introduce yourself to potential clients and showcase your training style.</p>
              <p class="my-2">Insert your trainer presentation video URL from Youtube or Vimeo. This will appear in your trainer public profile.</p>
              <p class="mb-2">To upload your presentation video to our platform, you can follow these steps using either YouTube or Vimeo.</p>
              <h3>YouTube</h3>
              <ol class="ma-3">
              <li>Create a YouTube account or sign in to your existing account.</li>
              <li>Upload your presentation video.</li>
              <li>Copy the video URL.</li>
              <li>Paste the copied video URL into the designated field on our platform.</li>
              </ol>
              </br>
              <h3>Vimeo</h3>
              <ol class="ma-3">
              <li>Create a Vimeo account or sign in to your existing account.</li>
              <li>Upload your video.</li>
              <li>Copy the video URL.</li>
              <li>Paste the copied video URL into the designated field on our platform.</li>
              </ol>
            </div>`,
      videoPresentationURLPreviewGuidance: `
            <div class="text-caption">
              <p>Make sure your video is visible in the preview.</p>
              <p>If you can't see your video, please check the following:</p>
              <ul class="ma-2">
              <li>Make sure your video is set to public or unlisted.</li>
              <li>Make sure your video is not set to private.</li>
              <li>Make sure your video is not age-restricted.</li>
              <li>Make sure your video is not blocked in any countries.</li>
              </ul>
              </br>
              <p>If you still can't see your video, please contact us at <u><b>support@networktal.com</b></u>.</p>
            </div>`,
    };
  },
  props: {
    trainerProfile: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.fillTrainerProfileInformation();
    this.searchLanguage();
  },
  methods: {
    ...mapActions({
      createOrUpdateTrainer: "trainers/createOrUpdateTrainer",
    }),
    resetSearch() {
      // console.log('reset search')
      this.searchLanguageInput = "";
      this.searchLanguage();
    },
    searchLanguage() {
      this.filteredLanguages = languages.filter((language) =>
        language.name
          .toLowerCase()
          .includes(this.searchLanguageInput.toLowerCase())
      );
    },
    saveDateOfBirth(date) {
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      this.updatedTrainerProfile.dateOfBirth = date.toISOString().split("T")[0];
      this.menu = false;
    },

    fillTrainerProfileInformation() {
      let trainerProfileInformation = "";

      if (
        this.trainerProfile &&
        this.trainerProfile.trainerProfileUpdateRequests &&
        this.trainerProfile.trainerProfileUpdateRequests.length > 0
      ) {
        // check if any trainer profile Update Requests are of type profile-information and status is pending
        this.trainerProfile.trainerProfileUpdateRequests.forEach(
          (updateRequest) => {
            if (
              updateRequest.type === "profile-information" &&
              updateRequest.status === "pending"
            ) {
              trainerProfileInformation = updateRequest;
            }
          }
        );

        // If the trainer profile is not approved, do not show the notification
        if (this.trainerProfile.registrationRequestStatus !== "approved") {
          trainerProfileInformation = "";
        }
      }

      if (!trainerProfileInformation) {
        trainerProfileInformation = this.trainerProfile;
      }

      this.updatedTrainerProfile.displayName =
        trainerProfileInformation.displayName;
      this.updatedTrainerProfile.languages =
        trainerProfileInformation.languages;
      this.updatedTrainerProfile.dateOfBirth =
        trainerProfileInformation.dateOfBirth;
      this.updatedTrainerProfile.gender = trainerProfileInformation.gender;
      this.updatedTrainerProfile.from.country =
        trainerProfileInformation.from.country;
      this.updatedTrainerProfile.from.region =
        trainerProfileInformation.from.region;
      this.updatedTrainerProfile.livingIn.country =
        trainerProfileInformation.livingIn.country;
      this.updatedTrainerProfile.livingIn.region =
        trainerProfileInformation.livingIn.region;
      this.updatedTrainerProfile.aboutMe = trainerProfileInformation.aboutMe;
      this.updatedTrainerProfile.myApproach =
        trainerProfileInformation.myApproach;
      this.updatedTrainerProfile.areas = trainerProfileInformation.areas;
      this.updatedTrainerProfile.videoPresentationURL =
        trainerProfileInformation.videoPresentationURL;
    },
    async submit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        try {
          // If the trainer profile is approved, create a new update request, else update the profile directly
          if (this.trainerProfile.registrationRequestStatus === "approved") {
            let trainerProfile = this.trainerProfile;

            if (!trainerProfile.trainerProfileUpdateRequests) {
              trainerProfile.trainerProfileUpdateRequests = [];
            }

            trainerProfile.trainerProfileUpdateRequested = true;

            trainerProfile.trainerProfileUpdateRequests.push({
              aboutMe: this.updatedTrainerProfile.aboutMe,
              areas: this.updatedTrainerProfile.areas,
              dateOfBirth: this.updatedTrainerProfile.dateOfBirth,
              displayName: this.updatedTrainerProfile.displayName,
              from: this.updatedTrainerProfile.from,
              gender: this.updatedTrainerProfile.gender,
              languages: this.updatedTrainerProfile.languages,
              livingIn: this.updatedTrainerProfile.livingIn,
              myApproach: this.updatedTrainerProfile.myApproach,
              status: "pending",
              timestamp: new Date().toISOString(),
              type: "profile-information",
              trainerProfileUpdated: false,
              videoPresentationURL:
                this.updatedTrainerProfile.videoPresentationURL,
            });

            await this.createOrUpdateTrainer({
              trainerId: this.trainerProfile.id,
              data: {
                ...trainerProfile,
              },
            });
          } else {
            await this.createOrUpdateTrainer({
              trainerId: this.trainerProfile.id,
              data: {
                ...this.trainerProfile,
                areas: this.updatedTrainerProfile.areas,
                aboutMe: this.updatedTrainerProfile.aboutMe,
                dateOfBirth: this.updatedTrainerProfile.dateOfBirth,
                displayName: this.updatedTrainerProfile.displayName,
                from: this.updatedTrainerProfile.from,
                gender: this.updatedTrainerProfile.gender,
                languages: this.updatedTrainerProfile.languages,
                livingIn: this.updatedTrainerProfile.livingIn,
                myApproach: this.updatedTrainerProfile.myApproach,
                videoPresentationURL:
                  this.updatedTrainerProfile.videoPresentationURL,
              },
            });
          }

          this.$emit("editBasicInformationSuccessful");
        } catch (error) {
          this.errorMessage = "Unknown error occurred. Please try again later!";
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        }
      } else {
        this.errorMessage = "Please fill in all the required information";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },
    cancel() {
      this.$emit("cancelEdit");
    },
  },
  computed: {
    ...mapGetters({
      trainerAreas: "trainers/areas",
      isTrainer: "auth/isTrainer",
    }),
    fromCountryRegions() {
      if (this.updatedTrainerProfile.from.country) {
        const country = countries.find(
          (country) =>
            country.countryName === this.updatedTrainerProfile.from.country
        );
        return country.regions;
      }
      return [];
    },
    livingInCountryRegions() {
      if (this.updatedTrainerProfile.livingIn.country) {
        const country = countries.find(
          (country) =>
            country.countryName === this.updatedTrainerProfile.livingIn.country
        );
        return country.regions;
      }
      return [];
    },
    displayNameErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "displayName") {
          if (error.$validator === "required") {
            errors.push("Full name is required");
          } else if (error.$validator === "maxLength") {
            errors.push("Full name cannot be more than 100 characters");
          }
        }
      });
      return errors;
    },
    dateOfBirthErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "dateOfBirth") {
          if (error.$validator === "required") {
            errors.push("Date of birth is required");
          } else if (error.$validator === "min18YearsOld") {
            errors.push("You must be at least 18 years old");
          }
        }
      });
      return errors;
    },
    genderErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "gender") {
          errors.push("Gender is required");
        }
      });
      return errors;
    },
    fromCountryErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$propertyPath === "updatedTrainerProfile.from.country") {
          if (error.$validator === "required") {
            errors.push("Country is required");
          }
        }
      });
      return errors;
    },
    fromRegionErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$propertyPath === "updatedTrainerProfile.from.region") {
          if (error.$validator === "required") {
            errors.push("Region is required");
          }
        }
      });
      return errors;
    },
    livingInCountryErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$propertyPath === "updatedTrainerProfile.livingIn.country") {
          if (error.$validator === "required") {
            errors.push("Country is required");
          }
        }
      });
      return errors;
    },
    livingInRegionErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$propertyPath === "updatedTrainerProfile.livingIn.region") {
          if (error.$validator === "required") {
            errors.push("Region is required");
          }
        }
      });
      return errors;
    },
    languagesErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "languages") {
          if (error.$validator === "required") {
            errors.push("Languages are required");
          } else if (error.$validator === "minLength") {
            errors.push("You must select at least one language");
          } else if (error.$validator === "containsEnglish") {
            errors.push("English is mandatory");
          }
        }
      });
      return errors;
    },
    aboutMeErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "aboutMe") {
          if (error.$validator === "required") {
            errors.push("About me is required");
          } else if (error.$validator === "maxLength") {
            errors.push("About me cannot be more than 500 characters");
          }
        }
      });
      return errors;
    },
    myApproachErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "myApproach") {
          if (error.$validator === "required") {
            errors.push("My approach is required");
          } else if (error.$validator === "maxLength") {
            errors.push("My approach cannot be more than 500 characters");
          }
        }
      });
      return errors;
    },
    areasErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "areas") {
          if (error.$validator === "required") {
            errors.push("Training areas are required");
          } else if (error.$validator === "maxLength") {
            errors.push("You can only select up to 3 training areas");
          } else if (error.$validator === "minLength") {
            errors.push("You must select at least one training area");
          }
        }
      });
      return errors;
    },
    videoPresentationURLErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "videoPresentationURL") {
          if (error.$validator === "required") {
            errors.push("Video presentation URL is required");
          } else if (error.$validator === "url") {
            errors.push("Video presentation URL must be a valid URL");
          } else if (error.$validator === "isLinkYoutubeOrVimeo") {
            errors.push(
              "Video presentation URL must be a valid YouTube or Vimeo link"
            );
          }
        }
      });
      return errors;
    },
  },
  watch: {
    "updatedTrainerProfile.languages": {
      handler() {
        this.searchLanguageInput = "";
        this.searchLanguage();
      },
      deep: true,
    },
    // watch searchLanguageInput for being not empty and stay like that for more than 10 secounds. If so clear it
    searchLanguageInput: {
      handler() {
        if (this.searchLanguageInput !== "") {
          setTimeout(() => {
            if (this.searchLanguageInput !== "") {
              this.resetSearch();
            }
          }, 10000);
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
