<template>
  <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col
        :class="{
          'pr-5': $vuetify.display.mdAndUp,
          'py-5': $vuetify.display.smAndDown,
          'pa-0': true,
        }"
        cols="12"
        md="10"
        lg="6"
        xl="6"
      >
        <base-card class="card-style">
          <div
            v-if="
              !verifyStripeAccountOnboardingCompleted ||
              !currentUserTrainerProfile
            "
          >
            <base-loader></base-loader>
          </div>
          <div v-else>
            <v-row align="center">
              <v-col align="center">
                <v-avatar size="100" color="colorSpecial2" class="mb-10 mt-10">
                  <v-icon size="60" color="white">mdi-account-tie</v-icon>
                </v-avatar>
                <div class="text-h5 mt-5">Registration status:</div>
                <div
                  class="text-h4 mb-10 font-weight-bold text-decoration-underline"
                >
                  {{ getRegistrationRequestStatusText() }}
                </div>
              </v-col>
            </v-row>
            <v-row class="text-body-1" justify="center">
              <v-col cols="11" md="10" lg="9" xl="9">
                <p class="font-weight-medium">Next steps:</p>
                <div
                  v-if="
                    currentUserTrainerProfile.registrationRequestStatus ===
                    'pending'
                  "
                >
                  <ul class="ml-5 mt-5">
                    <li v-if="!isStripeTrainerAccountOnboarded">
                      You must set up your payments account. Go to
                      <router-link
                        style="
                          text-decoration: none;
                          font-weight: bold;
                          color: rgb(var(--v-theme-warning));
                        "
                        to="/settings/trainer/mypayments"
                        >My Payments</router-link
                      >
                      page for completing the setup.
                    </li>
                    <li>
                      We will review your request and get back to you within 7
                      days.
                    </li>
                    <li>
                      You will be notified by email via email when the review is
                      complete.
                    </li>
                    <li>
                      Your trainer profile will be visible to the public once
                      your request is approved.
                    </li>
                  </ul>
                </div>
                <div
                  v-if="
                    currentUserTrainerProfile.registrationRequestStatus ===
                    'more-information-required'
                  "
                >
                  <ul class="ml-5 mt-5">
                    <li>
                      Your account needs to be updated with more information. An
                      email has been sent to you with more details. Please check
                      your email.
                    </li>
                  </ul>
                </div>
                <div
                  v-if="
                    currentUserTrainerProfile.registrationRequestStatus ===
                    'rejected'
                  "
                >
                  <ul class="ml-5 mt-5">
                    <li>
                      Your request has been rejected. An email has been sent to
                      you with more details. Please check your email.
                    </li>
                  </ul>
                </div>
                <div
                  v-if="
                    currentUserTrainerProfile.registrationRequestStatus ===
                    'approved'
                  "
                >
                  <ul class="ml-5 mt-5">
                    <li>
                      Your request has been approved. You can now start managing
                      your trainer profile and acceept bookings.
                    </li>
                  </ul>
                </div>
                <div
                  v-if="
                    currentUserTrainerProfile.registrationRequestStatus ===
                    'on-hold'
                  "
                >
                  <ul class="ml-5 mt-5">
                    <li>
                      Your request is on hold. An email has been sent to you
                      with more details.
                    </li>
                    <li>
                      Please check your email and reply to it for continuing the
                      registration process.
                    </li>
                    <li>
                      If you have any questions, please contact us at
                      <a href="mailto:support@networktal.com"
                        >support@networktal.com
                      </a>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col align="center" class="my-10">
                <base-button
                  customColor="primary"
                  toPath="/settings/trainer/dashboard"
                >
                  Trainer Dashboard
                </base-button>
              </v-col>
            </v-row>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { setMetaTags } from "../../../utils/utils.js";

export default {
  async created() {
    await this.verifyStripeTrainerAccountOnboarding(this.userId);
    setMetaTags(
      "NetworkTal - Trainer Registration Status",
      "Trainer Registration Status"
    );
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
      isStripeTrainerAccountOnboarded:
        "payments/isStripeTrainerAccountOnboarded",
      userId: "auth/userId",
      verifyStripeAccountOnboardingCompleted:
        "payments/verifyStripeAccountOnboardingCompleted",
    }),
    containerWidth() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "100";
        case "sm":
          return "80";
        case "md":
          return "70";
        case "lg":
          return "60";
        case "xl":
          return "60";
        default:
          return "60";
      }
    },
  },
  methods: {
    ...mapActions({
      verifyStripeTrainerAccountOnboarding:
        "payments/verifyStripeTrainerAccountOnboarding",
    }),
    getRegistrationRequestStatusText() {
      switch (this.currentUserTrainerProfile.registrationRequestStatus) {
        case "pending":
          return "PENDING";
        case "more-information-required":
          return "MORE INFORMATION REQUIRED";
        case "rejected":
          return "REJECTED";
        case "approved":
          return "APPROVED";
        case "on-hold":
          return "ON HOLD";
        default:
          return "PENDING";
      }
    },
  },
};
</script>

<style scoped>
.card-style {
  min-height: 50vh;
  /* make all content align-tabs="center" vertifcally */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.row {
  margin: 0px;
}
</style>
