<template>
  <div>
    <v-container fluid style="min-height: 80vh" class="fill-height">
      <v-row justify="center">
        <v-col cols="12" sm="8" md="6" lg="4" xl="3">
          <authform></authform>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import authform from "../../components/auth/authform.vue";
import { setMetaTags } from "../../utils/utils.js";

export default {
  components: {
    authform,
  },
  created() {
    if (this.isAuthenticated) {
      if (!this.$route.query.from) {
        if (this.isTrainer) {
          this.$router.replace("/settings/trainer/dashboard");
        } else {
          this.$router.replace("/settings/user/dashboard");
        }
      } else {
        this.$router.replace(this.$route.query.from);
      }
    }
    setMetaTags(
      "NetworkTal - Login or Sign Up",
      "Login or Sign Up to NetworkTal. Find a trainer today!"
    );
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      isTrainer: "auth/isTrainer",
    }),
  },
};
</script>

<style scoped></style>
