import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/messaging";
import { getPerformance } from "firebase/performance";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import store from "./store/index.js";

const app = firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
});

if (location.hostname === "localhost") {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.VUE_APP_RECAPTCHA_SITE_KEY
  ),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

export default firebase.firestore();
export const messaging = firebase.messaging();
export const firebaseAuth = firebase.auth();
export const perf = getPerformance(app);

// add or update the token in the fcm_tokens collection for a user
function addOrUpdateToken(token, currentUser) {
  const db = firebase.firestore();
  // check if the user exists in the fcm_tokens collection
  db.collection("fcm_tokens")
    .where("userId", "==", currentUser.userId)
    .get()
    .then((querySnapshot) => {
      // if the user exists
      if (!querySnapshot.empty) {
        // check if the token exists in the token list called tokens property
        // console.log('user exists in the fcm_tokens collection')
        var tokenExists = false;
        querySnapshot.forEach((doc) => {
          if (doc.data().tokens.includes(token)) {
            tokenExists = true;
          }
        });

        // if the token does not exist, add it to the tokens list
        if (!tokenExists) {
          // console.log('token does not exist in the fcm_tokens collection')
          let tokens = querySnapshot.docs[0].data().tokens;
          tokens.push(token);

          db.collection("fcm_tokens").doc(querySnapshot.docs[0].id).update({
            tokens: tokens,
            userId: currentUser.userId,
          });
        } else {
          // console.log('token exists in the fcm_tokens collection')
        }
      } else {
        // console.log('user does not exist in the fcm_tokens collection')
        db.collection("fcm_tokens").add({
          tokens: [token],
          userId: currentUser.userId,
        });
      }
    })
    .catch(() => {
      // console.log('Error getting documents: ', error)
    });
}

export function setUpNotifications() {
  const currentUser = store.getters["users/currentUser"];

  // if the user is logged in, request permission to send notifications
  if (currentUser !== null) {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // console.log('Notification permission granted.')
        messaging
          .getToken({
            vapidKey: process.env.VUE_APP_VAPID_KEY,
          })
          .then((currentToken) => {
            if (currentToken) {
              // console.log("current token for client: ", currentToken);
              addOrUpdateToken(currentToken, currentUser);
            } else {
              // console.log(
              //   "No registration token available. Request permission to generate one."
              // );
            }
          })
          .catch(() => {
            // console.log(err);
          });
      } else {
        // console.log('Unable to get permission to notify.')
      }
    });
  }
}

setTimeout(() => {
  setUpNotifications();
}, 3000);
