<template>
  <div>
    <base-dialog :model="addOrUpdateSessionDialog">
      <v-toolbar class="text-white" color="colorSpecial5" elevation="1">
        <v-row align="center">
          <v-col align="start" cols="10" class="pl-8">New Session</v-col>
          <v-col cols="2" align="end">
            <v-btn icon @click="closeDialog" class="text-white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <base-error-success-message
        class="ma-5"
        :errorMessage="errorMessage"
        :successMessage="successMessage"
      ></base-error-success-message>
      <v-container class="px-5">
        <v-form @submit.prevent="submitEvent">
          <v-text-field
            v-model="sessionTitle"
            :error-messages="sessionTitleErrors"
            :counter="100"
            label="Title"
            required
            clearable
          ></v-text-field>
          <v-textarea
            v-model="sessionDescription"
            :error-messages="sessionDescriptionErrors"
            :counter="200"
            label="Add session description..."
            required
            clearable
          ></v-textarea>
          <v-text-field
            label="Price"
            v-model="sessionPrice"
            :error-messages="sessionPriceErrors"
            :prefix="currency.symbol"
            clearable
          ></v-text-field>
          <v-select
            :items="sessionDurationIntervals"
            label="Meeting Duration"
            hint="Duration in minutes"
            :error-messages="sessionDurationErrors"
            v-model="sessionDuration"
          ></v-select>
          <div class="mt-5 mb-3">
            <base-button class="mr-4" @click="submitEvent()">
              Submit
              <base-icon>mdi-content-save</base-icon>
            </base-button>
            <base-button
              customColor="error"
              class="mr-4"
              @click="closeDialog()"
            >
              Close
              <base-icon>mdi-close</base-icon>
            </base-button>
          </div>
        </v-form>
      </v-container>
    </base-dialog>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { mapGetters, mapActions } from "vuex";
import {
  required,
  integer,
  minLength,
  maxLength,
  minValue,
  maxValue,
} from "@vuelidate/validators";

export default {
  inject: ["eventBus"],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      sessionTitle: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(100),
      },
      sessionDescription: {
        required,
        minLength: minLength(50),
        maxLength: maxLength(200),
      },
      sessionPrice: {
        required,
        integer,
        minValue: minValue(5),
        maxValue: maxValue(1000),
      },
      sessionDuration: { required, integer },
    };
  },
  emits: ["new-session-added"],
  props: {
    sessions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sessionId: "",
      sessionTitle: "",
      sessionDescription: "",
      sessionPrice: "",
      errorMessage: "",
      successMessage: "",
      sessionDurationIntervals: [15, 30, 45, 60],
      sessionDuration: null,
    };
  },
  created() {
    this.eventBus.on("trigger-update-session", (session) => {
      this.sessionId = session.id;
      this.sessionTitle = session.title;
      this.sessionDescription = session.description;
      this.sessionPrice = session.price;
      this.sessionDuration = session.duration;
      this.setAddOrUpdateSessionDialog(true);
    });
    this.eventBus.on("set-base-dialog", (value) => {
      this.setAddOrUpdateSessionDialog(value);
    });
  },
  unmounted() {
    this.eventBus.off("set-base-dialog");
  },
  computed: {
    ...mapGetters({
      addOrUpdateSessionDialog: "sessions/addOrUpdateSessionDialog",
      currency: "websiteData/currency",
    }),
    sessionTitleErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "sessionTitle") {
          if (error.$validator === "required") {
            errors.push("Title is required");
          } else if (error.$validator === "minLength") {
            errors.push("Title must be at least 10 characters long");
          } else if (error.$validator === "maxLength") {
            errors.push("Title cannot be longer than 100 characters");
          }
        }
      });
      return errors;
    },
    sessionDescriptionErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "sessionDescription") {
          if (error.$validator === "required") {
            errors.push("Description is required");
          } else if (error.$validator === "minLength") {
            errors.push("Description must be at least 50 characters long");
          } else if (error.$validator === "maxLength") {
            errors.push("Description cannot be longer than 200 characters");
          }
        }
      });
      return errors;
    },
    sessionPriceErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "sessionPrice") {
          if (error.$validator === "required") {
            errors.push("Price is required");
          } else if (error.$validator === "integer") {
            errors.push("Price must be an integer");
          } else if (error.$validator === "minValue") {
            errors.push("Price must be at least 5");
          } else if (error.$validator === "maxValue") {
            errors.push("Price cannot be more than 1000");
          }
        }
      });
      return errors;
    },
    sessionDurationErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "sessionDuration") {
          if (error.$validator === "required") {
            errors.push("Duration is required");
          } else if (error.$validator === "integer") {
            errors.push("Duration must be an integer");
          }
        }
      });
      return errors;
    },
  },
  methods: {
    ...mapActions({
      setAddOrUpdateSessionDialog: "sessions/setAddOrUpdateSessionDialog",
    }),
    async submitEvent() {
      if (!this.sessionId) {
        this.sessionId = this.generateId();
      }

      const isValid = await this.v$.$validate();

      if (isValid) {
        // eslint-disable-next-line vue/no-mutating-props
        this.sessions.push({
          id: this.sessionId,
          title: this.sessionTitle,
          description: this.sessionDescription,
          price: this.sessionPrice,
          duration: this.sessionDuration,
          isTrial: false,
        });

        this.setAddOrUpdateSessionDialog(false);
        this.clearData();
      } else {
        this.errorMessage = "Please fill in all the required fields";
      }

      setTimeout(() => {
        this.errorMessage = "";
      }, 5000);
    },
    generateId() {
      let id = Math.floor(
        Math.random() * Math.floor(Math.random() * Date.now())
      )
        .toString()
        .slice(0, 10);
      return id;
    },
    clearData() {
      this.sessionId = "";
      this.sessionTitle = "";
      this.sessionDescription = "";
      this.sessionPrice = "";
      this.sessionDuration = "";
      this.errorMessage = "";
      this.successMessage = "";
      this.v$.$reset();
    },
    closeDialog() {
      this.clearData();
      this.setAddOrUpdateSessionDialog(false);
    },
  },
};
</script>

<style scoped></style>
