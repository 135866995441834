<template>
  <div>
    <base-card class="pa-5 pt-2" style="text-align: center">
      <base-loader v-if="!currentUser"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0" align="right">
            <router-link to="/settings/user/profile/basicinformation">
              <v-icon size="15" color="grey">mdi-pencil</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <v-avatar size="60">
          <v-img :src="getPhotoURL"></v-img>
        </v-avatar>
        <p class="text-subtitle-1 font-weight-medium pt-5">
          {{ currentUser.displayName }}
        </p>
        <p class="text-caption font-weight-regular text-grey">Explorer</p>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
      userPhotoDefault: "websiteData/userPhotoDefault",
    }),
    getPhotoURL() {
      return this.currentUser.photoURL
        ? this.currentUser.photoURL
        : this.userPhotoDefault;
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
</style>
