<template>
  <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col class="pa-0" cols="12" sm="9" md="8" lg="6" xl="5">
        <base-card style="min-height: 50vh">
          <base-loader
            class="loading-spinner"
            v-if="!paymentIntent"
          ></base-loader>
          <div v-else>
            <payment-success
              v-if="paymentIntent && paymentIntent.status === 'succeeded'"
            ></payment-success>

            <payment-processing
              v-else-if="paymentIntent && paymentIntent.status === 'processing'"
            ></payment-processing>

            <payment-fail
              v-else-if="
                paymentIntent &&
                paymentIntent.status === 'requires_payment_method'
              "
            ></payment-fail>

            <payment-unknown v-else></payment-unknown>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import PaymentSuccess from "./statuses/success.vue";
import PaymentProcessing from "./statuses/processing.vue";
import PaymentFail from "./statuses/fail.vue";
import PaymentUnknown from "./statuses/unknown.vue";
import { setMetaTags } from "../../../../../utils/utils.js";

export default {
  data() {
    return {
      paymentIntent: "",
    };
  },
  components: {
    PaymentSuccess,
    PaymentProcessing,
    PaymentFail,
    PaymentUnknown,
  },
  async mounted() {
    this.paymentIntent = await this.retrievePaymentIntent({
      paymentIntentId: this.$route.query.payment_intent,
    });
  },
  created() {
    setMetaTags(
      "NetworkTal - Payment Status",
      "View the status of your payment for your session booking."
    );
  },
  methods: {
    ...mapActions({
      retrievePaymentIntent: "payments/retrievePaymentIntent",
    }),
  },
};
</script>

<style scoped>
.loading-spinner {
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
