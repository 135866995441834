<template>
  <div>
    <base-dialog :model="reportSessionDialog" customWidth="500px">
      <v-toolbar class="text-white" color="colorSpecial5" elevation="1">
        <v-row align="center" class="ma-0 pa-0">
          <v-col align="start" cols="10">Report a problem</v-col>
          <v-col cols="2" align="end">
            <v-btn icon @click="cancel" class="text-white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <div class="mx-9 ma-0 pa-0">
        <base-error-success-message
          :errorMessage="errorMessage"
          :successMessage="successMessage"
        ></base-error-success-message>
      </div>
      <v-container fluid>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <v-textarea
              class="mx-5"
              clearable
              v-model="message"
              counter="500"
              :error-messages="messageErrors"
            >
              <template v-slot:label>
                <div>Describe the issue in detail</div>
              </template>
            </v-textarea>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 my-1">
          <v-col align="center" class="ma-0 pa-0">
            <base-button class="mx-5" @click="submit()">Submit</base-button>
            <base-button class="mx-5" customColor="grey" @click="cancel()">
              Cancel
            </base-button>
          </v-col>
        </v-row>
      </v-container>
    </base-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";

export default {
  inject: ["eventBus"],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      message: {
        required,
        minLength: minLength(30),
        maxLength: maxLength(500),
      },
    };
  },
  data() {
    return {
      message: "",
      errorMessage: "",
      successMessage: "",
    };
  },
  props: {
    session: {
      required: true,
    },
    initiator: {
      required: true,
    },
  },
  created() {
    this.eventBus.on("set-base-dialog", (payload) => {
      this.setReportSessionDialog(payload);
    });
  },
  unmounted() {
    this.eventBus.off("set-base-dialog");
  },
  computed: {
    ...mapGetters({
      reportSessionDialog: "sessions/reportSessionDialog",
    }),
    messageErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "message") {
          if (error.$validator === "required") {
            errors.push("Explanation message is required");
          } else if (error.$validator === "minLength") {
            errors.push("Explanation message must be at least 30 characters");
          } else if (error.$validator === "maxLength") {
            errors.push("Explanation message must be at most 500 characters");
          }
        }
      });
      return errors;
    },
  },
  methods: {
    ...mapActions({
      sendEmail: "email/sendEmail",
      sendSessionReportedEmailToUser: "email/sendSessionReportedEmailToUser",
      setReportSessionDialog: "sessions/setReportSessionDialog",
      updateEvent: "calendar/updateEvent",
    }),
    async submit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        try {
          // Send email to admin
          await this.sendEmail({
            to: "admin@networktal.com",
            subject:
              "Session dispute reported on session with id " + this.session.id,
            html: `<p>Session dispute reported on session with id ${this.session.id} with message: ${this.message}</p>`,
          });

          // Update session status to disputed
          await this.updateSessionStatus("disputed", { message: this.message });

          // Send session problem reported by trainer notification email to user
          await this.sendSessionReportedEmailToUser(this.session);

          // Emit event to update session page
          this.eventBus.emit("session-reported");

          this.successMessage = "Session problem reported successfully!";
          setTimeout(() => {
            this.successMessage = "";
            this.setReportSessionDialog(false);
            this.clearData();
            this.v$.$reset();
          }, 2000);
        } catch (error) {
          this.errorMessage =
            "An unexpected error occurred. Please try again later or contact support.";
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        }
      }
      return;
    },
    cancel() {
      this.setReportSessionDialog(false);
      this.clearData();
    },
    clearData() {
      this.message = "";
    },
    async updateSessionStatus(status, payload) {
      let updatedSession = JSON.parse(JSON.stringify(this.session));

      updatedSession.statusLogs.push({
        timestamp: new Date().toISOString(),
        status: status,
        initiator: this.initiator,
        disputeReason: payload.message,
      });

      let id = updatedSession.id;

      await this.updateEvent({
        id: id,
        data: updatedSession,
      });
    },
  },
};
</script>

<style scoped></style>
