<template>
  <div>
    <base-dialog :model="addOrUpdateSessionDialog">
      <v-toolbar class="text-white" color="colorSpecial5" elevation="1">
        <v-row align="center" class="text-h5 font-weight-medium ma-0 pa-0">
          <v-col cols="10">Session</v-col>
          <v-col cols="2" align="end">
            <v-btn icon @click="closeDialog()" class="text-white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <base-error-success-message
        class="ma-5"
        :errorMessage="errorMessage"
        :successMessage="successMessage"
      ></base-error-success-message>
      <v-container class="px-5">
        <v-form class="mx-3" @submit.prevent="submitEvent">
          <v-text-field
            v-model="sessionTitle"
            :error-messages="sessionTitleErrors"
            :counter="100"
            label="Title"
            :readonly="isTrialSession"
            :disabled="isTrialSession"
            required
          ></v-text-field>
          <v-textarea
            v-model="sessionDescription"
            :error-messages="sessionDescriptionErrors"
            :counter="200"
            label="Add session description..."
            direction="horizontal"
            required
          ></v-textarea>
          <v-select
            :items="sessionDurationIntervals"
            :error-messages="sessionDurationErrors"
            label="Meeting Duration"
            hint="Duration in minutes"
            v-model="sessionDuration"
          ></v-select>
          <v-text-field
            label="Price"
            v-model="sessionPrice"
            v-if="!freeTrialSession"
            :error-messages="sessionPriceErrors"
            :prefix="currency.symbol"
            clearable
          ></v-text-field>
          <v-checkbox
            v-model="freeTrialSession"
            v-if="isTrialSession"
            label="Free Trial Session?"
            color="primary"
          ></v-checkbox>
          <div class="mb-4">
            <base-button class="mr-4" @click="submitEvent()">
              Submit
              <base-icon>mdi-content-save</base-icon>
            </base-button>
            <base-button customColor="error" @click="closeDialog()">
              Close
              <base-icon>mdi-close</base-icon>
            </base-button>
          </div>
        </v-form>
      </v-container>
    </base-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  requiredIf,
  integer,
  minLength,
  maxLength,
  minValue,
  maxValue,
} from "@vuelidate/validators";

export default {
  inject: ["eventBus"],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      sessionTitle: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(100),
      },
      sessionDescription: {
        required,
        minLength: minLength(50),
        maxLength: maxLength(200),
      },
      sessionPrice: {
        required: requiredIf(function () {
          return !this.freeTrialSession;
        }),
        integer,
        minValue: minValue(this.freeTrialSession ? 0 : 5),
        maxValue: maxValue(1000),
      },
      sessionDuration: { required, integer },
    };
  },
  props: {
    trainerId: {
      required: true,
    },
  },
  data() {
    return {
      sessionId: "",
      sessionTitle: "",
      sessionDescription: "",
      sessionPrice: "",
      errorMessage: "",
      successMessage: "",
      sessionDurationIntervals: [15, 30, 45, 60],
      sessionDuration: null,
      isTrialSession: false,
      freeTrialSession: false,
    };
  },
  created() {
    this.eventBus.on("update-session", (payload) => {
      this.sessionTitle = payload.sessionTitle;
      this.sessionDescription = payload.sessionDescription;
      this.sessionPrice = payload.sessionPrice;
      this.sessionDuration = payload.sessionDuration;
      this.sessionId = payload.sessionId;
      this.isTrialSession = payload.isTrial;
      payload.sessionPrice === 0
        ? (this.freeTrialSession = true)
        : (this.freeTrialSession = false);
    });
    this.eventBus.on("add-trial-session", (payload) => {
      this.sessionTitle = payload.sessionTitle;
      this.sessionId = payload.sessionId;
      this.isTrialSession = true;
    });
    this.eventBus.on("set-base-dialog", (payload) => {
      this.setAddOrUpdateSessionDialog(payload);
    });
  },
  unmounted() {
    this.eventBus.off("set-base-dialog");
  },
  computed: {
    ...mapGetters({
      addOrUpdateSessionDialog: "sessions/addOrUpdateSessionDialog",
      currency: "websiteData/currency",
    }),
    sessionTitleErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "sessionTitle") {
          if (error.$validator === "required") {
            errors.push("Title is required");
          } else if (error.$validator === "minLength") {
            errors.push("Title must be at least 10 characters long");
          } else if (error.$validator === "maxLength") {
            errors.push("Title cannot be longer than 100 characters");
          }
        }
      });
      return errors;
    },
    sessionDescriptionErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "sessionDescription") {
          if (error.$validator === "required") {
            errors.push("Description is required");
          } else if (error.$validator === "minLength") {
            errors.push("Description must be at least 50 characters long");
          } else if (error.$validator === "maxLength") {
            errors.push("Description cannot be longer than 200 characters");
          }
        }
      });
      return errors;
    },
    sessionPriceErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "sessionPrice") {
          if (error.$validator === "required") {
            errors.push("Price is required");
          } else if (error.$validator === "integer") {
            errors.push("Price must be an integer");
          } else if (error.$validator === "minValue") {
            errors.push("Price must be at least 5");
          } else if (error.$validator === "maxValue") {
            errors.push("Price cannot be more than 1000");
          }
        }
      });
      return errors;
    },
    sessionDurationErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "sessionDuration") {
          if (error.$validator === "required") {
            errors.push("Duration is required");
          } else if (error.$validator === "integer") {
            errors.push("Duration must be an integer");
          }
        }
      });
      return errors;
    },
  },
  methods: {
    ...mapActions({
      addOrUpdateSession: "sessions/addOrUpdateSession",
      setAddOrUpdateSessionDialog: "sessions/setAddOrUpdateSessionDialog",
    }),
    async submitEvent() {
      if (!this.sessionId) {
        this.sessionId = this.generateId();
      }

      const isValid = await this.v$.$validate();

      if (isValid) {
        this.addOrUpdateSession({
          trainerId: this.trainerId,
          data: {
            id: this.sessionId,
            title: this.sessionTitle,
            description: this.sessionDescription,
            price: parseInt(this.sessionPrice),
            duration: parseInt(this.sessionDuration),
            isTrial: this.isTrialSession,
          },
        });
        this.setAddOrUpdateSessionDialog(false);
        this.clearData();
        this.eventBus.emit("new-session-added");
        this.v$.$reset();
      } else {
        this.errorMessage = "Please fill in all the required fields";
      }

      setTimeout(() => {
        this.errorMessage = "";
      }, 5000);
    },
    generateId() {
      let id = Math.floor(
        Math.random() * Math.floor(Math.random() * Date.now())
      )
        .toString()
        .slice(0, 10);
      return id;
    },
    clearData() {
      this.sessionId = "";
      this.sessionTitle = "";
      this.sessionDescription = "";
      this.sessionPrice = "";
      this.sessionDuration = "";
      this.errorMessage = "";
      this.successMessage = "";
      this.isTrialSession = false;
      this.freeTrialSession = false;
    },
    closeDialog() {
      this.clearData();
      this.setAddOrUpdateSessionDialog(false);
    },
  },
  watch: {
    freeTrialSession(newValue) {
      // If the user selects the free trial session checkbox, set the price to 0
      if (newValue) {
        this.sessionPrice = 0;
      } else {
        this.sessionPrice = "";
      }
    },
  },
};
</script>

<style scoped></style>
