<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          class="mt-5"
          style="min-height: 70px"
          v-model="message"
          v-on:keyup.enter.exact="submitHandler"
          :append-icon="message ? 'mdi-send' : ''"
          variant="filled"
          clear-icon="mdi-close-circle"
          clearable
          label="Type a message..."
          type="text"
          @click:append="submitHandler()"
          @click:clear="clearMessage"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ChatInput",
  data() {
    return {
      message: "",
    };
  },
  computed: {
    ...mapGetters({
      userId: "auth/userId",
    }),
    icon() {
      return this.icons[this.iconIndex];
    },
  },
  methods: {
    ...mapActions({
      sendMessage: "room/sendMessage",
    }),
    async submitHandler() {
      if (this.message.length > 0) {
        const { message } = this;
        this.sendMessage({
          message: message,
          senderId: this.userId,
        });
      }
      this.clearMessage();
    },
    clearMessage() {
      this.message = "";
    },
  },
};
</script>
