<template>
  <div
    v-if="
      currentUserTrainerProfile &&
      currentUserTrainerProfile.registrationRequestStatus !== 'approved'
    "
  >
    <base-card class="pa-5 pt-2" style="text-align: center">
      <base-loader v-if="!currentUserTrainerProfile"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0" align="center">
          <v-col
            class="ma-0 pa-0 text-body-2 font-weight-medium"
            cols="10"
            align="start"
          >
            <v-icon size="small" class="pr-2">mdi-list-status</v-icon>
            Trainer Registration Status</v-col
          >
          <v-col class="ma-0 pa-0" align="right">
            <router-link to="/becometrainer/register/status">
              <v-icon size="15" color="grey">mdi-eye</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 py-5">
          <v-col
            align="center"
            class="ma-0 pa-0 py-3 text-subtitle-2 text-warning"
          >
            <router-link to="/becometrainer/register/status">
              {{ getRegistrationRequestStatusText() }}
            </router-link>
          </v-col>
        </v-row>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
    }),
  },
  methods: {
    getRegistrationRequestStatusText() {
      switch (this.currentUserTrainerProfile.registrationRequestStatus) {
        case "pending":
          return "PENDING";
        case "more-information-required":
          return "MORE INFORMATION REQUIRED";
        case "rejected":
          return "REJECTED";
        case "approved":
          return "APPROVED";
        case "on-hold":
          return "ON HOLD";
        default:
          return "PENDING";
      }
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
</style>
