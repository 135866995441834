<template>
  <base-card class="pa-5 description-card">
    <base-loader v-if="loading"></base-loader>
    <div v-else>
      <div class="">
        <p class="text-subtitle-1 font-weight-medium px-3">
          <v-icon>mdi-lightbulb-outline</v-icon>
          Skill areas
        </p>
        <v-chip-group class="px-3" column>
          <v-chip v-for="area in trainer.areas" :key="area">
            {{ area }}
          </v-chip>
        </v-chip-group>
      </div>
      <div class="my-2">
        <p class="text-subtitle-1 font-weight-medium px-3">
          <v-icon>mdi-map-marker-outline</v-icon>
          From
        </p>
        <p class="text-body-2 px-3 pt-3">
          {{ trainer.from.country }}, {{ trainer.from.region }}
        </p>
      </div>
      <div class="my-2">
        <p class="text-subtitle-1 font-weight-medium pt-2 px-3">
          <v-icon>mdi-map-marker-star-outline</v-icon>
          Living In
        </p>
        <p class="text-body-2 px-3 pt-3">
          {{ trainer.livingIn.country }}, {{ trainer.livingIn.region }}
        </p>
      </div>
      <div>
        <p class="text-subtitle-1 font-weight-medium pt-2 px-3">
          <v-icon>mdi-web</v-icon>
          Spoken Languages
        </p>
        <p class="text-body-2 px-3 pt-3">{{ formatTrainerLanguages }}</p>
      </div>
      <div class="my-2">
        <p class="text-subtitle-1 font-weight-medium pt-2 px-3">
          <v-icon>mdi-earth</v-icon>
          Timezone
        </p>
        <p class="text-body-2 px-3 pt-3">
          {{ trainerUserState.timezone }} {{ currentUserTimezoneOffset }}
        </p>
      </div>
      <div class="my-2">
        <p class="text-subtitle-1 font-weight-medium pt-2 px-3">
          <v-icon>mdi-account-outline</v-icon>
          About me
        </p>
        <div v-if="readMoreForAboutMe">
          <p class="text-body-2 px-3 pt-2 overflow-y-auto">
            {{ trainerAboutMe }} ...
          </p>
          <p class="readmore" @click="toggleReadMoreAboutMe">Read More</p>
        </div>
        <div v-else>
          <p class="text-body-2 px-3 pt-2 overflow-y-auto">
            {{ trainer.aboutMe }}
          </p>
          <p
            v-if="readMoreForAboutMeToggler"
            @click="toggleReadMoreAboutMe"
            class="readmore"
          >
            Show less
          </p>
        </div>
      </div>
      <div class="my-2">
        <p class="text-subtitle-1 font-weight-medium pt-2 px-3">
          <v-icon>mdi-chess-pawn</v-icon>
          My approach
        </p>
        <div v-if="readMoreForMyApproach">
          <p class="text-body-2 px-3 pt-3 overflow-y-auto">
            {{ trainerMyApproach }} ...
          </p>
          <p class="readmore" @click="toggleReadMoreMyApproach">Read More</p>
        </div>
        <div v-else>
          <p class="text-body-2 px-3 pt-3 overflow-y-auto">
            {{ trainer.myApproach }}
          </p>
          <p
            v-if="readMoreForreadMoreForMyApproachToggler"
            @click="toggleReadMoreMyApproach"
            class="readmore"
          >
            Show less
          </p>
        </div>
      </div>
    </div>
  </base-card>
</template>

<script>
import { mapActions } from "vuex";
import { getUTCValueOfTimezone } from "@/utils/utils.js";

export default {
  data() {
    return {
      longText:
        "Best business trainer Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer aBest business trainer Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer a adasdasasdasBest business trainer Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer aBest business trainer Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer a adasdasasdasBest business trainer Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer aBest business trainer Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer a adasdasasdas",
      readMoreForAboutMe: false,
      readMoreForAboutMeToggler: false,
      readMoreForMyApproach: false,
      readMoreForreadMoreForMyApproachToggler: false,
      trainerAboutMe: "",
      trainerMyApproach: "",
      trainerUserState: "",
    };
  },
  props: {
    trainer: {
      required: true,
    },
  },
  created() {
    if (this.trainer) {
      this.isReadMoreForAboutMeNeeded();
      this.isReadMoreForMyApproachNeeded();
    } else {
      const unwatch = this.$watch("trainer", () => {
        this.isReadMoreForDescriptionNeeded();
        this.isReadMoreForMyApproachNeeded();
        unwatch();
      });
    }
    this.getUserState(this.trainer.userId);
  },
  computed: {
    formatTrainerLanguages() {
      let languages = "";
      this.trainer.languages.forEach((language) => {
        languages += language.name + ", ";
      });
      return languages.slice(0, -2);
    },
    loading() {
      if (
        this.trainer.areas &&
        this.trainer.languages &&
        this.trainer.aboutMe &&
        this.trainer.myApproach &&
        this.trainerUserState.timezone
      ) {
        return false;
      } else {
        return true;
      }
    },
    currentUserTimezoneOffset() {
      return getUTCValueOfTimezone(this.trainerUserState.timezone);
    },
  },
  methods: {
    ...mapActions({
      getUserByUserId: "users/getUserByUserId",
    }),
    async getUserState(userId) {
      this.trainerUserState = await this.getUserByUserId(userId);
    },
    getTrainerAboutMeSliced() {
      this.trainerAboutMe = this.trainer.aboutMe.slice(0, 200);
    },
    getTrainerMyApproachSliced() {
      this.trainerMyApproach = this.trainer.myApproach.slice(0, 200);
    },
    isReadMoreForAboutMeNeeded() {
      if (this.trainer.aboutMe.length > 200) {
        this.getTrainerAboutMeSliced();
        this.readMoreForAboutMe = true;
        return;
      }
      this.readMoreForAboutMe = false;
      return;
    },
    isReadMoreForMyApproachNeeded() {
      if (this.trainer.myApproach.length > 200) {
        this.getTrainerMyApproachSliced();
        this.readMoreForMyApproach = true;
        return;
      }
      this.readMoreForMyApproach = false;
      return;
    },
    toggleReadMoreMyApproach() {
      this.readMoreForreadMoreForMyApproachToggler = true;
      this.readMoreForMyApproach = !this.readMoreForMyApproach;
    },
    toggleReadMoreAboutMe() {
      this.readMoreForAboutMeToggler = true;
      this.readMoreForAboutMe = !this.readMoreForAboutMe;
    },
  },
};
</script>

<style scoped>
.description-card {
  color: rgb(var(--v-theme-textPrimary));
}

.readmore {
  font-size: 16px;
  text-align: right;
}
.readmore:hover {
  color: darkgray;
  cursor: pointer;
}
</style>
