<template>
  <v-container fluid :style="'width:' + containerWidth + '%;'">
    <base-card class="my-5" justify="center">
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0 pa-5" cols="12" md="4" align="center">
          <v-img
            :src="require('@/assets/preview/preview-placeholder.png')"
            width="200px"
            cover
          ></v-img>
        </v-col>
        <v-col cols="12" md="8" class="ma-0 pa-0 pa-10">
          <p class="text-h5 font-weight-medium my-2">
            Trainers, join the movement!
          </p>
          <p class="text-h6 font-weight-regular my-5">
            Trainer registration is now open. Display of talents coming soon!
          </p>
          <v-row class="mt-5">
            <v-col class="text-body-1 font-weight-regular">
              Sign up now if you would like to become a trainer
            </v-col>
          </v-row>
          <v-row>
            <v-col align="end">
              <base-button
                toPath="/becometrainer/register"
                customColor="colorSpecial1"
              >
                Become a trainer
              </base-button>
              <base-button
                toPath="/trainer/131869510"
                customColor="colorSpecial4"
              >
                View demo trainer profile
              </base-button></v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
export default {
  computed: {
    containerWidth() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "100";
        case "sm":
          return "80";
        case "md":
          return "80";
        case "lg":
          return "70";
        case "xl":
          return "70";
        default:
          return "70";
      }
    },
  },
};
</script>

<style scoped></style>
