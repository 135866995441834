<template>
  <div>
    <v-card
      :class="{
        'pa-10': $vuetify.display.mdAndUp,
        'pa-3': $vuetify.display.smAndDown,
        'profile-picture-card': true,
      }"
      elevation="0"
    >
      <base-error-success-message
        :errorMessage="errorMessage"
        :successMessage="successMessage"
      ></base-error-success-message>
      <v-row align="center" class="mb-5">
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="6"
          v-if="profilePictureDownloadURL"
          align="center"
        >
          <v-avatar size="150">
            <img
              :src="profilePictureDownloadURL"
              alt="Profile Picture"
              :style="{ height: imageHeight }"
            />
          </v-avatar>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6" v-else align="center">
          <v-avatar size="150">
            <img
              :src="userPhotoDefault"
              alt="Profile Picture"
              :style="{ height: imageHeight }"
            />
          </v-avatar>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <v-file-input
            class="px-5"
            chips
            clearabale
            :rules="profilePictureFileRules"
            accept="image/png, image/jpeg, image/bmp"
            prepend-icon="mdi-camera"
            label="Profile Picture"
            show-size
            validate-on="input"
            v-model="profilePictureFile"
            @update:modelValue="validateAndUploadPicture($event, userId)"
          ></v-file-input>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";
import { computed, inject, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  inject: ["eventBus"],
  setup() {
    const { isPictureValid, validateAndUploadPicture } = useGlobalFunctions();
    const eventBus = inject("eventBus");
    const store = useStore();
    const profilePictureFile = ref(null);

    const profilePictureFileRules = [
      (value) => {
        return (
          !value ||
          !value.length ||
          value[0].size < 2000000 ||
          "Profile Picture size should be less than 2 MB!"
        );
      },
      (value) => {
        return (
          !value ||
          !value.length ||
          ["image/jpeg", "image/png", "image/bmp"].includes(value[0].type) ||
          "Profile Picture must be an image file in format JPG, PNG or BMP!"
        );
      },
    ];

    let profilePictureDownloadURL = computed(() => {
      return store.getters["data/downloadURL"];
    });

    let userId = computed(() => {
      return store.getters["auth/userId"];
    });

    const userPhotoDefault = computed(() => {
      return store.getters["websiteData/userPhotoDefault"];
    });

    onMounted(async () => {
      eventBus.on("validate-profile-picture", async () => {
        if (profilePictureDownloadURL.value) {
          eventBus.emit("profile-picture-valid");
          return;
        }

        if (isPictureValid(profilePictureFile.value)) {
          eventBus.emit("profile-picture-valid");
        } else {
          eventBus.emit("profile-picture-invalid");
        }
      });
    });

    return {
      userPhotoDefault,
      profilePictureFile,
      profilePictureFileRules,
      profilePictureDownloadURL,
      userId,
      validateAndUploadPicture,
    };
  },
  props: {
    errorMessage: {
      type: String,
      required: false,
    },
    successMessage: {
      type: String,
      required: false,
    },
  },
  computed: {
    imageHeight() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "120px";
        case "sm":
          return "150px";
        case "md":
          return "200px";
        case "lg":
          return "200px";
        case "xl":
          return "200px";
        default:
          return "200px";
      }
    },
  },
};
</script>

<style scoped>
.profile-picture-card {
  background-color: rgb(var(--v-theme-card));
  border: 1px solid rgb(var(--v-theme-background)) !important;
}
</style>
