<template>
  <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col
        :class="{
          'pr-5': $vuetify.display.mdAndUp,
          'py-5': $vuetify.display.smAndDown,
          'pa-0': true,
        }"
        cols="12"
        md="10"
        lg="6"
        xl="6"
      >
        <base-card>
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            Session Types Management
          </p>
          <base-loader v-if="loading"></base-loader>
          <div
            v-else
            :class="{
              'ma-10': $vuetify.display.mdAndUp,
              'ma-2': $vuetify.display.smAndDown,
            }"
          >
            <trainer-sessions
              @fetchTrainerProfile="getTrainerProfileDetails()"
              :trainerProfile="currentUserTrainerProfile"
            ></trainer-sessions>
            <trainer-trial-sessions
              @fetchTrainerProfile="getTrainerProfileDetails()"
              :trainerProfile="currentUserTrainerProfile"
            ></trainer-trial-sessions>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import trainerSessions from "@/components/trainer/settings/sessionsmanagement/sessionsmanagement.vue";
import trainerTrialSessions from "@/components/trainer/settings/sessionsmanagement/trialsessionsmanagement.vue";
import { mapActions, mapGetters } from "vuex";
import { setMetaTags } from "../../../utils/utils.js";

export default {
  inject: ["eventBus"],
  data() {
    return {};
  },
  components: {
    trainerSessions,
    trainerTrialSessions,
  },
  created() {
    this.eventBus.on("new-session-added", () => {
      // console.log('New session added triggered')
      setTimeout(() => {
        this.getTrainerProfileDetails();
      }, 1000);
    });
    this.eventBus.on("session-removed", () => {
      // console.log('Session removed triggered')
      setTimeout(() => {
        this.getTrainerProfileDetails();
      }, 1000);
    });
    setMetaTags(
      "NetworkTal - Session Types Management",
      "Session Types Management"
    );
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
      isTrainer: "auth/isTrainer",
      userId: "auth/userId",
    }),
    loading() {
      if (this.currentUserTrainerProfile) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions({
      getTrainerByUserId: "trainers/getTrainerByUserId",
    }),
    async getTrainerProfileDetails() {
      await this.getTrainerByUserId(this.userId);
    },
  },
};
</script>

<style scoped></style>
