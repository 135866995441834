<template>
  <div>
    <base-card class="pa-5" style="text-align: center">
      <base-loader v-if="!trainerProfile"></base-loader>
      <div v-else>
        <div v-if="allowAcceptDecline()">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-1">
              <base-button
                customColor="colorSpecial1"
                @click="triggerAcceptSession()"
                customHeight="35px"
                customFontSize="10px"
                customWidth="160px"
              >
                <v-icon size="small" start>mdi-check-circle-outline</v-icon>
                Accept
              </base-button>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-1">
              <base-button
                customColor="primary"
                @click="triggerDeclineSession()"
                customHeight="35px"
                customFontSize="10px"
                customWidth="160px"
              >
                <v-icon size="small" start>mdi-close-circle-outline</v-icon>
                Decline
              </base-button>
            </v-col>
          </v-row>
        </div>
        <v-row v-else class="ma-0 pa-0">
          <v-col class="ma-0 pa-1">
            <base-tooltip-info
              text="You can cancel the session up to 24 hours before the session start time."
              :custom="true"
              :disabled="allowCancel()"
            >
              <base-button
                :disabled="!allowCancel()"
                customColor="primary"
                @click="triggerCancelSession()"
                customHeight="35px"
                customFontSize="10px"
                customWidth="160px"
              >
                <v-icon size="small" start>mdi-close-circle-outline</v-icon>
                Cancel
              </base-button>
            </base-tooltip-info>
          </v-col>
        </v-row>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
} from "@/utils/utils.js";

export default {
  inject: ["eventBus", "dayjs"],
  props: {
    session: {
      required: true,
    },
    trainerProfile: {
      required: true,
    },
    userProfile: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
    }),
  },
  methods: {
    allowAcceptDecline() {
      let allowAcceptDecline = false;
      let latestStatus =
        this.session.statusLogs[this.session.statusLogs.length - 1];

      // Allow accept/decline if the session is new and the session start time is in the future
      if (
        latestStatus.status === "new" &&
        convertUTCDateTimeToUserTimezoneNoFormat(
          this.session.start,
          this.currentUser.timezone
        ).isAfter(getCurrentTimeInUserTimezone(this.currentUser.timezone))
      ) {
        allowAcceptDecline = true;
      }

      return allowAcceptDecline;
    },
    allowCancel() {
      let latestStatus =
        this.session.statusLogs[this.session.statusLogs.length - 1];

      // Allow cancel if the session is accepted and the session start time is more than 24 hours in the future
      if (latestStatus.status === "accepted") {
        let sessionStartTimeMinus24Hours = this.dayjs(
          convertUTCDateTimeToUserTimezoneNoFormat(
            this.session.start,
            this.currentUser.timezone
          )
        ).subtract(24, "hours");

        return sessionStartTimeMinus24Hours.isAfter(
          getCurrentTimeInUserTimezone(this.currentUser.timezone)
        );
      } else {
        return false;
      }
    },
    triggerSesionStatusUpdate(status, eventName) {
      let updatedSession = JSON.parse(JSON.stringify(this.session));

      updatedSession.statusLogs.push({
        timestamp: new Date().toISOString(),
        status: status,
        initiator: "trainer",
      });

      let id = updatedSession.id;

      this.eventBus.emit("trigger-" + eventName + "-session-confirmation", {
        id: id,
        data: updatedSession,
        userDisplayName: this.userProfile.displayName,
      });
    },
    triggerAcceptSession() {
      this.triggerSesionStatusUpdate("accepted", "accept");
    },
    triggerDeclineSession() {
      this.triggerSesionStatusUpdate("declined", "decline");
    },
    triggerCancelSession() {
      this.triggerSesionStatusUpdate("cancelled", "cancel");
    },
  },
};
</script>

<style scoped></style>
