<template>
  <div class="text-subtitle-1">
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col cols="7" class="text-h5 font-weight-medium pl-5"
        >Communication Tools</v-col
      >
      <v-col align="end">
        <base-button v-if="!inEditMode" @click="toggleInEditMode">
          Edit
          <base-icon>mdi-pencil</base-icon>
        </base-button>
      </v-col>
    </v-row>
    <base-card
      outlined
      :class="{
        'px-10 py-5': $vuetify.display.mdAndUp,
        'pa-2': $vuetify.display.smAndDown,
      }"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <base-error-success-message
        :successMessage="successMessage"
      ></base-error-success-message>
      <communication-tools-settings-data
        v-if="!inEditMode && trainerProfile"
        :trainerProfile="trainerProfile"
      ></communication-tools-settings-data>
      <edit-communication-tools-settings-data
        v-if="inEditMode"
        @cancelEdit="editCommunicationToolsCancel()"
        @editCommunicationToolsSuccessful="editCommunicationToolsSuccessful()"
        :trainerProfile="trainerProfile"
      ></edit-communication-tools-settings-data>
    </base-card>
  </div>
</template>

<script>
import communicationToolsSettingsData from "./communicationtoolssettingsdata.vue";
import editCommunicationToolsSettingsData from "./editcommunicationtoolssettingsdata.vue";

export default {
  data() {
    return {
      inEditMode: false,
      successMessage: "",
    };
  },
  components: {
    communicationToolsSettingsData,
    editCommunicationToolsSettingsData,
  },
  computed: {
    paddingComponentsClass() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "pa-0";
        case "sm":
          return "pa-5";
        case "md":
          return "pa-10";
        case "lg":
          return "pa-10";
        case "xl":
          return "pa-10";
        default:
          return "pa-10";
      }
    },
  },
  props: {
    trainerProfile: {
      required: true,
    },
  },
  methods: {
    editCommunicationToolsCancel() {
      this.$emit("fetchTrainerProfile");
      this.toggleInEditMode();
    },
    editCommunicationToolsSuccessful() {
      this.$emit("fetchTrainerProfile");
      this.toggleInEditMode();
      this.successMessage = "Operation successful!";
      setTimeout(() => {
        this.successMessage = "";
      }, 5000);
    },
    toggleInEditMode() {
      this.inEditMode = !this.inEditMode;
    },
  },
};
</script>

<style scoped></style>
