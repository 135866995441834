<template>
  <v-row
    class="ma-2 session-card text-body-2 ma-0 pa-0 pa-3"
    align="center"
    justify="space-around"
  >
    <v-col cols="8" sm="6" class="ma-0 pa-0">
      <p class="text-body-2 font-weight-medium pa-2">
        {{ session.title }}
      </p>
      <p class="pr-4 pl-2 text-caption">
        {{ session.description }}
      </p>
    </v-col>
    <v-col cols="4" sm="3" class="ma-0 pa-0">
      <p class="ma-0 mb-2 pa-0">
        <v-icon size="small">mdi-cash</v-icon>
        {{
          session.price === 0 ? "Free" : session.price + " " + currency.symbol
        }}
      </p>
      <p class="ma-0 mt-2 pa-0">
        <v-icon size="small">mdi-clock-outline</v-icon>
        {{ session.duration }} min
      </p>
    </v-col>
    <v-col align="center" cols="12" sm="3" class="ma-0 pa-0">
      <v-icon
        :class="{
          'mx-5': $vuetify.display.mdAndUp,
          'my-5': $vuetify.display.mdAndUp,
          'ma-5': $vuetify.display.mdAndDown,
        }"
        color="darkgray"
        @click="triggerUpdateSession()"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        :class="{
          'mx-5': $vuetify.display.mdAndUp,
          'my-5': $vuetify.display.mdAndUp,
          'ma-5': $vuetify.display.mdAndDown,
        }"
        color="darkgray"
        @click="triggerRemoveSessionConfirmation()"
      >
        mdi-delete
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  inject: ["eventBus"],
  props: {
    session: {
      type: Object,
      required: true,
    },
    trainerId: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currency: "websiteData/currency",
    }),
  },
  methods: {
    ...mapActions({
      setAddOrUpdateSessionDialog: "sessions/setAddOrUpdateSessionDialog",
      removeSession: "sessions/removeSession",
    }),
    triggerUpdateSession() {
      this.eventBus.emit("update-session", {
        sessionId: this.session.id,
        sessionTitle: this.session.title,
        sessionDescription: this.session.description,
        sessionPrice: this.session.price,
        sessionDuration: this.session.duration,
        isTrial: this.session.isTrial,
      });
      this.setAddOrUpdateSessionDialog(true);
    },
    triggerRemoveSessionConfirmation() {
      this.eventBus.emit("trigger-remove-session-confirmation", {
        sessionId: this.session.id,
        trainerId: this.trainerId,
        sessionTitle: this.session.title,
        isTrial: this.session.isTrial,
      });
    },
  },
};
</script>

<style scoped>
.session-card {
  background-color: rgb(var(--v-theme-background1));
  color: rgb(var(--v-theme-textPrimary));
  border-radius: 10px;
  min-width: 80%;
}
</style>
