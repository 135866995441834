<template>
  <div>
    <v-card class="verify-email-card" elevation="0">
      <p class="verify-email-card-title">Verify your email</p>
      <!-- <p>{{ fbUP }}</p> -->
      <base-error-success-message
        :errorMessage="errorMessage"
        :successMessage="successMessage"
      ></base-error-success-message>
      <form v-if="!verificationEmailSent">
        <p>
          We have sent you an email with a link to verify your email address.
          Please click on the link in the email to verify your email address.
        </p>
        <base-button class="my-5" @click="submitForm">Resend</base-button>
      </form>
      <div v-else>
        <v-alert type="success">Verification email sent successfully!</v-alert>
        <p class="my-5">Didn`t you receive the email?</p>
        <ul class="ma-5">
          <li>Check your spam folder</li>
          <li @click="resetValues">
            <router-link to="/verifyemail">Verify Email</router-link>
            again
          </li>
        </ul>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    errorMessage: "",
    successMessage: "",
    verificationEmailSent: false,
  }),

  methods: {
    ...mapActions({
      verifyEmail: "auth/verifyemail",
      fbUP: "auth/firebaseUserProfile",
    }),
    async submitForm() {
      try {
        await this.verifyEmail({
          continueUrl: process.env.VUE_APP_NETWORKTAL_PLATFORM_URL + "/",
        });
        this.verificationEmailSent = true;
      } catch (errorMessage) {
        switch (errorMessage.code) {
          case "auth/invalid-email":
            this.errorMessage = "Invalid email";
            break;
          case "auth/missing-email":
            this.errorMessage = "Email address is missing";
            break;
          case "auth/user-not-found":
            this.errorMessage = "No account with that email was found";
            break;
          default:
            this.errorMessage = "Unknown error. Something went wrong";
            break;
        }
      }
    },
    resetValues() {
      this.verificationEmailSent = false;
    },
  },
};
</script>

<style scoped>
.verify-email-card {
  background-color: rgb(var(--v-theme-authBackground));
  color: rgb(var(--v-theme-textPrimary));
  border-radius: 20px;
  padding: 50px;
  margin: 0 auto;
  font-size: 17px;
}

@media (max-width: 600px) {
  .verify-email-card {
    padding: 30px;
  }
}

.verify-email-card-title {
  color: rgb(var(--v-theme-textPrimary));
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
