<template>
  <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col
        :class="{
          'pr-5': $vuetify.display.mdAndUp,
          'py-5': $vuetify.display.smAndDown,
          'pa-0': true,
        }"
        cols="12"
        sm="9"
        md="4"
        lg="3"
        xl="2"
      >
        <settings-sidemenu></settings-sidemenu>
      </v-col>
      <v-col class="pa-0" cols="12" sm="9" md="8" lg="6" xl="5">
        <base-card :loading="loading">
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            Explorer Profile
          </p>
          <base-loader v-if="loading"></base-loader>
          <div
            :class="{
              'ma-10': $vuetify.display.mdAndUp,
              'ma-2': $vuetify.display.smAndDown,
            }"
            v-else
          >
            <profile-picture
              v-if="$route.name === 'SettingsUserProfilePicture'"
              @fetchUserProfile="getUserProfileDetails()"
              :userProfile="currentUser"
            ></profile-picture>
            <basic-settings
              v-if="$route.name === 'SettingsUserBasicInformation'"
              @fetchUserProfile="getUserProfileDetails()"
              :userProfile="currentUser"
            ></basic-settings>
            <interest-areas
              v-if="$route.name === 'SettingsUserInterestAreas'"
              @editInterestAreasSuccessful="getUserProfileDetails()"
              :userProfile="currentUser"
            ></interest-areas>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import basicSettings from "@/components/user/profile/basic/basicsettings.vue";
import profilePicture from "@/components/user/profile/picture/profilepicture.vue";
import interestAreas from "@/components/user/profile/interestareas/interestareas.vue";
import settingsSidemenu from "@/components/user/profile/settingssidemenu.vue";
import { mapActions, mapGetters } from "vuex";
import { setMetaTags } from "../../../utils/utils.js";

export default {
  data() {
    return {};
  },
  components: {
    basicSettings,
    profilePicture,
    interestAreas,
    settingsSidemenu,
  },
  created() {
    setMetaTags(
      "NetworkTal - User Profile Management",
      "User Profile Management"
    );
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
      isTrainer: "auth/isTrainer",
      userId: "auth/userId",
    }),
    loading() {
      if (this.currentUser) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions({
      getUserByUserId: "users/getUserByUserId",
    }),
    async getUserProfileDetails() {
      await this.getUserByUserId(this.userId);
    },
  },
};
</script>

<style scoped></style>
