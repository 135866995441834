import db from "../../../firebase-init";

export default {
  async getBlogPosts(context) {
    const blogposts = [];
    const data = await db.collection("blogposts").get();

    data.forEach((doc) => {
      const post = doc.data();
      post.id = doc.id;
      blogposts.push(post);
    });

    context.commit("setBlogposts", blogposts);
  },
};
