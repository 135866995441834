<template>
  <div>
    <base-card class="pa-5 pt-2" style="text-align: center">
      <base-loader v-if="!currentUserTrainerProfile"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0" align="center">
          <v-col
            class="ma-0 pa-0 text-body-2 font-weight-medium"
            cols="10"
            align="start"
          >
            <v-icon size="small" class="pr-2">mdi-certificate</v-icon>
            Certifications Overview</v-col
          >
          <v-col class="ma-0 pa-0" align="right">
            <router-link to="/settings/trainer/profile/certifications">
              <v-icon size="15" color="grey">mdi-pencil</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <v-row
          v-if="currentUserTrainerProfile.certifications.length === 0"
          class="ma-0 pa-0 py-5"
        >
          <v-col
            align="center"
            class="ma-0 pa-0 py-3 text-caption text-grey"
            style="font-style: italic"
          >
            No certifications added. Add first now!
          </v-col>
        </v-row>
        <div v-else>
          <v-row class="ma-0 pa-0 py-5">
            <v-col align="center" class="ma-0 pa-0 pt-3 pb-1 text-h4">
              {{ currentUserTrainerProfile.certifications.length }}
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col align="center" class="ma-0 pa-0 pb-2 text-caption">
              Certifications
            </v-col>
          </v-row>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
    }),
  },
};
</script>

<style scoped></style>
