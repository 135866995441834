<template>
  <div>
    <v-sheet
      style="
        height: 600px;
        overflow: auto;
        background-color: rgb(var(--v-theme-background3)) !important;
      "
    >
      <!-- eslint-disable -->
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :view-mode="type"
        :events="events"
        :hide-week-number="true"
        style="background-color: rgb(var(--v-theme-background3)) !important"
      ></v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  getCurrentTimeInUserTimezone,
  convertUTCDateTimeToUserTimezoneNoFormat,
} from "@/utils/utils";

export default {
  inject: ["eventBus"],
  props: {
    trainerId: {
      required: true,
    },
  },
  data: () => ({
    type: "week",
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: [new Date()],
    events: [],
  }),
  mounted() {
    this.fetchEvents();
  },
  computed: {
    ...mapGetters({
      availabilityColor: "calendar/availabilityColor",
      currentUser: "users/currentUser",
      sessionsTrainer: "calendar/sessionsTrainer",
      userTimezone: "websiteData/timezone",
    }),
    currentUserTimezone() {
      if (this.currentUser && this.currentUser.timezone) {
        return this.currentUser.timezone;
      } else {
        return this.userTimezone;
      }
    },
  },
  async created() {
    this.eventBus.on("new-availability-added", async () => {
      await this.fetchEvents();
    });
    this.eventBus.on("new-event-added", async () => {
      await this.fetchEvents();
    });
    this.eventBus.on("availability-removed", async () => {
      await this.fetchEvents();
    });
  },
  methods: {
    getFutureEvents(events) {
      let futureEvents = [];

      // Only show future events (availability and sessions) that are not declined or cancelled
      events.forEach((event) => {
        if (
          convertUTCDateTimeToUserTimezoneNoFormat(
            event.end,
            this.currentUserTimezone
          ).isAfter(getCurrentTimeInUserTimezone(this.currentUserTimezone))
        ) {
          if (
            event.statusLogs &&
            ["declined", "cancelled"].includes(
              event.statusLogs[event.statusLogs.length - 1].status
            )
          ) {
            return;
          }
          futureEvents.push({
            start: new Date(
              convertUTCDateTimeToUserTimezoneNoFormat(
                event.start,
                this.currentUserTimezone
              ).format("YYYY-MM-DD HH:mm")
            ),
            end: new Date(
              convertUTCDateTimeToUserTimezoneNoFormat(
                event.end,
                this.currentUserTimezone
              ).format("YYYY-MM-DD HH:mm")
            ),
            color: event.color,
            allDay: false,
          });
        }
      });

      this.events = futureEvents;
    },
    ...mapActions({
      loadTrainerEvents: "calendar/loadTrainerEvents",
      getTrainerAvailability: "trainerAvailability/getTrainerAvailability",
    }),
    async fetchEvents() {
      let events = [];

      // If the user is logged in, load sessions and availability, otherwise only load availability
      if (this.currentUser) {
        await this.loadTrainerEvents({
          trainerId: this.trainerId,
          loadAvailability: true,
        });
        events = this.sessionsTrainer;
      } else {
        let trainerAvailability = [];

        trainerAvailability = await this.getTrainerAvailability({
          trainerId: this.trainerId,
        });

        trainerAvailability.forEach((availability) => {
          events.push({
            start: availability.startTime,
            end: availability.endTime,
            color: this.availabilityColor,
          });
        });
      }

      this.getFutureEvents(events);
    },
  },
};
</script>

<style scoped>
div :deep(.v-sheet) {
  border-radius: 0px !important;
}
</style>
