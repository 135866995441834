<template>
  <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col class="pa-0" cols="12" sm="9" md="8" lg="6" xl="5">
        <base-card>
          <base-loader v-if="paymentIntent === null"></base-loader>
          <div v-else>
            <v-row class="my-15" align="center">
              <v-col class="text-h4 font-weight-medium" align="center">
                Payment
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="4" md="3" align="end">
                <v-avatar size="100">
                  <v-img :src="paymentIntent.metadata.trainerPhotoURL"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="6" md="5">
                <v-row class="text-subtitle-1 font-weight-medium">
                  <v-col>{{ paymentIntent.metadata.trainerDisplayName }}</v-col>
                </v-row>
                <v-divider class="my-2"></v-divider>
                <v-row class="text-body-2 text-grey">
                  <v-col>{{ paymentIntent.metadata.sessionTitle }}</v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="mt-10" justify="center">
              <v-col cols="10" md="8"><v-divider></v-divider></v-col>
            </v-row>

            <v-row justify="center">
              <v-col class="text-subtitle-1 font-weight-medium" cols="5" md="4">
                Total:
              </v-col>
              <v-col
                class="text h5 font-weight-bold text-decoration-underline"
                cols="5"
                md="4"
                align="end"
              >
                {{ paymentIntent.amount / 100 }}
                {{ paymentIntent.currency.toUpperCase() }}
              </v-col>
            </v-row>

            <v-row class="mt-5 mb-15" justify="center">
              <v-col cols="10" md="8" align="center">
                <base-error-success-message
                  :errorMessage="errorMessage"
                  :successMessage="successMessage"
                ></base-error-success-message>
                <form id="payment-form">
                  <div id="payment-element">
                    <!-- Elements will create form elements here -->
                  </div>

                  <div class="text-caption px-1 mt-5" align="start">
                    By proceeding with the session booking, you acknowledge and
                    agree to our
                    <router-link
                      to="/terms-and-conditions"
                      target="_blank"
                      class="text-caption text-decoration-underline"
                    >
                      Terms and Conditions
                    </router-link>
                  </div>

                  <button
                    id="submit"
                    class="home-page-button text-subtitle-2 font-weight-regular px-5 py-2 mt-10 mb-5 text-white"
                    style="min-width: 150px"
                  >
                    PAY {{ paymentIntent.amount / 100 }}
                    {{ paymentIntent.currency.toUpperCase() }}
                  </button>
                </form>
              </v-col>
            </v-row>
          </div>
        </base-card>
      </v-col></v-row
    >
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import { setMetaTags } from "../../../../utils/utils.js";

const stripePublishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

export default {
  data() {
    return {
      errorMessage: "",
      successMessage: "",
    };
  },
  async mounted() {
    const stripe = await loadStripe(stripePublishableKey);
    if (this.paymentIntent && this.paymentIntent.client_secret) {
      const elements = stripe.elements({
        clientSecret: this.paymentIntent.client_secret,
        appearance: {
          theme: "flat",
          labels: "floating",
        },
      });

      const cardElement = elements.create("payment");
      cardElement.mount("#payment-element");

      const form = document.getElementById("payment-form");

      form.addEventListener("submit", async (e) => {
        e.preventDefault();

        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url:
              process.env.VUE_APP_NETWORKTAL_PLATFORM_URL +
              "/trainer/sessionbooking/paymentstatus",
          },
        });

        if (error) {
          this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        } else {
          this.successMessage = "Payment Successful";
          setTimeout(() => {
            this.successMessage = "";
          }, 5000);
        }
      });
    }
  },
  created() {
    setMetaTags(
      "NetworkTal - Payment",
      "Complete your payment to book a session with a trainer."
    );
  },
  computed: {
    ...mapGetters({
      paymentIntent: "payments/paymentIntent",
    }),
  },
};
</script>

<style scoped>
a {
  color: rgb(var(--v-theme-textPrimary)) !important;
}
</style>
