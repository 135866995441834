<template>
  <div>
    <v-skeleton-loader
      v-if="!isAuthenticated"
      type="list-item-avatar-two-line"
      class="mx-auto"
    ></v-skeleton-loader>
    <v-list-item v-else lines="two" class="px-0">
      <template v-slot:prepend>
        <v-avatar class="mr-2" size="38">
          <v-img :src="getPhotoURL"></v-img>
        </v-avatar>
      </template>
      <v-list-item-title>{{ currentUser.displayName }}</v-list-item-title>
      <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
    </v-list-item>

    <v-divider></v-divider>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Avatar",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      userId: "auth/userId",
      userPhotoDefault: "websiteData/userPhotoDefault",
      currentUser: "users/currentUser",
    }),
    getPhotoURL() {
      return this.currentUser.photoURL
        ? this.currentUser.photoURL
        : this.userPhotoDefault;
    },
  },
};
</script>
<style scoped></style>
