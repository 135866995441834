<template>
  <div style="text-align: center">
    <h3 class="text-h4 font-weight-light">Select a conversation</h3>
  </div>
</template>

<script>
export default {
  name: "RoomNotSelected",
};
</script>
