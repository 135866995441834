<template>
  <v-row
    class="ma-0 pa-0 session-card text-body-2"
    align="center"
    justify="space-around"
  >
    <v-col class="ma-0 pa-0" cols="8" sm="7" md="7" lg="7" xl="7">
      <p class="text-body-1 font-weight-medium ma-4">{{ session.title }}</p>
      <p class="ma-4">
        {{ session.description }}
      </p>
    </v-col>
    <v-col cols="4" sm="2" md="2" lg="2" xl="2">
      <p class="ma-0 pa-0 mb-2">
        <v-icon size="small">mdi-cash</v-icon>
        {{ session.price }} {{ currency.symbol }}
      </p>
      <p class="ma-0 pa-0">
        <v-icon size="small">mdi-clock-outline</v-icon>
        {{ session.duration }} min
      </p>
    </v-col>
    <v-col align="center" cols="12" sm="3" md="3" lg="3" xl="3">
      <v-icon
        :class="{
          'mx-5': $vuetify.display.mdAndUp,
          'my-5': $vuetify.display.mdAndUp,
          'ma-5': $vuetify.display.mdAndDown,
        }"
        color="darkgray"
        @click="triggerRemoveSession()"
      >
        mdi-delete
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  inject: ["eventBus"],
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currency: "websiteData/currency",
    }),
  },
  methods: {
    ...mapActions({
      setAddOrUpdateSessionDialog: "sessions/setAddOrUpdateSessionDialog",
      removeSession: "sessions/removeSession",
    }),
    triggerRemoveSession() {
      this.eventBus.emit("trigger-remove-session", {
        id: this.session.id,
      });
    },
  },
};
</script>

<style scoped>
.session-card {
  background-color: rgb(var(--v-theme-background1));
  color: rgb(var(--v-theme-textPrimary));
  border-radius: 10px;
  min-width: 100%;
}
</style>
