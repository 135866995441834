<template>
  <div>
    <base-dialog :model="viewSessionDialog">
      <v-toolbar class="text-h6 text-white" color="colorSpecial5" elevation="1">
        <v-row justify="space-between" class="ma-0 pa-0">
          <v-col cols="10" class="pt-5">Session Type</v-col>
          <v-col cols="2" align="end">
            <v-btn icon @click="closeDialog" class="text-white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-container class="text-body-2 pt-8 px-10 pb-10">
        <v-row>
          <v-col
            cols="12"
            md="5"
            lg="5"
            xl="5"
            class="text-body-1 font-weight-medium"
          >
            Title
          </v-col>
          <v-col cols="12" md="7" lg="7" xl="7">
            {{ trainerSession.title }}
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col
            cols="12"
            md="5"
            lg="5"
            xl="5"
            class="text-body-1 font-weight-medium"
          >
            Description
          </v-col>
          <v-col cols="12" md="7" lg="7" xl="7">
            {{ trainerSession.description }}
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col
            cols="12"
            md="5"
            lg="5"
            xl="5"
            class="text-body-1 font-weight-medium"
          >
            Duration
          </v-col>
          <v-col cols="12" md="7" lg="7" xl="7"
            >{{ trainerSession.duration }} min</v-col
          >
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col
            cols="12"
            md="5"
            lg="5"
            xl="5"
            class="text-body-1 font-weight-medium"
          >
            Price
          </v-col>
          <v-col cols="12" md="7" lg="7" xl="7">
            {{
              trainerSession.price === 0
                ? "Free"
                : trainerSession.price + " " + currency.symbol
            }}
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row class="mt-5">
          <v-col>
            <base-button
              customColor="colorSpecial1"
              @click="bookSession()"
              customWidth="125"
            >
              Book
            </base-button>
          </v-col>
        </v-row>
      </v-container>
    </base-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  inject: ["eventBus"],
  props: {
    trainerSession: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {
    this.eventBus.on("set-base-dialog", (value) => {
      this.setViewSessionDialog(value);
    });
  },
  unmounted() {
    this.eventBus.off("set-base-dialog");
  },
  computed: {
    ...mapGetters({
      viewSessionDialog: "sessions/viewSessionDialog",
      currency: "websiteData/currency",
    }),
  },
  methods: {
    ...mapActions({
      setViewSessionDialog: "sessions/setViewSessionDialog",
    }),
    closeDialog() {
      this.setViewSessionDialog(false);
    },
    bookSession() {
      this.setViewSessionDialog(false);
      this.eventBus.emit("book-session", this.trainerSession);
    },
  },
};
</script>

<style scoped>
.seesion-category-data {
  padding-left: 30px;
}
</style>
