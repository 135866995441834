<template>
  <div>
    <add-session-dialog :sessions="trainer.sessions"></add-session-dialog>
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col cols="1" class="pt-8 pl-8">
        <base-tooltip-info :text="sessionsGuidance"></base-tooltip-info>
      </v-col>
      <v-col align="end">
        <base-button @click="setAddOrUpdateSessionDialog(true)">
          Add
          <base-icon>mdi-plus</base-icon>
        </base-button>
      </v-col>
    </v-row>
    <v-card class="sessions-card px-2" elevation="0">
      <base-error-success-message
        class="ma-10"
        :errorMessage="errorMessage"
        :successMessage="successMessage"
      ></base-error-success-message>
      <div v-if="trainer.sessions.length !== 0">
        <session-card
          v-for="session in trainer.sessions"
          :key="session.id"
          :session="session"
          class="my-2"
        ></session-card>
      </div>
      <v-row v-else class="ma-10">
        <v-col><p>Add your first trainer session...</p></v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddSessionDialog from "./addsessiondialog.vue";
import SessionCard from "./sessioncard.vue";

export default {
  inject: ["eventBus"],
  data() {
    return {
      sessionsGuidance: `
            <ul style="margin-left: 10px">
                <li>Your trainer account must have at least one session.</li>
                <li>Click on the "Add" button to add a new session.</li>
                <li>Click on the "Delete" button to delete an existing session.</li>
            </ul>    
            `,
    };
  },
  props: {
    trainer: {
      type: Object,
      required: true,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    successMessage: {
      type: String,
      required: false,
    },
  },
  components: {
    AddSessionDialog,
    SessionCard,
  },
  created() {
    this.eventBus.on("trigger-remove-session", (payload) => {
      this.removeSession(payload.id);
    });
    this.eventBus.on("validate-add-first-session", () => {
      this.triggerValidation();
    });
  },
  computed: {
    ...mapGetters({
      addOrUpdateSessionDialog: "sessions/addOrUpdateSessionDialog",
    }),
  },
  methods: {
    ...mapActions({
      setAddOrUpdateSessionDialog: "sessions/setAddOrUpdateSessionDialog",
    }),
    removeSession(id) {
      // eslint-disable-next-line vue/no-mutating-props
      this.trainer.sessions = this.trainer.sessions.filter(
        (session) => session.id !== id
      );
    },
    triggerValidation() {
      if (this.trainer.sessions.length === 0) {
        this.eventBus.emit("add-first-session-invalid");
      } else {
        this.eventBus.emit("add-first-session-valid");
      }
    },
  },
};
</script>

<style scoped>
.sessions-card {
  background-color: rgb(var(--v-theme-card)) !important;
  border: 1px solid rgb(var(--v-theme-background)) !important;
  max-height: 100vh;
  overflow: auto;
}
</style>
