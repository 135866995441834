<template>
  <div style="width: 100%">
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col class="text-h5 font-weight-medium pl-5">Display</v-col>
    </v-row>
    <base-card
      :class="{
        'pa-10': $vuetify.display.mdAndUp,
        'pa-2': $vuetify.display.smAndDown,
      }"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <base-error-success-message
        :successMessage="successMessage"
      ></base-error-success-message>
      <v-row class="ma-0 pa-0">
        <v-col cols="5" class="font-weight-medium">Dark Mode</v-col>
        <v-col class="ma-0 pa-0">
          <v-switch v-model="theme" inset></v-switch>
        </v-col>
      </v-row>
    </base-card>
  </div>
</template>

<script>
export default {
  inject: ["eventBus"],
  data() {
    return {
      successMessage: "",
      theme: null,
    };
  },
  created() {
    this.getTheme();
  },
  components: {},
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getTheme() {
      const savedTheme = localStorage.getItem("dark-theme");

      if (savedTheme) {
        if (savedTheme === "true") {
          this.theme = true;
        } else {
          this.theme = false;
        }
      } else {
        this.theme = false;
      }
    },
    changeTheme() {
      localStorage.setItem("dark-theme", this.theme);
      this.eventBus.emit("apply-theme");
    },
  },
  watch: {
    theme() {
      this.changeTheme();
    },
  },
};
</script>

<style scoped></style>
