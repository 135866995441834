<template>
  <div class="text-subtitle-1">
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col cols="7" class="text-h5 font-weight-medium pl-5"
        >Interest Areas</v-col
      >
      <v-col align="end">
        <base-button v-if="!inEditMode" @click="toggleInEditMode">
          Edit
          <base-icon>mdi-pencil</base-icon>
        </base-button>
      </v-col>
    </v-row>
    <base-card
      :class="{
        'pa-10': $vuetify.display.mdAndUp,
        'pa-2': $vuetify.display.smAndDown,
      }"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <v-container>
        <base-error-success-message
          :errorMessage="errorMessage"
          :successMessage="successMessage"
        ></base-error-success-message>
        <v-row style="min-height: 100px">
          <v-col class="font-weight-medium" cols="4" sm="5">Areas:</v-col>
          <v-col v-if="!inEditMode" justify="center">
            <v-chip
              class="ma-1"
              v-for="interestArea in userProfile.interestAreas"
              :key="interestArea"
              :ripple="false"
            >
              {{ interestArea }}
            </v-chip>
          </v-col>
          <v-col v-if="inEditMode">
            <v-select
              v-model="selectedAreas"
              :items="trainerAreas"
              :error-messages="selectedAreasErrors"
              counter
              chips
              closable-chips
              label="Interest Areas"
              multiple
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="inEditMode">
          <base-button @click="submit">
            Save
            <base-icon>mdi-content-save</base-icon>
          </base-button>
          <base-button customColor="error" @click="cancel">
            Cancel
            <base-icon>mdi-close</base-icon>
          </base-button>
        </v-row>
      </v-container>
    </base-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { maxLength } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      selectedAreas: {
        maxLength: maxLength(10),
      },
    };
  },
  data() {
    return {
      successMessage: "",
      errorMessage: "",
      inEditMode: false,
      selectedAreas: null,
    };
  },
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.fillInterestAreasInformation();
  },
  computed: {
    ...mapGetters({
      trainerAreas: "trainers/areas",
    }),
    selectedAreasErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "selectedAreas") {
          if (error.$validator === "maxLength") {
            errors.push("You must select at most 10 training areas");
          }
        }
      });
      return errors;
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateUser: "users/createOrUpdateUser",
    }),
    toggleInEditMode() {
      this.inEditMode = !this.inEditMode;
    },
    fillInterestAreasInformation() {
      this.selectedAreas = this.userProfile.interestAreas;
    },
    async submit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        try {
          let id = this.userProfile.id;
          let userProfileComplete = this.userProfile;
          delete userProfileComplete.id;

          this.createOrUpdateUser({
            id: id,
            data: {
              ...userProfileComplete,
              interestAreas: this.selectedAreas,
            },
          });
          this.$emit("editInterestAreasSuccessful");
          this.toggleInEditMode();
          this.successMessage = "Operation successful!";
          setTimeout(() => {
            this.successMessage = "";
          }, 5000);
        } catch (error) {
          this.errorMessage = "Unknown error occurred. Please try again later!";
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        }
      } else {
        this.errorMessage = "Please fill in all the required information";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },
    cancel() {
      this.toggleInEditMode();
      this.fillInterestAreasInformation();
    },
  },
};
</script>

<style scoped></style>
