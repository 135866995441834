<template>
  <div>
    <base-error-success-message
      :errorMessage="errorMessage"
      :successMessage="successMessage"
    ></base-error-success-message>
    <base-dialog-confirm
      v-if="confirmDialog"
      :displayText="availabilityRemovalDisplayText"
      eventName="confirm-delete-availability"
      :payload="availabilityRemovalPayload"
      toolbarText="Delete confirmation"
    ></base-dialog-confirm>
    <base-card
      class="availability-list ma-0 pa-0"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <availability-card
        v-for="availability in trainerAvailability"
        :key="availability.id"
        :availabilityId="availability.id"
        :startTime="availability.startTime"
        :endTime="availability.endTime"
        :trainerId="trainerId"
      ></availability-card>
    </base-card>
  </div>
</template>

<script>
import AvailabilityCard from "./availabilitycard.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  inject: ["eventBus"],
  data() {
    return {
      successMessage: "",
      errorMessage: "",
      availabilityRemovalPayload: null,
      availabilityRemovalDisplayText: "",
    };
  },
  components: {
    AvailabilityCard,
  },
  props: {
    trainerId: {
      type: String,
      required: true,
    },
    trainerAvailability: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.eventBus.on("confirm-delete-availability", (payload) => {
      // console.log('confirm-delete-availability triggered with payload:', JSON.stringify(payload))
      try {
        this.removeAvailability({
          ...payload,
        })
          .then(() => {
            this.successMessage = "Availability removed successfully";
            setTimeout(() => {
              this.successMessage = "";
            }, 5000);
          })
          .catch(() => {
            // this.errorMessage = error
            this.errorMessage =
              "An unexpected error occurred. Please try again later.";
            setTimeout(() => {
              this.errorMessage = "";
            }, 5000);
          });
      } catch (error) {
        // this.errorMessage = error
        this.errorMessage =
          "An unexpected error occurred. Please try again later.";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }

      this.eventBus.emit("availability-removed");
    });
    this.eventBus.on("trigger-remove-availability-confirmation", (payload) => {
      this.availabilityRemovalPayload = {
        id: payload.id,
        trainerId: payload.trainerId,
      };
      this.availabilityRemovalDisplayText = `delete the availability with the start time at ${payload.availabilityStartTime}`;
      this.setConfirmDialog(true);
    });
  },
  computed: {
    ...mapGetters({
      confirmDialog: "websiteData/confirmDialog",
    }),
  },
  methods: {
    ...mapActions({
      setConfirmDialog: "websiteData/setConfirmDialog",
      removeAvailability: "trainerAvailability/removeAvailability",
    }),
  },
};
</script>

<style scoped>
.availability-list {
  overflow-y: scroll;
  max-height: 532px;
}
</style>
