<template>
  <v-row id="home-section-1" align="center" justify="center">
    <v-col class="order-first order-md-last" cols="12" md="6" lg="5">
      <v-img
        :src="require('@/assets/home/picture-home-01.png')"
        :height="imageHeight"
      ></v-img>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="5"
      :class="alignTextCenter ? 'px-5 main-text text-center' : 'px-5 main-text'"
    >
      <div
        :class="{
          'font-weight-medium text-h2': $vuetify.display.mdAndUp,
          'font-weight-medium text-h3': $vuetify.display.mdAndDown,
        }"
      >
        Connect with Experts
      </div>
      <div
        :class="{
          'text-h4 mt-5 pl-1': $vuetify.display.mdAndUp,
          'text-h5 mt-7 pl-1': $vuetify.display.mdAndDown,
        }"
      >
        Master new skills with one-to-one personalized video sessions
      </div>
      <div
        :class="{
          'text-h6 font-weight-regular pl-1 text-start mt-5':
            $vuetify.display.mdAndUp,
          'mx-15': $vuetify.display.sm,
          'text-subtitle-1 pl-1 text-start mt-5 mx-2':
            $vuetify.display.mdAndDown,
        }"
      >
        <div class="py-1">
          <v-icon size="small" class="pr-5 pl-3">mdi-lightbulb-on-10</v-icon
          >Gain insights from real-world experience and knowledge
        </div>
        <div class="py-1">
          <v-icon size="small" class="pr-5 pl-3">mdi-connection</v-icon>Build
          valuable connections with professionals
        </div>
        <div class="py-1">
          <v-icon size="small" class="pr-5 pl-3">mdi-bullseye-arrow</v-icon
          >Reach your goals faster
        </div>
      </div>
      <div class="mt-10 mb-10">
        <base-button
          class="ma-2"
          color="colorSpecial1"
          :customWidth="buttonWidth"
          :customHeight="buttonHeight"
          :customFontSize="buttonSize"
          to="/trainers"
          >trainers
        </base-button>
        <base-button
          class="ma-2"
          color="colorSpecial3"
          :customWidth="buttonWidth"
          :customHeight="buttonHeight"
          :customFontSize="buttonSize"
          to="/sessions"
          >sessions
        </base-button>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import "@/assets/css/global.css";

export default {
  computed: {
    imageHeight() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "300px";
        case "sm":
          return "400px";
        case "md":
          return "550px";
        case "lg":
          return "600px";
        case "xl":
          return "700px";
        default:
          return "700px";
      }
    },
    buttonSize() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "18px";
        case "sm":
          return "22px";
        case "md":
          return "24px";
        case "lg":
          return "26px";
        case "xl":
          return "28px";
        default:
          return "30px";
      }
    },
    buttonWidth() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "150px";
        case "sm":
          return "180px";
        case "md":
          return "200px";
        case "lg":
          return "220px";
        case "xl":
          return "250px";
        default:
          return "250px";
      }
    },
    buttonHeight() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "50px";
        case "sm":
          return "50px";
        case "md":
          return "55px";
        case "lg":
          return "55px";
        case "xl":
          return "60px";
        default:
          return "55px";
      }
    },
    alignTextCenter() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },
  methods: {
    learnMore() {
      const element = document.querySelector("#home-section-10");
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
#home-section-1 {
  background-color: rgb(var(--v-theme-background3));
  color: rgb(var(--v-theme-textPrimary));
  /* border: 2px solid black; */
  min-height: 75vh;
  margin: 0px;
  padding: 0px;
}
</style>
