<template>
  <div>
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col cols="7" class="text-h5 font-weight-medium pl-5">
        Trial Session
        <base-tooltip-info
          text="Add your trial session here. This will be visible to your clients."
        ></base-tooltip-info>
      </v-col>
      <v-col
        align="end"
        v-if="getTrialSessions().length === 0"
        class="col-auto"
      >
        <base-button @click="triggerAddTrialSession()">
          Add
          <base-icon>mdi-plus</base-icon>
        </base-button>
      </v-col>
    </v-row>
    <base-error-success-message
      :errorMessage="errorMessage"
      :successMessage="successMessage"
    ></base-error-success-message>
    <base-card
      class="pa-5"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <v-row v-if="getTrialSessions().length > 0">
        <session-card
          v-for="session in getTrialSessions()"
          :key="session.id"
          :trainerId="trainerProfile.id"
          :session="session"
        ></session-card>
      </v-row>
      <v-row v-else>
        <v-col class="session-page-text">No trial session added yet...</v-col>
      </v-row>
    </base-card>
  </div>
</template>

<script>
import SessionCard from "./sessioncard.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  inject: ["eventBus"],
  data() {
    return {
      successMessage: "",
      errorMessage: "",
      sessionRemovalPayload: null,
      sessionRemovalDisplayText: "",
    };
  },
  components: {
    SessionCard,
  },
  created() {
    this.eventBus.on("trial-session-removed", () => {
      this.successMessage = "Trial session removed successfully";
      setTimeout(() => {
        this.successMessage = "";
      }, 5000);
    });
    this.eventBus.on("trial-session-removed-error", (error) => {
      this.errorMessage = error.message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 5000);
    });
  },
  props: {
    trainerProfile: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      addOrUpdateSessionDialog: "sessions/addOrUpdateSessionDialog",
      confirmDialog: "websiteData/confirmDialog",
    }),
  },
  methods: {
    ...mapActions({
      setAddOrUpdateSessionDialog: "sessions/setAddOrUpdateSessionDialog",
      setConfirmDialog: "websiteData/setConfirmDialog",
      removeSession: "sessions/removeSession",
    }),
    triggerAddTrialSession() {
      let sessionId = this.generateId();

      this.eventBus.emit("add-trial-session", {
        sessionId: sessionId,
        sessionTitle: "Trial Session",
      });
      this.setAddOrUpdateSessionDialog(true);
    },
    generateId() {
      let id = Math.floor(
        Math.random() * Math.floor(Math.random() * Date.now())
      )
        .toString()
        .slice(0, 10);
      return id;
    },
    getTrialSessions() {
      let trialSessions = [];
      if (this.trainerProfile.sessions) {
        trialSessions = this.trainerProfile.sessions.filter(
          (session) => session.isTrial === true
        );
      }
      return trialSessions;
    },
  },
};
</script>

<style scoped>
.session-page-title {
  font-size: 1.6rem;
  font-weight: 600;
}

.session-page-text {
  font-size: 1rem;
  font-weight: 400;
}
</style>
