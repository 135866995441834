import { roomService } from "../../../_services/room.service.js";
import db from "../../../firebase-init";
import { collection, getDocs, query, where } from "firebase/firestore";

export default {
  async getRooms(_, { userId }) {
    const rooms = [];
    const q = query(
      collection(db, "rooms"),
      where("users", "array-contains", userId)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const room = doc.data();
      room.id = doc.id;
      rooms.push(room);
    });

    return rooms;
  },

  async getRoomMessages({ commit }, { roomId }) {
    const newChats = [];
    if (roomId) {
      db.collection("rooms")
        .doc(roomId)
        .collection("messages")
        .orderBy("timestamp", "asc")
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach(function (change) {
            if (change.type === "added") {
              // verify if the sender of the previous chat is the same as the current chat
              // if so, set continuation to true
              var continuation = false;
              if (newChats.length > 0) {
                if (
                  newChats[newChats.length - 1].senderID ==
                  change.doc.data().sender
                ) {
                  continuation = true;
                }
              }

              var chat = {
                id: change.doc.id,
                message: change.doc.data().message,
                timestamp: change.doc.data().timestamp,
                senderID: change.doc.data().sender,
                continuation: continuation,
              };
              newChats.push(chat);
            }
          });
          // console.log("All Chats Vuex: " + JSON.stringify(newChats))
          commit("setRoomMessages", newChats);
        });
    } else {
      // console.log(
      //     'Warning: Room meessages could not be retrieved as no room id was provided!'
      // )
    }
  },

  async selectRoom({ commit }, { id, name, currentUser, targetUser }) {
    // If the room has already been created, then set it as active room (usually triggered from selecting the chat room from left)
    var roomID;

    if (id) {
      // console.log('Room id set internal')
      roomID = id;

      if (name) {
        commit("setActiveRoomName", name);
      }

      // get the users in the room and set it to active users
      db.collection("rooms")
        .doc(roomID)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            commit("setActiveUsers", snapshot.data().users);
          }
        });
    } else {
      // Else check if there is existing chat room created for the user
      const roomResp = await roomService.get(currentUser, targetUser);

      if (roomResp.success) {
        roomID = roomResp.roomID;
      } else {
        const resp = await roomService.createPrivateChat(
          currentUser,
          targetUser
        );
        if (resp.success) {
          roomID = resp.roomID;
        }
      }

      commit("setActiveUsers", [currentUser, targetUser]);
    }

    commit("setActiveRoomId", roomID);
  },

  async sendMessage({ state }, { message, senderId }) {
    var room = state.activeRoomId;

    const resp = await roomService.sendMessage(senderId, room, message);
    return resp;
  },

  async createChatRoom({ userIDs }) {
    // var currentUserID = rootState.userModule.user ? rootState.userModule.user.ID : null
    // if (currentUserID) {
    //     userIDs.push(currentUserID)
    // }

    const resp = await roomService.createChatRoom(userIDs);
    return resp;
  },

  clearRoom({ commit }) {
    commit("clearRoom");
  },
};
