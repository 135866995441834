<template>
  <div>
    <v-row style="min-height: 40vh" align="center">
      <v-col align="center">
        <v-avatar size="100" class="my-10">
          <v-icon size="100" color="warning">mdi-timer-sand</v-icon>
        </v-avatar>
        <p class="mt-5 text-h5 font-weight-medium mx-5">Payment processing</p>
        <p class="text-subtitle-1">
          Your payment is being processed. Please wait a few seconds then
          refresh this page to check the status.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center" class="mb-15 mt-5">
        <base-button toPath="/settings/user/mysessions"
          >My Sessions</base-button
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
