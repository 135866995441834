<template>
  <div>
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col cols="7" class="text-h5 font-weight-medium pl-5">
        Sessions
        <base-tooltip-info
          text="A session is a meeting that you offer on a specific topic to users. You can add one or multiple sessions to your trainer profile."
        ></base-tooltip-info>
      </v-col>
      <v-col align="end">
        <base-button @click="setAddOrUpdateSessionDialog(true)">
          Add
          <base-icon>mdi-plus</base-icon>
        </base-button>
      </v-col>
    </v-row>
    <base-error-success-message
      :errorMessage="errorMessage"
      :successMessage="successMessage"
    ></base-error-success-message>
    <add-or-update-session-dialog
      v-if="trainerProfile.id"
      :trainerId="trainerProfile.id"
    ></add-or-update-session-dialog>
    <base-dialog-confirm
      v-if="confirmDialog"
      :displayText="sessionRemovalDisplayText"
      eventName="confirm-delete-session"
      :payload="sessionRemovalPayload"
      toolbarText="Delete confirmation"
    ></base-dialog-confirm>
    <base-card
      class="pa-5"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <v-row style="max-height: 50vh; overflow: auto">
        <session-card
          v-for="session in getNonTrialSessions()"
          :key="session.id"
          :trainerId="trainerProfile.id"
          :session="session"
        ></session-card>
      </v-row>
    </base-card>
  </div>
</template>

<script>
import addOrUpdateSessionDialog from "./addorupdatesessiondialog.vue";
import sessionCard from "./sessioncard.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  inject: ["eventBus"],
  data() {
    return {
      successMessage: "",
      errorMessage: "",
      sessionRemovalPayload: null,
      sessionRemovalDisplayText: "",
      isSessionToBeRemovedTrial: false,
    };
  },
  components: {
    addOrUpdateSessionDialog,
    sessionCard,
  },
  created() {
    this.eventBus.on("confirm-delete-session", (payload) => {
      // console.log('confirm-delete-session triggered with payload:', JSON.stringify(payload))
      try {
        this.removeSession({
          ...payload,
        })
          .then(() => {
            if (this.isSessionToBeRemovedTrial) {
              this.eventBus.emit("trial-session-removed");
            } else {
              this.successMessage = "Session removed successfully";
              setTimeout(() => {
                this.successMessage = "";
              }, 5000);
            }
          })
          .catch((error) => {
            if (this.isSessionToBeRemovedTrial) {
              this.eventBus.emit("trial-session-removed-error", error);
            } else {
              this.errorMessage = error.message;
              setTimeout(() => {
                this.errorMessage = "";
              }, 5000);
            }
          });
      } catch (error) {
        if (this.isSessionToBeRemovedTrial) {
          this.eventBus.emit("trial-session-removed-error", error);
        } else {
          this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        }
      }

      this.eventBus.emit("session-removed");
    });
    this.eventBus.on("trigger-remove-session-confirmation", (payload) => {
      this.sessionRemovalPayload = {
        sessionId: payload.sessionId,
        trainerId: payload.trainerId,
      };
      this.sessionRemovalDisplayText = `delete the session with the title ${payload.sessionTitle}`;
      this.isSessionToBeRemovedTrial = payload.isTrial;
      this.setConfirmDialog(true);
    });
  },
  props: {
    trainerProfile: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      addOrUpdateSessionDialog: "sessions/addOrUpdateSessionDialog",
      confirmDialog: "websiteData/confirmDialog",
    }),
  },
  methods: {
    ...mapActions({
      setAddOrUpdateSessionDialog: "sessions/setAddOrUpdateSessionDialog",
      setConfirmDialog: "websiteData/setConfirmDialog",
      removeSession: "sessions/removeSession",
    }),
    getNonTrialSessions() {
      let trialSessions = [];
      if (this.trainerProfile.sessions) {
        trialSessions = this.trainerProfile.sessions.filter(
          (session) => session.isTrial === false
        );
      }
      return trialSessions;
    },
  },
};
</script>

<style scoped>
.session-page-title {
  font-size: 1.6rem;
  font-weight: 600;
}
</style>
