<template>
  <div>
    <base-dialog :model="addAvailabilityDialog">
      <v-toolbar class="text-white" color="colorSpecial5" elevation="1">
        <v-row align="center" class="ma-0 pa-0">
          <v-col align="start" cols="10">Availability</v-col>
          <v-col cols="2" align="end">
            <v-btn icon @click="closeDialog" class="text-white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <base-error-success-message
        class="ma-5"
        :errorMessage="errorMessage"
        :successMessage="successMessage"
      ></base-error-success-message>
      <v-container>
        <v-form class="mx-3" @submit.prevent="submitEvent">
          <v-menu
            class="offset-y"
            v-model="menuDatePicker"
            :close-on-content-click="false"
            :offset="40"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-model="date"
                :error-messages="dateErrors"
                label="Pick the date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="props"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              show-adjacent-months
              @update:modelValue="saveDate"
            ></v-date-picker>
          </v-menu>
          <v-select
            v-model="startTime"
            :items="allowedTimes"
            label="Pick the start time"
            prepend-icon="mdi-clock-time-four-outline"
            clearable
            :error-messages="startTimeErrors"
          ></v-select>
          <v-select
            v-model="endTime"
            :items="allowedTimes"
            label="Pick the end time"
            prepend-icon="mdi-clock-time-four-outline"
            clearable
            :error-messages="endTimeErrors"
          ></v-select>
          <v-checkbox
            v-model="recurringAvailability"
            label="Make recurring"
          ></v-checkbox>
          <div v-if="recurringAvailability">
            <p class="text-subtitle-1 font-weight-regular">
              Recurring Pattern
              <base-tooltip-info
                :text="recurringPatternGuidance"
              ></base-tooltip-info>
            </p>
            <v-radio-group
              class="radio-group-buttons"
              v-model="recurrencePattern"
            >
              <v-radio label="Daily" value="daily"></v-radio>
              <v-radio label="Weekly" value="weekly"></v-radio>
              <v-radio label="Monthly" value="monthly"></v-radio>
            </v-radio-group>
            <p class="text-subtitle-1 font-weight-regular mb-3">
              End date of the recurrence
              <base-tooltip-info
                :text="recurrenceEndDateGuidance"
              ></base-tooltip-info>
            </p>
            <v-menu
              class="offset-y"
              v-model="menuRecurrenceEndDatePicker"
              :close-on-content-click="false"
              :offset="40"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ props }">
                <v-text-field
                  v-model="recurrenceEndDate"
                  :error-messages="recurrenceEndDateErrors"
                  label="End recurrence by"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="props"
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                show-adjacent-months
                @update:modelValue="saveRecurrenceEndDate"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="mt-5 mb-4">
            <base-button class="mr-4" @click="submitEvent()">
              Submit
              <base-icon>mdi-content-save</base-icon>
            </base-button>
            <base-button customColor="error" @click="closeDialog()">
              Close
              <base-icon>mdi-close</base-icon>
            </base-button>
          </div>
        </v-form>
      </v-container>
    </base-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import {
  convertUserTimezoneDateTimeToUTC,
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
} from "../../../../utils/utils.js";

export default {
  inject: ["eventBus", "dayjs"],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      startTime: {
        required,
      },
      endTime: {
        required,
      },
      date: {
        required,
        max6MonthsFromNow: (value) => {
          const date = new Date(value);
          const sixMonthsFromNow = new Date();
          sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);
          return date < sixMonthsFromNow;
        },
      },
      recurrenceEndDate: {
        required: requiredIf(function () {
          return this.recurringAvailability;
        }),
        max6MonthsFromNow: (value) => {
          const date = new Date(value);
          const sixMonthsFromNow = new Date();
          sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);
          return date < sixMonthsFromNow;
        },
      },
    };
  },
  emits: ["new-availability-added"],
  props: {
    trainerId: {
      required: true,
    },
    trainerAvailability: {
      required: true,
    },
  },
  data() {
    return {
      // allowedTimes: Array.from({ length: 48 }, (v, i) => {
      //   const hour = Math.floor(i / 2);
      //   const minute = i % 2 === 0 ? "00" : "30";
      //   return `${hour.toString().padStart(2, "0")}:${minute}`;
      // }),
      allowedMinutesStart: [0, 30],
      allowedMinutesEnd: [0, 30],
      allowedTimes: Array.from({ length: 25 }, (v, i) => {
        const hour = i.toString().padStart(2, "0");
        return `${hour}:00`;
      }),
      date: null,
      menuDatePicker: false,
      menuStartTimePicker: false,
      recurringAvailability: false,
      recurrencePattern: "daily",
      recurrenceEndDate: null,
      recurrenceEndDateGuidance:
        "Select the date when the recurring availability ends. The availability will be available until this date, including this date. The start date of the recurring availability is the date selected above",
      recurringPatternGuidance:
        "Select the pattern for the recurring availability. The availability will be available based on the selected pattern starting from the date selected above",
      menuRecurrenceEndDatePicker: false,
      startTime: null,
      endTime: null,
      successMessage: "",
      errorMessage: "",
    };
  },
  created() {
    this.eventBus.on("set-base-dialog", (value) => {
      this.setAddAvailabilityDialog(value);
    });
  },
  unmounted() {
    this.eventBus.off("set-base-dialog");
  },
  computed: {
    ...mapGetters({
      addAvailabilityDialog: "trainerAvailability/addAvailabilityDialog",
      currentUser: "users/currentUser",
    }),
    startTimeErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "startTime") {
          errors.push("Start time is required");
        }
      });
      return errors;
    },
    endTimeErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "endTime") {
          errors.push("End time is required");
        }
      });
      return errors;
    },
    dateErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "date") {
          if (error.$validator === "required") {
            errors.push("Date is required");
          } else if (error.$validator === "max6MonthsFromNow") {
            errors.push("Date cannot be more than 6 months from now");
          }
        }
      });
      return errors;
    },
    recurrenceEndDateErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "recurrenceEndDate") {
          if (error.$validator === "required") {
            errors.push("Recurrence end date is required");
          } else if (error.$validator === "max6MonthsFromNow") {
            errors.push(
              "Recurrence end date cannot be more than 6 months from now"
            );
          }
        }
      });
      return errors;
    },
  },
  methods: {
    ...mapActions({
      setAddAvailabilityDialog: "trainerAvailability/setAddAvailabilityDialog",
      addAvailability: "trainerAvailability/addAvailability",
    }),
    async submitEvent() {
      const isValid = await this.v$.$validate();

      if (isValid) {
        try {
          // Check if the availability is recurring
          if (this.recurringAvailability) {
            let availabilityDates = [];
            if (this.recurrencePattern === "daily") {
              availabilityDates = this.getDatesBetween(
                this.date,
                this.recurrenceEndDate,
                "day"
              );
            } else if (this.recurrencePattern === "weekly") {
              availabilityDates = this.getDatesBetween(
                this.date,
                this.recurrenceEndDate,
                "week"
              );
            } else if (this.recurrencePattern === "monthly") {
              availabilityDates = this.getDatesBetween(
                this.date,
                this.recurrenceEndDate,
                "month"
              );
            }

            // check if any timeframes are overlapping
            this.verifyAvailabilityDates(
              availabilityDates,
              this.startTime,
              this.endTime
            );

            // Check if the number of availability dates is too large or 0
            if (availabilityDates.length === 0) {
              this.errorMessage =
                "No availability dates found. Please check the date range";
              setTimeout(() => {
                this.errorMessage = "";
              }, 5000);
              return;
            } else if (availabilityDates.length > 50) {
              this.errorMessage =
                "Too many availability dates. Please select a smaller date range";
              setTimeout(() => {
                this.errorMessage = "";
              }, 5000);
              return;
            }

            // Submit availability for each date
            availabilityDates.forEach(async (date) => {
              await this.submitAvailability(date, this.startTime, this.endTime);
            });
          } else {
            await this.submitAvailability(
              this.date,
              this.startTime,
              this.endTime
            );
          }

          this.eventBus.emit("new-availability-added");
          this.closeDialog();
        } catch (error) {
          this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        }
      } else {
        this.errorMessage = "Please fill in all the required fields";
      }
    },
    clearData() {
      this.date = null;
      this.startTime = null;
      this.endTime = null;
      this.successMessage = "";
      this.errorMessage = "";
      this.recurrenceEndDate = null;
      this.recurringAvailability = false;
      this.recurrencePattern = "daily";
    },
    closeDialog() {
      this.clearData();
      this.setAddAvailabilityDialog(false);
      this.v$.$reset();
    },
    // Function to format the date as YYYY-MM-DD
    formatDate(date) {
      let d = date.getDate();
      let m = date.getMonth() + 1; // Months are zero-based
      let y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    getDatesBetween(startDate, endDate, incrementType) {
      let datesBetween = [];
      let currentDateInDateFormat = new Date(startDate);
      let endDateInDateFormat = new Date(endDate);

      while (currentDateInDateFormat <= endDateInDateFormat) {
        // Add the current date to the array
        datesBetween.push(this.formatDate(currentDateInDateFormat));
        // Increment the date by specified unit
        currentDateInDateFormat = this.incrementDate(
          currentDateInDateFormat,
          incrementType
        );
      }

      return datesBetween;
    },
    incrementDate(date, incrementType) {
      let newDate = new Date(date);

      switch (incrementType) {
        case "day":
          newDate.setDate(newDate.getDate() + 1);
          break;
        case "week":
          newDate.setDate(newDate.getDate() + 7);
          break;
        case "month":
          newDate.setMonth(newDate.getMonth() + 1);
          break;
        default:
          return date;
      }

      return newDate;
    },
    async submitAvailability(date, startTime, endTime) {
      let startTimeUserTimezone = date + " " + startTime;
      let endTimeUserTimezone = date + " " + endTime;

      let startTimeUTC = convertUserTimezoneDateTimeToUTC(
        startTimeUserTimezone,
        this.currentUser.timezone
      );

      let endTimeUTC = convertUserTimezoneDateTimeToUTC(
        endTimeUserTimezone,
        this.currentUser.timezone
      );

      this.verifyAvailability(startTimeUTC, endTimeUTC);

      await this.addAvailability({
        trainerId: this.trainerId,
        startTime: startTimeUTC,
        endTime: endTimeUTC,
      });
    },
    saveDate(date) {
      this.menuDatePicker = false;
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      this.date = date.toISOString().split("T")[0];
    },
    saveRecurrenceEndDate(date) {
      this.menuRecurrenceEndDatePicker = false;
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      this.recurrenceEndDate = date.toISOString().split("T")[0];
    },
    verifyAvailability(startTimeUTC, endTimeUTC) {
      let startTimeUserTimezone = convertUTCDateTimeToUserTimezoneNoFormat(
        startTimeUTC,
        this.currentUser.timezone
      );

      let endTimeUserTimezone = convertUTCDateTimeToUserTimezoneNoFormat(
        endTimeUTC,
        this.currentUser.timezone
      );

      let localTimeUserTimezone = getCurrentTimeInUserTimezone(
        this.currentUser.timezone
      );

      // Availability start and end time checks
      if (startTimeUserTimezone.isAfter(endTimeUserTimezone)) {
        throw new Error("Start time cannot be after end time");
      }
      if (startTimeUserTimezone.isSame(endTimeUserTimezone)) {
        throw new Error("Start time cannot be the same as end time");
      }
      if (startTimeUserTimezone.isBefore(localTimeUserTimezone)) {
        throw new Error("Start time cannot be in the past");
      }
      if (endTimeUserTimezone.isBefore(localTimeUserTimezone)) {
        throw new Error("End time cannot be in the past");
      }

      // Availability overlap checks with existing availabilities
      for (const availability of this.trainerAvailability) {
        let availabilityStartTimeInUserTimezone =
          convertUTCDateTimeToUserTimezoneNoFormat(
            availability.startTime,
            this.currentUser.timezone
          );

        let availabilityEndTimeInUserTimezone =
          convertUTCDateTimeToUserTimezoneNoFormat(
            availability.endTime,
            this.currentUser.timezone
          );

        if (
          startTimeUserTimezone.isSame(availabilityStartTimeInUserTimezone) &&
          endTimeUserTimezone.isSame(availabilityEndTimeInUserTimezone)
        ) {
          throw new Error("Availability overlaps with existing availability");
        }
        if (
          startTimeUserTimezone.isSameOrAfter(
            availabilityStartTimeInUserTimezone
          ) &&
          startTimeUserTimezone.isBefore(availabilityEndTimeInUserTimezone)
        ) {
          throw new Error("Start time overlaps with existing availability");
        }
        if (
          endTimeUserTimezone.isAfter(availabilityStartTimeInUserTimezone) &&
          endTimeUserTimezone.isSameOrBefore(availabilityEndTimeInUserTimezone)
        ) {
          throw new Error("End time overlaps with existing availability");
        }
        if (
          startTimeUserTimezone.isBefore(availabilityStartTimeInUserTimezone) &&
          endTimeUserTimezone.isAfter(availabilityEndTimeInUserTimezone)
        ) {
          throw new Error("Availability overlaps with existing availability");
        }
      }
    },
    verifyAvailabilityDates(availabilityDates, startTime, endTime) {
      for (const date of availabilityDates) {
        let startTimeUserTimezone = date + " " + startTime;
        let endTimeUserTimezone = date + " " + endTime;

        let startTimeUTC = convertUserTimezoneDateTimeToUTC(
          startTimeUserTimezone,
          this.currentUser.timezone
        );

        let endTimeUTC = convertUserTimezoneDateTimeToUTC(
          endTimeUserTimezone,
          this.currentUser.timezone
        );

        this.verifyAvailability(startTimeUTC, endTimeUTC);
      }
    },
  },
};
</script>

<style scoped>
.radio-group-header {
  padding: 5px;
  padding-left: 15px;
  padding-top: 15px;
}

.radio-group-buttons {
  font-size: 12px;
  padding: 5px;
}
</style>
