<template>
  <v-container style="min-height: 70vh">
    <v-row class="my-15" justify="center">
      <v-col xs="12" sm="12" md="12" lg="9" xl="6">
        <base-card class="pt-10 pb-15">
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            Cookie Policy
          </p>
          <div
            :class="{
              'mx-15': $vuetify.display.mdAndUp,
              'mx-5': $vuetify.display.smAndDown,
              'text text-body-2': true,
            }"
            style="font-size: 16px"
          >
            <p>
              <strong>NETWORKTAL</strong> ("we," "us," or "our") uses cookies
              and other tracking technologies on our platform (the "Platform"),
              including our website, to distinguish you from other users and
              improve your experience. This Cookie Policy explains what cookies
              are, the different types of cookies we use, how we use them, your
              choices regarding cookies, and how to contact us with any
              questions.
            </p>

            <h2>What are Cookies?</h2>

            <p>
              Cookies are small text files that a website transfers to your
              device (computer, phone, tablet) when you visit the site. They
              store information about your visit, such as your preferences and
              settings, which can then be used to personalize your experience on
              the Platform.
            </p>
            <br />
            <p>Here are some additional details about cookies:</p>
            <ul>
              <li>
                <strong>Session Cookies:</strong> These cookies are temporary
                and are deleted from your device when you close your browser.
              </li>
              <li>
                <strong>Persistent Cookies:</strong> These cookies remain on
                your device for a predetermined period of time or until you
                manually delete them.
              </li>
              <li>
                <strong>First-Party Cookies:</strong> These cookies are placed
                on your device by the website you are visiting.
              </li>
              <li>
                <strong>Third-Party Cookies:</strong> These cookies are placed
                on your device by a different website or domain than the one you
                are visiting. These are often used for advertising and website
                analytics purposes.
              </li>
            </ul>

            <h2>What Other Tracking Technologies Do We Use?</h2>
            <p>
              In addition to cookies, we may also use other tracking
              technologies on the Platform such as:
            </p>
            <ul>
              <li>
                <strong>Web Beacons:</strong> These are small electronic images
                embedded on a webpage or email that can be used to track
                activity on the page or email, such as whether it has been
                viewed or clicked on.
              </li>
              <li>
                <strong>Pixel Tags:</strong> Similar to web beacons, pixel tags
                are used to track activity on the Platform but they are
                invisible to the user.
              </li>
              <li>
                <strong>Local Storage:</strong> This technology allows websites
                to store data locally on your device, such as your browsing
                history or preferences.
              </li>
            </ul>

            <h2>What Types of Cookies Do We Use?</h2>
            <p>We use the following types of cookies on the Platform:</p>
            <ul>
              <li>
                <strong>Essential Cookies (Strictly Necessary Cookies):</strong>
                These cookies are essential for the operation of the Platform.
                They allow you to navigate the Platform and use its essential
                features, such as accessing secure areas and booking sessions.
                Without these cookies, the Platform would not function properly.
              </li>
              <li>
                <strong>Functionality Cookies:</strong> These cookies remember
                your preferences and settings on the Platform, such as your
                preferred language or location. This helps us personalize your
                experience and avoid you having to re-enter your preferences
                every time you visit the Platform.
              </li>
              <li>
                <strong>Analytics Cookies:</strong> These cookies collect
                information about how you use the Platform, such as the pages
                you visit, the time you spend on each page, the links you click
                on, and any errors you encounter. This information helps us
                understand how users interact with the Platform and improve its
                functionality, performance, and user experience. We use
                third-party analytics tools like Google Analytics for this
                purpose.
              </li>
              <li>
                <strong>Marketing Cookies:</strong> These cookies are used to
                track your activity across different websites and platforms.
                This information is used to deliver targeted advertising to you
                that is relevant to your interests. We may use third-party
                advertising platforms like Facebook Ads or Google Ads for this
                purpose.
              </li>
            </ul>

            <h2>How We Use Cookies</h2>
            <p>
              We use the information collected from cookies and other tracking
              technologies for the following purposes:
            </p>
            <ul>
              <li>
                To operate and improve the Platform by providing a smooth,
                efficient, and personalized user experience.
              </li>
              <li>
                To remember your preferences and settings to avoid you having to
                re-enter them every time you visit the Platform.
              </li>
              <li>
                To understand how users interact with the Platform to improve
                its functionality and user experience.
              </li>
              <li>
                To analyze user behavior and trends to identify areas for
                improvement and provide relevant content and features.
              </li>
              <li>
                To deliver targeted advertising to you based on your interests
                and browsing behavior.
              </li>
            </ul>

            <h2>Changes to this Cookie Policy</h2>
            <p>
              We may update this Cookie Policy from time to time to reflect
              changes in our practices or applicable laws. We will notify you of
              any changes by posting the new Cookie Policy on the Platform. We
              recommend that you review this Cookie Policy periodically to stay
              informed about our practices.
            </p>

            <h2>Your Choices and Control</h2>

            <p>
              You can set your browser to block cookies, so it does not store
              cookies anymore. You can also delete all information which has
              been stored earlier via the settings in your browser.
            </p>

            <p>
              Please note that this cookie policy is not applicable to and we
              are not responsible for the cookie and other privacy related
              practices on third party websites or web applications which may be
              linked to this platform.
            </p>

            <h2>More Information</h2>

            <p>
              For more information about the processing of personal data, please
              read our
              <router-link to="/privacy-policy">Privacy Policy</router-link>.
            </p>

            <h2>Contact Us</h2>
            <p>
              If you have any questions or concerns about our use of cookies or
              this Cookie Policy, please contact us at
              <a href="mailto:support@networktal.com">support@networktal.com</a>
            </p>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { setMetaTags } from "../../utils/utils.js";

export default {
  created() {
    setMetaTags(
      "NetworkTal - Cookie Policy",
      "Learn more about our Cookie Policy"
    );
  },
};
</script>

<style scoped>
.text {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text h2 {
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 20px;
}

.paragraph-title {
  font-weight: 450;
  margin-top: 30px;
}

.text ul {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.text a {
  color: #3f51b5;
  text-decoration: none;
}

.text a:hover {
  text-decoration: underline;
}
</style>
