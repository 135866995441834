<template>
  <v-container style="max-height: 50vh; overflow: auto">
    <div v-if="trainerProfile.certifications.length !== 0">
      <certification-card
        class="ma-0 pa-0 ma-2"
        v-for="certification in trainerProfile.certifications"
        :key="certification.id"
        :certification="certification"
        :trainerId="trainerProfile.id"
      ></certification-card>
    </div>
    <p class="text-body-2" v-else>No certifications added yet...</p>
  </v-container>
</template>

<script>
import certificationCard from "./certificationcard.vue";

export default {
  components: {
    certificationCard,
  },
  props: {
    trainerProfile: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
