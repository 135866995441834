<template>
  <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col
        cols="12"
        md="10"
        lg="6"
        xl="6"
        :class="{
          'pr-5': $vuetify.display.mdAndUp,
          'py-5': $vuetify.display.smAndDown,
          'pa-0': true,
        }"
      >
        <base-card v-if="loading">
          <base-loader></base-loader>
        </base-card>
        <base-card
          :loading="loading"
          v-if="!stripeTrainerAccountOnboarded && displayCard"
        >
          <p class="text-h4 font-weight-medium text-center my-10 mx-15">
            Connect your account to Stripe
          </p>
          <p class="mx-15 text-subtitle-1">
            We use Stripe to make sure you get paid on time and keep your
            personal and bank details secure.
          </p>
          <base-loader v-if="false"></base-loader>
          <div class="mx-15 my-10 text-center">
            <a :href="stripeAccountLink">
              <base-button
                :disabled="stripeAccountLink === null"
                customColor="warning"
              >
                Set up payments
              </base-button>
            </a>
            <!-- <base-tooltip-info
              text="Payments account set up will be available soon"
              :custom="true"
            >
              <base-button :disabled="true"> Set up payments </base-button>
            </base-tooltip-info> -->
          </div>
        </base-card>
        <base-card
          :loading="loading"
          v-else-if="stripeTrainerAccountOnboarded && displayCard"
        >
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            My Payments
          </p>
          <p class="mx-15" style="font-size: 16px">
            We use Stripe to make sure you get paid on time and keep your
            personal and bank details secure.
          </p>
          <base-loader v-if="false"></base-loader>
          <div class="mx-15 my-10 text-center">
            <a :href="stripeLoginLink" target="_blank">
              <base-button :disabled="stripeLoginLink === null">
                View Payments Account
              </base-button>
            </a>
            <!-- <base-tooltip-info
              text="Payments account set up will be available soon"
              :custom="true"
            >
              <base-button :disabled="true">
                View Payments Account
              </base-button>
            </base-tooltip-info> -->
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { setMetaTags } from "../../../utils/utils.js";

export default {
  data() {
    return {
      stripeTrainerAccountOnboarded: false,
      displayCard: false,
    };
  },
  created() {
    this.createAccountOrLoginLink();
    setMetaTags("NetworkTal - My Payments", "My Payments");
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
      stripeAccountLink: "payments/stripeAccountLink",
      stripeLoginLink: "payments/stripeLoginLink",
      stripeTrainerAccount: "payments/stripeTrainerAccount",
    }),
    loading() {
      if (
        this.currentUserTrainerProfile === null ||
        this.displayCard === false
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      createStripeAccountLink: "payments/createStripeAccountLink",
      createStripeLoginLink: "payments/createStripeLoginLink",
      retrieveConnectedAccount: "payments/retrieveConnectedAccount",
    }),
    createAccountOrLoginLink() {
      if (this.currentUserTrainerProfile) {
        this.retrieveConnectedAccount({
          stripeTrainerAccountId:
            this.currentUserTrainerProfile.stripeAccountId,
        }).then(() => {
          if (
            this.stripeTrainerAccount !== null &&
            this.stripeTrainerAccount.charges_enabled === true
          ) {
            this.stripeTrainerAccountOnboarded = true;
            this.createStripeLoginLink({
              stripeTrainerAccountId:
                this.currentUserTrainerProfile.stripeAccountId,
            });
            this.displayCard = true;
          } else {
            this.createStripeAccountLink({
              stripeTrainerAccountId:
                this.currentUserTrainerProfile.stripeAccountId,
            });
            this.displayCard = true;
          }
        });
      }
    },
  },
  watch: {
    currentUserTrainerProfile() {
      this.createAccountOrLoginLink();
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
</style>
