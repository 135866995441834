import db from "../firebase-init";
import store from "../store/index.js";

export const roomService = {
  get,
  createPrivateChat,
  createChatRoom,
  getRoomDetail,
  sendMessage,
};

async function get(currentUser, targetUser) {
  return db
    .collection("rooms")
    .where("isPrivate", "==", true)
    .where("users", "array-contains", currentUser)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return { success: false, error: "No chat room(s)" };
      }

      var roomId;
      var success = false;
      for (var i = 0; i < snapshot.docs.length; i++) {
        // Workaround as multiple array-contains filter is not allowed
        if (snapshot.docs[i].data().users.indexOf(targetUser) >= 0) {
          roomId = snapshot.docs[i].id;
          success = true;
          break;
        }
      }

      return { success: success, roomID: roomId };
    })
    .catch(handleError);
}

async function createPrivateChat(currentUser, targetUser) {
  var data = {
    isPrivate: true,
    users: [currentUser, targetUser],
  };

  return db
    .collection("rooms")
    .add(data)
    .then(function (doc) {
      return { success: true, roomID: doc.id };
    })
    .catch(handleError);
}

async function getRoomDetail(room) {
  return db
    .collection("rooms")
    .doc(room)
    .get()
    .then((snapshot) => {
      if (!snapshot.exists) {
        return { success: false, error: "No chat room(s)" };
      }

      return { success: true, userIds: snapshot.data().users };
    })
    .catch(handleError);
}

async function sendMessage(sender, room, message) {
  var now = new Date();

  var data = {
    sender: sender,
    message: message,
    timestamp: now.toISOString(),
  };

  // get recipient user id
  let roomActiveUsers = store.getters["room/activeUsers"];
  let receiverUserId = roomActiveUsers.filter((user) => user !== sender)[0];

  // get sender profile details
  let currentUser = store.getters["users/currentUser"];

  // get receiver user fcm tokens
  let receiverFcmTokens = await store.dispatch(
    "notifications/getUserFcmTokens",
    receiverUserId
  );

  // send message
  await db
    .collection("rooms")
    .doc(room)
    .collection("messages")
    .add(data)
    .then(function (doc) {
      // send notification
      receiverFcmTokens.forEach((token) => {
        store.dispatch("notifications/sendNewNotification", {
          title: "New message from " + currentUser.displayName,
          body: message,
          token: token,
          data: {
            roomId: room,
            senderId: sender,
            senderName: currentUser.displayName,
            receiverId: receiverUserId,
            type: "new-message",
          },
        });
      });
      return { success: true, messageID: doc.id };
    })
    .catch(handleError);
}

async function createChatRoom(userIDs) {
  var data = {
    isPrivate: userIDs.length <= 2,
    users: userIDs,
  };

  return db
    .collection("rooms")
    .add(data)
    .then(function (doc) {
      return { success: true, roomID: doc.id };
    })
    .catch(handleError);
}

function handleError(error) {
  // console.log('Ops! ' + error)
  return Promise.reject(error);
}
