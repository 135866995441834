<template>
  <div>
    <v-container class="my-10" style="min-height: 70vh">
      <v-row justify="center">
        <v-col cols="12" sm="8" md="11" lg="9" xl="7" align="center">
          <base-card class="py-7 text-h5 font-weight-medium">
            <v-icon size="small" start>mdi-view-dashboard</v-icon>
            Explorer Dashboard
          </base-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="4" lg="3" xl="2">
          <user-overview />
          <user-interests-overview class="mt-5" />
          <user-find-trainer class="mt-5" />
          <user-become-trainer class="mt-5" />
        </v-col>
        <v-col cols="12" sm="8" md="7" lg="6" xl="5">
          <my-user-sessions-overview class="" />
          <my-trainers-overview class="mt-5" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MyTrainersOverview from "../../../components/user/dashboard/mytrainersoverview.vue";
import MyUserSessionsOverview from "../../../components/user/dashboard/myusersessionsoverview.vue";
import UserInterestsOverview from "../../../components/user/dashboard/userinterestsoverview.vue";
import UserOverview from "../../../components/user/dashboard/useroverview.vue";
import UserBecomeTrainer from "../../../components/user/dashboard/userbecometrainer.vue";
import UserFindTrainer from "../../../components/user/dashboard/userfindtrainer.vue";
import { setMetaTags } from "../../../utils/utils.js";

export default {
  components: {
    MyTrainersOverview,
    MyUserSessionsOverview,
    UserBecomeTrainer,
    UserInterestsOverview,
    UserOverview,
    UserFindTrainer,
  },
  created() {
    setMetaTags(
      "NetworkTal - Explorer Dashboard",
      "Welcome to your explorer dashboard! Here you can manage your explorer profile, view booked sessions, and more."
    );
  },
};
</script>

<style scoped></style>
