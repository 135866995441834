<template>
  <div>
    <base-dialog :model="viewCertificationDialog">
      <v-toolbar class="text-h6 text-white" color="colorSpecial5" elevation="1">
        <v-row justify="space-between" class="ma-0 pa-0">
          <v-col cols="10" class="pt-5">Certification</v-col>
          <v-col cols="2" align="end">
            <v-btn icon @click="closeDialog" class="text-white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-container class="text-body-2 pt-8 px-10 pb-10">
        <v-row justify="center">
          <v-col align="center">
            <v-img
              max-height="300"
              :src="getPhotoURL(certification.photoURL)"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="5"
            lg="5"
            xl="5"
            class="text-body-1 font-weight-medium"
          >
            Name
          </v-col>
          <v-col cols="12" md="7" lg="7" xl="7">{{ certification.name }}</v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col
            cols="12"
            md="5"
            lg="5"
            xl="5"
            class="text-body-1 font-weight-medium"
          >
            Description
          </v-col>
          <v-col cols="12" md="7" lg="7" xl="7">{{
            certification.description
          }}</v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col
            cols="12"
            md="5"
            lg="5"
            xl="5"
            class="text-body-1 font-weight-medium"
          >
            Provider
          </v-col>
          <v-col cols="12" md="7" lg="7" xl="7">{{
            certification.provider
          }}</v-col>
        </v-row>
        <v-divider v-if="certification.number" class="my-3"></v-divider>
        <v-row v-if="certification.number">
          <v-col
            cols="12"
            md="5"
            lg="5"
            xl="5"
            class="text-body-1 font-weight-medium"
          >
            Number
          </v-col>
          <v-col cols="12" md="7" lg="7" xl="7">{{
            certification.number
          }}</v-col>
        </v-row>
        <v-divider v-if="certification.link" class="my-3"></v-divider>
        <v-row v-if="certification.link">
          <v-col
            cols="12"
            md="5"
            lg="5"
            xl="5"
            class="text-body-1 font-weight-medium"
          >
            Link
          </v-col>
          <v-col cols="12" md="7" lg="7" xl="7">
            <a :href="certification.link" target="_blank">{{
              certification.link
            }}</a>
          </v-col>
        </v-row>
        <!-- <v-divider class="my-3"></v-divider> -->
        <!-- <v-row class="mt-5">
          <v-col>
            <base-button customColor="primary" @click="closeDialog()"
              >Close</base-button
            >
          </v-col>
        </v-row> -->
      </v-container>
    </base-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  inject: ["eventBus"],
  props: {
    certification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {
    this.eventBus.on("set-base-dialog", (value) => {
      this.setViewCertificationDialog(value);
    });
  },
  unmounted() {
    this.eventBus.off("set-base-dialog");
  },
  computed: {
    ...mapGetters({
      certificationPhotoDefault: "websiteData/certificationPhotoDefault",
      viewCertificationDialog: "trainers/viewCertificationDialog",
    }),
  },
  methods: {
    ...mapActions({
      setViewCertificationDialog: "trainers/setViewCertificationDialog",
    }),
    closeDialog() {
      this.setViewCertificationDialog(false);
    },
    getPhotoURL(photoURL) {
      return photoURL ? photoURL : this.certificationPhotoDefault;
    },
  },
};
</script>

<style scoped>
.seesion-category-data {
  padding-left: 30px;
}
</style>
