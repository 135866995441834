<template>
  <v-container style="min-height: 70vh">
    <v-row class="my-15" justify="center">
      <v-col xs="12" sm="12" md="12" lg="9" xl="6">
        <base-card class="pt-10 pb-15 px-5">
          <p class="text-h4 font-weight-medium text-center my-15 mx-5">
            {{ blogpost.title }}
          </p>
          <div class="blog" v-html="content"></div>
          <div class="blog-button">
            <base-button to="/blog">
              <v-icon size="small" class="mr-2">mdi-post-outline</v-icon>
              read more
            </base-button>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { setMetaTags } from "../../utils/utils.js";

// Function to require all markdown files from the blogposts folder
function requireAll(requireContext) {
  return requireContext.keys().map((fileName) => {
    const id = fileName.replace(/^\.\/(.*)\.md$/, "$1");
    const content = requireContext(fileName);
    return { id, content };
  });
}

// Import all markdown files from the blogposts folder
const blogpostsContentFiles = requireAll(
  require.context("@/content/blogposts", false, /\.md$/)
);

export default {
  data() {
    return {
      blogpostsContentFiles,
      content: "",
      blogpost: {
        title: "",
      },
    };
  },
  async created() {
    await this.getBlogPosts();
    this.blogpost = this.getBlogPost();
    this.content = this.getBlogpostContent();
    setMetaTags(
      `NetworkTal - ${this.blogpost.title}`,
      this.blogpost.description
    );
  },
  computed: {
    ...mapGetters({
      blogposts: "blogposts/blogposts",
    }),
  },
  methods: {
    ...mapActions({
      getBlogPosts: "blogposts/getBlogPosts",
    }),
    getBlogPost() {
      const blogpost = this.blogposts.find(
        (blogpost) => blogpost.path === this.$route.path.split("/")[2]
      );

      if (blogpost) {
        return blogpost;
      } else {
        return "";
      }
    },
    getBlogpostContent() {
      // find in the blogpostsContentFiles array the blogpostsContentFile with the same id as the route path
      const blogpostsContentFile = this.blogpostsContentFiles.find(
        (blogpost) => blogpost.id === this.$route.path.split("/")[2]
      );

      if (blogpostsContentFile) {
        return blogpostsContentFile.content.default;
      } else {
        this.$router.push({ name: "PageNotFound", params: { pathMatch: "*" } });
        return "";
      }
    },
  },
};
</script>

<style scoped>
.blog {
  margin: 50px;
  margin-top: 100px;
}

@media (max-width: 600px) {
  .blog {
    margin: 10px;
    margin-top: 50px;
  }
}

.blog-button {
  margin-left: 50px;
  margin-top: 50px;
}

@media (max-width: 600px) {
  .blog-button {
    margin-left: 5px;
    margin-top: 20px;
  }
}

.blog :deep() img {
  max-width: 100%;
  max-height: 100%;
}

.blog :deep() h1 {
  margin-bottom: 25px;
  font-size: 30px;
}

.blog :deep() h2 {
  margin: 25px 0px;
  font-size: 25px;
  font-weight: 410;
}

.blog :deep() h3 {
  margin: 25px 0px;
  font-size: 20px;
}

.blog :deep() hr {
  margin-bottom: 25px;
}

.blog :deep() ol {
  margin-top: 20px;
  margin-bottom: 20px;
}
.blog :deep() ol > li {
  margin: 5px 25px;
}
.blog :deep() ul {
  margin-top: 20px;
  margin-bottom: 20px;
}
.blog :deep() ul > li {
  margin: 5px 25px;
}

.blog :deep() p,
.blog :deep() li {
  font-size: 14px;
  margin-bottom: 12px;
}

.blog :deep() table,
.blog :deep() td,
.blog :deep() tr,
.blog :deep() th {
  border: 1px solid black;
  text-align: center;
  width: auto;
}

.blog :deep() th {
  border: 1px solid black;
  text-align: center;
  width: 180px;
}

.blog :deep() table {
  margin: 5% 0%;
  font-size: 20px;
  justify-content: center;
}

.blog :deep() img {
  max-width: 100%;
  max-height: 100%;
  margin: 20px 0px;
}
</style>
