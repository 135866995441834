<template>
  <div>
    <base-card class="pa-5 pt-2" style="text-align: center">
      <base-loader v-if="!currentUserTrainerProfile"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0" align="center">
          <v-col
            class="ma-0 pa-0 text-body-2 font-weight-medium"
            cols="10"
            align="start"
          >
            <v-icon size="small" class="pr-2">mdi-message-cog-outline</v-icon>
            Communication Tools</v-col
          >
          <v-col class="ma-0 pa-0" align="right">
            <router-link to="/settings/trainer/profile/communicationtools">
              <v-icon size="15" color="grey">mdi-pencil</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <v-row class="ma-0 px-5 py-5" justify="center">
          <v-col
            v-if="
              currentUserTrainerProfile.communicationTools[0].enabled === true
            "
            cols="3"
            align="center"
            class="ma-0 pa-2"
          >
            <v-img
              width="30"
              :src="
                require(`@/assets/default/communicationtools/skype-icon.png`)
              "
            ></v-img>
          </v-col>
          <v-col
            v-if="
              currentUserTrainerProfile.communicationTools[1].enabled === true
            "
            cols="3"
            align="center"
            class="ma-0 pa-2"
          >
            <v-img
              width="30"
              :src="
                require(`@/assets/default/communicationtools/facetime-icon.png`)
              "
            ></v-img>
          </v-col>
          <v-col
            v-if="
              currentUserTrainerProfile.communicationTools[2].enabled === true
            "
            cols="3"
            align="center"
            class="ma-0 pa-2"
          >
            <v-img
              width="30"
              :src="
                require(`@/assets/default/communicationtools/googlemeet-icon.png`)
              "
            ></v-img>
          </v-col>
          <v-col
            v-if="
              currentUserTrainerProfile.communicationTools[3].enabled === true
            "
            cols="3"
            align="center"
            class="ma-0 pa-2"
          >
            <v-img
              width="30"
              :src="
                require(`@/assets/default/communicationtools/zoom-icon.png`)
              "
            ></v-img>
          </v-col>
        </v-row>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
    }),
  },
};
</script>

<style scoped></style>
