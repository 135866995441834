<template>
  <v-container
    fluid
    class="ma-0 pa-0 px-2 text-subtitle-1"
    style="min-height: 70vh"
  >
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col class="pa-0" xs="12" sm="9" md="8" lg="6" xl="6">
        <base-card :loading="loading" style="min-height: 300px">
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            Settings
          </p>
          <base-loader v-if="loading"></base-loader>
          <div v-else>
            <v-row
              :class="{
                'ma-10': $vuetify.display.mdAndUp,
                'ma-2': $vuetify.display.smAndDown,
              }"
            >
              <general-settings :userProfile="currentUser"></general-settings>
            </v-row>
            <v-row
              :class="{
                'ma-10': $vuetify.display.mdAndUp,
                'ma-2': $vuetify.display.smAndDown,
              }"
            >
              <display-settings :userProfile="currentUser"></display-settings>
            </v-row>
            <v-row
              :class="{
                'ma-10': $vuetify.display.mdAndUp,
                'ma-2': $vuetify.display.smAndDown,
              }"
            >
              <delete-account :userProfile="currentUser"></delete-account>
            </v-row>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import generalSettings from "@/components/user/settings/general/settings.vue";
import displaySettings from "@/components/user/settings/display/settings.vue";
import deleteAccount from "@/components/user/settings/deleteaccount/deleteaccount.vue";
import { mapActions, mapGetters } from "vuex";
import { setMetaTags } from "../../../utils/utils.js";

export default {
  inject: ["eventBus"],
  data() {
    return {};
  },
  components: {
    deleteAccount,
    displaySettings,
    generalSettings,
  },
  created() {
    this.eventBus.on("general-settings-edited", () => {
      this.getUserProfileDetails();
    });
    setMetaTags("NetworkTal - User Account Settings", "User Account Settings");
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
      userId: "auth/userId",
    }),
    loading() {
      if (this.currentUser) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions({
      getUserByUserId: "users/getUserByUserId",
    }),
    async getUserProfileDetails() {
      await this.getUserByUserId(this.userId);
    },
  },
};
</script>

<style scoped></style>
