import db from "../../../firebase-init";
import {
  collection,
  query,
  doc,
  getDoc,
  getDocs,
  setDoc,
  where,
} from "firebase/firestore";

export default {
  async addOrUpdateCertification(context, payload) {
    // console.log(
    //     'Add or Update Certification triggered with payload: ' + JSON.stringify(payload)
    // )

    let trainer = context.getters.currentUserTrainerProfile;

    if (!trainer.certifications) {
      trainer.certifications = [];
    }

    let index = trainer.certifications.findIndex(
      (certification) => certification.id === payload.data.id
    );

    if (index >= 0) {
      trainer.certifications[index] = {
        ...payload.data,
      };
    } else {
      trainer.certifications.push({
        ...payload.data,
      });
    }

    await context.dispatch("createOrUpdateTrainer", {
      trainerId: payload.trainerId,
      data: {
        ...trainer,
      },
    });
  },

  async removeCertification(context, payload) {
    // console.log('Remove Certification triggered with payload: ' + JSON.stringify(payload))

    let trainer = context.getters.currentUserTrainerProfile;

    let index = trainer.certifications.findIndex(
      (certification) => certification.id === payload.id
    );

    // console.log('Index: ' + index)

    trainer.certifications.splice(index, 1);

    await context.dispatch("createOrUpdateTrainer", {
      trainerId: payload.trainerId,
      data: {
        ...trainer,
      },
    });
  },

  async createOrUpdateTrainer(context, payload) {
    // console.log('Create or update Trainer Payload: ' + JSON.stringify(payload))

    try {
      await setDoc(doc(db, "trainers", payload.trainerId), payload.data);
    } catch (error) {
      throw Error(error);
    }

    let currentUserId = context.rootGetters["auth/userId"];
    if (currentUserId === payload.data.userId) {
      context.dispatch("setCurrentUserTrainerProfile", payload.data);
    }

    await context.dispatch("loadTrainers", {
      forceRefresh: true,
    });
  },

  async getTrainerByUserId(context, payload) {
    let trainerDetails;
    const q = query(collection(db, "trainers"), where("userId", "==", payload));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.size === 0) {
      // console.log('Warning: No trainer has been found with the user id: ' + payload)
    } else if (querySnapshot.size === 1) {
      querySnapshot.forEach((doc) => {
        trainerDetails = {
          id: doc.id,
          ...doc.data(),
        };
        // console.log('Trainer details: ' + JSON.stringify(trainerDetails))
      });

      let currentUserId = context.rootGetters["auth/userId"];
      if (currentUserId === trainerDetails.userId) {
        context.dispatch("setCurrentUserTrainerProfile", trainerDetails);
      }
    } else {
      // console.log('Warning: Multiple trainers been found with the user id: ' + payload)
    }
    return trainerDetails;
  },

  async getTrainerById(context, payload) {
    const q = doc(db, "trainers", payload.id);
    const querySnapshot = await getDoc(q);

    if (querySnapshot.exists()) {
      let selectedTrainer = {
        id: querySnapshot.id,
        ...querySnapshot.data(),
      };

      let currentUserId = context.rootGetters["auth/userId"];
      if (currentUserId === selectedTrainer.userId) {
        context.dispatch("setCurrentUserTrainerProfile", selectedTrainer);
      }
      return selectedTrainer;
    } else {
      throw Error("Trainer with id " + payload.id + " was not found.");
    }
  },

  async loadTrainers(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    const trainers = [];
    let responseData = await db.collection("trainers").get();

    responseData.forEach((doc) => {
      let trainer = doc.data();
      trainer.id = doc.id;
      // load only approved and stripe enabled trainers
      if (
        trainer.registrationRequestStatus === "approved" &&
        trainer.stripeAccountEnabled === true
      ) {
        trainers.push(trainer);
      }
    });

    // console.log("Trainers: " + JSON.stringify(trainers));

    context.commit("setTrainers", trainers);
    context.commit("setFetchTimestamp");
  },

  setFilter(context, payload) {
    var newFilter = context.getters.filter;
    newFilter[payload.area] = payload.value;
    context.commit("setFilter", newFilter);
  },

  setMessageTrainerDialog(context, payload) {
    context.commit("setMessageTrainerDialog", payload);
  },

  setAddOrUpdateCertificationDialog(context, payload) {
    context.commit("setAddOrUpdateCertificationDialog", payload);
  },

  setViewCertificationDialog(context, payload) {
    context.commit("setViewCertificationDialog", payload);
  },

  getEditedTrainerCommunicationToolsStatus(context, payload) {
    // initialize statuses array
    let statuses = [];

    // check if all communication tools are valid
    payload.communicationTools.forEach((tool) => {
      if (tool.name !== "Zoom") {
        if (tool.enabled) {
          if (tool.toolId !== "" && tool.toolId !== null) {
            statuses.push("valid");
          } else {
            statuses.push("invalid");
          }
        } else {
          statuses.push("not-enabled");
        }
      } else {
        if (tool.enabled) {
          if (
            tool.toolId.meetingURL !== "" &&
            tool.toolId.meetingURL !== null &&
            tool.toolId.passcode !== "" &&
            tool.toolId.passcode !== null &&
            tool.toolId.personalMeetingId !== "" &&
            tool.toolId.personalMeetingId !== null
          ) {
            statuses.push("valid");
          } else {
            statuses.push("invalid");
          }
        } else {
          statuses.push("not-enabled");
        }
      }
    });

    return statuses;
  },

  setCurrentUserTrainerProfile(context, payload) {
    context.commit("setCurrentUserTrainerProfile", payload);
  },
};
