<template>
  <div>
    <base-card class="pt-10" style="text-align: center">
      <!-- <base-notification-card
        class="mx-5 mb-5"
        align="start"
        :notification="notification"
      ></base-notification-card> -->
      <v-avatar size="150">
        <v-img :src="getPhotoPath"></v-img>
      </v-avatar>
      <p class="text-h5 font-weight-medium pt-5">{{ trainer.displayName }}</p>
      <v-row class="ma-0 pa-0" justify="center">
        <v-col class="ma-0 pa-0" cols="4" align="end">
          <span class="text-grey text-caption mr-2">
            ({{ trainer.reviewsInfo.averageReviewsRating }})
          </span>
        </v-col>
        <v-col class="ma-0 pa-0" cols="6" align="start">
          <v-rating
            v-model="trainer.reviewsInfo.averageReviewsRating"
            color="yellow-accent-4"
            empty-icon="mdi-star-outline"
            full-icon="mdi-star"
            half-increments
            readonly
            density="compact"
            length="5"
            size="small"
          ></v-rating>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0 my-2">
        <v-col :align="alignmentBookSessionButton" class="ma-0 pa-0 px-2 py-1">
          <base-button
            v-if="!isAuthenticated"
            customColor="colorSpecial1"
            :toPath="
              '/login?from=/trainer/' +
              createTrainerProfileDisplayNamePath(trainer.displayName) +
              '-' +
              trainer.id
            "
            >Book Session
          </base-button>
          <base-tooltip-info
            v-else
            :text="toolTipMessage"
            :custom="true"
            :disabled="allowBookOrRequestSession()"
          >
            <base-button
              customColor="colorSpecial1"
              @click="setAddEventDialog(true)"
              :disabled="!allowBookOrRequestSession()"
            >
              Book session
            </base-button>
          </base-tooltip-info>
          <!-- <base-tooltip-info
            text="Booking a session will be available soon"
            :custom="true"
          >
            <base-button :disabled="true"> Book a session </base-button>
          </base-tooltip-info> -->
        </v-col>
        <v-col :align="alignmentSendMessageButton" class="ma-0 pa-0 px-2 py-1">
          <base-button
            v-if="!isAuthenticated"
            :toPath="
              '/login?from=/trainer/' +
              createTrainerProfileDisplayNamePath(trainer.displayName) +
              '-' +
              trainer.id
            "
            >Request Session
          </base-button>
          <base-tooltip-info
            v-else
            :text="toolTipMessage"
            :custom="true"
            :disabled="allowBookOrRequestSession()"
          >
            <base-button
              @click="setMessageTrainerDialog(true)"
              :disabled="!allowBookOrRequestSession()"
            >
              Request Session
            </base-button>
          </base-tooltip-info>
          <!-- <base-tooltip-info
            text="Sending a message will be available soon"
            :custom="true"
          >
            <base-button :disabled="true"> Send a message </base-button>
          </base-tooltip-info> -->
        </v-col>
      </v-row>
      <message-trainer-dialog
        v-if="trainerId"
        :trainerId="trainerId"
      ></message-trainer-dialog>
    </base-card>
    <add-event-dialog v-if="trainer" :trainer="trainer"></add-event-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";
import AddEventDialog from "./booksessiondialog/booksessiondialog.vue";
import MessageTrainerDialog from "./messagetrainerdialog/messagetrainerdialog.vue";

export default {
  setup() {
    const {
      createTrainerProfileDisplayNamePath,
      getTrainerIdFromTrainerProfileFullPath,
    } = useGlobalFunctions();
    return {
      createTrainerProfileDisplayNamePath,
      getTrainerIdFromTrainerProfileFullPath,
    };
  },
  data() {
    return {
      toolTipMessage: "",
      notification: {
        id: 1,
        color: "info",
        icon: "mdi-information-outline",
        message:
          "Session booking and messaging will be available soon! Stay tuned!",
        title: "Coming Soon!",
      },
    };
  },
  components: {
    AddEventDialog,
    MessageTrainerDialog,
  },
  props: {
    trainer: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      userPhotoDefault: "websiteData/userPhotoDefault",
      userId: "auth/userId",
    }),
    trainerId() {
      return this.getTrainerIdFromTrainerProfileFullPath(this.$route.path);
    },
    getPhotoPath() {
      if (this.trainer.photoURL) {
        return this.trainer.photoURL;
      } else {
        return this.userPhotoDefault;
      }
    },
    alignmentBookSessionButton() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "center";
        case "sm":
          return "end";
        case "md":
          return "end";
        case "lg":
          return "end";
        case "xl":
          return "end";
        default:
          return "end";
      }
    },
    alignmentSendMessageButton() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "center";
        case "sm":
          return "start";
        case "md":
          return "start";
        case "lg":
          return "start";
        case "xl":
          return "start";
        default:
          return "start";
      }
    },
  },
  methods: {
    ...mapActions({
      setAddEventDialog: "calendar/setAddEventDialog",
      setMessageTrainerDialog: "trainers/setMessageTrainerDialog",
    }),
    allowBookOrRequestSession() {
      // check if own trainer profile
      if (this.trainer.userId === this.userId) {
        this.toolTipMessage = "You cannot book a session with yourself";
        return false;
      }

      // check if trainer is approved and payments enabled
      if (
        this.trainer.registrationRequestStatus === "approved" &&
        this.trainer.stripeAccountEnabled === true
      ) {
        return true;
      } else {
        this.toolTipMessage = "Trainer account not enabled for session booking";
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
