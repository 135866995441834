<template>
  <div>
    <base-card class="pa-5" style="text-align: center">
      <base-loader v-if="!currentUserTrainerProfile"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0">
          <v-col
            class="ma-0 pa-0 text-body-2 font-weight-medium"
            cols="10"
            align="start"
          >
            <v-icon size="small" class="pr-2">mdi-bell</v-icon>
            Notifications</v-col
          >
        </v-row>
        <v-row
          v-if="
            !addAvailabilityNotificationDisplayed &&
            !addTrialSessionNotificationDisplayed &&
            !addCertificationsNotificationDisplayed &&
            !createPaymentAccountNotificationDisplayed &&
            !addCommunicationToolsNotificationDisplayed
          "
          class="ma-0 pa-0"
        >
          <v-col
            align="center"
            class="ma-0 pa-0 py-3 text-caption text-grey"
            style="font-style: italic"
          >
            No notifications
          </v-col>
        </v-row>
        <div v-else>
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0 pt-3 pb-1" align="start">
              <base-notification-card
                v-if="createPaymentAccountNotificationDisplayed"
                :notification="notifications[0]"
              ></base-notification-card>
              <base-notification-card
                v-if="addAvailabilityNotificationDisplayed"
                :notification="notifications[1]"
              ></base-notification-card>
              <base-notification-card
                v-if="addTrialSessionNotificationDisplayed"
                :notification="notifications[2]"
              ></base-notification-card>
              <base-notification-card
                v-if="addCertificationsNotificationDisplayed"
                :notification="notifications[3]"
              ></base-notification-card>
              <base-notification-card
                v-if="addCommunicationToolsNotificationDisplayed"
                :notification="notifications[4]"
              ></base-notification-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
} from "@/utils/utils.js";

export default {
  data() {
    return {
      addAvailabilityNotificationDisplayed: false,
      notifications: [
        {
          id: 1,
          color: "warning",
          icon: "mdi-alert-outline",
          link: "/settings/trainer/mypayments",
          message:
            "Before you jump into your first sessions with explorers, remember, you must set up your payment account. ",
          title: "Create Payment Account",
        },
        {
          id: 2,
          color: "info",
          icon: "mdi-information-outline",
          link: "/settings/trainer/availability",
          message:
            "Let's help Explorers find the perfect time to connect with you. Add your availability to your profile and make scheduling a breeze!",
          title: "Add Availability",
        },
        {
          id: 3,
          color: "info",
          icon: "mdi-information-outline",
          link: "/settings/trainer/sessions",
          message:
            "Curious Explorers would love to meet you! Why not offer a Trial Session? It's a great way for them to get to know you and see the magic you bring.",
          title: "Add Trial Session (optional)",
        },
        {
          id: 4,
          color: "info",
          icon: "mdi-information-outline",
          link: "/settings/trainer/profile/certifications",
          message:
            "Show off what makes you amazing! Add your Certifications to your profile to highlight your expertise and inspire trust in Explorers.",
          title: "Add Certifications (optional)",
        },
        {
          id: 5,
          color: "info",
          icon: "mdi-information-outline",
          link: "/settings/trainer/profile/communicationtools",
          message:
            "Let’s make sure Explorers can reach you easily! Adding more communication tools to your profile means more ways to engage and share your knowledge.",
          title: "Add More Communication Tools (optional)",
        },
      ],
    };
  },
  async created() {
    this.addAvailabilityNotificationDisplayed =
      await this.getAddAvailabilityNotificationDisplayed();
    if (this.userId) {
      await this.verifyStripeTrainerAccountOnboarding(this.currentUser.userId);
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
      isStripeTrainerAccountOnboarded:
        "payments/isStripeTrainerAccountOnboarded",
      userId: "auth/userId",
    }),
    addTrialSessionNotificationDisplayed() {
      let addTrialSessionNotificationDisplayed = false;
      if (this.currentUserTrainerProfile) {
        let trialSession = this.currentUserTrainerProfile.sessions.find(
          (session) => session.isTrial === true
        );
        addTrialSessionNotificationDisplayed = !trialSession;
      }
      return addTrialSessionNotificationDisplayed;
    },
    addCertificationsNotificationDisplayed() {
      let addCertificationsNotificationDisplayed = false;
      if (this.currentUserTrainerProfile) {
        addCertificationsNotificationDisplayed =
          this.currentUserTrainerProfile.certifications.length === 0;
      }
      return addCertificationsNotificationDisplayed;
    },
    addCommunicationToolsNotificationDisplayed() {
      let addCommunicationToolsNotificationDisplayed = false;
      if (this.currentUserTrainerProfile) {
        let enabledCommunicationToolsNumber =
          this.currentUserTrainerProfile.communicationTools.filter(
            (tool) => tool.enabled === true
          ).length;
        addCommunicationToolsNotificationDisplayed =
          enabledCommunicationToolsNumber < 2;
      }
      return addCommunicationToolsNotificationDisplayed;
    },
    createPaymentAccountNotificationDisplayed() {
      if (
        !this.isStripeTrainerAccountOnboarded &&
        this.currentUserTrainerProfile.registrationRequestStatus !== "rejected"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      getTrainerAvailability: "trainerAvailability/getTrainerAvailability",
      verifyStripeTrainerAccountOnboarding:
        "payments/verifyStripeTrainerAccountOnboarding",
    }),
    async getAddAvailabilityNotificationDisplayed() {
      let addAvailabilityNotificationDisplayed = false;
      let trainerAvailability = await this.getTrainerAvailability({
        trainerId: this.currentUserTrainerProfile.id,
      });

      if (trainerAvailability) {
        // Get all future availability timeframes
        let allFutureAvailabilityTimeframes = trainerAvailability.filter(
          (timeframe) =>
            convertUTCDateTimeToUserTimezoneNoFormat(
              timeframe.endTime,
              this.currentUser.timezone
            ).isAfter(getCurrentTimeInUserTimezone(this.currentUser.timezone))
        );

        // If there are no future availability timeframes, show the notification
        addAvailabilityNotificationDisplayed =
          allFutureAvailabilityTimeframes.length === 0;
      }
      return addAvailabilityNotificationDisplayed;
    },
  },
};
</script>

<style scoped></style>
