import { createRouter, createWebHistory } from "vue-router";
import AboutUs from "../views/general/aboutus.vue";
import Auth from "../views/authentication/auth.vue";
import Blog from "../views/blog/blog.vue";
import BlogPost from "../views/blog/blogpost.vue";
import Contact from "../views/general/contact.vue";
import CookiePolicy from "../views/general/cookiepolicy.vue";
import FAQ from "../views/general/faq.vue";
import HomeView from "../views/home.vue";
import Messages from "../views/messages.vue";
import PasswordReset from "../views/authentication/passwordreset.vue";
import PageNotFound from "../components/notfound/notfound.vue";
import PrivacyPolicy from "../views/general/privacypolicy.vue";
import Sessions from "../views/findsessions.vue";
import SettingsUserGeneral from "../views/user/settings/general.vue";
import SettingsUserProfile from "../views/user/settings/profile.vue";
import SettingsUserProfilePicture from "../views/user/settings/profile.vue";
import SettingsUserBasicInformation from "../views/user/settings/profile.vue";
import SettingsUserInterestAreas from "../views/user/settings/profile.vue";
import SettingsTrainerProfile from "../views/trainer/settings/profile.vue";
import SettingsTrainerInformation from "../views/trainer/settings/profile.vue";
import SettingsTrainerCommunicationTools from "../views/trainer/settings/profile.vue";
import SettingsTrainerCertifications from "../views/trainer/settings/profile.vue";
import SettingsTrainerProfilePicture from "../views/trainer/settings/profile.vue";
import SettingsAvailability from "../views/trainer/settings/availability.vue";
import SettingsTrainerSessionsManagement from "../views/trainer/settings/sessionsmanagement.vue";
import SettingsTrainerMySession from "../views/trainer/settings/session.vue";
import SettingsTrainerMySessions from "../views/trainer/settings/mysessions.vue";
import SettingsTrainerMyExplorers from "../views/trainer/settings/myexplorers.vue";
import SettingsTrainerMyPayments from "../views/trainer/settings/mypayments.vue";
import TermsAndConditions from "../views/general/termsandconditions.vue";
import Trainers from "../views/findtrainers.vue";
import TrainerDashboard from "../views/trainer/dashboard/dashboard.vue";
import TrainerProfile from "../views/trainer/profile/profile.vue";
import TrainerProfileSessionBookingPayment from "../views/trainer/profile/sessionbooking/payment.vue";
import TrainerProfileSessionBookingPaymentStatus from "../views/trainer/profile/sessionbooking/paymentstatus/paymentstatus.vue";
import TrainerProfileSessionBookingSessionStatus from "../views/trainer/profile/sessionbooking/sessionstatus/sessionstatus.vue";
import TrainerRegister from "../views/trainer/register/register.vue";
import TrainerRegisterSuccess from "../views/trainer/register/registersuccess.vue";
import TrainerRegistrationStatus from "../views/trainer/register/registrationstatus.vue";
import UserDashboard from "../views/user/dashboard/dashboard.vue";
import UserMySession from "../views/user/overview/session.vue";
import UserMySessions from "../views/user/overview/mysessions.vue";
import UserMyTrainers from "../views/user/overview/mytrainers.vue";
import VerifyEmail from "../views/authentication/verifyemail.vue";
import { getAuth } from "firebase/auth";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/blog/:id",
    name: "BlogPost",
    component: BlogPost,
  },
  {
    path: "/trainers",
    name: "Trainers",
    component: Trainers,
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: Sessions,
  },
  {
    path: "/trainer/:id",
    props: true,
    component: TrainerProfile,
  },
  {
    path: "/trainer/sessionbooking/payment",
    component: TrainerProfileSessionBookingPayment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/trainer/sessionbooking/paymentstatus",
    component: TrainerProfileSessionBookingPaymentStatus,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/trainer/sessionbooking/sessionstatus",
    component: TrainerProfileSessionBookingSessionStatus,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/becometrainer/register",
    name: "TrainerRegister",
    component: TrainerRegister,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/becometrainer/register/success",
    name: "TrainerRegisterSuccess",
    component: TrainerRegisterSuccess,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/becometrainer/register/status",
    name: "TrainerRegistrationStatus",
    component: TrainerRegistrationStatus,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Auth,
  },
  {
    path: "/passwordreset",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/verifyemail",
    name: "VerifyEmail",
    component: VerifyEmail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Auth,
  },
  {
    path: "/settings/user/dashboard",
    name: "UserDashboard",
    component: UserDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/user/general",
    name: "SettingsUserGeneral",
    component: SettingsUserGeneral,
  },
  {
    path: "/settings/user/profile",
    name: "SettingsUserProfile",
    component: SettingsUserProfile,
    default: SettingsUserProfilePicture,
    children: [
      {
        path: "basicinformation",
        name: "SettingsUserBasicInformation",
        component: SettingsUserBasicInformation,
      },
      {
        path: "picture",
        name: "SettingsUserProfilePicture",
        component: SettingsUserProfilePicture,
      },
      {
        path: "interestareas",
        name: "SettingsUserInterestAreas",
        component: SettingsUserInterestAreas,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/user/mytrainers",
    name: "UserMyTrainers",
    component: UserMyTrainers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/user/mysessions",
    name: "UserMySessions",
    component: UserMySessions,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/user/mysessions/:id",
    name: "UserMySession",
    component: UserMySession,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/dashboard",
    name: "TrainerDashboard",
    component: TrainerDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/profile",
    name: "SettingsTrainerProfile",
    component: SettingsTrainerProfile,
    children: [
      {
        path: "information",
        name: "SettingsTrainerInformation",
        component: SettingsTrainerInformation,
      },
      {
        path: "communicationtools",
        name: "SettingsTrainerCommunicationTools",
        component: SettingsTrainerCommunicationTools,
      },
      {
        path: "certifications",
        name: "SettingsTrainerCertifications",
        component: SettingsTrainerCertifications,
      },
      {
        path: "picture",
        name: "SettingsTrainerProfilePicture",
        component: SettingsTrainerProfilePicture,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/availability",
    name: "SettingsAvailability",
    component: SettingsAvailability,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/sessions",
    name: "SettingsTrainerSessionsManagement",
    component: SettingsTrainerSessionsManagement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/mysessions",
    name: "SettingsTrainerMySessions",
    component: SettingsTrainerMySessions,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/mysessions/:id",
    name: "SettingsTrainerMySession",
    component: SettingsTrainerMySession,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/myexplorers",
    name: "SettingsTrainerMyExplorers",
    component: SettingsTrainerMyExplorers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/mypayments",
    name: "SettingsTrainerMyPayments",
    component: SettingsTrainerMyPayments,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/messages",
    name: "Messages",
    component: Messages,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: TermsAndConditions,
  },

  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/cookie-policy",
    name: "CookiePolicy",
    component: CookiePolicy,
  },
  {
    path: "/:pathMatch(.*)",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;

router.resolve({
  name: "PageNotFound",
  params: { pathMatch: "not-found" },
}).href;

router.beforeEach((to, from, next) => {
  const user = getAuth();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (!from.name) {
    setTimeout(() => {
      if (requiresAuth && !user.currentUser) {
        next("/login?from=" + to.fullPath);
      } else {
        next();
      }
    }, 1500);
  } else {
    if (requiresAuth && !user.currentUser) {
      next("/login?from=" + to.fullPath);
    } else {
      next();
    }
  }
});

// route to login and verifyemail
router.beforeEach(async (to, from, next) => {
  const currentUser = getAuth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    if (!currentUser) {
      next("/login?from=" + to.fullPath);
    } else {
      const isEmailVerified = await new Promise((resolve, reject) => {
        currentUser
          .reload()
          .then(() => {
            resolve(currentUser.emailVerified);
          })
          .catch(reject);
      });

      if (!isEmailVerified && to.name !== "VerifyEmail") {
        next("/verifyemail");
      } else {
        next();
      }
    }
  } else {
    next();
  }
});
