<template>
  <div>
    <base-card class="pa-5 pt-2" style="text-align: center">
      <base-loader v-if="!currentUser"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0">
          <v-col
            class="ma-0 pa-0 pt-2 text-body-2 font-weight-medium"
            cols="10"
            align="start"
          >
            <v-icon size="small" class="pr-2">mdi-account-tie</v-icon>
            My Trainers Overview</v-col
          >
          <v-col class="ma-0 pa-0" align="right">
            <router-link to="/settings/user/mytrainers">
              <v-icon size="15" color="grey">mdi-eye</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <v-row
          v-if="currentUser.myTrainers.length === 0"
          class="ma-0 pa-0 py-5"
        >
          <v-col
            align="center"
            class="ma-0 pa-0 py-3 text-caption text-grey"
            style="font-style: italic"
          >
            No trainers met yet
          </v-col>
        </v-row>
        <div v-else>
          <v-row class="ma-0 pa-0 py-5" justify="center">
            <v-col
              :class="{
                'ma-0 pa-1': $vuetify.display.smAndDown,
                'ma-0 pa-3': $vuetify.display.mdAndUp,
                'ma-0 pa-3 px-7': $vuetify.display.xlAndUp,
              }"
              v-for="trainer in getMostRecent4Trainers()"
              :key="trainer.id"
              cols="3"
            >
              <trainer-card :trainerId="trainer.trainerId"></trainer-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TrainerCard from "./mytrainersoverview/trainercard.vue";

export default {
  data() {
    return {};
  },
  components: {
    TrainerCard,
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
    }),
  },
  methods: {
    getMostRecent4Trainers() {
      let myTrainersOrderedDesc = [];

      try {
        myTrainersOrderedDesc = this.currentUser.myTrainers.sort((a, b) => {
          return new Date(b.dateFirstAdded) - new Date(a.dateFirstAdded);
        });
      } catch (error) {
        // console.error("Error sorting trainers");
      }

      return myTrainersOrderedDesc.slice(0, 4);
    },
  },
};
</script>

<style scoped></style>
