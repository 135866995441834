<template>
  <div>
    <base-card class="trainer-card">
      <base-loader v-if="!trainerId || !trainerProfile"></base-loader>
      <div v-else>
        <router-link
          :to="
            '/trainer/' +
            createTrainerProfileDisplayNamePath(trainerProfile.displayName) +
            '-' +
            trainerProfile.id
          "
        >
          <v-row class="ma-0 pa-0" justify="center">
            <v-col class="ma-0 pa-0" cols="12" sm="6">
              <v-avatar size="30">
                <v-img :src="getPhotoURL"></v-img>
              </v-avatar>
            </v-col>
          </v-row>
          <p
            class="ma-0 pa-0 font-weight-regular text-grey pt-2"
            style="font-size: 8px"
          >
            Trainer
          </p>
          <v-row class="ma-0 pa-0" style="min-height: 35px" align="center">
            <v-col
              class="ma-0 pa-0 px-1"
              style="font-size: 10px; font-weight: 450"
            >
              {{ trainerProfile.displayName }}
            </v-col>
          </v-row>
        </router-link>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";

export default {
  setup() {
    const { createTrainerProfileDisplayNamePath } = useGlobalFunctions();
    return { createTrainerProfileDisplayNamePath };
  },
  data() {
    return {
      trainerProfile: "",
    };
  },
  props: {
    trainerId: {
      required: true,
    },
  },
  async created() {
    await this.fetchTrainerProfile();
  },
  computed: {
    ...mapGetters({
      userPhotoDefault: "websiteData/userPhotoDefault",
    }),
    getPhotoURL() {
      if (this.trainerProfile) {
        if (this.trainerProfile.photoURL) {
          return this.trainerProfile.photoURL;
        } else {
          return this.userPhotoDefault;
        }
      } else {
        return this.userPhotoDefault;
      }
    },
  },
  methods: {
    ...mapActions({
      getTrainerById: "trainers/getTrainerById",
    }),
    async fetchTrainerProfile() {
      try {
        this.trainerProfile = await this.getTrainerById({
          id: this.trainerId,
        });
      } catch (error) {
        // console.error("Something went wrong! Please try again later.");
      }
    },
  },
};
</script>

<style scoped>
.trainer-card {
  background-color: rgb(var(--v-theme-background)) !important;
  min-height: 100px;
}

a {
  text-decoration: none;
  color: inherit;
}
</style>
