<template>
  <div>
    <base-card class="pa-5 pt-2" style="text-align: center">
      <base-loader v-if="!currentUserTrainerProfile"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0" align="right">
            <router-link to="/settings/trainer/profile/information">
              <v-icon size="15" color="grey">mdi-pencil</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <v-avatar size="60">
          <v-img :src="currentUserTrainerProfile.photoURL"></v-img>
        </v-avatar>
        <router-link :to="trainerURL">
          <p class="text-subtitle-1 font-weight-medium pt-5">
            {{ currentUserTrainerProfile.displayName }}
          </p>
        </router-link>
        <p class="text-caption font-weight-regular text-grey">Trainer</p>
        <v-row class="ma-0 pa-0 mt-3" justify="center">
          <v-col align="center" class="ma-0 pa-0">
            <base-button
              customHeight="27px"
              customFontSize="10px"
              customWidth="95px"
              :toPath="trainerURL"
              >View Public Profile</base-button
            ></v-col
          >
        </v-row>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";

export default {
  setup() {
    const { createTrainerProfileDisplayNamePath } = useGlobalFunctions();
    return { createTrainerProfileDisplayNamePath };
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
    }),
    trainerURL() {
      return (
        "/trainer/" +
        this.createTrainerProfileDisplayNamePath(
          this.currentUserTrainerProfile.displayName
        ) +
        "-" +
        this.currentUserTrainerProfile.id
      );
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
</style>
