<template>
  <div>
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col cols="7" class="text-h5 font-weight-medium pl-5"
        >Basic Information</v-col
      >
      <v-col align="end">
        <base-button v-if="!inEditMode" @click="toggleInEditMode">
          Edit
          <base-icon>mdi-pencil</base-icon>
        </base-button>
      </v-col>
    </v-row>
    <base-card
      outlined
      :class="{
        'pa-10': $vuetify.display.mdAndUp,
        'pa-2': $vuetify.display.smAndDown,
      }"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <base-error-success-message
        :successMessage="successMessage"
      ></base-error-success-message>
      <basic-settings-data
        v-if="!inEditMode"
        :userProfile="userProfile"
      ></basic-settings-data>
      <edit-basic-settings-data
        v-if="inEditMode"
        @cancelEdit="toggleInEditMode()"
        @editBasicInformationSuccessful="editBasicInformationSuccessful()"
        :userProfile="userProfile"
      ></edit-basic-settings-data>
    </base-card>
  </div>
</template>

<script>
import basicSettingsData from "../basic/basicsettingsdata.vue";
import editBasicSettingsData from "../basic/editbasicsettingsdata.vue";

export default {
  data() {
    return {
      inEditMode: false,
      successMessage: "",
    };
  },
  components: {
    basicSettingsData,
    editBasicSettingsData,
  },
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toggleInEditMode() {
      this.inEditMode = !this.inEditMode;
    },
    editBasicInformationSuccessful() {
      this.$emit("fetchUserProfile");
      this.toggleInEditMode();
      this.successMessage = "Operation successful!";
      setTimeout(() => {
        this.successMessage = "";
      }, 5000);
    },
  },
};
</script>

<style scoped></style>
