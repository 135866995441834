<template>
  <div>
    <page-header :disableFilterAndSearch="PlatformInPreview"></page-header>
    <preview-placeholder
      v-if="PlatformInPreview"
      class="px-2 pa-0"
    ></preview-placeholder>
    <v-container v-else fluid>
      <v-row v-if="loading" class="ma-0 pa-0" justify="center" align="center">
        <v-col class="ma-0 pa-0" cols="12" md="10" lg="9" xl="7">
          <trainer-card-skeleton></trainer-card-skeleton>
          <trainer-card-skeleton></trainer-card-skeleton>
          <trainer-card-skeleton></trainer-card-skeleton
        ></v-col>
      </v-row>
      <v-row v-else class="ma-0 pa-0" justify="center" align="center">
        <v-col
          v-if="filteredTrainers.length !== 0"
          class="ma-0 pa-0"
          cols="12"
          md="10"
          lg="9"
          xl="7"
        >
          <v-infinite-scroll
            class="ma-0 pa-0"
            :items="filteredTrainers"
            :onLoad="load"
          >
            <template
              v-for="trainer in filteredTrainers.slice(
                0,
                trainersDisplayNumber
              )"
              :key="trainer.id"
            >
              <trainer-card class="my-5" :trainer="trainer"></trainer-card>
            </template>
            <template v-slot:empty>
              <base-button customVariant="outlined" disabled>
                all trainers loaded
              </base-button>
            </template>
          </v-infinite-scroll>
        </v-col>
        <v-col v-else class="ma-0 pa-0" cols="12" md="10" lg="9" xl="7">
          <base-card
            align="center"
            class="text-h5 text-grey py-15 font-weight-regular"
          >
            <v-icon>mdi-magnify-remove-outline</v-icon>
            No trainer found</base-card
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PreviewPlaceholder from "../components/trainer/findtrainers/previewplaceholder.vue";
import TrainerCard from "../components/trainer/findtrainers/trainercard.vue";
import TrainerCardSkeleton from "../components/trainer/findtrainers/trainercardskeleton.vue";
import PageHeader from "../components/trainer/findtrainers/pageheader.vue";
import { setMetaTags } from "../utils/utils.js";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";
import { computed, inject, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const { fetchTrainersInRandomOrder } = useGlobalFunctions();
    const eventBus = inject("eventBus");
    const store = useStore();
    const filteredTrainers = ref("");
    const PlatformInPreview = ref(false);
    const trainersDisplayNumber = ref(5);
    const trainersDisplayStep = ref(5);

    onMounted(async () => {
      // fetch trainers in random order
      filteredTrainers.value = await fetchTrainersInRandomOrder();

      // filter trainers by name
      eventBus.on("filter-trainers-by-name", (name) => {
        // reset the display number to 5
        trainersDisplayNumber.value = 5;

        // filter trainers by name
        filteredTrainers.value = store.getters["trainers/trainers"].filter(
          (trainer) => {
            return trainer.displayName
              .toLowerCase()
              .includes(name ? name.toLowerCase() : "");
          }
        );
      });

      // filter trainers by areas
      eventBus.on("filter-trainers-by-areas", (areas) => {
        // reset the display number to 5
        trainersDisplayNumber.value = 5;

        // filter trainers by areas
        filteredTrainers.value = store.getters["trainers/trainers"].filter(
          (trainer) => {
            return areas.every((area) => {
              return trainer.areas.includes(area);
            });
          }
        );
      });

      setMetaTags(
        "NetworkTal - Find Trainers",
        "Find the right trainer for you from a wide variety of fields"
      );
    });

    const loading = computed(() => {
      return filteredTrainers.value ? false : true;
    });

    const load = ({ done }) => {
      if (
        filteredTrainers.value.length <=
        trainersDisplayNumber.value + trainersDisplayStep.value
      ) {
        trainersDisplayNumber.value = filteredTrainers.value.length;
        done("empty");
      } else {
        trainersDisplayNumber.value += trainersDisplayStep.value;
        done();
      }
    };

    return {
      filteredTrainers,
      load,
      loading,
      PlatformInPreview,
      trainersDisplayNumber,
    };
  },
  components: {
    PageHeader,
    TrainerCard,
    TrainerCardSkeleton,
    PreviewPlaceholder,
  },
};
</script>

<style scoped></style>
