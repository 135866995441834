import db from "../../../firebase-init";
import { collection, getDocs, query, where } from "firebase/firestore";

export default {
  async addReview(_, payload) {
    await db.collection("reviews").add(payload);
  },

  async getReviewsByTrainerId(context, payload) {
    let reviews = [];
    const q = query(
      collection(db, "reviews"),
      where("trainerId", "==", payload)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.size === 0) {
      // console.log('Warning: No reviews has been found with the trainer id: ' + payload)
    } else {
      querySnapshot.forEach((doc) => {
        reviews.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      // console.log('Trainer reviews: ' + JSON.stringify(reviews))
    }

    return reviews;
  },

  async setTrainerRatingInfo(context, payload) {
    // call the action to get the reviews by trainer id
    const reviews = await context.dispatch("getReviewsByTrainerId", payload);

    // calculate the average rating
    let trainerAverageReviewsRating = 0;
    if (reviews.length > 0) {
      let totalRating = 0;
      reviews.forEach((review) => {
        totalRating += review.rating;
      });
      // calculate the average rating with 1 decimal
      trainerAverageReviewsRating =
        Math.round((totalRating / reviews.length) * 10) / 10;
    }

    // get the trainer profile
    let trainerProfile = await context.dispatch(
      "trainers/getTrainerById",
      {
        id: payload,
      },
      {
        root: true,
      }
    );

    // set the rating info
    trainerProfile.reviewsInfo = {
      averageReviewsRating: trainerAverageReviewsRating,
      reviewsCount: reviews.length,
    };

    // update the trainer profile
    const trainerId = trainerProfile.id;
    delete trainerProfile.id;

    await context.dispatch(
      "trainers/createOrUpdateTrainer",
      {
        trainerId: trainerId,
        data: {
          ...trainerProfile,
        },
      },
      {
        root: true,
      }
    );

    // console.log('Reviews: ' + JSON.stringify(reviews))
    // console.log('Reviews length: ' + reviews.length)
    // console.log('Trainer average rating: ' + trainerAverageReviewsRating)
    // console.log('Trainer profile: ' + JSON.stringify(trainerProfile))
  },

  setTrainerReviewDialog(context, payload) {
    context.commit("setTrainerReviewDialog", payload);
  },
};
