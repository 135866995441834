<template>
  <div>
    <v-expansion-panels class="expansion-panels">
      <v-expansion-panel v-for="FAQ in FAQs" :key="FAQ.question">
        <v-expansion-panel-title class="question-header">
          {{ FAQ.question }}
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          {{ FAQ.answer }}
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  data() {
    return {
      FAQs: [
        {
          question: "How do I book a session with a trainer?",
          answer:
            "Once you've found a trainer you're interested in, you can book a session directly on our platform. Simply click on the trainer's profile and click on the book session button.",
        },
        {
          question: "Do I need an account to book a session?",
          answer:
            'Yes, you need to create an account to book a session. Creating an account is simple and takes just a few seconds. Simply click on the "Sign Up" button and follow the on-screen instructions. Once you have completed the process, you will be able to browse through trainer profiles and book sessions.',
        },
        {
          question: "How can I find a trainer for a specific skill or topic?",
          answer:
            "You can browse through our extensive trainer profiles to find a trainer that specializes in the skill or topic that you're interested in learning about. You can also use our search filter to narrow down your results by skills.",
        },
        {
          question:
            "Can I get in contact with a trainer before booking a session?",
          answer:
            'Yes, you can contact a trainer directly through our platform. Simply click on the trainer profile and click on the "Send a message" button. You can then send them a message and ask any questions you may have.',
        },
        {
          question: "How can I see if a trainer is available for a session?",
          answer:
            "You can see a trainer’s availability on their profile page. There is a calendar that shows their availability by day and time. You can also contact them directly to ask about their availability.",
        },
        {
          question: "How do I cancel a session?",
          answer:
            'You can cancel a session by going to "My Explorer Sessions" page and clicking on the "Cancel" session button. You will be asked to confirm your cancellation. Once you confirm, the session will be cancelled and you will be refunded the full amount. The session can only be cancelled up to 24 hours before the scheduled start time.',
        },
        {
          question: "How do I reschedule a session?",
          answer:
            "For rescheduling a session you will need to cancel the session and book a new one.",
        },
        {
          question: "How do I pay for my sessions?",
          answer:
            "We offer a variety of payment methods to accommodate your preferences, including credit card.",
        },
        {
          question: "How can I give feedback to my trainer?",
          answer:
            "You can provide feedback after you complete a session with the trainer. We encourage you to provide feedback to your trainer after each session. This will help them improve their teaching style and tailoring the session to your needs.",
        },
        {
          question: "What can I do to prepare for my session?",
          answer:
            "To get the most out of your session, it's important to come prepared. Here are a few things you can do to prepare: familiarize yourself with the topic or skill, gather any materials that you may need, and come with a clear understanding of your goals for the session.",
        },
        {
          question: "How do I get the most out of my session?",
          answer:
            "Participate actively in the session, ask questions, and take notes. Be open to feedback from your trainer and take action to implement their suggestions. Also, try to set aside time after the session to review what you learned and practice what you've learned.",
        },
        {
          question: "How do I report a problem?",
          answer:
            "If you encounter a problem on NetworkTal, you can report it to our customer support team. We take all reports seriously and will investigate them promptly. You can contact our customer support team by email. Go to our Contact section at the bottom of the page for more details.",
        },
      ],
    };
  },
  components: {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
.expansion-panels {
  /* remove elevation */
  box-shadow: none !important;
}

.question-header {
  font-weight: 450;
  color: rgb(var(--v-theme-textPrimary));
}
</style>
