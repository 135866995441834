<template>
  <base-card class="ma-0 pa-0 pa-5">
    <v-row class="mb-4">
      <v-col cols="9" class="font-weight-medium text-h6">Certifications</v-col>
      <v-col cols="3" align="end"
        ><v-icon size="small">mdi-certificate</v-icon></v-col
      >
    </v-row>
    <view-certification-dialog
      v-if="viewCertificationDialog && selectedCertification"
      :certification="selectedCertification"
    ></view-certification-dialog>
    <v-card
      @click="triggerViewCertificationDialog(certification)"
      v-for="certification in trainer.certifications"
      :key="certification.id"
      class="certification-card ma-0 pa-0 my-2"
      elevation="0"
    >
      <v-row class="ma-0 pa-0" align="center">
        <v-col cols="3" class="ma-0 pa-0" align="center">
          <v-avatar tile size="60" class="ma-2">
            <v-img :src="getPhotoURL(certification.photoURL)"></v-img>
          </v-avatar>
        </v-col>
        <v-col class="ma-0 pa-0 py-2 pr-5 pl-3">
          <v-row class="ma-0 pa-0 py-1">
            <v-col class="text-subtitle-2 font-weight-medium ma-0 pa-0">
              {{ certification.name }}
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col class="text-caption ma-0 pa-0">{{
              certification.description
            }}</v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </base-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ViewCertificationDialog from "./viewcertificationdialog.vue";

export default {
  data() {
    return {
      selectedCertification: null,
    };
  },
  props: {
    trainer: {
      required: true,
    },
  },
  components: {
    ViewCertificationDialog,
  },
  computed: {
    ...mapGetters({
      certificationPhotoDefault: "websiteData/certificationPhotoDefault",
      viewCertificationDialog: "trainers/viewCertificationDialog",
    }),
  },
  methods: {
    ...mapActions({
      setViewCertificationDialog: "trainers/setViewCertificationDialog",
    }),
    getPhotoURL(photoURL) {
      return photoURL ? photoURL : this.certificationPhotoDefault;
    },
    triggerViewCertificationDialog(certification) {
      if (certification) {
        this.selectedCertification = certification;
        this.setViewCertificationDialog(true);
      }
    },
  },
};
</script>

<style scoped>
.certification-card {
  background-color: rgb(var(--v-theme-background1));
  color: rgb(var(--v-theme-textPrimary));
  border-radius: 10px;
  min-height: 50px;
}

.certification-card-insights {
  color: rgb(var(--v-theme-textQuaternary));
  font-size: 9px;
  font-weight: 410;
}

.certification-card-insights a {
  text-decoration: none;
  font-weight: 500 !important;
}

.certification-card-insights a:hover {
  text-decoration: underline;
}

.certification-card:hover {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
</style>
