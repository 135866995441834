<template>
  <v-container id="trainers-preview" fluid>
    <v-row class="font-weight-bold py-5 text-center" justify="center">
      <v-col
        :class="{
          'mt-12 mx-5 text-h3 font-weight-medium': $vuetify.display.mdAndUp,
          'mt-8 mx-5 text-h4 font-weight-medium': $vuetify.display.mdAndDown,
        }"
        cols="12"
        md="9"
      >
        Discover top Trainers in your area
      </v-col>
    </v-row>
    <v-row
      :class="{
        'ma-0 pa-0 my-10 mx-15': $vuetify.display.mdAndUp,
        'ma-0 pa-0 my-10 mx-2': $vuetify.display.mdAndDown,
      }"
    >
      <v-col class="ma-0 pa-0">
        <v-slide-group>
          <v-slide-group-item
            v-for="area in trainingAreas"
            :key="area"
            v-slot="{ isSelected, toggle }"
          >
            <v-btn
              :color="isSelected ? 'primary' : undefined"
              class="ma-2"
              rounded
              elevation="0"
              @click="toggle"
            >
              {{ area }}
            </v-btn>
          </v-slide-group-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <v-row v-if="!filteredTrainers" align="center" justify="center">
      <v-col align="center">
        <v-slide-group mobile-breakpoint="sm">
          <v-slide-group-item v-for="n in [1, 2, 3, 4]" :key="n">
            <trainer-preview-card-skeleton
              class="mx-3"
            ></trainer-preview-card-skeleton>
          </v-slide-group-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <v-row v-else align="center" justify="center">
      <v-col align="center">
        <v-slide-group mobile-breakpoint="sm">
          <v-slide-group-item
            v-for="trainer in filteredTrainers"
            :key="trainer.id"
          >
            <trainer-preview-card
              class="mx-3"
              :trainer="trainer"
            ></trainer-preview-card>
          </v-slide-group-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <v-row class="my-5">
      <v-col align="center">
        <base-button customVariant="outlined" to="/trainers">
          View all trainers
        </base-button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import trainerPreviewCard from "./HomeSection11/trainerpreviewcard.vue";
import trainerPreviewCardSkeleton from "./HomeSection11/trainerpreviewcardskeleton.vue";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    trainerPreviewCard,
    trainerPreviewCardSkeleton,
  },
  setup() {
    const { fetchTrainersInRandomOrder } = useGlobalFunctions();
    const filteredTrainers = ref("");
    const model = ref(null);
    const store = useStore();

    const trainingAreas = computed(() => {
      return store.getters["trainers/areas"];
    });

    onMounted(async () => {
      // fetch trainers in random order
      filteredTrainers.value = await fetchTrainersInRandomOrder();
    });

    return { filteredTrainers, model, trainingAreas };
  },
  computed: {
    titleSize() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "40px";
        case "sm":
          return "45px";
        case "md":
          return "50px";
        case "lg":
          return "65px";
        case "xl":
          return "75px";
        default:
          return "50px"; // Add a default case to return a value
      }
    },
    buttonSize() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "22px";
        case "sm":
          return "25px";
        case "md":
          return "27px";
        case "lg":
          return "30px";
        case "xl":
          return "32px";
        default:
          return "30px";
      }
    },
    buttonWidth() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "220px";
        case "sm":
          return "220px";
        case "md":
          return "300px";
        case "lg":
          return "300px";
        case "xl":
          return "350px";
        default:
          return "300px";
      }
    },
    buttonHeight() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "55px";
        case "sm":
          return "55px";
        case "md":
          return "60px";
        case "lg":
          return "60px";
        case "xl":
          return "65px";
        default:
          return "55px";
      }
    },
  },
};
</script>

<style scoped>
#trainers-preview {
  /* border: 2px solid black; */
  background-color: rgb(var(--v-theme-background1));
}
</style>
