<template>
  <v-container id="home-section-5" fluid>
    <v-row
      class="font-weight-bold py-10 px-10 text-center"
      justify="space-around"
      align="center"
    >
      <v-col cols="12" md="4">
        <div class="mb-10" :style="'font-size: ' + titleSize + ';'">
          Wide variety of fields
        </div>
        <v-img
          :src="require('@/assets/home/home-training-fields-01.png')"
          :height="imageHeight"
        ></v-img>
      </v-col>
      <v-col cols="12" md="6" justify="center">
        <v-row class="my-4" justify="space-around">
          <v-spacer></v-spacer>
          <v-col cols="5" class="feature-card font-weight-medium"
            >AI and Machine Learning</v-col
          >
          <v-spacer></v-spacer>
          <v-col cols="5" class="feature-card">Arts and Crafts</v-col>
        </v-row>
        <v-row class="my-4" justify="space-around">
          <v-col cols="5" class="feature-card">Business and Finance</v-col>
          <v-spacer></v-spacer>
          <v-col cols="5" class="feature-card">Coaching and Mentoring</v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="my-4" justify="space-around">
          <v-spacer></v-spacer>
          <v-col cols="5" class="feature-card"
            >Cloud Computing and DevOps</v-col
          >
          <v-spacer></v-spacer>
          <v-col cols="5" class="feature-card"
            >Data Science and Analytics</v-col
          >
        </v-row>
        <v-row class="my-4" justify="space-around">
          <v-col cols="5" class="feature-card">Digital Marketing</v-col>
          <v-spacer></v-spacer>
          <v-col cols="5" class="feature-card">Graphics and Design</v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="my-4" justify="space-around">
          <v-spacer></v-spacer>
          <v-col cols="5" class="feature-card">Fitness and Wellbeing</v-col>
          <v-spacer></v-spacer>
          <v-col cols="5" class="feature-card">Health and Nutrition</v-col>
        </v-row>
        <v-row class="my-4" justify="space-around">
          <v-col cols="5" class="feature-card">Language Learning</v-col>
          <v-spacer></v-spacer>
          <v-col cols="5" class="feature-card">Personal Development</v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="my-4" justify="space-around">
          <v-spacer></v-spacer>
          <v-col cols="5" class="feature-card">Programming and Tech</v-col>
          <v-spacer></v-spacer>
          <v-col cols="5" class="feature-card">And many more...</v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    imageHeight() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "300px";
        case "sm":
          return "300px";
        case "md":
          return "300px";
        case "lg":
          return "400px";
        case "xl":
          return "400px";
        default:
          return "400px";
      }
    },
    titleSize() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "40px";
        case "sm":
          return "45px";
        case "md":
          return "50px";
        case "lg":
          return "50px";
        case "xl":
          return "50px";
        default:
          return "50px";
      }
    },
  },
};
</script>

<style scoped>
#home-section-5 {
  /* border: 2px solid black; */
  background-color: rgb(var(--v-theme-background2));
  color: rgb(var(--v-theme-textSecondary));
  min-height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.feature-card {
  /* background-color: rgb(var(--v-theme-background1)); */
  border: 5px solid rgb(var(--v-theme-background1));
  color: rgb(var(--v-theme-background1));
  border-radius: 40px;
  /* text center vertically */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

/* add feature card class for when the screen is on mobile */
@media (max-width: 600px) {
  .feature-card {
    font-size: 10px;
  }
}

.row {
  margin: 0px;
}
</style>
