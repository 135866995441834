<template>
  <div class="text-body-1">
    <v-card class="auth-card" elevation="0">
      <v-row class="mt-1">
        <v-col align="center">
          <img
            src="@/assets/default/logoplatform2.png"
            alt="Company Logo"
            style="width: 60px; height: 60px"
        /></v-col>
      </v-row>
      <v-row class="mb-10">
        <v-col align="center" class="text-h5 font-weight-medium">{{
          authActionName().headerTitle
        }}</v-col>
      </v-row>
      <base-error-success-message
        :errorMessage="errorMessage"
        :successMessage="successMessage"
      ></base-error-success-message>
      <form>
        <v-text-field
          v-if="authActionName().action === 'signup'"
          v-model="firstName"
          :error-messages="firstNameErrors"
          :counter="30"
          label="First name"
          required
        ></v-text-field>
        <v-text-field
          v-if="authActionName().action === 'signup'"
          v-model="lastName"
          :error-messages="lastNameErrors"
          :counter="30"
          label="Last name"
          required
        ></v-text-field>
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          label="E-mail"
          :counter="50"
          required
        ></v-text-field>
        <v-text-field
          v-model="password"
          :error-messages="passwordErrors"
          label="Password"
          required
          type="password"
        ></v-text-field>
        <div class="text-caption">
          By signing in or creating an account using your email or Google, you
          agree to our
          <router-link
            to="/terms-and-conditions"
            target="_blank"
            class="text-caption text-decoration-underline"
          >
            Terms and Conditions
          </router-link>
          and
          <router-link
            to="/privacy-policy"
            target="_blank"
            class="text-caption text-decoration-underline"
          >
            Privacy Policy
          </router-link>
        </div>
        <div class="text-caption mt-2">
          This site is protected by reCAPTCHA and the Google
          <a
            href="https://policies.google.com/privacy"
            class="text-caption text-decoration-underline"
            >Privacy Policy</a
          >
          and
          <a
            href="https://policies.google.com/terms"
            class="text-caption text-decoration-underline"
            >Terms of Service</a
          >
          apply.
        </div>
        <p class="passwordReset">
          <router-link to="/passwordreset" class="font-weight-medium"
            >Forgot Password?</router-link
          >
        </p>
        <v-row class="ma-0 pa-0 my-5">
          <v-col align="center" class="ma-0 pa-0">
            <base-button @click="submitForm" customWidth="120px">
              {{ authActionName().display }}
            </base-button>
          </v-col>
          <v-col align="center" class="ma-0 pa-0">
            <base-button customColor="error" @click="clear" customWidth="120px">
              clear
            </base-button>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 my-5" align="center">
          <v-col class="ma-0 pa-0"><v-divider></v-divider></v-col>
          <v-col cols="6" lg="5" align="center">Or continue with</v-col>
          <v-col class="ma-0 pa-0"><v-divider></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-btn class="googleButton" @click="signInWithGoogle" elevation="2">
              <v-avatar class="mr-3" size="15">
                <v-img
                  :src="require('@/assets/auth/google-logo-01.png')"
                ></v-img>
              </v-avatar>
              <div class="mr-5">Google</div>
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </v-card>
    <div v-if="authActionName().action === 'login'" class="mt-3">
      Don`t have an account?
      <router-link
        :to="
          $route.query.from ? '/signup?from=' + $route.query.from : '/signup'
        "
        class="font-weight-medium"
        >Sign up now</router-link
      >
    </div>
    <div v-if="authActionName().action === 'signup'" class="mt-3">
      Already have an account?
      <router-link
        :to="$route.query.from ? '/login?from=' + $route.query.from : '/login'"
        class="font-weight-medium"
        >Login now</router-link
      >
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength, email } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      firstName: { required, maxLength: maxLength(30) },
      lastName: { required, maxLength: maxLength(30) },
      email: { required, email },
      password: {
        required,
        ...(this.$route.name === "Signup" && {
          minLength: minLength(8),
        }),
        ...(this.$route.name === "Signup" && {
          valid: (value) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#^()@$!%*?&])[A-Za-z\d#^()@$!%*?&\S]{8,}$/.test(
              value
            ),
        }),
      },
    };
  },

  data: () => ({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    errorMessage: "",
    successMessage: "",
  }),

  computed: {
    ...mapGetters({
      isEmailVerified: "auth/isEmailVerified",
      isTrainer: "auth/isTrainer",
    }),
    firstNameErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "firstName")
          errors.push("First name is required");
      });
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "lastName")
          errors.push("Last name is required");
      });
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "email") {
          if (error.$validator === "required") {
            errors.push("Email is required");
          } else if (error.$validator === "email") {
            errors.push("Enter a valid email address");
          }
        }
      });
      return errors;
    },
    passwordErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "password") {
          if (error.$validator === "required") {
            errors.push("Password is required");
          } else if (error.$validator === "minLength") {
            errors.push("Password must be at least 8 characters");
          } else if (
            error.$validator === "valid" &&
            this.$route.name === "Signup"
          ) {
            errors.push(
              "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character (#^()@$!%*?&)"
            );
          }
        }
      });
      return errors;
    },
  },

  methods: {
    ...mapActions({
      signingoogle: "auth/signingoogle",
    }),
    async submitForm() {
      let payload = "";

      if (this.authActionName().action === "signup") {
        payload = {
          firstName: this.firstName.trim(),
          lastName: this.lastName.trim(),
          email: this.email.toLowerCase(),
          password: this.password,
        };
      } else {
        payload = {
          email: this.email,
          password: this.password,
        };
        this.firstName = "NoFirstName";
        this.lastName = "NoLastName";
      }

      const isValid = await this.v$.$validate();

      if (isValid) {
        try {
          await this.$store.dispatch(
            "auth/" + this.authActionName().action,
            payload
          );

          // Redirect to verify email page if email is not verified
          if (!this.isEmailVerified) {
            this.$router.replace("/verifyemail");
          }

          setTimeout(() => {
            // Redirect to dashboard if email is verified
            if (!this.$route.query.from) {
              if (this.isTrainer) {
                this.$router.replace("/settings/trainer/dashboard");
              } else {
                this.$router.replace("/settings/user/dashboard");
              }
            } else {
              this.$router.replace(this.$route.query.from);
            }
          }, 500);
        } catch (errorMessage) {
          this.firstName = "";
          this.lastName = "";
          switch (errorMessage.code) {
            case "auth/invalid-email":
              this.errorMessage = "Invalid email";
              break;
            case "auth/missing-email":
              this.errorMessage = "Email address is missing";
              break;
            case "auth/email-already-in-use":
              this.errorMessage = "Email address already in use";
              break;
            case "auth/user-not-found":
              this.$router.replace("signup");
              break;
            case "auth/wrong-password":
              this.errorMessage = "Incorrect password";
              break;
            default:
              // console.log("Error message: " + errorMessage);
              this.errorMessage = "Unknown error. Something went wrong";
              break;
          }
        }
      } else {
        this.errorMessage = "Please fill in all the required information";
        if (
          this.firstName === "NoFirstName" &&
          this.lastName === "NoLastName"
        ) {
          this.firstName = "";
          this.lastName = "";
        }
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },
    async signInWithGoogle() {
      this.errorMessage = "";
      try {
        await this.signingoogle();

        // Redirect to verify email page if email is not verified
        if (!this.isEmailVerified) {
          this.$router.replace("/verifyemail");
        }

        // Redirect to dashboard if email is verified
        if (!this.$route.query.from) {
          if (this.isTrainer) {
            this.$router.replace("/settings/trainer/dashboard");
          } else {
            this.$router.replace("/settings/user/dashboard");
          }
        } else {
          this.$router.replace(this.$route.query.from);
        }
      } catch (error) {
        this.errorMessage = "Something went wrong";
      }
    },
    clear() {
      this.v$.$reset();
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.password = "";
    },
    authActionName() {
      if (this.$route.name === "Login") {
        return {
          title: "Login",
          display: "Log in",
          action: "login",
          headerTitle: "Login to your account",
        };
      } else if (this.$route.name === "Signup") {
        return {
          title: "Sign up",
          display: "Sign up",
          action: "signup",
          headerTitle: "Sign up for an account",
        };
      }
    },
    authActionNameWatcher() {
      this.clear();
    },
  },
};
</script>

<style scoped>
.auth-card {
  background-color: rgb(var(--v-theme-authBackground));
  border-radius: 20px;
  width: 100%;
  padding: 50px;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .auth-card {
    padding: 30px;
  }
}

.auth-card a {
  color: rgb(var(--v-theme-textPrimary));
  text-decoration: none;
  font-size: 16px;
}

.auth-card a:hover {
  text-decoration: underline;
}

a {
  color: rgb(var(--v-theme-textPrimary)) !important;
  text-decoration: none;
  font-size: 16px;
}

a:hover {
  text-decoration: underline;
}

.passwordReset {
  text-align: end;
  margin-top: 20px;
}

.googleButton {
  color: rgb(var(--v-theme-textPrimary));
  background-color: rgb(var(--v-theme-background3)) !important;
  border-radius: 5px;
  border: 1px solid rgb(var(--v-theme-textPrimary));
  height: 50px !important;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  /* remove uppercase */
  text-transform: none;
}
</style>
