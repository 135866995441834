<template>
  <div>
    <v-row class="date-time ma-0 pa-0" justify="center">
      <v-col cols="12" md="6" lg="6" xl="6">
        <p class="text-subtitle-1 font-weight-medium mb-5">
          Select Session Timeframe
        </p>
        <v-card class="date-time-card text-body-2 pa-5" elevation="0">
          <v-menu
            class="offset-y"
            v-model="menuDatePicker"
            :close-on-content-click="false"
            :offset="40"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-model="date"
                label="Pick the date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="props"
                :error-messages="dateErrors"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              show-adjacent-months
              @update:modelValue="saveDate"
            ></v-date-picker>
          </v-menu>
          <v-select
            v-model="time"
            :items="allowedStartTimes"
            label="Pick the start time"
            prepend-icon="mdi-clock-time-four-outline"
            clearable
            :error-messages="timeErrors"
          ></v-select>
          <base-tooltip-info
            text="Timezone can only be changed in the user settings page"
            :custom="true"
            ><v-text-field
              v-model="fullUserTimezone"
              label="Timezone"
              prepend-icon="mdi-earth"
              :disabled="true"
            ></v-text-field>
          </base-tooltip-info>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <p class="text-subtitle-1 font-weight-medium">
          Trainer Availability Timeframes
        </p>
        <base-error-success-message
          class="ma-2"
          :errorMessage="errorMessage"
          :successMessage="successMessage"
        ></base-error-success-message>
        <v-card
          v-if="this.getFutureTrainerAvailability().length !== 0"
          class="date-time-card text-body-2"
          elevation="0"
        >
          <div>
            <v-row class="py-2 ma-0">
              <v-col align="center">Start Time</v-col>
              <v-divider class="my-2" vertical></v-divider>
              <v-col align="center">End Time</v-col>
            </v-row>
            <v-divider class="mx-4"></v-divider>
            <availability-card
              v-for="availability in getFutureTrainerAvailability()"
              :key="availability.id"
              :availability="availability"
            ></availability-card>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AvailabilityCard from "./availabilitycard.vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import {
  convertUserTimezoneDateTimeToUTC,
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
  getUTCValueOfTimezone,
} from "@/utils/utils.js";

export default {
  inject: ["eventBus", "dayjs"],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      selectedSession: { required },
      date: { required },
      time: { required },
    };
  },
  data() {
    return {
      isSelectedTimeframeValid: false,
      selectedStartTimeUTC: null,
      selectedEndTimeUTC: null,
      menuDatePicker: false,
      menuTimePicker: false,
      date: null,
      time: null,
      allowedMinutes: [0, 30],
      errorMessage: null,
      successMessage: null,
      allowedStartTimes: Array.from({ length: 48 }, (v, i) => {
        const hour = Math.floor(i / 2);
        const minute = i % 2 === 0 ? "00" : "30";
        return `${hour.toString().padStart(2, "0")}:${minute}`;
      }),
    };
  },
  props: {
    availability: {
      type: Array,
      required: true,
    },
    selectedSession: {
      type: Object,
      required: false,
    },
    trainerId: {
      type: String,
      required: true,
    },
  },
  components: {
    AvailabilityCard,
  },
  async created() {
    await this.loadTrainerEvents({
      trainerId: this.trainerId,
      loadAvailability: false,
    });
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
      sessionsTrainer: "calendar/sessionsTrainer",
      meetingColor: "calendar/meetingColor",
    }),
    dateErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "date") {
          errors.push("Date is required");
        }
      });
      return errors;
    },
    timeErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "time") {
          errors.push("Time is required");
        }
      });
      return errors;
    },
    currentUserTimezoneOffset() {
      return getUTCValueOfTimezone(this.currentUser.timezone);
    },
    fullUserTimezone() {
      return this.currentUser.timezone + " " + this.currentUserTimezoneOffset;
    },
  },
  methods: {
    ...mapActions({
      loadTrainerEvents: "calendar/loadTrainerEvents",
    }),
    saveDate(date) {
      this.menuDatePicker = false;
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      this.date = date.toISOString().split("T")[0];
    },

    setStartAndEndDateAndTime() {
      if (this.time && this.date) {
        const [hours, minutes] = this.time.split(":");
        const [year, month, day] = this.date.split("-");

        // convert the selected start and end time to the user's timezone
        let selectedStartTimeUserTimezone = this.dayjs.tz(
          year + "-" + month + "-" + day + " " + hours + ":" + minutes,
          this.currentUser.timezone
        );

        // add the session duration to the selected start time
        let selectedEndTimeUserTimezone = selectedStartTimeUserTimezone.add(
          this.selectedSession.duration,
          "minutes"
        );

        // convert the selected start and end time to UTC
        this.selectedStartTimeUTC = convertUserTimezoneDateTimeToUTC(
          selectedStartTimeUserTimezone,
          this.currentUser.timezone
        );

        this.selectedEndTimeUTC = convertUserTimezoneDateTimeToUTC(
          selectedEndTimeUserTimezone,
          this.currentUser.timezone
        );
      }
    },

    validateSelectedTimeframe() {
      let currentTimeInUserTimezone = getCurrentTimeInUserTimezone(
        this.currentUser.timezone
      );

      let selectedStartTimeUserTimezone =
        convertUTCDateTimeToUserTimezoneNoFormat(
          this.selectedStartTimeUTC,
          this.currentUser.timezone
        );

      let selectedEndTimeUserTimezone =
        convertUTCDateTimeToUserTimezoneNoFormat(
          this.selectedEndTimeUTC,
          this.currentUser.timezone
        );

      if (this.getFutureTrainerAvailability().length !== 0) {
        if (this.date && this.time) {
          if (
            selectedStartTimeUserTimezone.isBefore(currentTimeInUserTimezone)
          ) {
            this.isSelectedTimeframeValid = false;
            this.errorMessage = "The start date must not be in the past.";
            this.eventBus.emit("selected-timeframe-updated", {
              selectedStartTimeUTC: this.selectedStartTimeUTC,
              selectedEndTimeUTC: this.selectedEndTimeUTC,
              isSelectedTimeframeValid: this.isSelectedTimeframeValid,
            });
          } else if (
            // Check if the selected timeframe is within the trainer's availability
            this.getFutureTrainerAvailability().filter(
              (availability) =>
                convertUTCDateTimeToUserTimezoneNoFormat(
                  availability.startTime,
                  this.currentUser.timezone
                ).isSameOrBefore(selectedStartTimeUserTimezone) &&
                convertUTCDateTimeToUserTimezoneNoFormat(
                  availability.endTime,
                  this.currentUser.timezone
                ).isSameOrAfter(selectedEndTimeUserTimezone)
            ).length >= 1
          ) {
            this.isSelectedTimeframeValid = true;
            this.errorMessage = null;
            this.eventBus.emit("selected-timeframe-updated", {
              selectedStartTimeUTC: this.selectedStartTimeUTC,
              selectedEndTimeUTC: this.selectedEndTimeUTC,
              isSelectedTimeframeValid: this.isSelectedTimeframeValid,
            });
          } else {
            this.isSelectedTimeframeValid = false;
            this.errorMessage =
              "No availability found on the selected date and time";
            this.eventBus.emit("selected-timeframe-updated", {
              selectedStartTimeUTC: this.selectedStartTimeUTC,
              selectedEndTimeUTC: this.selectedEndTimeUTC,
              isSelectedTimeframeValid: this.isSelectedTimeframeValid,
            });
          }
        } else {
          this.errorMessage = "Please select a date and time";
        }
      }

      // Check if there are any overlapping sessions
      if (this.sessionsTrainer && this.sessionsTrainer.length > 0) {
        this.sessionsTrainer.forEach((session) => {
          if (session.color === this.meetingColor) {
            let sessionStartInUserTimezone =
              convertUTCDateTimeToUserTimezoneNoFormat(
                session.start,
                this.currentUser.timezone
              );
            let sessionEndInUserTimezone =
              convertUTCDateTimeToUserTimezoneNoFormat(
                session.end,
                this.currentUser.timezone
              );

            // Check if the selected timeframe overlaps with another session that is not declined or cancelled
            if (
              (selectedStartTimeUserTimezone.isBefore(
                sessionEndInUserTimezone
              ) &&
                selectedStartTimeUserTimezone.isSameOrAfter(
                  sessionStartInUserTimezone
                ) &&
                !["declined", "cancelled"].includes(
                  session.statusLogs[session.statusLogs.length - 1].status
                )) ||
              (selectedEndTimeUserTimezone.isSameOrBefore(
                sessionEndInUserTimezone
              ) &&
                selectedEndTimeUserTimezone.isAfter(
                  sessionStartInUserTimezone
                ) &&
                !["declined", "cancelled"].includes(
                  session.statusLogs[session.statusLogs.length - 1].status
                ))
            ) {
              this.isSelectedTimeframeValid = false;
              this.errorMessage =
                "Session overlaps with another session. Please select another time.";
              this.eventBus.emit("selected-timeframe-updated", {
                selectedStartTimeUTC: this.selectedStartTimeUTC,
                selectedEndTimeUTC: this.selectedEndTimeUTC,
                isSelectedTimeframeValid: this.isSelectedTimeframeValid,
              });
            }
          }
        });
      }
    },

    getFutureTrainerAvailability() {
      // Get the current time in the user's timezone
      let currentTimeInUserTimezone = getCurrentTimeInUserTimezone(
        this.currentUser.timezone
      );

      // Filter trainer availability that has end time after the current time
      const futureAvailability = this.availability.filter((availability) => {
        return convertUTCDateTimeToUserTimezoneNoFormat(
          availability.endTime,
          this.currentUser.timezone
        ).isAfter(currentTimeInUserTimezone);
      });

      if (this.date) {
        // Filter trainer availability on the selected date
        const futureAvailabilityFilteredByDate = futureAvailability.filter(
          (availability) => {
            // convert the availability start time to the user's timezone
            let availabilityStartTimeInUserTimezone =
              convertUTCDateTimeToUserTimezoneNoFormat(
                availability.startTime,
                this.currentUser.timezone
              ).format("YYYY-MM-DD HH:mm");

            let availablityEndTimeInUserTimezone =
              convertUTCDateTimeToUserTimezoneNoFormat(
                availability.endTime,
                this.currentUser.timezone
              ).format("YYYY-MM-DD HH:mm");
            // compare the availability start date in user timezone with the selected date
            return (
              availabilityStartTimeInUserTimezone.split(" ")[0] === this.date ||
              availablityEndTimeInUserTimezone.split(" ")[0] === this.date
            );
          }
        );

        if (this.time) {
          // Filter trainer availability on the selected date and time
          const futureAvailabilityFilteredByDateAndTime =
            futureAvailabilityFilteredByDate.filter((availability) => {
              // compare the availability start and end time with the selected timeframe in user timezone
              return (
                convertUTCDateTimeToUserTimezoneNoFormat(
                  availability.startTime,
                  this.currentUser.timezone
                ).isSameOrBefore(
                  convertUTCDateTimeToUserTimezoneNoFormat(
                    this.selectedStartTimeUTC,
                    this.currentUser.timezone
                  )
                ) &&
                convertUTCDateTimeToUserTimezoneNoFormat(
                  availability.endTime,
                  this.currentUser.timezone
                ).isSameOrAfter(
                  convertUTCDateTimeToUserTimezoneNoFormat(
                    this.selectedEndTimeUTC,
                    this.currentUser.timezone
                  )
                )
              );
            });
          if (futureAvailabilityFilteredByDateAndTime.length === 0) {
            this.errorMessage =
              "No availability found on the selected date and time";
          }
          return futureAvailabilityFilteredByDateAndTime;
        } else {
          if (futureAvailabilityFilteredByDate.length === 0) {
            this.errorMessage = "No availability found on the selected date";
          } else {
            this.errorMessage = null;
          }
          return futureAvailabilityFilteredByDate;
        }
      } else {
        if (futureAvailability.length === 0) {
          this.errorMessage = "No availability found";
        } else {
          this.errorMessage = null;
        }
        return futureAvailability;
      }
    },

    clearData() {
      this.selectedStartTimeUTC = null;
      this.selectedEndTimeUTC = null;
      this.isSelectedTimeframeValid = false;
      this.eventBus.emit("selected-timeframe-updated", {
        selectedStartTimeUTC: this.selectedStartTimeUTC,
        selectedEndTimeUTC: this.selectedEndTimeUTC,
        isSelectedTimeframeValid: this.isSelectedTimeframeValid,
      });
    },
  },
  watch: {
    date() {
      this.clearData();
      this.setStartAndEndDateAndTime();
      this.getFutureTrainerAvailability();
      this.validateSelectedTimeframe();
    },
    time() {
      this.clearData();
      this.setStartAndEndDateAndTime();
      this.getFutureTrainerAvailability();
      this.validateSelectedTimeframe();
    },
  },
};
</script>

<style scoped>
.date-time {
  height: 45vh !important;
  overflow: auto;
}

.date-time-card {
  background-color: rgb(var(--v-theme-card));
  border: 1px solid rgb(var(--v-theme-background)) !important;
  /* font-size: 0.9rem; */
  height: 35vh;
  overflow: auto;
}
</style>
