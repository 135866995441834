<template>
  <div>
    <base-card class="pa-5" style="text-align: center">
      <base-loader v-if="!session"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-1">
            <base-button
              customColor="primary"
              @click="triggerReportSessionDialog()"
              customHeight="35px"
              customFontSize="10px"
              customWidth="160px"
              customVariant="outlined"
            >
              <v-icon size="small" start>mdi-alert-box-outline</v-icon>
              Report problem
            </base-button>
          </v-col>
        </v-row>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    session: {
      required: true,
    },
  },
  methods: {
    ...mapActions({
      setReportSessionDialog: "sessions/setReportSessionDialog",
    }),
    triggerReportSessionDialog() {
      this.setReportSessionDialog(true);
    },
  },
};
</script>

<style scoped></style>
