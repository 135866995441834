<template>
  <base-card class="ma-3" customColor="background">
    <v-row class="ma-0 pa-0 pa-1" justify="space-around">
      <v-col class="ma-0 pa-0" align="center">
        <v-chip color="primary" class="text-white text-caption">
          {{ getFormattedDateTimeInUserTimezone(startTime) }}
        </v-chip>
      </v-col>

      <v-col class="ma-0 pa-0" align="center">
        <v-chip color="primary" class="text-white text-caption">
          {{ getFormattedDateTimeInUserTimezone(endTime) }}
        </v-chip>
      </v-col>

      <v-col class="ma-0 pa-0" align="center" cols="2">
        <v-icon
          color="darkgray"
          @click="triggerRemoveSessionConfirmation()"
          size="x-small"
        >
          mdi-delete
        </v-icon>
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
import { mapGetters } from "vuex";
import { convertUTCDateTimeToUserTimezoneNoFormat } from "../../../../utils/utils.js";

export default {
  inject: ["eventBus"],
  props: {
    availabilityId: {
      type: String,
      required: true,
    },
    startTime: {
      type: String,
      required: true,
    },
    endTime: {
      type: String,
      required: true,
    },
    trainerId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
    }),
  },
  methods: {
    triggerRemoveSessionConfirmation() {
      this.eventBus.emit("trigger-remove-availability-confirmation", {
        trainerId: this.trainerId,
        id: this.availabilityId,
        availabilityStartTime: this.startTime,
      });
    },
    getFormattedDateTimeInUserTimezone(dateTimeUTC) {
      let datetimeInUserTimezone = convertUTCDateTimeToUserTimezoneNoFormat(
        dateTimeUTC,
        this.currentUser.timezone
      );
      return datetimeInUserTimezone.format("MMM D, YYYY, HH:mm");
    },
  },
};
</script>

<style scoped></style>
