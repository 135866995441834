<template>
  <v-app>
    <websiteloader v-if="!autoLoginCompleted"></websiteloader>
    <div class="ma-0 pa-0" v-else>
      <cookiebanner></cookiebanner>
      <appbar></appbar>
      <becometrainernowannouncement></becometrainernowannouncement>
      <v-main id="app" style="--v-layout-top: 0px">
        <router-view />
      </v-main>
    </div>
    <appfooter v-if="autoLoginCompleted"></appfooter>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useTheme } from "vuetify";
import appbar from "@/components/navbar/appbar.vue";
import appfooter from "@/components/footer/appfooter.vue";
import cookiebanner from "@/components/cookiesettings/cookiebanner.vue";
import becometrainernowannouncement from "@/components/announcements/becometrainernow.vue";
import websiteloader from "@/components/app/websiteloader.vue";

export default {
  inject: ["eventBus"],
  name: "App",
  data() {
    return {
      themeVuetify: null,
    };
  },
  components: {
    appbar,
    appfooter,
    cookiebanner,
    becometrainernowannouncement,
    websiteloader,
  },
  created() {
    this.themeVuetify = useTheme();
    this.autoLogin();
    this.applyTheme();
    this.eventBus.on("apply-theme", this.applyTheme);
  },
  computed: {
    ...mapGetters({
      autoLoginCompleted: "auth/autoLoginCompleted",
    }),
  },
  methods: {
    ...mapActions({
      autoLogin: "auth/autoLogin",
    }),
    applyTheme() {
      const savedTheme = localStorage.getItem("dark-theme");

      if (savedTheme) {
        this.darkTheme = savedTheme;
      }

      if (this.darkTheme === "true") {
        this.themeVuetify.global.name = "darkTheme";
      } else {
        this.themeVuetify.global.name = "lightTheme";
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-size: x-large;
}

#app {
  min-height: 600px;
  background-color: rgb(var(--v-theme-background));
  color: rgb(var(--v-theme-textPrimary));
}
</style>
