<template>
  <v-container
    class="my-5 px-2"
    fluid
    :style="'width:' + containerWidth + '%; min-height: 70vh'"
  >
    <v-row class="ma-0 pa-3" id="messagebox">
      <v-col class="room-list" cols="12" md="4" lg="4" xl="4">
        <v-list
          density="compact"
          nav
          class="py-0"
          color="chatBackground"
          style="
            background-color: rgb(var(--v-theme-chatBackground)) !important;
          "
        >
          <Avatar />
          <div class="text-overline mt-2 mb-2">Recent chats</div>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            variant="underlined"
          />
          <div class="mt-2">
            <RoomList :filterByName="search" class="room-list" />
          </div>
        </v-list>
      </v-col>
      <v-divider
        v-if="isRoomSelected"
        class="mx-2 mt-15 mb-5 hidden-md-and-up"
      />
      <v-col class="ma-0 pa-0" cols="12" md="8" lg="8" xl="8">
        <MessageContainer />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import MessageContainer from "../components/chat/messages/MessageContainer.vue";
import Avatar from "../components/chat/Avatar.vue";
import RoomList from "../components/chat/room/RoomList.vue";
import { setMetaTags } from "../utils/utils.js";

export default {
  name: "App",
  data() {
    return {
      search: "",
      leftdrawer: null,
      rightdrawer: null,
    };
  },
  components: {
    MessageContainer,
    Avatar,
    RoomList,
  },
  created() {
    setMetaTags("NetworkTal - Chat", "Chat with trainers and learners");
  },
  computed: {
    ...mapGetters({
      roomID: "room/activeRoomId",
    }),
    isRoomSelected: function () {
      return this.roomID;
    },
    containerWidth() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "100";
        case "sm":
          return "100";
        case "md":
          return "80";
        case "lg":
          return "80";
        case "xl":
          return "80";
        default:
          return "70";
      }
    },
  },
};
</script>

<style scoped>
#messagebox {
  background-color: rgb(var(--v-theme-chatBackground)) !important;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  padding: 5px;
  min-height: 60vh;
}

.room-list {
  background-color: rgb(var(--v-theme-chatBackground)) !important;
  overflow-y: auto;
  /* height: 100%; */
}
</style>
