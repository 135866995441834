<template>
  <v-container style="min-height: 70vh">
    <v-row class="my-15" justify="center">
      <v-col xs="12" sm="12" md="12" lg="9" xl="6">
        <base-card class="pt-10 pb-15">
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            About Us
          </p>
          <div
            :class="{
              'mx-15': $vuetify.display.mdAndUp,
              'mx-5': $vuetify.display.smAndDown,
              'text text-body-2': true,
            }"
            style="font-size: 16px"
          >
            <p>
              <strong>NETWORKTAL</strong>
              is a platform that connects users with trainers for personal and
              professional development. We believe that everyone has the
              potential to learn and grow, and we are committed to providing a
              safe and supportive environment where people can reach their full
              potential.
            </p>

            <h2>We are passionate about helping people:</h2>

            <ul>
              <li>Expand their knowledge and skills</li>
              <li>Improve their personal lives</li>
              <li>Develop their careers</li>
              <li>Grow their businesses</li>
            </ul>

            <h2>We believe that our platform can:</h2>

            <ul>
              <li>Make learning more accessible and affordable</li>
              <li>Connect people with the resources they need to succeed</li>
              <li>Foster a community of learners and educators</li>
            </ul>

            <h2>We are committed to:</h2>

            <ul>
              <li>
                Providing high-quality environment for training and education
              </li>
              <li>Making a positive impact on the lives of our users</li>
              <li>Building a strong community of learners</li>
            </ul>

            <p class="mt-5 mb-3">
              If you are looking for a way to learn and grow, NETWORKTAL is the
              place for you.
            </p>

            <p class="paragraph-title">
              Join us today and start your journey to success!
            </p>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { setMetaTags } from "../../utils/utils.js";

export default {
  created() {
    setMetaTags("NetworkTal - About Us", "Learn more about NetworkTal");
  },
};
</script>

<style scoped>
.text {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text h2 {
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 20px;
}

.paragraph-title {
  font-weight: 450;
  margin-top: 10px;
}

.text ul {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}
</style>
