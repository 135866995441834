<template>
  <v-toolbar-title>
    <router-link to="/" class="app-bar-name">NetworkTal</router-link>
  </v-toolbar-title>
</template>

<style scoped>
.app-bar-name {
  text-decoration: none;
  text-align: center;
  color: white;
}
</style>
