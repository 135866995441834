<template>
  <div>
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col cols="7" class="text-h5 font-weight-medium pl-7"
        >Profile Picture</v-col
      >
      <v-col align="end">
        <base-button v-if="!inEditMode" @click="toggleInEditMode">
          Edit
          <base-icon>mdi-pencil</base-icon>
        </base-button>
      </v-col>
    </v-row>
    <base-card
      :class="{
        'pa-10': $vuetify.display.mdAndUp,
        'pa-2': $vuetify.display.smAndDown,
      }"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <base-error-success-message
        :errorMessage="errorMessage"
        :successMessage="successMessage"
      ></base-error-success-message>
      <v-row align="center" class="mb-5 mx-5">
        <v-col align="center" v-if="!inEditMode">
          <v-avatar size="150">
            <img
              :src="getPhotoURL"
              alt="Profile Picture"
              style="height: 200px"
            />
          </v-avatar>
        </v-col>
        <v-col v-if="inEditMode && profilePictureDownloadURL" align="center">
          <v-avatar size="150">
            <img
              :src="profilePictureDownloadURL"
              alt="Profile Picture"
              style="height: 200px"
            />
          </v-avatar>
        </v-col>
        <v-file-input
          v-if="inEditMode"
          class="mt-5"
          chips
          clearabale
          accept="image/png, image/jpeg, image/bmp"
          :rules="profilePictureFileRules"
          prepend-icon="mdi-camera"
          label="Profile Picture"
          show-size
          validate-on="input"
          v-model="profilePictureFile"
          @update:modelValue="validateAndUploadPicture($event, userId)"
        ></v-file-input>
      </v-row>
      <v-row class="mx-10 my-5" v-if="inEditMode">
        <base-button @click="submit">
          Save
          <base-icon>mdi-content-save</base-icon>
        </base-button>
        <base-button customColor="error" @click="cancel">
          Cancel
          <base-icon>mdi-close</base-icon>
        </base-button>
      </v-row>
    </base-card>
  </div>
</template>

<script>
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";
import { computed, inject, ref } from "vue";
import { useStore } from "vuex";

export default {
  emits: ["fetchUserProfile"],
  setup(props, { emit }) {
    const { isPictureValid, validateAndUploadPicture } = useGlobalFunctions();
    const eventBus = inject("eventBus");
    const store = useStore();
    const profilePictureFile = ref(null);
    const errorMessage = ref("");
    const successMessage = ref("");
    const inEditMode = ref(false);

    const profilePictureFileRules = [
      (value) => {
        return (
          !value ||
          !value.length ||
          value[0].size < 2000000 ||
          "Profile Picture size should be less than 2 MB!"
        );
      },
      (value) => {
        return (
          !value ||
          !value.length ||
          ["image/jpeg", "image/png", "image/bmp"].includes(value[0].type) ||
          "Profile Picture must be an image file in format JPG, PNG or BMP!"
        );
      },
    ];

    let userId = computed(() => {
      return store.getters["auth/userId"];
    });
    let profilePictureDownloadURL = computed(() => {
      return store.getters["data/downloadURL"];
    });

    const getPhotoURL = computed(() => {
      return props.userProfile.photoURL
        ? props.userProfile.photoURL
        : store.getters["websiteData/userPhotoDefault"];
    });

    function toggleInEditMode() {
      inEditMode.value = !inEditMode.value;
    }

    function cancel() {
      toggleInEditMode();
      store.dispatch("data/clearState");
      profilePictureFile.value = null;
    }

    async function submit() {
      let isProfilePictureValid = isPictureValid(profilePictureFile.value);
      if (isProfilePictureValid) {
        try {
          await store.dispatch("users/createOrUpdateUser", {
            id: props.userProfile.id,
            data: {
              ...props.userProfile,
              photoURL: profilePictureDownloadURL.value,
            },
          });

          setTimeout(() => {
            emit("fetchUserProfile");
            eventBus.emit("profile-picture-updated");
          }, 1000);

          cancel();
          successMessage.value = "Operation successful!";
          setTimeout(() => {
            successMessage.value = "";
          }, 5000);
        } catch (error) {
          store.dispatch("data/clearState");
          profilePictureFile.value = null;
          errorMessage.value = error.message;
          setTimeout(() => {
            errorMessage.value = "";
          }, 5000);
        }
      } else {
        errorMessage.value =
          "The uploaded file must be an image file in format JPG, PNG or BMP and less than 2MB in size";
        setTimeout(() => {
          errorMessage.value = "";
        }, 5000);
      }
    }

    return {
      cancel,
      errorMessage,
      getPhotoURL,
      inEditMode,
      profilePictureFile,
      profilePictureFileRules,
      profilePictureDownloadURL,
      submit,
      successMessage,
      toggleInEditMode,
      userId,
      validateAndUploadPicture,
    };
  },
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
