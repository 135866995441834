<template>
  <v-container>
    <v-row>
      <v-col class="font-weight-medium" cols="3">Enabled</v-col>
      <v-col class="font-weight-medium" cols="4">Name</v-col>
      <v-col class="font-weight-medium" cols="5">Id / URL</v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row v-for="tool in trainerProfile.communicationTools" :key="tool.id">
      <v-col cols="3">
        <div v-if="tool.enabled">
          <v-icon color="green">mdi-toggle-switch-variant</v-icon>
        </div>
        <div v-else>
          <v-icon>mdi-toggle-switch-variant-off</v-icon>
        </div>
      </v-col>
      <v-col class="trainer-profile-data-tool-name" cols="4">
        {{ tool.name }}
      </v-col>
      <v-col v-if="tool.name === 'Zoom'" cols="5">
        {{ tool.toolId.meetingURL }}
      </v-col>
      <v-col v-else cols="5">{{ tool.toolId }}</v-col>
    </v-row>
    <v-row justify="end" v-if="getZoomPersonalPasscode">
      <v-col cols="5">{{ getZoomPersonalPasscode }}</v-col>
    </v-row>
    <v-row justify="end" v-if="getZoomPersonalMeetingId">
      <v-col cols="5">{{ getZoomPersonalMeetingId }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    trainerProfile: {
      required: true,
    },
  },
  computed: {
    formatTrainerLanguages() {
      let languages = "";
      this.trainerProfile.languages.forEach((language) => {
        languages += language.name + ", ";
      });
      return languages.slice(0, -2);
    },
    getZoomPersonalMeetingId() {
      return this.trainerProfile.communicationTools.find(
        (tool) => tool.name === "Zoom"
      ).toolId.personalMeetingId;
    },
    getZoomPersonalPasscode() {
      return this.trainerProfile.communicationTools.find(
        (tool) => tool.name === "Zoom"
      ).toolId.passcode;
    },
  },
};
</script>

<style scoped></style>
