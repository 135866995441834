<template>
  <base-card class="ma-0 pa-0 pa-5">
    <v-row class="mb-4">
      <v-col cols="9" class="text-h6 font-weight-medium">Session Types</v-col>
      <v-col cols="3" align="end"
        ><v-icon size="small">mdi-presentation</v-icon></v-col
      >
    </v-row>
    <view-session-dialog
      v-if="viewSessionDialog"
      :trainerSession="selectedSession"
    ></view-session-dialog>
    <v-card
      @click="triggerViewSessionDialog(session)"
      class="card-session my-2"
      v-for="session in trainer.sessions"
      :key="session.id"
      elevation="0"
    >
      <v-row justify="center" align="center" class="ma-0 pa-0 pa-1">
        <v-col class="font-weight-medium pl-5 text-body-2" cols="6">
          {{ session.title }}
        </v-col>
        <v-col class="text-center text-body-2 ma-0 pa-0" cols="3">
          <v-icon size="small">mdi-clock-outline</v-icon>
          {{ session.duration }} min
        </v-col>
        <v-col class="text-center text-body-2 ma-0 pa-0" cols="3">
          <v-icon size="small">mdi-cash</v-icon>
          {{
            session.price === 0 ? "Free" : session.price + " " + currency.symbol
          }}
        </v-col>
      </v-row>
    </v-card>
  </base-card>
</template>

<script>
import ViewSessionDialog from "./viewsessiondialog.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      selectedSession: null,
    };
  },
  components: {
    ViewSessionDialog,
  },
  props: {
    trainer: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      viewSessionDialog: "sessions/viewSessionDialog",
      currency: "websiteData/currency",
    }),
  },
  methods: {
    ...mapActions({
      setViewSessionDialog: "sessions/setViewSessionDialog",
    }),
    triggerViewSessionDialog(session) {
      if (session) {
        this.selectedSession = session;
        this.setViewSessionDialog(true);
      }
    },
  },
};
</script>

<style scoped>
.card-session {
  background-color: rgb(var(--v-theme-background1));
  color: rgb(var(--v-theme-textPrimary));
  border-radius: 10px;
}

.card-session:hover {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.row {
  margin: 0px;
  padding: 0px;
}
</style>
