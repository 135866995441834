<template>
  <div>
    <v-container class="my-10" style="min-height: 70vh">
      <v-row justify="center">
        <v-col cols="12" sm="8" md="11" lg="9" xl="7" align="center">
          <base-card class="py-7 text-h5 font-weight-medium">
            <v-icon size="small" start>mdi-view-dashboard</v-icon>
            Trainer Dashboard
          </base-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="4" lg="3" xl="2">
          <trainer-overview />
          <trainer-registration-status class="mt-5" />
          <my-payments-overview class="mt-5" />
          <communication-tools-overview class="mt-5" />
          <certifications-overview class="mt-5" />
        </v-col>
        <v-col cols="12" sm="8" md="7" lg="6" xl="5">
          <notifications />
          <my-sessions-overview class="mt-5" />
          <my-explorers-overview class="mt-5" />
          <my-availability-overview class="mt-5" />
          <session-types-overview class="mt-5" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CertificationsOverview from "../../../components/trainer/dashboard/certificationsoverview.vue";
import CommunicationToolsOverview from "../../../components/trainer/dashboard/communicationtoolsoverview.vue";
import MyAvailabilityOverview from "../../../components/trainer/dashboard/myavailabilityoverview.vue";
import MySessionsOverview from "../../../components/trainer/dashboard/mysessionsoverview.vue";
import MyExplorersOverview from "../../../components/trainer/dashboard/myexplorersoverview.vue";
import MyPaymentsOverview from "../../../components/trainer/dashboard/mypaymentsoverview.vue";
import Notifications from "../../../components/trainer/dashboard/notifications.vue";
import SessionTypesOverview from "../../../components/trainer/dashboard/sessiontypesoverview.vue";
import TrainerOverview from "../../../components/trainer/dashboard/traineroverview.vue";
import TrainerRegistrationStatus from "../../../components/trainer/dashboard/trainerregistrationstatus.vue";
import { setMetaTags } from "../../../utils/utils.js";

export default {
  components: {
    CertificationsOverview,
    CommunicationToolsOverview,
    MyAvailabilityOverview,
    MyExplorersOverview,
    MyPaymentsOverview,
    MySessionsOverview,
    Notifications,
    SessionTypesOverview,
    TrainerOverview,
    TrainerRegistrationStatus,
  },
  created() {
    setMetaTags(
      "NetworkTal - Trainer Dashboard",
      "Welcome to your trainer dashboard! Here you can manage your sessions, availability, certifications, and more."
    );
  },
};
</script>

<style scoped></style>
