<template>
  <div v-on:click="selectRoomHandler">
    <v-row
      :class="[{ 'has-notifications': hasNotifications }, 'room', status]"
      align="center"
    >
      <v-col cols="2" align="center" justify-center>
        <v-avatar size="38" class="mr-2" color="indigo">
          <v-icon v-if="!room.isPrivate" dark>mdi-account-circle</v-icon>
          <v-img v-if="room.isPrivate" :src="room.photoUrl"></v-img>
        </v-avatar>
      </v-col>
      <v-col>
        {{ room.name }}
      </v-col>
      <v-col cols="2" v-if="hasNotifications">
        <div class="notifications-number">
          {{ getRoomUserNotifications().length }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Room",
  data() {
    return {
      roomUserId: null,
    };
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
    active: {
      required: true,
    },
  },
  created() {
    this.getRoomUserId();
  },
  computed: {
    ...mapGetters({
      roomID: "room/activeRoomId",
      userId: "auth/userId",
      notifications: "notifications/notifications",
    }),
    status() {
      return this.active ? "active" : "";
    },
    hasNotifications() {
      return this.getRoomUserNotifications().length > 0;
    },
  },
  methods: {
    ...mapActions({
      selectRoom: "room/selectRoom",
      clearRoom: "room/clearRoom",
      deleteNotification: "notifications/deleteNotification",
      getUserNotifications: "notifications/getUserNotifications",
    }),
    selectRoomHandler: function () {
      if (this.roomID != this.room.id) {
        this.clearRoom();
        this.selectRoom({
          id: this.room.id,
          name: this.room.name,
          targetUser: null,
          currentUser: null,
        });
        this.deleteNotifications();
      }
    },
    getRoomUserId() {
      if (this.room) {
        // filter out the current user from this.room.users
        this.roomUserId = this.room.users.filter(
          (userId) => userId != this.userId
        )[0];
      }
    },
    getRoomUserNotifications() {
      // filter notifications that have as sender the roomUserId
      return this.notifications.filter(
        (notification) =>
          notification.data.senderId == this.roomUserId &&
          notification.data.type == "new-message"
      );
    },
    deleteNotifications() {
      let userNotifications = this.getRoomUserNotifications();
      if (userNotifications.length > 0) {
        userNotifications.forEach((notification) => {
          this.deleteNotification({
            userId: this.userId,
            notificationId: notification.id,
          });
        });
      }
      this.getUserNotifications(this.userId);
    },
  },
};
</script>
<style scoped>
.room {
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
}
.room.active {
  background-color: rgb(var(--v-theme-chatRoomBackgroundActive));
}
.room:hover {
  background-color: rgb(var(--v-theme-chatRoomBackgroundHover));
}
.has-notifications {
  font-weight: 600 !important;
}
.notifications-number {
  background-color: rgb(var(--v-theme-accent));
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding-top: 2px;
}
</style>
