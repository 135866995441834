<template>
  <div>
    <v-row
      v-if="communicationTool.enabled === true"
      @click="selectCommunicationTool(communicationTool)"
      :class="
        selectedCommunicationTool.id === communicationTool.id
          ? 'card-communication-tool card-communication-tool-active text-body-2'
          : 'card-communication-tool text-body-2'
      "
      elevation="0"
      align="center"
    >
      <v-col v-if="communicationTool.name === 'Skype'" align="right" cols="5">
        <v-img
          width="35"
          :src="require('@/assets/default/communicationtools/skype-icon.png')"
        ></v-img>
      </v-col>
      <v-col
        v-if="communicationTool.name === 'Facetime'"
        align="right"
        cols="5"
      >
        <v-img
          width="32"
          :src="
            require('@/assets/default/communicationtools/facetime-icon.png')
          "
        ></v-img>
      </v-col>
      <v-col
        v-if="communicationTool.name === 'Google Meet'"
        align="right"
        cols="5"
      >
        <v-img
          width="32"
          :src="
            require('@/assets/default/communicationtools/googlemeet-icon.png')
          "
        ></v-img>
      </v-col>
      <v-col v-if="communicationTool.name === 'Zoom'" align="right" cols="5">
        <v-img
          width="35"
          :src="require('@/assets/default/communicationtools/zoom-icon.png')"
        >
        </v-img>
      </v-col>
      <v-col cols="7">
        {{ communicationTool.name }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  inject: ["eventBus"],
  data: () => ({}),
  props: {
    communicationTool: {
      type: Object,
      required: true,
    },
    selectedCommunicationTool: {
      type: Object,
      required: true,
    },
  },
  methods: {
    selectCommunicationTool(communicationTool) {
      this.eventBus.emit("select-communication-tool", communicationTool);
    },
  },
};
</script>

<style scoped>
.card-communication-tool {
  background-color: rgb(var(--v-theme-background1));
  color: rgb(var(--v-theme-textPrimary));
  padding: 0px;
  margin: 15px 20px;
  min-height: 60px;
  border-radius: 10px !important;
}

.card-communication-tool-active {
  border: 2px solid rgb(var(--v-theme-colorSpecial1));
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 450;
}

.card-session:hover {
  background-color: rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
}
</style>
