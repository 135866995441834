<template>
  <div>
    <base-card class="pa-5 pt-2" style="text-align: center">
      <base-loader v-if="!currentUserTrainerProfile"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0">
          <v-col
            class="ma-0 pa-0 pt-2 text-body-2 font-weight-medium"
            cols="10"
            align="start"
          >
            <v-icon size="small" class="pr-2">mdi-account-school</v-icon>
            My Explorers Overview</v-col
          >
          <v-col class="ma-0 pa-0" align="right">
            <router-link to="/settings/trainer/myexplorers">
              <v-icon size="15" color="grey">mdi-eye</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <v-row
          v-if="currentUserTrainerProfile.myStudents.length === 0"
          class="ma-0 pa-0 py-5"
        >
          <v-col
            align="center"
            class="ma-0 pa-0 py-3 text-caption text-grey"
            style="font-style: italic"
          >
            No explorers met yet
          </v-col>
        </v-row>
        <div v-else>
          <v-row class="ma-0 pa-0 py-5" justify="center">
            <v-col
              :class="{
                'ma-0 pa-1': $vuetify.display.smAndDown,
                'ma-0 pa-3': $vuetify.display.mdAndUp,
                'ma-0 pa-3 px-7': $vuetify.display.xlAndUp,
              }"
              v-for="explorer in getMostRecent4Explorers()"
              :key="explorer.id"
              cols="3"
              ><explorer-card :userId="explorer.userId"></explorer-card
            ></v-col>
          </v-row>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ExplorerCard from "./myexplorersoverview/explorercard.vue";

export default {
  data() {
    return {};
  },
  components: {
    ExplorerCard,
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
    }),
  },
  methods: {
    getMostRecent4Explorers() {
      let myStudentsOrderedDesc =
        this.currentUserTrainerProfile.myStudents.sort((a, b) => {
          return new Date(b.dateFirstAdded) - new Date(a.dateFirstAdded);
        });

      return myStudentsOrderedDesc.slice(0, 4);
    },
  },
};
</script>

<style scoped></style>
