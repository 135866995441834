<template>
  <v-container>
    <v-row class="my-15" justify="center" align="center">
      <v-col xs="12" sm="11" md="10" lg="9" xl="8">
        <base-card style="min-height: 40vh">
          <div class="page-not-found-title">Page not found</div>
          <base-button toPath="/" class="mt-5 ml-7">
            <v-icon size="small" class="mr-1">mdi-home</v-icon>
            Home
          </base-button>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.page-not-found-title {
  /* place the paragraph in the middle of the parent element */
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: 430;
}
</style>
