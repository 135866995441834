<template>
  <div class="session-card text-caption ma-3">
    <router-link
      class="font-weight-medium ma-0 pa-0"
      :to="'/settings/user/mysessions/' + session.id"
      style="text-decoration: none"
    >
      <v-row
        align="center"
        v-if="trainerProfile"
        justify="space-around"
        class="ma-0 pa-0"
      >
        <v-col cols="5" sm="4" align="center">
          <v-row class="ma-0 pa-0">
            <v-col
              cols="12"
              sm="4"
              class="ma-0 pa-0 pr-2"
              :align="photoAlignment"
            >
              <v-avatar size="35"> <v-img :src="getPhotoURL"></v-img> </v-avatar
            ></v-col>
            <v-col
              cols="12"
              sm="7"
              class="ma-0 pa-0"
              :style="{
                'justify-content': nameAlignment,
                display: 'flex',
                'align-items': 'center',
              }"
            >
              <strong>{{ trainerProfile.displayName }}</strong>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          sm="4"
          md="4"
          lg="3"
          xl="3"
          class="px-2"
          style="font-size: 10px"
        >
          <v-icon size="small" class="mr-2">mdi-human-male-board</v-icon>
          {{ getSessionType().title }}
          <br />
          <v-icon size="small" class="mr-2">mdi-clock-outline</v-icon>
          {{ getSessionStartTime() }}
        </v-col>
        <v-col align="center">
          <div
            v-if="newSessionCardMessage !== null"
            style="display: flex; align-items: center; justify-content: center"
          >
            <v-chip
              size="small"
              :color="chipColorNewCardMessage"
              class="text-white"
              variant="outlined"
              label
              style="font-size: 10px"
            >
              <v-icon size="small" start>mdi-label</v-icon>
              {{ newSessionCardMessage }}
            </v-chip>
            <v-icon>mdi-question</v-icon>
          </div>
          <div v-else>
            <v-chip
              size="small"
              :color="chipColor"
              class="text-white"
              variant="outlined"
              label
              style="font-size: 10px"
            >
              <v-icon size="small" start>mdi-label</v-icon>
              {{ sessionCardMessage }}
            </v-chip>
          </div>
        </v-col>
      </v-row>
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import currencies from "../../../assets/general/currencies.json";
import {
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
} from "@/utils/utils.js";

export default {
  inject: ["eventBus"],
  data() {
    return {
      trainerProfile: null,
    };
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    sessionCardMessage: {
      type: String,
      required: false,
      default: "",
    },
  },
  created() {
    this.getTrainerProfileDetails();
  },
  computed: {
    ...mapGetters({
      currency: "websiteData/currency",
      currentUser: "users/currentUser",
      userPhotoDefault: "websiteData/userPhotoDefault",
    }),
    chipColor() {
      switch (this.sessionCardMessage) {
        case "TO COMPLETE":
          return "warning";
        case "UPCOMING":
          return "info";
        case "COMPLETED":
          return "success";
        case "DECLINED":
          return "error";
        case "CANCELLED":
          return "error";
        case "DISPUTED":
          return "warning";
        default:
          return "info";
      }
    },
    chipColorNewCardMessage() {
      switch (this.newSessionCardMessage) {
        case "PENDING TRAINER CONFIRMATION":
          return "info";
        case "PENDING SYSTEM ACTION":
          return "warning";
        default:
          return "info";
      }
    },
    photoAlignment() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "center";
        case "sm":
          return "center";
        case "md":
          return "end";
        case "lg":
          return "end";
        case "xl":
          return "end";
        default:
          return "end";
      }
    },
    nameAlignment() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "center";
        case "sm":
          return "center";
        case "md":
          return "start";
        case "lg":
          return "start";
        case "xl":
          return "start";
        default:
          return "start";
      }
    },
    getPhotoURL() {
      if (this.trainerProfile) {
        return this.trainerProfile.photoURL;
      } else {
        return this.userPhotoDefault;
      }
    },
    newSessionCardMessage() {
      let lastStatus =
        this.session.statusLogs[this.session.statusLogs.length - 1];

      if (lastStatus.status === "new") {
        if (
          convertUTCDateTimeToUserTimezoneNoFormat(
            this.session.start,
            this.currentUser.timezone
          ).isAfter(getCurrentTimeInUserTimezone(this.currentUser.timezone))
        ) {
          return "PENDING TRAINER CONFIRMATION";
        } else {
          return "PENDING SYSTEM ACTION";
        }
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions({
      getTrainerById: "trainers/getTrainerById",
    }),
    getSessionCurrency(currencyCode) {
      return currencies.find(
        (currency) => currency.code === currencyCode.toUpperCase()
      ).symbol;
    },
    getSessionStartTime() {
      return convertUTCDateTimeToUserTimezoneNoFormat(
        this.session.start,
        this.currentUser.timezone
      ).format("MMM D, YYYY, HH:mm");
    },
    async getTrainerProfileDetails() {
      this.trainerProfile = await this.getTrainerById({
        id: this.session.trainerId,
      });
    },
    getSessionType() {
      return this.trainerProfile.sessions.find(
        (session) => session.id === this.session.sessionId
      );
    },
    getNewStartTime() {
      if (this.session.statusLogs.length > 1) {
        let lastStatus =
          this.session.statusLogs[this.session.statusLogs.length - 1];

        return lastStatus.details.newStart;
      }
    },
  },
};
</script>

<style scoped>
strong {
  font-weight: 450;
}

.session-card {
  background-color: rgb(var(--v-theme-background1));
  color: rgb(var(--v-theme-textPrimary));
  border-radius: 10px;
  border: 2px solid rgb(var(--v-theme-background1));
}

.session-card:hover {
  /* add border */
  border: 2px solid rgb(var(--v-theme-colorSpecial1));
}

a {
  text-decoration: none;
  color: rgb(var(--v-theme-textPrimary));
}
</style>
