<template>
  <div>
    <p class="text-subtitle-1 font-weight-medium ma-2">{{ listTitle }}</p>
    <base-card
      class="sessions-card"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <session-card
        v-for="session in sessions"
        :key="session.id"
        :session="session"
        :sessionCardMessage="sessionCardMessage"
      ></session-card>
    </base-card>
  </div>
</template>

<script>
import SessionCard from "./sessioncard.vue";

export default {
  props: {
    sessions: {
      type: Array,
      required: true,
    },
    listTitle: {
      type: String,
      required: true,
    },
    sessionCardMessage: {
      type: String,
      required: false,
    },
  },
  components: {
    SessionCard,
  },
};
</script>

<style scoped>
.sessions-card {
  max-height: 40vh;
  overflow-y: auto;
}
</style>
