<template>
  <div>
    <v-row align="center">
      <v-col align="center">
        <v-avatar size="100" color="colorSpecial2" class="my-15">
          <v-icon size="60" color="white">mdi-check</v-icon>
        </v-avatar>
        <p class="text-h5 font-weight-medium mx-5">Payment Success</p>
        <p class="text-h6 font-weight-regular mx-5">
          You have booked a new session!
        </p>
      </v-col>
    </v-row>

    <v-row class="text-body-1" justify="center">
      <v-col cols="11" md="10" lg="9" xl="9">
        <p>What happens next?</p>
        <ul class="ml-5 mt-5">
          <li>
            You will receive a booking confirmation email with the session
            details
          </li>
          <li>
            Trainer will receive your booking request and has to accept it
          </li>
          <li>
            Once the trainer accepts your booking request, you will receive a
            confirmation email
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center" class="mb-15 mt-5">
        <base-button toPath="/settings/user/mysessions"
          >My Sessions</base-button
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
