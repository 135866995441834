<template>
  <div style="width: 100%">
    <ChatLoader v-if="!isLoaded" />
    <div v-else>
      <div class="chat-header">{{ roomName }}</div>
      <div class="chat-list">
        <Chat
          v-for="chat in chats"
          :key="chat.id"
          :chat="chat"
          :continuation="chat.continuation"
          :class="`chat-${chat.id}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ChatLoader from "./ChatLoader";
import Chat from "./Chat";

export default {
  name: "ChatList",
  components: {
    ChatLoader,
    Chat,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      roomID: "room/activeRoomId",
      roomName: "room/activeRoomName",
      chats: "room/roomMessages",
    }),
    blockPosition() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "nearest";
        case "sm":
          return "nearest";
        case "md":
          return "end";
        case "lg":
          return "end";
        case "xl":
          return "end";
        default:
          return "nearest";
      }
    },
  },
  created() {
    this.getRoomMessagesAsync(this.roomID);
  },
  methods: {
    ...mapActions({
      getRoomMessages: "room/getRoomMessages",
    }),
    async getRoomMessagesAsync(roomId) {
      await this.getRoomMessages({
        roomId: roomId,
      });
      this.isLoaded = true;
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    },
    scrollToBottom() {
      var classId = `.chat-${this.chats[this.chats.length - 1].id}`;
      var element = document.querySelector(classId);
      // scroll to bottom of the chat list and not the page

      if (typeof element != "undefined" && element != null) {
        element.scrollIntoView({
          behavior: "auto",
          block: this.blockPosition,
          inline: "nearest",
        });
      }
      classId = null;
      element = null;
    },
  },
  watch: {
    roomID(newValue) {
      this.getRoomMessagesAsync(newValue);
    },
    chats() {
      setTimeout(() => {
        this.scrollToBottom();
      }, 200);
    },
  },
};
</script>

<style scoped>
.chat-header {
  font-size: 20px;
  font-weight: 500;
  padding: 1rem;
  /* border: 2px solid black; */
}
.chat-list {
  /* make the div height with a maximum of 800px and scrollable */
  height: 55vh;
  overflow-y: scroll;
  overflow-x: hidden;
  /* change the color of te scroll bar  */
  scrollbar-color: #e0e0e0 #f5f5f5;
}
</style>
