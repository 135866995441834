<template>
  <div>
    <base-card class="pa-10">
      <base-loader v-if="!trainerProfile"></base-loader>
      <div v-else>
        <v-row class="text-subtitle-1">
          <v-col align="start">{{ getSessionDate() }}</v-col>
          <v-col align="end" class="text-caption">
            <base-tooltip-info
              text="Timezone can only be changed in the user settings page"
              :custom="true"
            >
              <v-icon size="small">mdi-earth</v-icon>
              {{ currentUser.timezone }} {{ currentUserTimezoneOffset }}
            </base-tooltip-info>
          </v-col>
        </v-row>
        <v-row class="text-h4">
          <v-col align="center">{{ getSessionTimeInterval() }}</v-col>
        </v-row>
        <v-divider class="my-8"></v-divider>
        <v-row>
          <v-col class="text-h6 font-weight-medium">{{
            session.sessionTitle
          }}</v-col>
        </v-row>
        <v-row>
          <v-col class="text-body-2">{{ session.sessionDescription }}</v-col>
        </v-row>
        <!-- <v-row>
          <v-col>{{ getSessionDisplayPrice() }}</v-col>
        </v-row> -->
        <v-divider class="my-8"></v-divider>
        <v-row>
          <v-col class="text-subtitle-1 font-weight-medium"
            >Communication Tool
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col align="right" cols="2">
            <v-img
              :width="getCommunicationTool().name === 'Skype' ? '40' : '35'"
              :src="
                require(`@/assets/default/communicationtools/${getCommunicationTool()
                  .name.toLowerCase()
                  .replace(/\s/g, '')}-icon.png`)
              "
            ></v-img>
          </v-col>
          <v-col class="text-body-2 font-weight-medium">
            {{ getCommunicationTool().name }}
          </v-col>
        </v-row>
        <v-row
          v-if="getCommunicationTool().name !== 'Zoom'"
          class="mt-2 text-caption"
        >
          <v-col>
            <strong>{{ getCommunicationTool().name }} Id:</strong>
            {{ getCommunicationTool().toolId }}
          </v-col>
        </v-row>
        <div v-else class="mt-5 text-caption">
          <v-row class="ma-0 pa-0 my-2">
            <v-col cols="6" md="4" class="ma-0 pa-0">Meeting URL: </v-col>
            <v-col cols="6" md="8" class="ma-0 pa-0">{{
              getCommunicationTool().toolId.meetingURL
            }}</v-col>
          </v-row>
          <v-row class="ma-0 pa-0 my-2">
            <v-col cols="6" md="4" class="ma-0 pa-0">Passcode: </v-col>
            <v-col cols="6" md="8" class="ma-0 pa-0">{{
              getCommunicationTool().toolId.passcode
            }}</v-col>
          </v-row>
          <v-row class="ma-0 pa-0 my-2">
            <v-col cols="6" md="4" class="ma-0 pa-0"
              >Personal Meeting Id:
            </v-col>
            <v-col cols="6" md="8" class="ma-0 pa-0">{{
              getCommunicationTool().toolId.personalMeetingId
            }}</v-col>
          </v-row>
        </div>
        <v-row
          class="ma-0 mt-5 pa-3"
          style="background: lightgray; border-radius: 5px"
        >
          <v-col class="text-caption ma-0 pa-0"
            ><strong>Important:</strong> Connect with your trainer at session
            start time on the selected communication tool by using the above
            provided details.
          </v-col>
        </v-row>
        <!-- <v-divider class="my-8"></v-divider>
        <v-row>
          <v-col class="text-subtitle-1 font-weight-medium"
            >Session Review
          </v-col>
        </v-row>
        <v-row v-if="getLastSessionStatus() !== 'completed'">
          <v-col class="text-body-2">
            Session review will be available after the session is completed.
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col></v-col>
        </v-row> -->
        <v-divider class="my-8"></v-divider>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0" align="center">
            <base-button
              toPath="/settings/user/mysessions"
              customHeight="27px"
              customFontSize="10px"
              customWidth="95px"
            >
              <v-icon class="mr-2" size="small">mdi-calendar</v-icon>
              View all my sessions
            </base-button>
          </v-col>
        </v-row>
      </div>
    </base-card>
  </div>
</template>

<script>
import currencies from "../../../../assets/general/currencies.json";
import { mapGetters } from "vuex";
import {
  convertUTCDateTimeToUserTimezoneNoFormat,
  getUTCValueOfTimezone,
} from "@/utils/utils.js";

export default {
  props: {
    session: {
      required: true,
    },
    trainerProfile: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
    }),
    currentUserTimezoneOffset() {
      return getUTCValueOfTimezone(this.currentUser.timezone);
    },
  },
  methods: {
    getSessionDate() {
      let dateInUserTimezone = convertUTCDateTimeToUserTimezoneNoFormat(
        this.session.start,
        this.currentUser.timezone
      );

      return dateInUserTimezone.format("dddd, MMMM D, YYYY");
    },
    getSessionTimeInterval() {
      let startTimeInUserTimezone = convertUTCDateTimeToUserTimezoneNoFormat(
        this.session.start,
        this.currentUser.timezone
      );

      let endTimeInUserTimezone = convertUTCDateTimeToUserTimezoneNoFormat(
        this.session.end,
        this.currentUser.timezone
      );

      return `${startTimeInUserTimezone.format(
        "HH:mm"
      )} - ${endTimeInUserTimezone.format("HH:mm")}`;
    },
    getSessionDisplayPrice() {
      let currencyCode = this.session.sessionCurrency;
      let price = this.session.sessionPrice / 100;
      let currencySymbol = currencies.find(
        (currency) => currency.code === currencyCode.toUpperCase()
      ).symbol;
      return `${currencySymbol} ${price}`;
    },
    getCommunicationTool() {
      let sessionCommunicationToolId = this.session.communicationToolId;
      let communicationTool = this.trainerProfile.communicationTools.find(
        (tool) => tool.id === sessionCommunicationToolId
      );
      return communicationTool;
    },
    getLastSessionStatus() {
      return this.session.statusLogs[this.session.statusLogs.length - 1].status;
    },
  },
};
</script>

<style scoped></style>
