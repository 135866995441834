<template>
  <div>
    <v-card class="password-reset-card" elevation="0">
      <p class="password-reset-card-title">Forgot Password?</p>
      <base-error-success-message
        :errorMessage="errorMessage"
        :successMessage="successMessage"
      ></base-error-success-message>
      <form v-if="!passwordResetEmailSent">
        <p class="my-5">
          Please enter your email address below. You will receive an email with
          a link to reset your password.
        </p>
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          label="E-mail"
          required
        ></v-text-field>

        <v-row class="my-5 px-5">
          <v-col align="center" class="ma-0 pa-0">
            <base-button @click="submitForm">Submit</base-button>
          </v-col>
          <v-col align="center" class="ma-0 pa-0">
            <base-button customColor="error" @click="clear">
              clear
            </base-button>
          </v-col>
        </v-row>
      </form>
      <div v-else>
        <v-alert type="success">Password reset email sent successfully</v-alert>
        <div class="my-5">
          <p>Didn`t you receive the email?</p>
          <ul class="ma-5">
            <li>Check your spam folder</li>
            <li @click="resetValues">
              <router-link to="/passwordreset">Reset password</router-link>
              again
            </li>
          </ul>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { mapActions } from "vuex";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      email: { required, email },
    };
  },

  data: () => ({
    email: "",
    errorMessage: "",
    successMessage: "",
    passwordResetEmailSent: false,
  }),

  computed: {
    emailErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "email") {
          if (error.$validator === "required") {
            errors.push("Email is required");
          } else if (error.$validator === "email") {
            errors.push("Invalid email");
          }
        }
      });
      return errors;
    },
  },

  methods: {
    ...mapActions({
      resetPassword: "auth/resetpassword",
    }),
    async submitForm() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        try {
          await this.resetPassword({
            email: this.email,
            continueUrl: process.env.VUE_APP_NETWORKTAL_PLATFORM_URL + "/login",
          });
          this.passwordResetEmailSent = true;
          this.clear();
        } catch (errorMessage) {
          // console.log("Error code: " + errorMessage)
          // switch (errorMessage) {
          //     case 'auth/invalid-email':
          //         this.errorMessage = 'Invalid email'
          //         break
          //     case 'auth/missing-email':
          //         this.errorMessage = 'Email address is missing'
          //         break
          //     case 'auth/user-not-found':
          //         this.errorMessage = 'No account with that email was found'
          //         break
          //     default:
          //         this.errorMessage = 'Unknown error. Something went wrong'
          //         break
          // }
          // check if error message contains a string
          if (errorMessage.message.includes("auth/invalid-email")) {
            this.errorMessage = "Invalid email";
            setTimeout(() => {
              this.errorMessage = "";
            }, 5000);
          } else if (errorMessage.message.includes("auth/missing-email")) {
            this.errorMessage = "Email address is missing";
            setTimeout(() => {
              this.errorMessage = "";
            }, 5000);
          } else if (errorMessage.message.includes("auth/user-not-found")) {
            this.errorMessage = "No account with that email was found";
            setTimeout(() => {
              this.errorMessage = "";
            }, 5000);
          } else {
            this.errorMessage = "Unknown error. Something went wrong";
            setTimeout(() => {
              this.errorMessage = "";
            }, 5000);
          }
        }
      } else {
        this.errorMessage = "Please fill in all the required information";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },
    clear() {
      this.email = "";
      this.errorMessage = "";
      this.v$.$reset();
    },
    resetValues() {
      this.passwordResetEmailSent = false;
      this.clear();
    },
  },
};
</script>

<style scoped>
.password-reset-card {
  background-color: rgb(var(--v-theme-authBackground));
  color: rgb(var(--v-theme-textPrimary));
  border-radius: 20px;
  padding: 50px;
  margin: 0 auto;
  font-size: 17px;
}

@media (max-width: 600px) {
  .password-reset-card {
    padding: 30px;
  }
}

.password-reset-card-title {
  color: rgb(var(--v-theme-textPrimary));
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
