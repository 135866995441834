<template>
  <div>
    <v-expansion-panels class="expansion-panels">
      <v-expansion-panel v-for="FAQ in FAQs" :key="FAQ.question">
        <v-expansion-panel-title class="question-header">
          {{ FAQ.question }}
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          {{ FAQ.answer }}
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  data() {
    return {
      FAQs: [
        {
          question: "What do I need to become a trainer on NetworkTal?",
          answer:
            "To become a trainer on NetworkTal, you need to have a passion for teaching and a desire to help others learn. You also need to have a skill or topic that you are knowledgeable about and can teach to others in one-to-one sessions. Finally, you need to have a computer with a webcam and microphone, as well as a reliable internet connection.",
        },
        {
          question: "How do I create a trainer profile on NetworkTal?",
          answer:
            'Creating a profile on NetworkTal is simple and takes just a few minutes. First, sign up and create a user account, then simply click on the "Become a Trainer" button and follow the on-screen instructions. You will need to provide information about yourself and your training services. Once you have completed the process, your profile will be reviewed by our team and you will be notified when it is approved.',
        },
        {
          question: "How do I manage my sessions?",
          answer:
            'You can manage your sessions directly on your profile. Go to your trainer profile and select "My Sessions" You can view all of your upcoming sessions and manage them from there.',
        },
        {
          question: "What is a session in my trainer account?",
          answer:
            "A session is a one-on-one meeting that a trainer has with a user (explorer). Explorers can book sessions by choosing a session type and a date and time.",
        },
        {
          question: "What is a session type in my trainer account?",
          answer:
            "A session type is a type of session that you offer to your students. You can create multiple session types to offer different types of sessions to your students. A session contains a title, description, price and duration (30 or 60 minutes). When the explorer books a session with you he can choose the session type he wants to book. An example of session type: 'Introduction to Artificial Intelligence' or 'Advanced Guitar Lessons'.",
        },
        {
          question: "How do I create a session type?",
          answer:
            'You can create a session type directly on your profile. Go to your trainer profile and select "Session Types Management" You can create multiple session types to offer different types of sessions to your students.',
        },
        {
          question: "How do I set my availability?",
          answer:
            'You can set your availability directly on your profile. Go to your trainer profile and select "Availability Management" You can set your availability by day and time. We recommend setting your availability at least 2 weeks in advance to give your students time to book sessions with you.',
        },
        {
          question: "How do I get paid?",
          answer:
            "You will get paid for each session you complete with your students. You can set your own rates and NetworkTal will take a commission of 15% on each session. You can view your earnings and payment history directly on your payment account. NetworkTal partners with Stripe to process payments and payouts. From your trainer profile under My Payments you can access your stripe account to manage your payments.",
        },
        {
          question: "How do I provide feedback to my students?",
          answer:
            "After each session, you can provide feedback to your students directly on NetworkTal through private message. This is a great way to show your appreciation to them and to help them improve their skills.",
        },
        {
          question: "How do I attract more students?",
          answer:
            "There are a few things you can do to attract more students to your NetworkTal profile: create a compelling profile with a strong bio and professional headshot, set competitive rates and offer flexible scheduling options, provide high-quality sessions that deliver results, and actively engage with your students by responding to reviews and answering questions.",
        },
        {
          question: "How do I report a problem or abuse?",
          answer:
            "If you encounter a problem or abuse on NetworkTal, you can report it to our customer support team. We take all reports seriously and will investigate them promptly. You can contact our customer support team by email. Go to our Contact page at the bottom of the page for more details.",
        },
      ],
    };
  },
  components: {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
.expansion-panels {
  /* remove elevation */
  box-shadow: none !important;
}

.question-header {
  font-weight: 450;
  color: rgb(var(--v-theme-textPrimary));
}
</style>
