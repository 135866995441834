<template>
  <div>
    <v-row class="summary-info" justify="center">
      <v-col cols="12" md="10" lg="6" xl="6">
        <p class="text-subtitle-1 font-weight-medium mb-2">Summary</p>
        <v-card class="summary-card mb-5 pa-5 text-body-2" elevation="0">
          <v-row class="py-2">
            <v-col class="font-weight-medium">Session Type</v-col>
            <v-col>{{ selectedSession.title }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="py-2">
            <v-col class="font-weight-medium">Duration</v-col>
            <v-col>{{ selectedSession.duration }} minutes</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="py-2">
            <v-col class="font-weight-medium">Date</v-col>
            <v-col>{{ formattedDate }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="py-2">
            <v-col class="font-weight-medium">Start Time</v-col>
            <v-col>{{ formattedStartTime }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="py-2">
            <v-col class="font-weight-medium">End Time</v-col>
            <v-col>{{ formattedEndTime }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="py-2">
            <v-col class="font-weight-medium">Timezone</v-col>
            <v-col>{{ this.currentUser.timezone }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="py-2">
            <v-col class="font-weight-medium">Communication Tool</v-col>
            <v-col>{{ selectedCommunicationTool.name }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="py-2">
            <v-col class="font-weight-medium">Price</v-col>
            <v-col>{{
              selectedSession.price === 0
                ? "Free"
                : selectedSession.price + " " + currentUser.currency.symbol
            }}</v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { convertUTCDateTimeToUserTimezoneNoFormat } from "@/utils/utils.js";

export default {
  props: {
    selectedSession: {
      required: false,
    },
    selectedStartTimeUTC: {
      required: false,
    },
    selectedEndTimeUTC: {
      required: false,
    },
    selectedCommunicationTool: {
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
    }),
    formattedStartTime() {
      return convertUTCDateTimeToUserTimezoneNoFormat(
        this.selectedStartTimeUTC,
        this.currentUser.timezone
      ).format("HH:mm");
    },
    formattedEndTime() {
      return convertUTCDateTimeToUserTimezoneNoFormat(
        this.selectedEndTimeUTC,
        this.currentUser.timezone
      ).format("HH:mm");
    },
    formattedDate() {
      return convertUTCDateTimeToUserTimezoneNoFormat(
        this.selectedStartTimeUTC,
        this.currentUser.timezone
      ).format("dddd, MMMM D, YYYY");
    },
  },
};
</script>

<style scoped>
.summary-info {
  margin: 0px;
  height: 45vh !important;
  overflow: auto;
}

.summary-card {
  background-color: rgb(var(--v-theme-card));
  border: 1px solid rgb(var(--v-theme-background)) !important;
}
</style>
