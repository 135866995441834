<template>
  <base-card :style="{ height: heightVideo, padding: '15px' }">
    <video-card :src="videoURL"></video-card>
  </base-card>
</template>

<script>
export default {
  props: {
    videoURL: {
      required: true,
    },
  },
  computed: {
    heightVideo() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "250px";
        case "sm":
          return "350px";
        case "md":
          return "500px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
  },
};
</script>

<style scoped></style>
