<template>
  <v-container style="min-height: 70vh">
    <v-row class="my-15" justify="center">
      <v-col xs="12" sm="12" md="12" lg="9" xl="6">
        <base-card class="pt-10 pb-15 px-5">
          <p class="text-h4 font-weight-medium text-center my-15 mx-5">
            NetworkTal Blog
          </p>
          <v-text-field
            class="mb-10 mx-5"
            v-model="searchByNameInput"
            prepend-icon="mdi-magnify"
            label="Search blog post by name"
            single-line
            hide-details
            clearable
            variant="underlined"
            @update:modelValue="filterBlogPostsByName"
          ></v-text-field>
          <v-row justify="center" v-if="filteredBlogPosts">
            <v-col
              v-for="blogpost in filteredBlogPosts"
              :key="blogpost.id"
              cols="12"
              sm="6"
              class="pa-5"
            >
              <blogpost-preview
                :Title="blogpost.title"
                TitleColor="white"
                :Date="blogpost.date"
                :Description="blogpost.description"
                :ImageURL="blogpost.imageURL"
                :ArticlePath="`/blog/${blogpost.path}`"
              ></blogpost-preview>
            </v-col>
          </v-row>
          <v-row justify="center" v-else>
            <v-col
              v-for="placeholder in [1, 2, 3, 4]"
              :key="placeholder"
              cols="12"
              sm="6"
              class="pa-5"
            >
              <blogpost-preview-skeleton></blogpost-preview-skeleton>
            </v-col>
          </v-row>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BlogpostPreview from "../../components/blog/blogpostpreview.vue";
import BlogpostPreviewSkeleton from "../../components/blog/blogpostpreviewskeleton.vue";
import { mapActions, mapGetters } from "vuex";
import { setMetaTags } from "../../utils/utils.js";

export default {
  data() {
    return {
      searchByNameInput: "",
      filteredBlogPosts: null,
    };
  },
  created() {
    this.fetchBlogPosts();
    setMetaTags(
      "NetworkTal - Blog",
      "Get the latest news and updates from NetworkTal Blog. Stay tuned for more exciting content!"
    );
  },
  components: {
    BlogpostPreview,
    BlogpostPreviewSkeleton,
  },
  computed: {
    ...mapGetters({
      blogposts: "blogposts/blogposts",
    }),
  },
  methods: {
    ...mapActions({
      getBlogPosts: "blogposts/getBlogPosts",
    }),
    filterBlogPostsByName() {
      this.filteredBlogPosts = this.blogposts.filter((blogpost) => {
        return blogpost.title
          .toLowerCase()
          .includes(
            this.searchByNameInput ? this.searchByNameInput.toLowerCase() : ""
          );
      });
    },
    async fetchBlogPosts() {
      await this.getBlogPosts();

      this.filteredBlogPosts = this.blogposts.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    },
  },
};
</script>

<style scoped>
.text {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text h2 {
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 20px;
}

.paragraph-title {
  font-weight: 450;
  margin-top: 10px;
}

.text ul {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}
</style>
