<template>
  <div>
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col cols="7" class="text-h5 font-weight-medium pl-7"
        >Profile Picture</v-col
      >
      <v-col align="end">
        <base-button
          v-if="!inEditMode"
          @click="toggleInEditMode"
          :disabled="isTrainerProfilePictureUpdated"
        >
          Edit
          <base-icon>mdi-pencil</base-icon>
        </base-button>
      </v-col>
    </v-row>
    <base-card
      :class="{
        'px-10 py-5': $vuetify.display.mdAndUp,
        'pa-2': $vuetify.display.smAndDown,
      }"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <base-error-success-message
        :errorMessage="errorMessage"
        :successMessage="successMessage"
      ></base-error-success-message>
      <base-notification-card
        v-if="displayTrainerProfileUpdatesInfoNotification"
        :notification="notifications[0]"
      ></base-notification-card>
      <base-notification-card
        v-if="displayTrainerProfileUpdatedNotification"
        :notification="notifications[1]"
      ></base-notification-card>
      <v-row align="center" class="mb-5 mx-5 mt-3">
        <v-col align="center" v-if="!inEditMode">
          <v-avatar size="150">
            <img
              :src="getPhotoURL"
              alt="Profile Picture"
              style="height: 200px"
            />
          </v-avatar>
        </v-col>
        <v-col v-if="inEditMode && profilePictureDownloadURL" align="center">
          <v-avatar size="150">
            <img
              :src="profilePictureDownloadURL"
              alt="Profile Picture"
              style="height: 200px"
            />
          </v-avatar>
        </v-col>
        <v-file-input
          v-if="inEditMode"
          class="mt-5"
          chips
          clearabale
          :rules="rules"
          accept="image/png, image/jpeg, image/bmp"
          prepend-icon="mdi-camera"
          label="Profile Picture"
          show-size
          validate-on="input"
          @update:modelValue="previewImage($event)"
        ></v-file-input>
      </v-row>
      <v-row class="mx-10 my-5" v-if="inEditMode">
        <base-button @click="submit">
          Save
          <base-icon>mdi-content-save</base-icon>
        </base-button>
        <base-button customColor="error" @click="cancel">
          Cancel
          <base-icon>mdi-close</base-icon>
        </base-button>
      </v-row>
    </base-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    errorMessage: "",
    successMessage: "",
    inEditMode: false,
    profilePictureFile: [],
    notifications: [
      {
        id: 1,
        color: "info",
        icon: "mdi-information-outline",
        message:
          "Updating your profile picture will not be visible directly in your public profile. This will be reviewed by our team first, and published once approved. This process may take up to 7 days.",
        title: "Profile Picture Update",
      },
      {
        id: 2,
        color: "warning",
        icon: "mdi-alert-outline",
        message:
          "You have updated your profile picture. This change will be reviewed by our team first, and published once approved. This process may take up to 7 days.",
        title: "Profile Picture Updated",
      },
    ],
    rules: [
      (value) => {
        return (
          !value ||
          !value.length ||
          value[0].size < 2000000 ||
          "Profile Picture size should be less than 2 MB!"
        );
      },
      (value) => {
        return (
          !value ||
          !value.length ||
          ["image/jpeg", "image/png", "image/bmp"].includes(value[0].type) ||
          "Profile Picture must be an image file in format JPG, PNG or BMP!"
        );
      },
    ],
  }),
  props: {
    trainerProfile: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      profilePictureDownloadURL: "data/downloadURL",
      userId: "auth/userId",
      userPhotoDefault: "websiteData/userPhotoDefault",
    }),
    getPhotoURL() {
      let photoURL = "";
      if (this.isTrainerProfilePictureUpdated) {
        let updateRequest = this.getTrainerProfilePictureUpdateRequest();
        photoURL = updateRequest.photoURL;
      } else {
        photoURL = this.trainerProfile.photoURL
          ? this.trainerProfile.photoURL
          : this.userPhotoDefault;
      }
      return photoURL;
    },
    displayTrainerProfileUpdatesInfoNotification() {
      let displayTrainerProfileUpdatesInfoNotification = false;

      if (!this.isTrainerProfilePictureUpdated) {
        displayTrainerProfileUpdatesInfoNotification = true;
      }

      // If the trainer profile is not approved, do not show the notification
      if (this.trainerProfile.registrationRequestStatus !== "approved") {
        displayTrainerProfileUpdatesInfoNotification = false;
      }

      return displayTrainerProfileUpdatesInfoNotification;
    },
    displayTrainerProfileUpdatedNotification() {
      let displayTrainerProfileUpdatedNotification = false;

      if (this.isTrainerProfilePictureUpdated) {
        displayTrainerProfileUpdatedNotification = true;
      }

      // If the trainer profile is not approved, do not show the notification
      if (this.trainerProfile.registrationRequestStatus !== "approved") {
        displayTrainerProfileUpdatedNotification = false;
      }

      return displayTrainerProfileUpdatedNotification;
    },
    isTrainerProfilePictureUpdated() {
      let isTrainerProfilePictureUpdated = false;

      if (
        this.trainerProfile &&
        this.trainerProfile.trainerProfileUpdateRequests &&
        this.trainerProfile.trainerProfileUpdateRequests.length > 0
      ) {
        // check if any trainer profile Update Requests are of type profile-information and status is pending
        this.trainerProfile.trainerProfileUpdateRequests.forEach(
          (updateRequest) => {
            if (
              updateRequest.type === "profile-picture" &&
              updateRequest.status === "pending"
            ) {
              isTrainerProfilePictureUpdated = true;
            }
          }
        );
      }

      // If the trainer profile is not approved, do not show the notification
      if (this.trainerProfile.registrationRequestStatus !== "approved") {
        isTrainerProfilePictureUpdated = false;
      }

      return isTrainerProfilePictureUpdated;
    },
  },
  methods: {
    ...mapActions({
      uploadProfilePicture: "data/uploadProfilePicture",
      clearUploadState: "data/clearState",
      createOrUpdateUser: "users/createOrUpdateUser",
      createOrUpdateTrainer: "trainers/createOrUpdateTrainer",
    }),
    getTrainerProfilePictureUpdateRequest() {
      let trainerProfilePictureUpdateRequest = null;

      this.trainerProfile.trainerProfileUpdateRequests.forEach(
        (updateRequest) => {
          if (
            updateRequest.type === "profile-picture" &&
            updateRequest.status === "pending"
          ) {
            trainerProfilePictureUpdateRequest = updateRequest;
          }
        }
      );
      return trainerProfilePictureUpdateRequest;
    },
    isProfilePictureValid(picture) {
      let isProfilePictureValid = false;

      if (picture) {
        isProfilePictureValid =
          picture.size < 2000000 &&
          ["image/jpeg", "image/png", "image/bmp"].includes(picture.type);
      }
      return isProfilePictureValid;
    },
    async previewImage(event) {
      this.profilePictureFiles = event;
      let isProfilePictureValid = this.isProfilePictureValid(event);

      if (event && isProfilePictureValid) {
        await this.uploadProfilePicture({
          userId: this.userId,
          image: event,
        });
      } else {
        this.clearUploadState();
      }
    },
    toggleInEditMode() {
      this.inEditMode = !this.inEditMode;
    },
    async submit() {
      let isProfilePictureValid = this.isProfilePictureValid(
        this.profilePictureFiles
      );
      if (isProfilePictureValid) {
        try {
          if (!this.profilePictureDownloadURL) {
            throw new Error("Error uploading profile picture");
          }

          if (this.trainerProfile.registrationRequestStatus === "approved") {
            if (!this.trainerProfile.trainerProfileUpdateRequests) {
              this.trainerProfile.trainerProfileUpdateRequests = [];
            }

            this.trainerProfile.trainerProfileUpdateRequested = true;

            this.trainerProfile.trainerProfileUpdateRequests.push({
              photoURL: this.profilePictureDownloadURL,
              status: "pending",
              timestamp: new Date().toISOString(),
              type: "profile-picture",
              trainerProfileUpdated: false,
            });

            await this.createOrUpdateTrainer({
              trainerId: this.trainerProfile.id,
              data: {
                ...this.trainerProfile,
              },
            });
          } else {
            await this.createOrUpdateTrainer({
              trainerId: this.trainerProfile.id,
              data: {
                ...this.trainerProfile,
                photoURL: this.profilePictureDownloadURL,
              },
            });
          }

          this.toggleInEditMode();
          this.clearUploadState();
          this.profilePictureFiles = null;
          this.successMessage = "Operation successful!";
          setTimeout(() => {
            this.successMessage = "";
          }, 5000);
        } catch (error) {
          this.clearUploadState();
          this.profilePictureFiles = null;
          // this.errorMessage = error.message;
          this.errorMessage = "Something went wrong. Please try again later.";
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        }
      } else {
        this.errorMessage =
          "The uploaded file must be an image file in format JPG, PNG or BMP and less than 2MB in size";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },
    cancel() {
      this.toggleInEditMode();
      this.clearUploadState();
      this.profilePictureFiles = null;
    },
  },
};
</script>

<style scoped></style>
