<template>
  <base-card class="pa-10">
    <v-row class="mb-4" align="center">
      <v-col cols="12" sm="6" class="font-weight-medium text-h6"
        >Availability Calendar</v-col
      >
      <v-col class="text-caption" cols="12" sm="6" align="end">
        <base-tooltip-info
          text="Timezone can only be changed in the user settings page"
          :custom="true"
        >
          <v-icon size="small">mdi-earth</v-icon>
          {{ currentUserTimezone }} {{ currentUserTimezoneOffset }}
        </base-tooltip-info>
      </v-col>
    </v-row>
    <calendar-base :trainerId="trainerId"></calendar-base>
  </base-card>
</template>

<script>
import CalendarBase from "./calendarbase.vue";
import { mapGetters } from "vuex";
import { getUTCValueOfTimezone } from "@/utils/utils.js";

export default {
  props: {
    trainerId: {
      required: true,
    },
  },
  components: {
    CalendarBase,
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
      userTimezone: "websiteData/timezone",
    }),
    currentUserTimezone() {
      if (this.currentUser && this.currentUser.timezone) {
        return this.currentUser.timezone;
      } else {
        return this.userTimezone;
      }
    },
    currentUserTimezoneOffset() {
      return getUTCValueOfTimezone(this.currentUserTimezone);
    },
  },
};
</script>

<style scoped>
.row {
  border: 2px solid black;
  margin: 0px;
  padding: 0px;
}
</style>
