<template>
  <div>
    <page-header></page-header>
    <v-container fluid>
      <v-row v-if="loading" class="ma-0 pa-0" justify="center" align="center">
        <v-col class="ma-0 pa-0 py-2" cols="12" md="10" lg="9" xl="7">
          <v-row>
            <v-col cols="12" md="6" v-for="n in [1, 2, 3, 4]" :key="n">
              <session-card-skeleton></session-card-skeleton>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else class="ma-0 pa-0 my-2" justify="center" align="center">
        <v-col
          v-if="trainersSessions.length !== 0"
          class="ma-0 pa-0"
          cols="12"
          lg="10"
          xl="8"
        >
          <v-infinite-scroll
            class="ma-0 pa-0"
            :items="trainersSessions"
            :onLoad="load"
          >
            <v-row class="ma-0 pa-0" align="center">
              <template
                v-for="trainerSession in trainersSessions.slice(
                  0,
                  trainersSessionsDisplayNumber
                )"
                :key="trainerSession.id"
              >
                <v-col
                  :class="{
                    'ma-0 pa-0 my-5 px-5': $vuetify.display.mdAndUp,
                    'ma-0 pa-0 my-5': $vuetify.display.smAndDown,
                  }"
                  cols="12"
                  md="6"
                >
                  <session-card :session="trainerSession"></session-card>
                </v-col>
              </template>
            </v-row>
            <template v-slot:empty>
              <base-button customVariant="outlined" disabled>
                all sessions loaded
              </base-button>
            </template>
          </v-infinite-scroll>
        </v-col>
        <v-col v-else class="ma-0 pa-0" cols="12" md="10" lg="9" xl="7">
          <base-card
            align="center"
            class="text-h5 text-grey py-15 font-weight-regular"
          >
            <v-icon>mdi-magnify-remove-outline</v-icon>
            No sessions found</base-card
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SessionCard from "../components/trainer/findsessions/sessioncard.vue";
import SessionCardSkeleton from "../components/trainer/findsessions/sessioncardskeleton.vue";
import PageHeader from "../components/trainer/findsessions/pageheader.vue";
import { setMetaTags } from "../utils/utils.js";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";
import { computed, inject, onMounted, ref } from "vue";

export default {
  setup() {
    const { fetchTrainersSessionsInRandomOrder } = useGlobalFunctions();
    const eventBus = inject("eventBus");
    const trainersSessions = ref("");
    const trainersSessionsUnfiltered = ref("");
    const trainersSessionsDisplayNumber = ref(8);
    const trainersSessionsDisplayStep = ref(8);
    const areasFilter = ref([]);
    const priceFilter = ref(null);
    const titleFilter = ref(null);

    onMounted(async () => {
      // fetch sessions in random order
      trainersSessionsUnfiltered.value =
        await fetchTrainersSessionsInRandomOrder();
      trainersSessions.value = trainersSessionsUnfiltered.value;

      // filter trainers sessions
      eventBus.on("filter-trainers-sessions", (payload) => {
        // check which filters are already applied
        let areasFilterApplied = areasFilter.value.length > 0 ? true : false;
        let priceFilterApplied = priceFilter.value ? true : false;
        let titleFilterApplied = titleFilter.value ? true : false;
        let filteredSessions = trainersSessionsUnfiltered.value;

        switch (payload.filterBy) {
          case "title":
            // if areas filter is applied, filter sessions by title
            if (areasFilterApplied) {
              filteredSessions = filterSessionsByAreas(
                areasFilter.value,
                filteredSessions
              );
            }

            // if price filter is applied, filter sessions by price
            if (priceFilterApplied) {
              filteredSessions = filterSessionsByPrice(
                priceFilter.value,
                filteredSessions
              );
            }

            // set title filter value
            titleFilter.value = payload.filter;

            // if title filter is applied, filter sessions by title
            if (payload.filter !== null) {
              trainersSessions.value = filterTrainersSessionsByTitle(
                payload.filter,
                filteredSessions
              );
            } else {
              trainersSessions.value = filteredSessions;
            }

            break;
          case "areas":
            // if price filter is applied, filter sessions by price
            if (priceFilterApplied) {
              filteredSessions = filterSessionsByPrice(
                priceFilter.value,
                filteredSessions
              );
            }

            // if title filter is applied, filter sessions by title
            if (titleFilterApplied) {
              filteredSessions = filterTrainersSessionsByTitle(
                titleFilter.value,
                filteredSessions
              );
            }

            // set areas filter value
            areasFilter.value = payload.filter;

            // if areas filter is applied, filter sessions by areas
            if (payload.filter && payload.filter.length === 0) {
              trainersSessions.value = filteredSessions;
            } else {
              trainersSessions.value = filterSessionsByAreas(
                areasFilter.value,
                filteredSessions
              );
            }

            break;
          case "price":
            // if areas filter is applied, filter sessions by title
            if (areasFilterApplied) {
              filteredSessions = filterSessionsByAreas(
                areasFilter.value,
                filteredSessions
              );
            }

            // if title filter is applied, filter sessions by title
            if (titleFilterApplied) {
              filteredSessions = filterTrainersSessionsByTitle(
                titleFilter.value,
                filteredSessions
              );
            }

            // set price filter value
            priceFilter.value = payload.filter;

            // if price filter is applied, filter sessions by price
            if (payload.filter === null) {
              trainersSessions.value = filteredSessions;
            } else {
              trainersSessions.value = filterSessionsByPrice(
                priceFilter.value,
                filteredSessions
              );
            }

            break;
        }
      });

      // sort trainers sessions by price low to high
      eventBus.on("sort-trainers-sessions-by-pricelowhigh", () => {
        trainersSessionsUnfiltered.value =
          trainersSessionsUnfiltered.value.sort((a, b) => a.price - b.price);

        trainersSessions.value = trainersSessions.value.sort(
          (a, b) => a.price - b.price
        );
      });

      // sort trainers sessions by price high to low
      eventBus.on("sort-trainers-sessions-by-pricehighlow", () => {
        trainersSessionsUnfiltered.value =
          trainersSessionsUnfiltered.value.sort((a, b) => b.price - a.price);

        trainersSessions.value = trainersSessions.value.sort(
          (a, b) => b.price - a.price
        );
      });

      // sort trainers sessions randomly
      eventBus.on("sort-trainers-sessions-by-explore", async () => {
        trainersSessions.value = trainersSessions.value.sort(
          () => Math.random() - 0.5
        );
      });

      setMetaTags(
        "NetworkTal - Find Sessions",
        "Find the right session for you from a wide variety of fields"
      );
    });

    const filterTrainersSessionsByTitle = (title, sessions) => {
      let sessionsFilteredByTitle = [];
      sessionsFilteredByTitle = sessions.filter((session) => {
        return session.title
          .toLowerCase()
          .includes(title ? title.toLowerCase() : "");
      });
      return sessionsFilteredByTitle;
    };

    const filterSessionsByAreas = (areas, sessions) => {
      let sessionsFilteredByAreas = [];
      sessionsFilteredByAreas = sessions.filter((session) => {
        return areas.every((area) => {
          return session.trainerProfile.areas.includes(area);
        });
      });
      return sessionsFilteredByAreas;
    };

    const filterSessionsByPrice = (price, sessions) => {
      let sessionsFilteredByPrice = [];

      sessionsFilteredByPrice = sessions.filter((session) => {
        switch (price) {
          case null:
            return sessions;
          case "Free":
            return session.price === 0;
          case "< 50":
            return session.price < 50;
          case "50 - 100":
            return session.price >= 50 && session.price < 100;
          case "100 - 200":
            return session.price >= 100 && session.price < 200;
          case "200 - 500":
            return session.price >= 200 && session.price < 500;
          case "500+":
            return session.price >= 500;
        }
      });
      return sessionsFilteredByPrice;
    };

    const loading = computed(() => {
      return trainersSessions.value ? false : true;
    });

    const load = ({ done }) => {
      // check if total number of trainers sessions is smaller than display number
      // if number is smaller, set display number to total number of trainer sessions
      // if number is higher, increment the display number with the step number
      if (
        trainersSessionsUnfiltered.value.length <=
        trainersSessionsDisplayNumber.value + trainersSessionsDisplayStep.value
      ) {
        trainersSessionsDisplayNumber.value =
          trainersSessionsUnfiltered.value.length;
        done("empty");
      } else {
        trainersSessionsDisplayNumber.value +=
          trainersSessionsDisplayStep.value;
        done("ok");
      }
    };

    return {
      load,
      loading,
      trainersSessionsDisplayNumber,
      trainersSessions,
    };
  },
  components: {
    PageHeader,
    SessionCard,
    SessionCardSkeleton,
  },
};
</script>

<style scoped></style>
