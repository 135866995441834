<template>
  <div>
    <base-card class="pa-5 pt-2" style="text-align: center">
      <base-loader v-if="!currentUserTrainerProfile"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0" align="center">
          <v-col
            class="ma-0 pa-0 text-body-2 font-weight-medium"
            cols="10"
            align="start"
          >
            <v-icon size="small" class="pr-2">mdi-calendar-clock</v-icon>
            My Availability Overview</v-col
          >
          <v-col class="ma-0 pa-0" align="right">
            <router-link to="/settings/trainer/availability">
              <v-icon size="15" color="grey">mdi-pencil</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <v-row
          v-if="futureFirst4Availability.length === 0"
          class="ma-0 pa-0 py-5"
        >
          <v-col
            align="center"
            class="ma-0 pa-0 py-3 text-caption text-grey"
            style="font-style: italic"
          >
            No availability timeframe added yet
          </v-col>
        </v-row>
        <div v-else>
          <v-row class="ma-0 pa-0 py-5" justify="center">
            <v-col
              class="ma-0 pa-1"
              v-for="availability in futureFirst4Availability"
              :key="availability.id"
              cols="12"
              sm="10"
              md="6"
              ><availability-card
                :startTime="availability.startTime"
                :endTime="availability.endTime"
              ></availability-card
            ></v-col>
          </v-row>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AvailabilityCard from "./myavailabilityoverview/availabilitycard.vue";
import {
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
} from "@/utils/utils.js";

export default {
  data() {
    return {
      futureFirst4Availability: [],
    };
  },
  components: {
    AvailabilityCard,
  },
  async created() {
    if (this.currentUserTrainerProfile) {
      this.futureFirst4Availability = await this.getFutureFirst4Availability();
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
    }),
  },
  methods: {
    ...mapActions({
      getTrainerAvailability: "trainerAvailability/getTrainerAvailability",
    }),
    async getFutureFirst4Availability() {
      let futureAvailability = [];

      futureAvailability = await this.getTrainerAvailability({
        trainerId: this.currentUserTrainerProfile.id,
      });

      futureAvailability = futureAvailability.filter((availability) =>
        convertUTCDateTimeToUserTimezoneNoFormat(
          availability.startTime,
          this.currentUser.timezone
        ).isAfter(getCurrentTimeInUserTimezone(this.currentUser.timezone))
      );
      return futureAvailability.slice(0, 4);
    },
  },
};
</script>

<style scoped></style>
