<template>
  <div style="height: 100%">
    <base-loader v-if="upcomingSession === null"></base-loader>
    <div v-else class="text-caption" style="height: 100% !important">
      <v-row class="ma-0 pa-0 py-1">
        <v-col class="ma-0 pa-0 pt-2 font-weight-medium">Next session</v-col>
      </v-row>
      <v-row
        v-if="!upcomingSession"
        class="ma-0 pa-0"
        align="center"
        style="min-height: 100px"
      >
        <v-col
          align="center"
          class="ma-0 pa-0 text-caption text-grey"
          style="height: 100% !important; font-style: italic"
        >
          No upcoming session
        </v-col>
      </v-row>
      <div v-else>
        <router-link :to="'/settings/user/mysessions/' + upcomingSession.id">
          <base-card customColor="background" class="ma-0 pa-0 mx-3 my-4">
            <v-row class="ma-0 pa-0" align="center">
              <v-col class="ma-0 pa-0 pa-2 pl-4" cols="8">
                <v-row class="ma-0 pa-0">
                  <v-col class="ma-0 pa-0" align="start">
                    <v-icon size="small">mdi-account-tie</v-icon>
                    {{ upcomingSession.trainerDisplayName }}
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col class="ma-0 pa-0" align="start" style="font-size: 9px">
                    <v-icon size="small" class="pa-2">mdi-calendar</v-icon>
                    {{ getStartDate(upcomingSession.start) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ma-0 pa-0 text-h6" align="center">
                {{ getStartTime(upcomingSession.start) }}
              </v-col>
            </v-row>
          </base-card>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { convertUTCDateTimeToUserTimezoneNoFormat } from "@/utils/utils.js";
import { mapGetters } from "vuex";

export default {
  props: {
    upcomingSession: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
    }),
  },
  methods: {
    getStartTime(utcTimestamp) {
      const dateTimeInUserTimezone = convertUTCDateTimeToUserTimezoneNoFormat(
        utcTimestamp,
        this.currentUser.timezone
      );

      return dateTimeInUserTimezone.format("HH:mm");
    },
    getStartDate(utcTimestamp) {
      const dateTimeInUserTimezone = convertUTCDateTimeToUserTimezoneNoFormat(
        utcTimestamp,
        this.currentUser.timezone
      );

      return dateTimeInUserTimezone.format("ddd, MMM D, YYYY");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
</style>
