<template>
  <v-container v-if="isAuthenticated" style="height: 100%">
    <v-row
      class="ma-0 hidden-sm-and-down"
      align="center"
      v-if="!isRoomSelected"
      style="height: 100% !important"
    >
      <v-col><RoomNotSelected /></v-col>
    </v-row>
    <v-row
      class="ma-0 pa-0"
      align="center"
      v-else
      style="height: 100% !important"
    >
      <v-col class="ma-0 pa-0">
        <ChatContainer />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import RoomNotSelected from "./RoomNotSelected";
import ChatContainer from "./ChatContainer";

export default {
  name: "MessageContainer",
  components: {
    ChatContainer,
    RoomNotSelected,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      roomID: "room/activeRoomId",
    }),
    isRoomSelected: function () {
      return this.roomID;
    },
  },
  methods: {
    ...mapActions({
      clearRoom: "room/clearRoom",
    }),
  },
  created() {
    this.clearRoom();
  },
};
</script>

<style scoped></style>
