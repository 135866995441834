<template>
  <v-container style="min-height: 70vh">
    <v-row class="my-15" justify="center">
      <v-col xs="12" sm="12" md="12" lg="9" xl="6">
        <base-card class="pt-10 pb-15">
          <v-row>
            <v-col class="text-h4 font-weight-medium text-center my-10 mx-5"
              >FAQ</v-col
            >
          </v-row>
          <v-row justify="space-between" class="mt-10 mb-5">
            <v-col>
              <v-tabs align-tabs="center" v-model="selectedCategoryId">
                <v-tab v-for="category in FAQCategories" :key="category.id">
                  {{ category.text }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <v-row v-if="selectedCategoryId === 0">
            <v-col
              :class="{
                'mx-15': $vuetify.display.mdAndUp,
                'mx-5': $vuetify.display.smAndDown,
                'text-body-2': true,
              }"
            >
              <FAQ-general class="mt-15"></FAQ-general>
            </v-col>
          </v-row>
          <v-row v-if="selectedCategoryId === 1">
            <v-col
              :class="{
                'mx-15': $vuetify.display.mdAndUp,
                'mx-5': $vuetify.display.smAndDown,
                'text-body-2': true,
              }"
            >
              <FAQ-explorer class="mt-15"></FAQ-explorer>
            </v-col>
          </v-row>
          <v-row v-if="selectedCategoryId === 2">
            <v-col
              :class="{
                'mx-15': $vuetify.display.mdAndUp,
                'mx-5': $vuetify.display.smAndDown,
                'text-body-2': true,
              }"
            >
              <FAQ-trainer class="mt-15"></FAQ-trainer>
            </v-col>
          </v-row>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FAQExplorer from "../../components/faq/explorer.vue";
import FAQTrainer from "../../components/faq/trainer.vue";
import FAQGeneral from "../../components/faq/general.vue";
import { setMetaTags } from "../../utils/utils.js";

export default {
  components: {
    FAQExplorer,
    FAQTrainer,
    FAQGeneral,
  },
  data() {
    return {
      selectedCategoryId: 0,
      FAQCategories: [
        {
          id: 0,
          text: "General",
        },
        {
          id: 1,
          text: "Explorer",
        },
        {
          id: 2,
          text: "Trainer",
        },
      ],
    };
  },
  created() {
    this.setSelectedCategoryId();
    setMetaTags(
      "NetworkTal - FAQ",
      "Frequently asked questions about NetworkTal"
    );
  },
  computed: {},
  methods: {
    setSelectedCategoryId() {
      let URLQuery = this.$route.query.q;
      switch (URLQuery) {
        case "explorer":
          this.selectedCategoryId = 0;
          break;
        case "trainer":
          this.selectedCategoryId = 1;
          break;
        case "general":
          this.selectedCategoryId = 2;
          break;
        default:
          this.selectedCategoryId = 0;
          break;
      }
    },
  },
};
</script>

<style scoped></style>
