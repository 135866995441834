<template>
  <div>
    <v-row style="min-height: 40vh" align="center">
      <v-col align="center">
        <v-avatar size="100" class="my-10">
          <v-icon size="100" color="error">mdi-close-circle</v-icon>
        </v-avatar>
        <p class="mt-5 text-h5 font-weight-medium mx-5">Something went wrong</p>
        <p class="text-subtitle-1">
          Payment has been cancelled or has not been completed. Please try
          again.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center" class="mb-15">
        <base-button toPath="/trainers">Find Trainers</base-button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
