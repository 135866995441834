<template>
  <v-container>
    <v-row>
      <v-col cols="5" class="font-weight-medium">Timezone:</v-col>
      <v-col>{{ userProfile.timezone }} {{ currentUserTimezoneOffset }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="5" class="font-weight-medium">Currency:</v-col>
      <v-col>{{ userProfile.currency.name }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getUTCValueOfTimezone } from "@/utils/utils.js";

export default {
  props: {
    userProfile: {
      type: Object,
      required: false,
    },
  },
  computed: {
    currentUserTimezoneOffset() {
      return getUTCValueOfTimezone(this.userProfile.timezone);
    },
  },
};
</script>

<style scoped></style>
