import db from "../../../firebase-init";

export default {
  async sendNewNotification(_, payload) {
    try {
      await db
        .collection("notifications")
        .doc(payload.data.receiverId)
        .collection("notifications")
        .add({
          body: payload.body,
          data: payload.data,
          read: false,
          timestamp: new Date().toISOString(),
          title: payload.title,
          token: payload.token,
        });

      return true;
    } catch (error) {
      // console.log(error);
      return false;
    }
  },

  async deleteNotification(_, payload) {
    await db
      .collection("notifications")
      .doc(payload.userId)
      .collection("notifications")
      .doc(payload.notificationId)
      .delete();
  },

  async getUserFcmTokens(_, payload) {
    let userFcmTokens = [];

    await db
      .collection("fcm_tokens")
      .where("userId", "==", payload)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            doc.data().tokens.forEach((token) => {
              userFcmTokens.push(token);
            });
          });
        }
      });

    return userFcmTokens;
  },

  async getUserNotifications(context, payload) {
    let notifications = [];

    await db
      .collection("notifications")
      .doc(payload)
      .collection("notifications")
      .orderBy("timestamp", "desc")
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            let notification = doc.data();
            notification.id = doc.id;
            notifications.push(notification);
          });
        }
      });

    // console.log("Notifications: " + JSON.stringify(notifications));
    // console.log("Notifications length: " + notifications.length);

    context.commit("setNotifications", notifications);
    context.commit(
      "setNewMessageNotificationsCount",
      notifications.filter(
        (notification) => notification.data.type === "new-message"
      ).length
    );
    context.commit(
      "setSessionUpdateNotificationsCount",
      notifications.filter(
        (notification) => notification.data.type === "session-update"
      ).length
    );

    return notifications;
  },
};
