<template>
  <div>
    <base-dialog-confirm
      v-if="confirmDialog"
      :displayText="sessionActionConfirmationDisplayText"
      eventName="confirm-status-update-session"
      :payload="sessionActionConfirmationPayload"
      :toolbarText="sessionActionConfirmationToolbarText"
    ></base-dialog-confirm>

    <trainer-review-dialog
      v-if="trainerReviewDialog"
      :eventName="trainerReviewEventName"
      :payload="trainerReviewPayload"
    ></trainer-review-dialog>

    <report-session-dialog
      v-if="reportSessionDialog"
      :session="session"
      initiator="user"
    ></report-session-dialog>

    <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
      <v-row justify="center">
        <v-col cols="12" sm="11" md="11" lg="9" xl="7">
          <base-error-success-message
            :errorMessage="errorMessage"
            :successMessage="successMessage"
          ></base-error-success-message>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="7" md="7" lg="6" xl="5">
          <session-overview
            :session="session"
            :trainerProfile="trainerProfile"
          ></session-overview>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="3" xl="2">
          <trainer-overview
            :trainerProfile="trainerProfile"
            class="mb-6"
          ></trainer-overview>
          <session-status :session="session" class="mb-6"></session-status>
          <session-actions
            :session="session"
            :trainerProfile="trainerProfile"
            class="mb-6"
          ></session-actions>
          <report-session
            v-if="displayReportProblem"
            :session="session"
          ></report-session>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReportSession from "../../../components/user/mysessions/session/reportsession.vue";
import ReportSessionDialog from "../../../components/user/mysessions/session/reportsessiondialog.vue";
import SessionActions from "../../../components/user/mysessions/session/sessionactions.vue";
import SessionOverview from "../../../components/user/mysessions/session/sessionoverview.vue";
import SessionStatus from "../../../components/user/mysessions/session/sessionstatus.vue";
import TrainerOverview from "../../../components/user/mysessions/session/traineroverview.vue";
import TrainerReviewDialog from "../../../components/user/mysessions/trainerreviewdialog.vue";
import { setMetaTags } from "../../../utils/utils.js";
import {
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
} from "@/utils/utils.js";

export default {
  inject: ["eventBus"],
  data() {
    return {
      session: "",
      trainerProfile: "",
      successMessage: "",
      errorMessage: "",
      trainerReviewPayload: {},
      trainerReviewEventName: "new-trainer-review-added",
      sessionActionConfirmationPayload: null,
      sessionActionConfirmationDisplayText: "",
      sessionActionConfirmationToolbarText: "",
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    ReportSession,
    ReportSessionDialog,
    SessionActions,
    SessionOverview,
    SessionStatus,
    TrainerOverview,
    TrainerReviewDialog,
  },
  async created() {
    await this.fetchSession();
    await this.fetchTrainerProfile();
    this.eventBus.on("confirm-status-update-session", async (payload) => {
      // console.log(
      //     'confirm-status-update-session triggered with payload:',
      //     JSON.stringify(payload)
      // )

      const status =
        payload.data.statusLogs[payload.data.statusLogs.length - 1].status;
      let completedSessionTrainerProfile;

      try {
        switch (status) {
          case "cancelled":
            // Update session status
            await this.updateEvent({
              ...payload,
            }).then(async () => {
              // refund user for the session amount if the session is not a free trial
              if (payload.data.sessionIsFreeTrial !== true) {
                await this.createRefund({
                  paymentIntentId: payload.data.stripe.paymentIntentId,
                });
              }
            });

            await this.sendSessionCancelledEmailToTrainer({
              ...payload.data,
            });
            break;
          case "completed":
            completedSessionTrainerProfile = await this.getTrainerById({
              id: payload.data.trainerId,
            });
            // Update session status
            await this.updateEvent({
              ...payload,
            }).then(async () => {
              // send payment to trainer for the session amount if the session is not a free trial
              if (payload.data.sessionIsFreeTrial !== true) {
                await this.createTransfer({
                  amount: payload.data.amountToTransferToTrainer,
                  currency: payload.data.sessionCurrency,
                  paymentIntentChargeId:
                    payload.data.stripe.paymentIntentChargeId,
                  stripeTrainerAccountId:
                    completedSessionTrainerProfile.stripeAccountId,
                  transferGroup: payload.data.stripe.transferGroup,
                  description: `(User Completed Session) - Payment for session with id: ${payload.id}`,
                  metadata: {
                    paymentIntentId: payload.data.stripe.paymentIntentId,
                  },
                });
              }
            });

            await this.sendSessionCompletedEmailToTrainer({
              ...payload.data,
            });

            // trigger review dialog if session is completed
            this.triggerTrainerReviewDialog({
              sessionId: payload.id,
              trainerId: payload.data.trainerId,
              userId: payload.data.userId,
              trainerName: completedSessionTrainerProfile.displayName,
            });
            break;
        }
        // Display success message
        this.successMessage = `Session ${status} successfully`;
        setTimeout(() => {
          this.successMessage = "";
        }, 5000);
      } catch (error) {
        // this.errorMessage = error
        this.errorMessage =
          "An unexpected error occurred. Please try again later.";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }

      setTimeout(async () => {
        await this.fetchSession();
        await this.fetchTrainerProfile();
      }, 1000);
    });
    this.eventBus.on("trigger-complete-session-confirmation", (payload) => {
      this.triggerSessionConfirmationDialog(payload, "complete");
    });
    this.eventBus.on("trigger-cancel-session-confirmation", (payload) => {
      this.triggerSessionConfirmationDialog(payload, "cancel");
    });
    this.eventBus.on(this.trainerReviewEventName, (payload) => {
      this.addReview(payload)
        .then(() => {
          this.successMessage = "Review submitted successfully";
          setTimeout(() => {
            this.successMessage = "";
          }, 5000);

          this.setTrainerRatingInfo(payload.trainerId);
        })
        .catch(() => {
          // this.errorMessage = error
          // console.log(error)
          this.errorMessage =
            "An unexpected error occurred. Please try again later.";
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        });
    });
    this.eventBus.on("session-reported", () => {
      setTimeout(async () => {
        await this.fetchSession();
        await this.fetchTrainerProfile();
      }, 1000);
    });
    setMetaTags(
      "NetworkTal - Explorer Session Details",
      "View the details of your session, manage the session status, and more."
    );
  },
  unmounted() {
    this.eventBus.off("confirm-status-update-session");
    this.eventBus.off("trigger-complete-session-confirmation");
    this.eventBus.off("trigger-cancel-session-confirmation");
    this.eventBus.off(this.trainerReviewEventName);
  },
  computed: {
    ...mapGetters({
      confirmDialog: "websiteData/confirmDialog",
      currentUser: "users/currentUser",
      reportSessionDialog: "sessions/reportSessionDialog",
      trainerReviewDialog: "reviews/trainerReviewDialog",
    }),
    displayReportProblem() {
      if (this.session) {
        let latestStatus =
          this.session.statusLogs[this.session.statusLogs.length - 1];
        if (latestStatus.status === "accepted") {
          return convertUTCDateTimeToUserTimezoneNoFormat(
            this.session.end,
            this.currentUser.timezone
          ).isBefore(getCurrentTimeInUserTimezone(this.currentUser.timezone));
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      getSessionById: "sessions/getSessionById",
      getTrainerById: "trainers/getTrainerById",
      addReview: "reviews/addReview",
      createTransfer: "payments/createTransfer",
      removeSession: "sessions/removeSession",
      createRefund: "payments/createRefund",
      sendSessionCancelledEmailToTrainer:
        "email/sendSessionCancelledEmailToTrainer",
      sendSessionCompletedEmailToTrainer:
        "email/sendSessionCompletedEmailToTrainer",
      setConfirmDialog: "websiteData/setConfirmDialog",
      setTrainerRatingInfo: "reviews/setTrainerRatingInfo",
      setTrainerReviewDialog: "reviews/setTrainerReviewDialog",
      updateEvent: "calendar/updateEvent",
    }),
    async fetchSession() {
      try {
        this.session = await this.getSessionById(this.id);
      } catch (error) {
        // redirect to page not found
        this.$router.push({ name: "PageNotFound", params: { pathMatch: "*" } });
      }
    },
    async fetchTrainerProfile() {
      if (this.session) {
        this.trainerProfile = await this.getTrainerById({
          id: this.session.trainerId,
        });
      }
    },
    triggerSessionConfirmationDialog(payload, statusDisplayText) {
      this.sessionActionConfirmationPayload = {
        id: payload.id,
        data: payload.data,
      };
      this.sessionActionConfirmationDisplayText = `${statusDisplayText} the session with ${
        payload.userDisplayName
      } on ${convertUTCDateTimeToUserTimezoneNoFormat(
        payload.data.start,
        this.currentUser.timezone
      ).format("MMMM D, YYYY [at] HH:mm")}`;
      this.sessionActionConfirmationToolbarText = "Confirmation";

      this.setConfirmDialog(true);
    },
    triggerTrainerReviewDialog(payload) {
      this.trainerReviewPayload = payload;
      this.setTrainerReviewDialog(true);
    },
  },
};
</script>

<style scoped></style>
