<template>
  <v-container id="home-section-4" fluid>
    <v-row class="font-weight-bold py-5 text-center" justify="center">
      <v-col
        class="mt-15"
        cols="12"
        md="9"
        lg="9"
        xl="9"
        :style="'font-size: ' + titleSize + ';'"
      >
        How it works?
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="ma-0 pa-0">
      <v-col align="center" cols="12" md="4" lg="4" xl="4" class="ma-0 pa-0">
        <div class="feature-card">
          <v-img
            :src="require('@/assets/home/home-howitworks-01.png')"
            :height="imageHeight"
          ></v-img>
          <p
            class="text-h4 font-weight-bold mt-5"
            :style="'font-size: ' + contentTitleSize + ';'"
          >
            Find a trainer
          </p>
          <p class="text-h5 mb-10">
            Browse our network of skilled trainers in your desired field. Find
            the best one for you
          </p>
        </div>
      </v-col>
      <v-col align="center" cols="12" md="4" lg="4" xl="4" class="ma-0 pa-0">
        <div class="feature-card">
          <v-img
            :src="require('@/assets/home/home-howitworks-02.png')"
            :height="imageHeight"
          ></v-img>
          <p
            class="text-h4 font-weight-bold mt-5"
            :style="'font-size: ' + contentTitleSize + ';'"
          >
            Book a session
          </p>
          <p class="text-h5 mb-10">
            Choose the trainer that best fits your needs and book a session in
            just a few simple steps
          </p>
        </div>
      </v-col>
      <v-col align="center" cols="12" md="4" lg="4" xl="4" class="ma-0 pa-0">
        <div class="feature-card">
          <v-img
            :src="require('@/assets/home/home-howitworks-03.png')"
            :height="imageHeight"
          ></v-img>
          <p
            class="text-h4 font-weight-bold mt-5"
            :style="'font-size: ' + contentTitleSize + ';'"
          >
            Start learning
          </p>
          <p class="text-h5 mb-10">
            Connect with your trainer for personalized guidance and support to
            reach your goals
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    imageHeight() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "300px";
        case "sm":
          return "300px";
        case "md":
          return "350px";
        case "lg":
          return "350px";
        case "xl":
          return "400px";
        default:
          return "300px"; // Add a default case to return a value
      }
    },
    titleSize() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "40px";
        case "sm":
          return "45px";
        case "md":
          return "50px";
        case "lg":
          return "65px";
        case "xl":
          return "75px";
        default:
          return "50px"; // Add a default case to return a value
      }
    },
    contentTitleSize() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "25px";
        case "sm":
          return "30px";
        case "md":
          return "35px";
        case "lg":
          return "35px";
        case "xl":
          return "40px";
        default:
          return "35px"; // Add a default case to return a value
      }
    },
  },
};
</script>

<style scoped>
#home-section-4 {
  /* border: 2px solid black; */
  background-color: rgb(var(--v-theme-background1));
  color: rgb(var(--v-theme-textPrimary));
  min-height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feature-card {
  /* border: 2px solid black; */
  color: rgb(var(--v-theme-textPrimary));
  min-width: 250px;
  max-width: 400px;
  width: 80%;
  padding: 30px;
}

.row {
  margin: 0px !important;
  padding: 0px !important;
}
</style>
