<template>
  <v-container
    class="text-body-1 my-5 px-2"
    :style="'width:' + containerWidth + '%; min-height: 70vh'"
  >
    <base-card v-if="!isTrainer && isTrainer !== null">
      <v-stepper
        class="stepper-card"
        v-model="e1"
        elevation="0"
        rounded="10"
        alt-labels
      >
        <v-row align="center">
          <v-col align="center" class="text-h4 font-weight-medium my-15">
            Register as a trainer
          </v-col>
        </v-row>

        <v-stepper-header
          class="elevation-0 mx-5 text-caption"
          style="min-height: 110px"
        >
          <base-tooltip-info
            text="Add a picture with yourself. This will be your trainer and user profile picture."
            :custom="true"
          >
            <v-stepper-item
              title="Profile Picture"
              color="colorSpecial1"
              :complete="e1 > 0"
              value="1"
              class="ma-0 pa-0"
            >
            </v-stepper-item>
          </base-tooltip-info>

          <v-divider class="mx-0"></v-divider>

          <base-tooltip-info
            text="Add required information for your trainer profile. This information will be displayed in your trainer profile."
            :custom="true"
          >
            <v-stepper-item
              title="Profile Information"
              color="colorSpecial1"
              :complete="e1 > 1"
              value="2"
              class="ma-0 pa-0"
            >
            </v-stepper-item>
          </base-tooltip-info>

          <v-divider class="mx-0"></v-divider>

          <base-tooltip-info
            text="A session is a meeting that you offer on a specific topic to users. You can add one or multiple sessions to your trainer profile."
            :custom="true"
          >
            <v-stepper-item
              title="Add Session"
              color="colorSpecial1"
              :complete="e1 > 2"
              value="3"
              class="ma-0 pa-0"
            >
            </v-stepper-item>
          </base-tooltip-info>

          <v-divider class="mx-0"></v-divider>

          <base-tooltip-info
            text="Add communication tools that you use for your sessions. Your users will be able to choose one of them when they book a session with you."
            :custom="true"
          >
            <v-stepper-item
              title="Communication Tools"
              color="colorSpecial1"
              value="4"
              class="ma-0 pa-0"
            >
            </v-stepper-item>
          </base-tooltip-info>
        </v-stepper-header>

        <v-stepper-window>
          <v-stepper-window-item value="1">
            <profile-picture
              class="mb-5"
              :errorMessage="errorMessage"
              :successMessage="successMessage"
            ></profile-picture>

            <v-row>
              <v-col align="end">
                <base-button @click="continueProfilePicture"
                  >Continue</base-button
                >
              </v-col>
            </v-row>
          </v-stepper-window-item>

          <v-stepper-window-item value="2">
            <profile-information
              class="mb-5"
              :trainer="trainer"
              :errorMessage="errorMessage"
              :successMessage="successMessage"
            ></profile-information>

            <v-row>
              <v-col align="start">
                <base-button @click="e1 = 0">Back</base-button>
              </v-col>
              <v-col align="end">
                <base-button @click="continueProfileInformation">
                  Continue
                </base-button>
              </v-col>
            </v-row>
          </v-stepper-window-item>

          <v-stepper-window-item value="3">
            <profile-sessions
              class="mb-5"
              :trainer="trainer"
              :errorMessage="errorMessage"
              :successMessage="successMessage"
            ></profile-sessions>

            <v-row>
              <v-col align="start">
                <base-button @click="backToProfileInformation"
                  >Back</base-button
                >
              </v-col>
              <v-col align="end">
                <base-button @click="continueAddFirstSession"
                  >Continue</base-button
                >
              </v-col>
            </v-row>
          </v-stepper-window-item>

          <v-stepper-window-item value="4">
            <communication-tools
              class="mb-5"
              :trainer="trainer"
              :errorMessage="errorMessage"
              :successMessage="successMessage"
            ></communication-tools>

            <v-row>
              <v-col align="start">
                <base-button @click="backToAddFirstSession">Back</base-button>
              </v-col>
              <v-col align="end">
                <base-button
                  customColor="colorSpecial1"
                  @click="submit"
                  :disabled="disableSubmitButton"
                >
                  Submit
                </base-button>
              </v-col>
            </v-row>
          </v-stepper-window-item>
        </v-stepper-window>
      </v-stepper>
    </base-card>
    <base-card v-else-if="isTrainer">
      <v-row
        class="register-trainer-form-title"
        style="height: 50vh"
        align="center"
      >
        <v-col align="center">
          <v-avatar size="100" color="colorSpecial2" class="mb-15">
            <v-icon size="60" color="white">mdi-check</v-icon>
          </v-avatar>
          <p class="mt-10 text-h4 font-weight-medium">
            You are already a trainer!
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" class="mb-15">
          <base-button
            customColor="colorSpecial1"
            toPath="/settings/trainer/dashboard"
          >
            Trainer Dashboard
          </base-button>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import profilePicture from "../../../components/trainer/register/profilepicture/profilepicture.vue";
import profileInformation from "../../../components/trainer/register/profileinformation/profileinformation.vue";
import profileSessions from "../../../components/trainer/register/sessions/sessions.vue";
import communicationTools from "../../../components/trainer/register/communicationtools/communicationtools.vue";
import { setMetaTags } from "../../../utils/utils.js";

export default {
  inject: ["eventBus"],
  data: () => ({
    e1: 0,
    disableSubmitButton: false,
    trainer: {
      aboutMe: "",
      areas: [],
      availability: [],
      certifications: [],
      communicationTools: [
        {
          enabled: false,
          id: "001",
          name: "Skype",
          toolId: "",
        },
        {
          enabled: false,
          id: "002",
          name: "Facetime",
          toolId: "",
        },
        {
          enabled: false,
          id: "003",
          name: "Google Meet",
          toolId: "",
        },
        {
          enabled: false,
          id: "004",
          name: "Zoom",
          toolId: {
            meetingURL: "",
            passcode: "",
            personalMeetingId: "",
          },
        },
      ],
      dateOfBirth: "",
      from: {
        country: "",
        region: "",
      },
      gender: "",
      languages: [],
      livingIn: {
        country: "",
        region: "",
      },
      myApproach: "",
      myStudents: [],
      reviewsInfo: {
        averageReviewsRating: 0,
        reviewsCount: 0,
      },
      sessions: [],
      statuses: [],
      videoPresentationURL: "",
      registrationRequestStatus: "pending",
    },
    successMessage: "",
    errorMessage: "",
    profilePictureFile: null,
  }),
  components: {
    profilePicture,
    profileInformation,
    profileSessions,
    communicationTools,
  },
  created() {
    // get trainer profile data from local storage
    this.getTrainerProfileDataFromLocalStorage();

    // check if user is already a trainer
    this.isUserTrainer({
      userId: this.userId,
    });

    this.eventBus.on("profile-information-invalid", () => {
      // console.log('profile-information-invalid')
      this.errorMessage = "Please fill all the required fields";
      setTimeout(() => {
        this.errorMessage = "";
      }, 5000);
    });
    this.eventBus.on("profile-information-valid", () => {
      // console.log('profile-information-valid')
      this.errorMessage = "";
      this.e1 = 2;
    });
    this.eventBus.on("profile-picture-invalid", () => {
      // console.log('profile-picture-invalid-empty')
      this.errorMessage =
        "Please upload a profile picture of maximum 2MB in size";
      setTimeout(() => {
        this.errorMessage = "";
      }, 5000);
    });
    this.eventBus.on("profile-picture-valid", () => {
      // console.log('profile-picture-valid')
      this.errorMessage = "";
      this.e1 = 1;
    });
    this.eventBus.on("add-first-session-invalid", () => {
      // console.log('add-first-session-invalid')
      this.errorMessage = "Please add at least one session";
      setTimeout(() => {
        this.errorMessage = "";
      }, 5000);
    });
    this.eventBus.on("add-first-session-valid", () => {
      // console.log('add-first-session-valid')
      this.errorMessage = "";
      this.e1 = 3;
    });
    setMetaTags(
      "NetworkTal - Register as a trainer",
      "Register as a trainer on NetworkTal!"
    );
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
      profilePictureDownloadURL: "data/downloadURL",
      userId: "auth/userId",
      isTrainer: "auth/isTrainer",
      addOrUpdateSessionDialog: "sessions/addOrUpdateSessionDialog",
    }),
    containerWidth() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "100";
        case "sm":
          return "80";
        case "md":
          return "70";
        case "lg":
          return "60";
        case "xl":
          return "60";
        default:
          return "60";
      }
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateTrainer: "trainers/createOrUpdateTrainer",
      isUserTrainer: "auth/isUserTrainer",
      createOrUpdateUser: "users/createOrUpdateUser",
      clearUploadState: "data/clearState",
      createConnectedAccount: "payments/createConnectedAccount",
      getEditedTrainerCommunicationToolsStatus:
        "trainers/getEditedTrainerCommunicationToolsStatus",
    }),
    backToAddFirstSession() {
      this.e1--;
      this.clearErrorAndSuccessMessages();
    },
    backToProfileInformation() {
      this.e1--;
      this.clearErrorAndSuccessMessages();
    },
    clearData() {
      this.eventBus.emit("reset-trainer-data-validation");
      this.trainer = {
        areas: [],
        aboutMe: "",
        myApproach: "",
        languages: [],
        photoURL: "",
        videoPresentationURL: "",
        sessions: [],
        certifications: [],
        availability: [],
        communicationTools: [],
        userId: "",
      };
      this.profilePictureFile = null;
      this.clearUploadState();
    },
    clearErrorAndSuccessMessages() {
      this.errorMessage = "";
      this.successMessage = "";
    },
    continueProfilePicture() {
      this.eventBus.emit("validate-profile-picture");
    },
    continueProfileInformation() {
      this.eventBus.emit("validate-trainer-data");
    },
    continueAddFirstSession() {
      this.eventBus.emit("validate-add-first-session");
    },
    async createTrainerAccount() {
      try {
        let id = this.generateId();

        await this.createConnectedAccount({
          email: this.currentUser.email,
        }).then(async (account) => {
          await this.createOrUpdateTrainer({
            trainerId: id,
            data: {
              displayName: this.currentUser.displayName,
              email: this.currentUser.email,
              photoURL: this.profilePictureDownloadURL,
              registrationDate: new Date().toISOString(),
              stripeAccountId: account.id,
              stripeAccountEnabled: false,
              userId: this.userId,
              ...this.trainer,
            },
          });
        });

        // Update user profile
        this.updateUserProfile();

        // Update user role
        this.isUserTrainer({
          userId: this.userId,
        });

        // Remove trainer profile data from local storage
        this.removeTrainerProfileDataFromLocalStorage();

        // Redirect to success page
        this.$router.replace("/becometrainer/register/success");
      } catch (error) {
        this.disableSubmitButton = false;
        this.errorMessage =
          "An unexpected error occurred. Please try again later";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },
    generateId() {
      return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
        .toString()
        .slice(0, 9);
    },
    getTrainerProfileDataFromLocalStorage() {
      let trainer = localStorage.getItem("trainer-profile");
      if (trainer) {
        this.trainer = JSON.parse(trainer);
      }
    },
    removeTrainerProfileDataFromLocalStorage() {
      localStorage.removeItem("trainer-profile");
    },
    saveTrainerProfileDataInLocalStorage(trainer) {
      localStorage.setItem("trainer-profile", JSON.stringify(trainer));
    },
    async submit() {
      this.disableSubmitButton = true;
      // send event to validate communication tools
      this.eventBus.emit("validate-communication-tools");

      // get validation statuses
      let statuses = await this.getEditedTrainerCommunicationToolsStatus({
        communicationTools: this.trainer.communicationTools,
      });

      // if valid, create trainer account, else show error message
      if (statuses.includes("valid") && !statuses.includes("invalid")) {
        await this.createTrainerAccount();
        this.errorMessage = "";
      } else {
        this.disableSubmitButton = false;
        this.errorMessage =
          "Please select at least one communication tool and fill in the required fields";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },
    updateUserProfile() {
      this.createOrUpdateUser({
        id: this.currentUser.id,
        data: {
          ...this.currentUser,
          photoURL: this.profilePictureDownloadURL,
          from: this.trainer.from,
          livingIn: this.trainer.livingIn,
        },
      });
    },
  },
  watch: {
    trainer: {
      handler(newValue) {
        this.saveTrainerProfileDataInLocalStorage(newValue);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.stepper-card {
  background-color: rgb(var(--v-theme-card)) !important;
}

.register-trainer-form-subtitle {
  font-size: 30px !important;
  font-weight: bold;
  margin-bottom: 20px;
}

.register-trainer-form-card {
  padding: 50px !important;
  border: 2px solid black;
}
</style>
