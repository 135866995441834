<template>
  <base-card class="mx-3 my-2" customColor="background">
    <v-row class="ma-0 pa-0" justify="space-around">
      <v-col
        class="ma-0 pa-0 py-1"
        align="center"
        cols="6"
        style="min-width: 130px"
      >
        <v-chip color="colorSpecial3" class="text-white text-caption">{{
          getFormattedDateTimeInUserTimezone(availability.startTime)
        }}</v-chip>
      </v-col>
      <v-col
        class="ma-0 pa-0 py-1"
        align="center"
        cols="6"
        style="min-width: 130px"
      >
        <v-chip color="colorSpecial3" class="text-white text-caption">{{
          getFormattedDateTimeInUserTimezone(availability.endTime)
        }}</v-chip>
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
import { mapGetters } from "vuex";
import { convertUTCDateTimeToUserTimezoneNoFormat } from "@/utils/utils.js";

export default {
  props: {
    availability: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
    }),
  },
  methods: {
    getFormattedDateTimeInUserTimezone(dateTimeUTC) {
      let datetimeInUserTimezone = convertUTCDateTimeToUserTimezoneNoFormat(
        dateTimeUTC,
        this.currentUser.timezone
      );
      return datetimeInUserTimezone.format("MMM D, YYYY, HH:mm");
    },
  },
};
</script>

<style scoped>
.availability-card-chip {
  font-size: 0.7rem !important;
  min-width: 110px;
}
</style>
