<template>
  <div>
    <base-card
      class="trainers-card"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <message-trainer-dialog
        v-if="trainerId"
        :trainerId="trainerId"
      ></message-trainer-dialog>
      <user-trainer-card
        v-for="trainer in trainers"
        :key="trainer.id"
        :userTrainer="trainer"
      ></user-trainer-card>
    </base-card>
  </div>
</template>

<script>
import userTrainerCard from "./usertrainercard.vue";
import MessageTrainerDialog from "@/components/trainer/profile/overview/messagetrainerdialog/messagetrainerdialog.vue";
import { mapActions } from "vuex";

export default {
  inject: ["eventBus"],
  data() {
    return {
      trainerId: "",
    };
  },
  components: {
    userTrainerCard,
    MessageTrainerDialog,
  },
  props: {
    trainers: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.eventBus.on("send-message-to-trainer", (payload) => {
      this.trainerId = payload.trainerId;
      this.setMessageTrainerDialog(true);
    });
  },
  methods: {
    ...mapActions({
      setMessageTrainerDialog: "trainers/setMessageTrainerDialog",
    }),
  },
};
</script>

<style scoped>
.trainers-card {
  max-height: 500px;
  overflow-y: auto;
}
</style>
