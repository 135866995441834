<template>
  <div class="pa-2 pt-5">
    <v-row justify="center">
      <v-col class="ma-0 pa-0 px-2" cols="5">
        <v-select
          v-model="selectedAreas"
          :items="areasFiltered"
          chips
          :label="
            $vuetify.display.smAndDown ? 'Skill Areas' : 'Filter by skill areas'
          "
          clearable
          closable-chips=""
          multiple
          @update:modelValue="filterSessionsByAreas"
          style="z-index: 100; position: relative"
          variant="underlined"
        >
          <template v-slot:prepend-item>
            <v-text-field
              class="mx-5"
              v-model="searchAreaNameInput"
              placeholder="Search Area"
              @update:modelValue="searchAreas"
            ></v-text-field>
          </template>
        </v-select>
      </v-col>
      <v-col class="ma-0 pa-0 px-2" cols="5" sm="3">
        <v-text-field
          v-model="searchByTitleInput"
          :label="
            $vuetify.display.smAndDown ? 'Search Title' : 'Search by title'
          "
          placeholder="Search by title"
          single-line
          hide-details
          clearable
          @update:modelValue="filterSessionsByTitle"
          variant="underlined"
        ></v-text-field>
      </v-col>
      <v-col class="ma-0 pa-0 px-2" cols="5" sm="2">
        <v-select
          v-model="priceFilter"
          :items="priceFilters"
          chips
          label="Price"
          clearable
          style="z-index: 100; position: relative"
          variant="underlined"
        >
          <div v-if="!$vuetify.display.smAndDown">Filter</div>
        </v-select>
      </v-col>
      <v-col class="ma-0 pa-0 px-2 text-h2" cols="2">
        <v-btn
          id="menu-activator"
          variant="flat"
          rounded="0"
          prepend-icon="mdi-filter"
        >
        </v-btn>
        <v-menu activator="#menu-activator">
          <v-sheet>
            <p class="radio-group-header text-subtitle-2">Sort by</p>
            <v-divider class="mx-2"></v-divider>
            <v-radio-group class="radio-group-buttons" v-model="sortBy">
              <v-radio label="Explore" value="explore"></v-radio>
              <v-radio label="Price low - high" value="pricelowhigh"></v-radio>
              <v-radio label="Radio high - low" value="pricehighlow"></v-radio>
              <!-- <v-radio label="Highest Rating" value="highestrating"></v-radio>
              <v-radio label="Most Reviewed" value="mostreviewed"></v-radio> -->
            </v-radio-group>
          </v-sheet>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  inject: ["eventBus"],
  data() {
    return {
      areasFiltered: [],
      priceFilter: null,
      priceFilters: [
        "Free",
        "< 50",
        "50 - 100",
        "100 - 200",
        "200 - 500",
        "500+",
      ],
      searchAreaNameInput: "",
      searchByTitleInput: "",
      selectedAreas: [],
      sortBy: "explore",
    };
  },
  created() {
    this.areasFiltered = this.areas;
  },
  computed: {
    ...mapGetters({
      areas: "trainers/areas",
    }),
  },
  methods: {
    filterSessionsByAreas() {
      this.eventBus.emit("filter-trainers-sessions", {
        filterBy: "areas",
        filter: this.selectedAreas,
      });
    },
    filterSessionsByTitle() {
      this.eventBus.emit("filter-trainers-sessions", {
        filterBy: "title",
        filter: this.searchByTitleInput,
      });
    },
    searchAreas() {
      this.areasFiltered = this.areas.filter((area) => {
        return area
          .toLowerCase()
          .includes(this.searchAreaNameInput.toLowerCase());
      });
    },
  },
  watch: {
    sortBy(newValue) {
      switch (newValue) {
        case "explore":
          this.eventBus.emit("sort-trainers-sessions-by-explore");
          break;
        case "pricelowhigh":
          this.eventBus.emit("sort-trainers-sessions-by-pricelowhigh");
          break;
        case "pricehighlow":
          this.eventBus.emit("sort-trainers-sessions-by-pricehighlow");
          break;
        // case "highestrating":
        //   this.eventBus.emit("sort-trainers-sessions-by-highestrating");
        //   break;
        // case "mostreviewed":
        //   this.eventBus.emit("sort-trainers-sessions-by-mostreviewed");
        //   break;
      }
    },
    priceFilter(newValue) {
      this.eventBus.emit("filter-trainers-sessions", {
        filterBy: "price",
        filter: newValue,
      });
    },
  },
};
</script>

<style scoped>
.radio-group-buttons {
  font-size: 12px;
  padding: 5px;
  padding-right: 15px;
  padding-top: 15px;
}

.radio-group-header {
  padding: 5px;
  padding-left: 15px;
  padding-top: 15px;
}
</style>
