<template>
  <base-card class="trainer-card">
    <v-row class="ma-0 pa-0" align="center" justify="center">
      <v-col
        class="ma-0 pa-0 py-5"
        cols="12"
        md="5"
        lg="3"
        xl="3"
        align="center"
      >
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <v-avatar size="150">
              <v-img :src="getPhotoURL"></v-img>
            </v-avatar>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 py-2">
          <v-col class="ma-0 pa-0 pb-2 text-h6 font-weight-medium">
            {{ this.trainer.displayName }}
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <base-button
              customColor="colorSpecial1"
              :toPath="
                '/trainer/' +
                createTrainerProfileDisplayNamePath(trainer.displayName) +
                '-' +
                trainer.id
              "
              customHeight="40px"
              customFontSize="14px"
              customWidth="150px"
              target="_blank"
            >
              View Profile
            </base-button>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="ma-0 pa-0 px-5 py-2" cols="12" md="7" lg="5" xl="5">
        <v-row class="ma-0 pa-0 pb-1">
          <v-col class="ma-0 pa-0 text-subtitle-1"
            ><v-icon size="small" class="mr-1">mdi-lightbulb-outline</v-icon
            >Training areas:</v-col
          >
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <v-chip-group column>
              <v-chip
                v-for="area in trainer.areas"
                class="text-caption"
                variant="flat"
                color="colorSpecial5"
                :key="area"
              >
                {{ area }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 pt-3 pb-1">
          <v-col class="ma-0 pa-0 text-subtitle-1">
            <v-icon size="small" class="mr-1">mdi-human-male-board</v-icon>
            Sessions:</v-col
          >
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col
            v-if="trainer.sessions.length === 1"
            class="ma-0 pa-0 text-subtitle-1 font-weight-medium"
            >{{ trainer.sessions[0].title }}</v-col
          >
          <v-col v-else class="ma-0 pa-0 text-subtitle-1"
            ><strong>{{ trainer.sessions[0].title }}</strong> and more</v-col
          >
        </v-row>
        <v-row class="ma-0 pa-0 pt-3 pb-1">
          <v-col class="ma-0 pa-0 text-subtitle-1"
            ><v-icon size="small" class="mr-1"
              >mdi-map-marker-star-outline</v-icon
            >Country:</v-col
          >
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0 text-subtitle-1 font-weight-medium">{{
            trainer.livingIn.country
          }}</v-col>
        </v-row>
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="4">
        <div style="height: 270px">
          <video-card
            style="border-radius: 20px"
            :src="trainer.videoPresentationURL"
          ></video-card>
        </div>
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
import { mapGetters } from "vuex";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";

export default {
  setup() {
    const { createTrainerProfileDisplayNamePath } = useGlobalFunctions();
    return { createTrainerProfileDisplayNamePath };
  },
  props: {
    trainer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      userPhotoDefault: "websiteData/userPhotoDefault",
      currency: "websiteData/currency",
      currentUser: "users/currentUser",
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    getLowestSessionPrice() {
      if (this.trainer.sessions) {
        return this.trainer.sessions.reduce(
          (min, p) => (p.price < min ? p.price : min),
          this.trainer.sessions[0].price
        );
      }
    },
    getPhotoURL() {
      if (this.trainer.photoURL) {
        return this.trainer.photoURL;
      } else {
        return this.userPhotoDefault;
      }
    },
  },
};
</script>

<style scoped>
.trainer-card {
  width: 100%;
  border-radius: 20px !important;
}

a {
  text-decoration: none;
  color: inherit;
}
</style>
