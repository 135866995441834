<template>
  <base-card class="my-3 mx-5" customColor="background">
    <v-row justify="center" align="center">
      <v-col
        :class="$vuetify.display.xs ? 'pt-5' : ''"
        :align="trainerDataAlign"
        cols="12"
        sm="5"
      >
        <router-link :to="trainerURL">
          <v-avatar size="40" class="mr-5">
            <v-img :src="getPhotoURL"></v-img>
          </v-avatar>
        </router-link>
        <router-link :to="trainerURL">
          {{ trainerProfile.displayName }}
        </router-link>
      </v-col>
      <v-col
        :class="$vuetify.display.xs ? 'pt-0' : ''"
        :align="trainerButtonsAlign"
        cols="12"
        sm="6"
      >
        <base-button
          @click="triggerViewTrainerProfile()"
          customHeight="25px"
          customFontSize="10px"
        >
          View Profile
        </base-button>
        <base-button
          @click="triggerSendMessageToTrainer()"
          customHeight="25px"
          customFontSize="10px"
        >
          Send Message
        </base-button>
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";

export default {
  inject: ["eventBus"],
  setup() {
    const { createTrainerProfileDisplayNamePath } = useGlobalFunctions();
    return { createTrainerProfileDisplayNamePath };
  },
  data() {
    return {
      trainerProfile: "",
    };
  },
  props: {
    userTrainer: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.getTrainerProfileDetails();
  },
  computed: {
    ...mapGetters({
      userPhotoDefault: "websiteData/userPhotoDefault",
    }),
    getPhotoURL() {
      if (this.trainerProfile.photoURL) {
        return this.trainerProfile.photoURL;
      } else {
        return this.userPhotoDefault;
      }
    },
    trainerDataAlign() {
      if (this.$vuetify.display.mdAndUp) {
        return "start";
      } else {
        return "center";
      }
    },
    trainerButtonsAlign() {
      if (this.$vuetify.display.mdAndUp) {
        return "end";
      } else {
        return "center";
      }
    },
    trainerURL() {
      let trainerURL = "";
      if (this.trainerProfile) {
        trainerURL =
          "/trainer/" +
          this.createTrainerProfileDisplayNamePath(
            this.trainerProfile.displayName
          ) +
          "-" +
          this.userTrainer.trainerId;
      }
      return trainerURL;
    },
  },
  methods: {
    ...mapActions({
      getTrainerById: "trainers/getTrainerById",
      setMessageTrainerDialog: "trainers/setMessageTrainerDialog",
    }),
    async getTrainerProfileDetails() {
      this.trainerProfile = await this.getTrainerById({
        id: this.userTrainer.trainerId,
      });
    },
    triggerViewTrainerProfile() {
      this.$router.push(this.trainerURL);
    },
    triggerSendMessageToTrainer() {
      this.eventBus.emit("send-message-to-trainer", {
        trainerId: this.userTrainer.trainerId,
      });
    },
  },
};
</script>

<style scoped>
a {
  color: var(--v-theme-primary) !important;
  text-decoration: none;
  font-size: 12px;
  font-weight: 450;
}
a:hover {
  color: rgb(89, 88, 88) !important;
  text-decoration: none;
}
</style>
