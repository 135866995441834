<template>
  <base-card :loading="true" class="my-5" style="width: 100%">
    <v-row class="ma-0 pa-0">
      <v-col
        class="ma-0 pa-0 pt-5"
        cols="12"
        md="3"
        lg="3"
        xl="3"
        align="center"
      >
        <v-skeleton-loader class="px-15" type="avatar"></v-skeleton-loader>
        <v-skeleton-loader class="mt-2" type="heading"></v-skeleton-loader>
        <v-skeleton-loader class="px-15 mt-1" type="button"></v-skeleton-loader>
      </v-col>
      <v-col class="ma-0 pa-0 pt-10 px-5" cols="12" md="5" lg="5" xl="5">
        <v-skeleton-loader type="heading"></v-skeleton-loader>
        <v-skeleton-loader type="chip"></v-skeleton-loader>
        <v-skeleton-loader type="heading"></v-skeleton-loader>
        <v-skeleton-loader type="chip"></v-skeleton-loader>
      </v-col>
      <v-col class="ma-0 pa-0 pt-12 pr-5" cols="12" md="4" lg="4" xl="4">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
export default {};
</script>

<style scoped>
div :deep() .v-skeleton-loader__avatar {
  margin-top: 50px;
  min-height: 100px;
  min-width: 100px;
  /* align center horizontally */
  margin-left: auto;
  margin-right: auto;
}

div :deep() .v-skeleton-loader__chip {
  min-height: 40px;
  min-width: 100px !important;
}

div :deep() .v-skeleton-loader__button {
  min-height: 40px;
  min-width: 140px !important;
}

div :deep() .v-skeleton-loader__image {
  min-height: 240px;
  min-width: 100px !important;
}
</style>
