<template>
  <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col class="pa-0" cols="12" sm="9" md="8" lg="6" xl="5">
        <base-card style="min-height: 50vh">
          <base-loader class="loading-spinner" v-if="loading"></base-loader>
          <div v-else>
            <div v-if="sessionDetails">
              <v-row align="center">
                <v-col align="center">
                  <v-avatar size="100" color="colorSpecial2" class="my-15">
                    <v-icon size="60" color="white">mdi-check</v-icon>
                  </v-avatar>
                  <p class="text-h5 font-weight-medium mx-5">Booking Success</p>
                  <p class="text-h6 font-weight-regular mx-5 mt-5">
                    You booked a new session with
                    <router-link
                      class="text-primary font-weight-medium"
                      :to="
                        '/trainer/' +
                        createTrainerProfileDisplayNamePath(
                          sessionDetails.trainerDisplayName
                        ) +
                        '-' +
                        sessionDetails.trainerId
                      "
                      >{{ sessionDetails.trainerDisplayName }}</router-link
                    >
                  </p>
                </v-col>
              </v-row>

              <v-row class="text-body-1" justify="center">
                <v-col cols="11" md="10" lg="9" xl="9">
                  <p>What happens next?</p>
                  <ul class="ml-5 mt-5">
                    <li>
                      You will receive a booking confirmation email with the
                      session details
                    </li>
                    <li>
                      Trainer will receive your booking request and has to
                      accept it
                    </li>
                    <li>
                      Once the trainer accepts your booking request, you will
                      receive a confirmation email
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center" class="mb-15 mt-5">
                  <base-button
                    :toPath="'/settings/user/mysessions/' + sessionDetails.id"
                    customColor="colorSpecial1"
                    customWidth="150"
                  >
                    Session
                  </base-button>
                  <base-button
                    toPath="/settings/user/mysessions"
                    customWidth="150"
                  >
                    My Sessions
                  </base-button>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row style="min-height: 40vh" align="center">
                <v-col align="center">
                  <v-avatar size="100" class="my-10">
                    <v-icon size="100" color="error">mdi-close-circle</v-icon>
                  </v-avatar>
                  <p class="mt-5 text-h5 font-weight-medium mx-5">
                    Booking Failed
                  </p>
                  <p class="text-subtitle-1 mt-5">
                    Something went wrong. Please try again later.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center" class="mb-15 mt-5">
                  <base-button toPath="/settings/user/mysessions">
                    My Sessions
                  </base-button>
                </v-col>
              </v-row>
            </div>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { setMetaTags } from "../../../../../utils/utils.js";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";

export default {
  setup() {
    const { createTrainerProfileDisplayNamePath } = useGlobalFunctions();
    return { createTrainerProfileDisplayNamePath };
  },
  data() {
    return {
      sessionDetails: "",
      loading: true,
    };
  },
  async mounted() {
    if (this.$route.query.session_id) {
      this.sessionDetails = await this.getEventById(
        this.$route.query.session_id
      );
    }
    this.loading = false;
  },
  created() {
    setMetaTags(
      "NetworkTal - Session Booking Status",
      "View the status of your session booking"
    );
  },
  methods: {
    ...mapActions({
      getEventById: "calendar/getEventById",
    }),
  },
};
</script>

<style scoped>
.loading-spinner {
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
