<template>
  <div>
    <base-dialog :model="addOrUpdateCertificationDialog">
      <v-toolbar class="text-white" color="colorSpecial5" elevation="1">
        <v-row align="center" class="ma-0 pa-0">
          <v-col align="start" cols="10">New Certification</v-col>
          <v-col cols="2" align="end">
            <v-btn icon @click="closeDialog" class="text-white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <base-error-success-message
        class="ma-5"
        :errorMessage="errorMessage"
        :successMessage="successMessage"
      ></base-error-success-message>
      <v-container class="px-5">
        <v-form class="mx-3" @submit.prevent="submitEvent">
          <v-text-field
            v-model="certificationName"
            :error-messages="certificationNameErrors"
            :counter="100"
            label="Certification Name"
            required
          ></v-text-field>
          <v-text-field
            v-model="certificationDescription"
            :error-messages="certificationDescriptionErrors"
            :counter="200"
            label="Certification Description"
            required
          ></v-text-field>
          <v-text-field
            v-model="certificationProvider"
            :error-messages="certificationProviderErrors"
            :counter="100"
            label="Certification Provider"
            required
          ></v-text-field>
          <v-text-field
            v-model="certificationNumber"
            :error-messages="certificationNumberErrors"
            :counter="50"
            required
            label="Certification Number"
          ></v-text-field>
          <v-text-field
            v-model="certificationLink"
            :error-messages="certificationLinkErrors"
            required
            label="Certification Link"
          ></v-text-field>
          <v-checkbox
            v-model="addCertificationPicture"
            label="Add certification picture?"
            color="primary"
          ></v-checkbox>
          <v-file-input
            v-if="addCertificationPicture"
            chips
            clearabale
            :rules="rules"
            prepend-icon="mdi-image-outline"
            label="Certification Picture"
            show-size
            validate-on="input"
            @update:modelValue="previewImage($event)"
          ></v-file-input>
          <div class="mt-5 mb-4">
            <base-button
              class="mr-4"
              @click="submitEvent()"
              :disabled="addCertificationPicture && !certificationDownloadURL"
            >
              Submit
              <base-icon>mdi-content-save</base-icon>
            </base-button>
            <base-button customColor="error" @click="closeDialog()">
              Close
              <base-icon>mdi-close</base-icon>
            </base-button>
          </div>
        </v-form>
      </v-container>
    </base-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength, url } from "@vuelidate/validators";

export default {
  inject: ["eventBus"],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      certificationName: { required, maxLength: maxLength(100) },
      certificationDescription: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(200),
      },
      certificationNumber: { maxLength: maxLength(50) },
      certificationProvider: { required, maxLength: maxLength(100) },
      certificationLink: { url },
    };
  },
  props: {
    trainerProfile: {
      required: true,
    },
  },
  data() {
    return {
      certificationId: "",
      certificationName: "",
      certificationDescription: "",
      certificationPhotoURL: "",
      certificationNumber: "",
      certificationProvider: "",
      certificationLink: "",
      certificationPictureFiles: [],
      addCertificationPicture: false,
      errorMessage: "",
      successMessage: "",
      rules: [
        (v) => {
          return (
            !v ||
            !v.length ||
            ["image/jpeg", "image/png", "image/bmp"].includes(v[0].type) ||
            "Picture must be in JPG, PNG or BMP format!"
          );
        },
        (v) => {
          return (
            !v ||
            !v.length ||
            v[0].size < 2000000 ||
            "Picture size should be less than 2 MB!"
          );
        },
      ],
    };
  },
  created() {
    this.eventBus.on("update-certification", (payload) => {
      this.certificationId = payload.certificationId;
      this.certificationName = payload.certificationName;
      this.certificationDescription = payload.certificationDescription;
      this.certificationPhotoURL = payload.certificationPhotoURL;
      this.certificationNumber = payload.certificationNumber;
      this.certificationProvider = payload.certificationProvider;
      this.certificationLink = payload.certificationLink;
    });
    this.eventBus.on("set-base-dialog", (payload) => {
      this.setAddOrUpdateCertificationDialog(payload);
    });
  },
  unmounted() {
    this.eventBus.off("set-base-dialog");
  },
  computed: {
    ...mapGetters({
      addOrUpdateCertificationDialog: "trainers/addOrUpdateCertificationDialog",
      certificationDownloadURL: "data/certificationDownloadURL",
      certificationPhotoDefault: "websiteData/certificationPhotoDefault",
    }),
    certificationNameErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "certificationName") {
          if (error.$validator === "required") {
            errors.push("Certification name is required");
          } else if (error.$validator === "maxLength") {
            errors.push(
              "Certification name cannot be longer than 100 characters"
            );
          }
        }
      });
      return errors;
    },
    certificationDescriptionErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "certificationDescription") {
          if (error.$validator === "required") {
            errors.push("Certification description is required");
          } else if (error.$validator === "minLength") {
            errors.push(
              "Certification description cannot be shorter than 5 characters"
            );
          } else if (error.$validator === "maxLength") {
            errors.push(
              "Certification description cannot be longer than 200 characters"
            );
          }
        }
      });
      return errors;
    },
    certificationNumberErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "certificationNumber") {
          if (error.$validator === "maxLength") {
            errors.push(
              "Certification number cannot be longer than 50 characters"
            );
          }
        }
      });
      return errors;
    },
    certificationProviderErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "certificationProvider") {
          if (error.$validator === "required") {
            errors.push("Certification provider is required");
          } else if (error.$validator === "maxLength") {
            errors.push(
              "Certification provider cannot be longer than 100 characters"
            );
          }
        }
      });
      return errors;
    },
    certificationLinkErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "certificationLink") {
          if (error.$validator === "url") {
            errors.push("Certification link must be a valid URL");
          }
        }
      });
      return errors;
    },
  },
  methods: {
    ...mapActions({
      addOrUpdateCertification: "trainers/addOrUpdateCertification",
      clearCertificationDownloadURL: "data/clearCertificationDownloadURL",
      setAddOrUpdateCertificationDialog:
        "trainers/setAddOrUpdateCertificationDialog",
      uploadTrainerCertificationPicture:
        "data/uploadTrainerCertificationPicture",
    }),
    isPictureValid(picture) {
      let isPictureValid =
        picture &&
        picture.size < 2000000 &&
        ["image/jpeg", "image/png", "image/bmp"].includes(picture.type);
      return isPictureValid;
    },
    async previewImage(event) {
      this.certificationPictureFiles = event;
      let isPictureValid = this.isPictureValid(event);

      if (event && isPictureValid) {
        await this.uploadTrainerCertificationPicture({
          trainerId: this.trainerProfile.id,
          image: event,
        });
      } else {
        this.clearCertificationDownloadURL();
      }
    },
    async submitEvent() {
      try {
        if (!this.certificationId) {
          this.certificationId = this.generateId();
        }

        let photoURL;

        if (this.certificationDownloadURL === "") {
          photoURL = this.certificationPhotoURL;
        } else {
          photoURL = this.certificationDownloadURL;
        }

        const isValid = await this.v$.$validate();
        let isPictureValid = true;
        if (this.addCertificationPicture) {
          isPictureValid = this.isPictureValid(this.certificationPictureFiles);
        }

        // allow update to certification if it is already in the list and prevent adding more than 5 certifications
        if (
          !this.trainerProfile.certifications.some(
            (certification) => certification.id === this.certificationId
          )
        ) {
          if (this.trainerProfile.certifications.length >= 5) {
            throw new Error(
              "You can add a maximum of 5 certifications to your trainer profile"
            );
          }
        }

        if (isValid && isPictureValid) {
          await this.addOrUpdateCertification({
            trainerId: this.trainerProfile.id,
            data: {
              id: this.certificationId,
              name: this.certificationName,
              description: this.certificationDescription,
              photoURL: photoURL,
              number: this.certificationNumber,
              provider: this.certificationProvider,
              link: this.certificationLink,
            },
          });
          this.closeDialog();
          this.eventBus.emit("new-certification-added");
        } else {
          this.errorMessage = "Please fill in all the required fields";
        }

        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      } catch (error) {
        this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },
    generateId() {
      let id = Math.floor(
        Math.random() * Math.floor(Math.random() * Date.now())
      )
        .toString()
        .slice(0, 5);
      return id;
    },
    clearData() {
      this.certificationId = "";
      this.certificationName = "";
      this.certificationDescription = "";
      this.certificationPictureFiles = [];
      this.addCertificationPicture = false;
      this.certificationPhotoURL = "";
      this.certificationProvider = "";
      this.certificationLink = "";
      this.certificationNumber = "";
      this.errorMessage = "";
      this.successMessage = "";
    },
    closeDialog() {
      this.clearData();
      this.setAddOrUpdateCertificationDialog(false);
      this.clearCertificationDownloadURL();
      this.v$.$reset();
    },
  },
};
</script>

<style scoped></style>
