<template>
  <div class="register-trainer-form-section">
    <base-error-success-message
      :errorMessage="errorMessage"
      :successMessage="successMessage"
    ></base-error-success-message>
    <v-row align="center">
      <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="font-weight-medium">
        From
        <base-tooltip-info :text="fromGuidance"></base-tooltip-info>
      </v-col>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-row>
          <v-col cols="6">
            <v-select
              :items="countries"
              v-model="trainer.from.country"
              item-title="countryName"
              item-value="countryName"
              label="Country"
              :error-messages="fromCountryErrors"
              :return-object="false"
              @update:model-value="trainer.from.region = ''"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="fromCountryRegions"
              v-model="trainer.from.region"
              item-title="name"
              item-value="name"
              label="Region"
              :error-messages="fromRegionErrors"
              :return-object="false"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="font-weight-medium">
        Living In
        <base-tooltip-info :text="livingInGuidance"></base-tooltip-info>
      </v-col>

      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-row>
          <v-col cols="6">
            <v-select
              :items="countries"
              v-model="trainer.livingIn.country"
              item-title="countryName"
              item-value="countryName"
              label="Country"
              :error-messages="livingInCountryErrors"
              :return-object="false"
              @update:model-value="trainer.livingIn.region = ''"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="livingInCountryRegions"
              v-model="trainer.livingIn.region"
              item-title="name"
              item-value="name"
              label="Region"
              :error-messages="livingInRegionErrors"
              :return-object="false"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="font-weight-medium">
        Date of birth:
        <base-tooltip-info :text="dateOfBirthGuidance"></base-tooltip-info>
      </v-col>

      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-menu
          v-model="menu"
          class="offset-y"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ props }">
            <v-text-field
              v-model="trainer.dateOfBirth"
              label="Date of birth"
              placeholder="YYYY-MM-DD"
              readonly
              v-bind="props"
              clearable
              :error-messages="dateOfBirthErrors"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            show-adjacent-months
            v-model="date"
            @update:modelValue="saveDateOfBirth"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="font-weight-medium">
        Gender
        <base-tooltip-info :text="genderGuidance"></base-tooltip-info>
      </v-col>

      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-select
          v-model="trainer.gender"
          :items="['Not given', 'Male', 'Female']"
          label="Gender"
          :error-messages="genderErrors"
        ></v-select>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="font-weight-medium">
        Training Areas
        <base-tooltip-info :text="trainingAreasGuidance"></base-tooltip-info>
      </v-col>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-select
          v-model="trainer.areas"
          :items="trainerAreas"
          :error-messages="selectedTrainingAreasErrors"
          counter
          chips
          closable-chips
          label="Training Areas"
          multiple
          clearable
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="font-weight-medium">
        About Me
        <base-tooltip-info :text="aboutMeGuidance"></base-tooltip-info>
      </v-col>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-textarea
          v-model="trainer.aboutMe"
          :error-messages="aboutMeErrors"
          clearable
          :counter="500"
        >
          <template v-slot:label>
            <div>About Me</div>
          </template>
        </v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="font-weight-medium">
        My Approach
        <base-tooltip-info :text="myApproachGuidance"></base-tooltip-info>
      </v-col>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-textarea
          v-model="trainer.myApproach"
          :error-messages="myApproachErrors"
          clearable
          :counter="500"
        >
          <template v-slot:label>
            <div>My Approach</div>
          </template>
        </v-textarea>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="font-weight-medium">
        Spoken languages
        <base-tooltip-info :text="languagesGuidance"></base-tooltip-info>
      </v-col>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-select
          v-model="trainer.languages"
          :items="filteredLanguages"
          item-title="name"
          label="Languages"
          hint="Languages that you speak"
          multiple
          :error-messages="languagesErrors"
          clearable
          return-object
        >
          <template v-slot:prepend-item>
            <v-text-field
              class="mx-5"
              v-model="searchLanguageInput"
              placeholder="Search Area"
              @update:modelValue="searchLanguage"
            ></v-text-field>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row class="ma-0 pa-0" align="center">
      <v-col
        cols="12"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        class="ma-0 pa-0 font-weight-medium"
      >
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            Video presentation
            <base-tooltip-info
              :text="videoPresentationURLGuidance"
            ></base-tooltip-info> </v-col
        ></v-row>
        <v-row class="ma-0 pa-0" style="font-size: 10px">
          <v-col class="ma-0 pa-0">
            <router-link
              class="guidance-link"
              to="/blog/tips-for-trainer-video-presentation"
              target="_blank"
              >See video presentation guidance</router-link
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-text-field
          v-model="trainer.videoPresentationURL"
          :error-messages="videoPresentationURLErrors"
          clearable
          prepend-icon="mdi-video"
          label="Presentation Video URL"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="trainer.videoPresentationURL">
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        Video presentation preview
        <base-tooltip-info
          :text="videoPresentationURLPreviewGuidance"
        ></base-tooltip-info>
      </v-col>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <video-card
          class="video-preview"
          :src="trainer.videoPresentationURL"
        ></video-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength, url } from "@vuelidate/validators";
import languages from "@/assets/general/languages.json";
import countries from "country-region-data/data.json";

export default {
  inject: ["eventBus"],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      trainer: {
        areas: {
          required,
          maxLength: maxLength(3),
          minLength: minLength(1),
        },
        aboutMe: {
          required,
          maxLength: maxLength(500),
        },
        dateOfBirth: {
          required,
          min18YearsOld: (value) =>
            new Date(value) < new Date(Date.now() - 18 * 31557600000),
        },
        from: {
          country: {
            required,
          },
          region: {
            required,
          },
        },
        gender: {
          required,
        },
        livingIn: {
          country: {
            required,
          },
          region: {
            required,
          },
        },
        myApproach: {
          required,
          maxLength: maxLength(500),
        },
        languages: {
          required,
          minLength: minLength(1),
          containsEnglish: (value) =>
            value.some((item) => item.name === "English"),
        },
        videoPresentationURL: {
          required,
          url,
          isLinkYoutubeOrVimeo: (value) => {
            const youtubeRegex =
              /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/g;
            const vimeoRegex =
              /^(https?:\/\/)?(www\.vimeo\.com|vimeo\.?com)\/.+$/g;
            return youtubeRegex.test(value) || vimeoRegex.test(value);
          },
        },
      },
    };
  },

  data: () => ({
    activePicker: null,
    date: null,
    menu: false,
    countries,
    dateOfBirthGuidance: `
        <ul class="ma-2">
            <li>Select your date of birth.</li>
        </ul>`,
    fromCountry: null,
    fromRegion: null,
    fromGuidance: `
        <ul class="ma-2 text-caption">
            <li>Select the country and region you are from.</li>
            <li>This will appear in your trainer public profile.</li>
        </ul>`,
    genderGuidance: `
        <ul class="ma-2 text-caption">
            <li>Select your gender.</li>
        </ul>`,
    livingInGuidance: `
        <ul class="ma-2 text-caption">
            <li>Select the country and region you are currently living in.</li>
            <li>This will appear in your trainer public profile.</li>
        </ul>`,
    selectedTrainingAreas: null,
    userProfile: null,
    filteredLanguages: [],
    searchLanguageInput: "",
    videoPresentationURLGuidance: `
            <div class="text-caption"
              <p class="mb-2">The trainer presentation video is a great way to introduce yourself to potential clients and showcase your training style.</p>
              <p class="my-2">Insert your trainer presentation video URL from Youtube or Vimeo. This will appear in your trainer public profile.</p>
              <p class="mb-2">To upload your presentation video to our platform, you can follow these steps using either YouTube or Vimeo.</p>
              <h3>YouTube</h3>
              <ol class="ma-3">
              <li>Create a YouTube account or sign in to your existing account.</li>
              <li>Upload your presentation video.</li>
              <li>Copy the video URL.</li>
              <li>Paste the copied video URL into the designated field on our platform.</li>
              </ol>
              </br>
              <h3>Vimeo</h3>
              <ol class="ma-3">
              <li>Create a Vimeo account or sign in to your existing account.</li>
              <li>Upload your video.</li>
              <li>Copy the video URL.</li>
              <li>Paste the copied video URL into the designated field on our platform.</li>
              </ol>
            </div>`,
    videoPresentationURLPreviewGuidance: `
            <div class="text-caption">
              <p>Make sure your video is visible in the preview.</p>
              <p>If you can't see your video, please check the following:</p>
              <ul class="ma-2">
              <li>Make sure your video is set to public or unlisted.</li>
              <li>Make sure your video is not set to private.</li>
              <li>Make sure your video is not age-restricted.</li>
              <li>Make sure your video is not blocked in any countries.</li>
              </ul>
              </br>
              <p>If you still can't see your video, please contact us at <u><b>support@networktal.com</b></u>.</p>
            </div>`,
    trainingAreasGuidance: `
        <ul class="ma-2 text-caption">
            <li>Select the training areas that you are specialized in.</li>
            <li>Make sure to select at least one training area.</li>
            <li>Make sure to select at most 3 training areas.</li>
        </ul>`,
    aboutMeGuidance: `
        <ul class="ma-2 text-caption">
            <li>Write a short description about yourself.</li>
            <li>Describe your experience and your qualifications.</li>
            <li>Make sure to include your training philosophy and what makes you unique.</li>
        </ul>`,
    myApproachGuidance: `
            <ul class="ma-2 text-caption">
                <li>Write a short description about your training approach.</li>
                <li>Describe your training philosophy and what makes you unique.</li>
            </ul>`,
    languagesGuidance: `
            <ul class="ma-2 text-caption">
                <li>Select the languages that you speak.</li>
                <li>Make sure to select all the languages that you speak fluently.</li>
                <li>English is mandatory.</li>
            </ul>`,
  }),
  props: {
    trainer: {
      type: Object,
      required: true,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    successMessage: {
      type: String,
      required: false,
    },
  },
  created() {
    this.searchLanguage();
    this.eventBus.on("validate-trainer-data", async () => {
      await this.triggerValidation();
    });
    this.eventBus.on("reset-trainer-data-validation", () => {
      this.resetValidation();
    });
  },
  computed: {
    ...mapGetters({
      trainerAreas: "trainers/areas",
      userId: "auth/userId",
    }),
    fromCountryRegions() {
      if (this.trainer.from.country) {
        const country = countries.find(
          (country) => country.countryName === this.trainer.from.country
        );
        return country.regions;
      }
      return [];
    },
    livingInCountryRegions() {
      if (this.trainer.livingIn.country) {
        const country = countries.find(
          (country) => country.countryName === this.trainer.livingIn.country
        );
        return country.regions;
      }
      return [];
    },
    aboutMeErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "aboutMe") {
          if (error.$validator === "required") {
            errors.push("About Me is required");
          } else if (error.$validator === "maxLength") {
            errors.push("About Me cannot be longer than 500 characters");
          }
        }
      });
      return errors;
    },
    fromCountryErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$propertyPath === "trainer.from.country") {
          if (error.$validator === "required") {
            errors.push("Country is required");
          }
        }
      });
      return errors;
    },
    fromRegionErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$propertyPath === "trainer.from.region") {
          if (error.$validator === "required") {
            errors.push("Region is required");
          }
        }
      });
      return errors;
    },
    livingInCountryErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$propertyPath === "trainer.livingIn.country") {
          if (error.$validator === "required") {
            errors.push("Country is required");
          }
        }
      });
      return errors;
    },
    livingInRegionErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$propertyPath === "trainer.livingIn.region") {
          if (error.$validator === "required") {
            errors.push("Region is required");
          }
        }
      });
      return errors;
    },
    dateOfBirthErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "dateOfBirth") {
          if (error.$validator === "required") {
            errors.push("Date of birth is required");
          } else if (error.$validator === "min18YearsOld") {
            errors.push("You must be at least 18 years old");
          }
        }
      });
      return errors;
    },
    selectedTrainingAreasErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "areas") {
          if (error.$validator === "required") {
            errors.push("Training areas are required");
          } else if (error.$validator === "minLength") {
            errors.push("You must select at least one training area");
          } else if (error.$validator === "maxLength") {
            errors.push("You must select at most 3 training areas");
          }
        }
      });
      return errors;
    },
    myApproachErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "myApproach") {
          if (error.$validator === "required") {
            errors.push("My Approach is required");
          } else if (error.$validator === "maxLength") {
            errors.push("My Approach cannot be longer than 500 characters");
          }
        }
      });
      return errors;
    },
    genderErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "gender") {
          errors.push("Gender is required");
        }
      });
      return errors;
    },
    languagesErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "languages") {
          if (error.$validator === "required") {
            errors.push("Languages are required");
          } else if (error.$validator === "minLength") {
            errors.push("You must select at least one language");
          } else if (error.$validator === "containsEnglish") {
            errors.push("English is mandatory");
          }
        }
      });
      return errors;
    },
    videoPresentationURLErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "videoPresentationURL") {
          if (error.$validator === "required") {
            errors.push("Video presentation URL is required");
          } else if (error.$validator === "url") {
            errors.push("Video presentation URL must be a valid URL");
          } else if (error.$validator === "isLinkYoutubeOrVimeo") {
            errors.push(
              "Video presentation URL must be a valid YouTube or Vimeo link"
            );
          }
        }
      });
      return errors;
    },
  },
  methods: {
    ...mapActions({
      clearUploadState: "data/clearState",
    }),
    resetSearch() {
      // console.log('reset search')
      this.searchLanguageInput = "";
      this.searchLanguage();
    },
    resetValidation() {
      this.$v.$reset();
    },
    saveDateOfBirth(date) {
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      this.trainer.dateOfBirth = date.toISOString().split("T")[0];
      this.menu = false;
    },
    searchLanguage() {
      this.filteredLanguages = languages.filter((language) =>
        language.name
          .toLowerCase()
          .includes(this.searchLanguageInput.toLowerCase())
      );
    },
    async triggerValidation() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        this.eventBus.emit("profile-information-valid");
      } else {
        this.eventBus.emit("profile-information-invalid");
      }
    },
  },
  watch: {
    "trainer.languages": {
      handler() {
        this.searchLanguageInput = "";
        this.searchLanguage();
      },
      deep: true,
    },
    // watch searchLanguageInput for being not empty and stay like that for more than 10 secounds. If so clear it
    searchLanguageInput: {
      handler() {
        if (this.searchLanguageInput !== "") {
          setTimeout(() => {
            if (this.searchLanguageInput !== "") {
              this.resetSearch();
            }
          }, 10000);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.register-trainer-form-section {
  border: 1px solid rgb(var(--v-theme-background));
  padding: 40px;
  margin-bottom: 40px;
}

@media (max-width: 600px) {
  .register-trainer-form-section {
    padding: 10px;
  }
}
.video-preview {
  height: 300px;
  max-width: 500px;
}

.guidance-link {
  font-size: 10px;
  text-decoration: none;
  font-style: italic;
}
</style>
