<template>
  <div class="page-header">
    <v-row class="ma-0 align-end page-header-row-1">
      <v-col cols="12" md="6" lg="6" xl="6" :align="titlePosition">
        <p :class="classsesTitle">
          Find the right
          <span style="color: rgb(var(--v-theme-colorSpecial3))">session</span>
        </p>
        <p :class="classesSubTitle">that fits your development plans</p>
      </v-col>
    </v-row>
    <v-row justify="center" class="ma-0 align-center page-header-row-2">
      <v-col cols="12" md="9" lg="9" xl="9" align="start">
        <filter-bar></filter-bar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FilterBar from "./filterbar.vue";

export default {
  components: {
    FilterBar,
  },
  computed: {
    titlePosition() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "center";
        case "sm":
          return "center";
        case "md":
          return "end";
        case "lg":
          return "end";
        case "xl":
          return "end";
        default:
          return "end";
      }
    },
    classsesTitle() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "text-h4 font-weight-medium pt-5";
        case "sm":
          return "text-h4 font-weight-medium";
        case "md":
          return "text-h3 font-weight-medium";
        case "lg":
          return "text-h3 font-weight-medium";
        case "xl":
          return "text-h3 font-weight-medium";
        default:
          return "text-h3 font-weight-medium";
      }
    },
    classesSubTitle() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "text-h6 font-weight-regular";
        case "sm":
          return "text-h6 font-weight-regular";
        case "md":
          return "text-h5 font-weight-regular";
        case "lg":
          return "text-h5 font-weight-regular";
        case "xl":
          return "text-h5 font-weight-regular";
        default:
          return "text-h5 font-weight-regular";
      }
    },
  },
};
</script>

<style scoped>
.page-header {
  height: auto;
  /* border: 2px solid black; */
  background-color: rgb(var(--v-theme-background3));
}

.page-header-row-1 {
  min-height: 200px;
  /* border: 2px solid black; */
}
.page-header-row-2 {
  height: auto;
  /* border: 2px solid black; */
}
</style>
