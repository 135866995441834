<template>
  <div>
    <base-card>
      <p class="text-h4 font-weight-medium text-center my-10 mx-5">
        My Availability Calendar
      </p>
      <base-loader v-if="loading"></base-loader>
      <div
        v-else
        :class="{
          'ma-10': $vuetify.display.mdAndUp,
          'ma-5': $vuetify.display.smAndDown,
        }"
      >
        <calendar-base :trainerId="trainerId"> </calendar-base>
      </div>
    </base-card>
  </div>
</template>

<script>
import CalendarBase from "../../profile/calendar/calendarbase.vue";

export default {
  props: {
    trainerId: {
      required: true,
    },
    loading: {
      required: true,
    },
  },
  components: {
    CalendarBase,
  },
};
</script>

<style scoped></style>
