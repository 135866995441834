import db from "../../../firebase-init";

export default {
  async addOrUpdateSession(context, payload) {
    // console.log('Add or Update Sessions triggered with payload: ' + JSON.stringify(payload))

    let trainer = context.rootGetters["trainers/currentUserTrainerProfile"];

    if (!trainer.sessions) {
      trainer.sessions = [];
    }

    let index = trainer.sessions.findIndex(
      (session) => session.id === payload.data.id
    );

    // console.log('Index: ' + index)

    if (index >= 0) {
      trainer.sessions[index] = {
        ...payload.data,
      };
    } else {
      trainer.sessions.push({
        ...payload.data,
      });
    }

    await context.dispatch(
      "trainers/createOrUpdateTrainer",
      {
        trainerId: payload.trainerId,
        data: {
          ...trainer,
        },
      },
      {
        root: true,
      }
    );
  },

  async getSessionById(_, payload) {
    let session = null;

    await db
      .collection("sessions")
      .doc(payload)
      .get()
      .then((doc) => {
        if (doc.exists) {
          session = doc.data();
          session.id = doc.id;
        }
      });

    return session;
  },

  async removeSession(context, payload) {
    // console.log('Remove Sessions triggered with payload: ' + JSON.stringify(payload))

    let trainer = context.rootGetters["trainers/currentUserTrainerProfile"];

    let index = trainer.sessions.findIndex(
      (session) => session.id === payload.sessionId
    );
    let sessionToRemoveIsTrial = trainer.sessions[index].isTrial;

    if (
      trainer.sessions.find((session) => session.isTrial === true) &&
      !sessionToRemoveIsTrial
    ) {
      if (trainer.sessions.length === 2) {
        throw new Error(
          "A trainer must have at least one non-trial session active. You cannot remove the last session."
        );
      }
    } else {
      if (trainer.sessions.length === 1) {
        throw new Error(
          "A trainer must have at least one non-trial session active. You cannot remove the last session."
        );
      }
    }

    // console.log('Index: ' + index)
    // index is -1 if the session id does not exist

    if (index >= 0) {
      trainer.sessions.splice(index, 1);
    } else {
      throw new Error("The session id provided does not exist.");
    }

    await context.dispatch(
      "trainers/createOrUpdateTrainer",
      {
        trainerId: payload.trainerId,
        data: {
          ...trainer,
        },
      },
      {
        root: true,
      }
    );
  },

  setAddOrUpdateSessionDialog(context, payload) {
    context.commit("setAddOrUpdateSessionDialog", payload);
  },

  setReportSessionDialog(context, payload) {
    context.commit("setReportSessionDialog", payload);
  },

  setViewSessionDialog(context, payload) {
    context.commit("setViewSessionDialog", payload);
  },

  async verifyNonActiveSessionsByUserId(_, payload) {
    // console.log("Verify non-active sessions for user: " + payload);
    let newOrAcceptedSessionsExist = false;

    await db
      .collection("sessions")
      .where("trainerUserId", "==", payload)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            let session = doc.data();
            session.id = doc.id;
            const latestStatus =
              session.statusLogs[session.statusLogs.length - 1].status;
            if (latestStatus === "new" || latestStatus === "accepted") {
              newOrAcceptedSessionsExist = true;
            }
          });
        }
      });

    await db
      .collection("sessions")
      .where("userId", "==", payload)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            let session = doc.data();
            session.id = doc.id;
            const latestStatus =
              session.statusLogs[session.statusLogs.length - 1].status;
            if (latestStatus === "new" || latestStatus === "accepted") {
              newOrAcceptedSessionsExist = true;
            }
          });
        }
      });

    if (newOrAcceptedSessionsExist) {
      throw new Error(
        "You cannot delete your account as you have new or accepted sessions. Please cancel or complete them first."
      );
    } else {
      return true;
    }
  },
};
