<template>
  <div>
    <base-dialog :model="shareBlogLinkDialog">
      <base-card>
        <v-row class="mt-5 mb-3" justify="center">
          <v-col cols="10" sm="8" class="ma-0 pa-0 pt-2">
            <v-text-field
              :model-value="getBlogLink()"
              class="ma-0 pa-0"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="10" sm="3" align="center">
            <base-button
              v-if="!shareBlogLinkDialogSuccess && !shareBlogLinkDialogError"
              color="primary"
              @click="CopyLinkToClipboard"
              class="ma-0 pa-0 justify-center"
            >
              Copy Link
            </base-button>
            <base-button
              v-else-if="
                shareBlogLinkDialogSuccess && !shareBlogLinkDialogError
              "
              color="success"
              class="ma-0 pa-0 justify-center"
            >
              <v-icon>mdi-check</v-icon>
            </base-button>
            <base-button
              v-if="shareBlogLinkDialogError"
              color="error"
              @click="shareBlogLinkDialog = false"
              class="ma-0 pa-0 justify-center"
            >
              <v-icon>mdi-close</v-icon>
            </base-button>
          </v-col>
        </v-row>
      </base-card>
    </base-dialog>
    <base-card customColor="blogpostPreviewBackground" class="pa-0">
      <router-link :to="ArticlePath" style="text-decoration: none">
        <v-img class="align-end" height="200" :src="ImageURL" cover>
          <v-card-title :style="{ color: TitleColor }">{{
            Title
          }}</v-card-title>
        </v-img>
      </router-link>

      <v-card-subtitle class="pt-4">{{ Date }}</v-card-subtitle>

      <v-card-text>
        <div>
          {{ Description }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" :to="ArticlePath"> Explore </v-btn>
        <!-- create a share on social media button -->

        <v-btn color="colorSpecial1" @click="shareBlogLinkDialog = true">
          Share
        </v-btn>
      </v-card-actions>
    </base-card>
  </div>
</template>

<script>
export default {
  inject: ["eventBus"],
  data() {
    return {
      shareBlogLinkDialog: false,
      shareBlogLinkDialogSuccess: false,
      shareBlogLinkDialogError: false,
    };
  },
  created() {
    this.eventBus.on("set-base-dialog", (value) => {
      this.shareBlogLinkDialog = value;
    });
  },
  unmounted() {
    this.eventBus.off("set-base-dialog");
  },
  props: {
    Title: {
      type: String,
      default: "Blog Title",
    },
    TitleColor: {
      type: String,
      default: "white",
    },
    Date: {
      type: String,
      default: "February 14th, 2024",
    },
    Description: {
      type: String,
      default:
        "Short Description of the article. Minumum 10 words I would say.",
    },
    ImageURL: {
      type: String,
      default:
        "https://ntstorage01.blob.core.windows.net/blog-images/networktal-logo-dark-01.png",
    },
    ArticlePath: {
      type: String,
      default: "/blog/article-1",
      required: true,
    },
  },
  methods: {
    async CopyLinkToClipboard() {
      try {
        const blogLink = this.getBlogLink();
        await navigator.clipboard.writeText(blogLink);
        // alert("Success! Blog link copied to clipboard!");
        this.shareBlogLinkDialogSuccess = true;

        setTimeout(() => {
          this.shareBlogLinkDialogSuccess = false;
          this.shareBlogLinkDialog = false;
        }, 1000);
      } catch ($e) {
        // alert("Error copying link to clipboard!");
        this.shareBlogLinkDialogError = true;
      }
    },
    getBlogLink() {
      const link =
        process.env.VUE_APP_NETWORKTAL_PLATFORM_URL + this.ArticlePath;
      return link;
    },
  },
};
</script>

<style scoped></style>
