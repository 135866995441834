<template>
  <div>
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col>
        <v-tabs align-tabs="center" v-model="sessionStatusId">
          <v-tab v-for="status in mySessionsCategories" :key="status.value">
            {{ status }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row
      v-if="sessions.length === 0 && sessionStatusId == 0"
      class="sessions-not-found-text"
    >
      <v-col>No sessions found.</v-col>
    </v-row>

    <sessions-list
      v-if="
        (sessionStatusId == 0 || sessionStatusId == 1) &&
        sessionsWithStatuses([sessionStatuses[1].value]).length > 0
      "
      :sessions="sessionsWithStatuses([sessionStatuses[1].value])"
      :trainer="trainer"
      listTitle="Action required"
      sessionCardMessage="ACTION REQUIRED"
    ></sessions-list>
    <v-row
      v-if="
        sessionsWithStatuses([sessionStatuses[1].value]).length === 0 &&
        sessionStatusId == 1
      "
      class="sessions-not-found-text"
    >
      <v-col>No action required.</v-col>
    </v-row>

    <sessions-list
      class="mt-7"
      v-if="
        (sessionStatusId == 0 || sessionStatusId == 2) &&
        sessionsWithStatuses([sessionStatuses[2].value]).length > 0
      "
      :sessions="sessionsWithStatuses([sessionStatuses[2].value])"
      :trainer="trainer"
      listTitle="Upcoming sessions"
      sessionCardMessage="UPCOMING"
    ></sessions-list>
    <v-row
      v-if="
        sessionsWithStatuses([sessionStatuses[2].value]).length === 0 &&
        sessionStatusId == 2
      "
      class="sessions-not-found-text"
    >
      <v-col>No upcoming sessions.</v-col>
    </v-row>

    <sessions-list
      class="mt-7"
      v-if="
        (sessionStatusId == 0 || sessionStatusId == 3) &&
        sessionsWithStatuses([sessionStatuses[3].value]).length > 0
      "
      :sessions="sessionsWithStatuses([sessionStatuses[3].value])"
      :trainer="trainer"
      listTitle="Completed sessions"
      sessionCardMessage="COMPLETED"
    ></sessions-list>
    <v-row
      v-if="
        sessionsWithStatuses([sessionStatuses[3].value]).length === 0 &&
        sessionStatusId == 3
      "
      class="sessions-not-found-text"
    >
      <v-col>No completed sessions.</v-col>
    </v-row>

    <sessions-list
      class="mt-7"
      v-if="
        (sessionStatusId == 0 || sessionStatusId == 4) &&
        sessionsWithStatuses([sessionStatuses[4].value]).length > 0
      "
      :sessions="sessionsWithStatuses([sessionStatuses[4].value])"
      :trainer="trainer"
      listTitle="Declined sessions"
      sessionCardMessage="DECLINED"
    ></sessions-list>
    <v-row
      v-if="
        sessionsWithStatuses([sessionStatuses[4].value]).length === 0 &&
        sessionStatusId == 4
      "
      class="sessions-not-found-text"
    >
      <v-col>No declined sessions.</v-col>
    </v-row>

    <sessions-list
      class="mt-7"
      v-if="
        (sessionStatusId == 0 || sessionStatusId == 5) &&
        sessionsWithStatuses([sessionStatuses[5].value]).length > 0
      "
      :sessions="sessionsWithStatuses([sessionStatuses[5].value])"
      :trainer="trainer"
      listTitle="Cancelled sessions"
      sessionCardMessage="CANCELLED"
    ></sessions-list>
    <v-row
      v-if="
        sessionsWithStatuses([sessionStatuses[5].value]).length === 0 &&
        sessionStatusId == 5
      "
      class="sessions-not-found-text"
    >
      <v-col>No cancelled sessions.</v-col>
    </v-row>

    <sessions-list
      class="mt-7"
      v-if="
        (sessionStatusId == 0 || sessionStatusId == 6) &&
        sessionsWithStatuses([sessionStatuses[6].value]).length > 0
      "
      :sessions="sessionsWithStatuses([sessionStatuses[6].value])"
      :trainer="trainer"
      listTitle="Disputed sessions"
      sessionCardMessage="DISPUTED"
    ></sessions-list>
    <v-row
      v-if="
        sessionsWithStatuses([sessionStatuses[6].value]).length === 0 &&
        sessionStatusId == 6
      "
      class="sessions-not-found-text"
    >
      <v-col>No disputed sessions.</v-col>
    </v-row>
  </div>
</template>

<script>
import SessionsList from "./sessionslistcard.vue";
import { mapGetters } from "vuex";

export default {
  inject: ["eventBus"],
  data() {
    return {
      sessionStatusId: 0,
      mySessionsCategories: [
        "All",
        "Action required",
        "Upcoming",
        "Completed",
        "Declined",
        "Cancelled",
        "Disputed",
      ],
      sessionStatuses: [
        {
          id: 0,
          text: "All",
          value: "all",
        },
        {
          id: 1,
          text: "New",
          value: "new",
        },
        {
          id: 2,
          text: "Upcoming",
          value: "accepted",
        },
        {
          id: 3,
          text: "Completed",
          value: "completed",
        },
        {
          id: 4,
          text: "Declined",
          value: "declined",
        },
        {
          id: 5,
          text: "Cancelled",
          value: "cancelled",
        },
        {
          id: 6,
          text: "Disputed",
          value: "disputed",
        },
      ],
    };
  },
  components: {
    SessionsList,
  },
  props: {
    sessions: {
      type: Array,
      required: true,
    },
    trainer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      confirmDialog: "websiteData/confirmDialog",
    }),
  },
  methods: {
    sessionsWithStatuses(statuses) {
      return this.sessions.filter((session) => {
        if (session.statusLogs.length > 0) {
          const latestStatus =
            session.statusLogs[session.statusLogs.length - 1];
          if (statuses.includes(latestStatus.status)) {
            return session;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.sessions-not-found-text {
  font-size: 1.2rem;
  font-weight: 450;
}
.active-chip {
  background-color: rgb(var(--v-theme-colorSpecial1)) !important;
  color: rgb(var(--v-theme-white)) !important;
}
.session-page-title {
  font-size: 1.6rem;
  font-weight: 600;
}
</style>
