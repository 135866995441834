<template>
  <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col
        :class="{
          'pr-5': $vuetify.display.mdAndUp,
          'py-5': $vuetify.display.smAndDown,
          'pa-0': true,
        }"
        cols="12"
        md="10"
        lg="6"
        xl="6"
      >
        <base-card>
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            My Sessions
          </p>
          <base-loader v-if="loading"></base-loader>
          <div
            v-else
            :class="{
              'ma-10': $vuetify.display.mdAndUp,
              'ma-2': $vuetify.display.smAndDown,
            }"
          >
            <my-sessions
              v-if="currentUserTrainerProfile && trainerSessions"
              :sessions="trainerSessions"
              :trainer="currentUserTrainerProfile"
            ></my-sessions>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mySessions from "@/components/trainer/settings/mysessions/mysessions.vue";
import { mapActions, mapGetters } from "vuex";
import { setMetaTags } from "../../../utils/utils.js";

export default {
  inject: ["eventBus"],
  data() {
    return {
      trainerSessions: null,
    };
  },
  components: {
    mySessions,
  },
  created() {
    if (this.currentUserTrainerProfile) {
      this.loadTrainerSessions();
    }
    this.eventBus.on("session-updated", () => {
      setTimeout(() => {
        this.loadTrainerSessions();
      }, 1000);
    });
    setMetaTags("NetworkTal - My Trainer Sessions", "My Trainer Sessions");
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
      userId: "auth/userId",
    }),
    loading() {
      if (
        this.trainerSessions !== null &&
        this.currentUserTrainerProfile !== null
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions({
      getTrainerSessions: "calendar/getTrainerSessions",
    }),
    async loadTrainerSessions() {
      this.trainerSessions = await this.getTrainerSessions({
        trainerId: this.currentUserTrainerProfile.id,
        loadAvailability: false,
      });
    },
  },
};
</script>

<style scoped></style>
