<template>
  <div>
    <base-card class="explorer-card">
      <base-loader v-if="!startTime || !endTime"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0 pa-1" justify="center" style="font-size: 10px">
          <v-col class="ma-0 pa-0 px-1" cols="1">
            <v-icon size="small">mdi-clock-outline</v-icon>
          </v-col>
          <v-col class="ma-0 pa-0" cols
            >{{ getFormattedDateTimeInUserTimezone(startTime) }}
          </v-col>
          <v-col class="ma-0 pa-0" cols="1">-</v-col>
          <v-col class="ma-0 pa-0 pr-1">{{
            getFormattedDateTimeInUserTimezone(endTime)
          }}</v-col>
        </v-row>
      </div>
    </base-card>
  </div>
</template>

<script>
import { convertUTCDateTimeToUserTimezoneNoFormat } from "@/utils/utils.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  props: {
    startTime: {
      required: true,
    },
    endTime: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
    }),
  },
  methods: {
    getFormattedDateTimeInUserTimezone(utcTimestamp) {
      let dateTimeInUserTimezone = convertUTCDateTimeToUserTimezoneNoFormat(
        utcTimestamp,
        this.currentUser.timezone
      );
      return dateTimeInUserTimezone.format("MMM DD YYYY, HH:mm");
    },
  },
};
</script>

<style scoped>
.explorer-card {
  background-color: rgb(var(--v-theme-background)) !important;
}
</style>
