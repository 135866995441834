<template>
  <div>
    <base-dialog :model="trainerReviewDialog" customWidth="500px">
      <v-toolbar class="text-white" color="colorSpecial5" elevation="1">
        <v-row align="center" class="ma-0 pa-0">
          <v-col align="start" cols="10">Feedback</v-col>
          <v-col cols="2" align="end">
            <v-btn icon @click="cancel" class="text-white">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <div class="mx-5 mb-0 mt-5">
        <base-error-success-message
          :errorMessage="errorMessage"
          :successMessage="successMessage"
        ></base-error-success-message>
      </div>
      <v-container class="trainer-review-dialog">
        <v-row>
          <v-col class="trainer-review-dialog-title" align="center">
            <p>
              Rate your experience with
              <strong>{{ payload.trainerName }}</strong>
            </p>
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <v-col align="center">
            <v-input :error-messages="ratingErrors">
              <v-col align="center">
                <v-rating
                  color="warning"
                  class="yellow-lighten-2"
                  empty-icon="mdi-star-outline"
                  full-icon="mdi-star"
                  hover
                  length="5"
                  size="40"
                  v-model="rating"
                ></v-rating>
              </v-col>
            </v-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea class="mx-10" clearable v-model="message">
              <template v-slot:label>
                <div class="trainer-review-dialog-message-text">
                  Write a review
                  <small>(optional)</small>
                </div>
              </template>
            </v-textarea>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col align="center">
            <base-button class="mx-5" @click="submit()">Submit</base-button>
            <base-button class="mx-5" customColor="error" @click="cancel()">
              Cancel
            </base-button>
          </v-col>
        </v-row>
      </v-container>
    </base-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, integer, minValue, maxValue } from "@vuelidate/validators";

export default {
  inject: ["eventBus"],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      rating: {
        required,
        integer,
        minValue: minValue(1),
        maxValue: maxValue(5),
      },
    };
  },
  props: {
    eventName: {
      type: String,
      required: true,
    },
    payload: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      rating: 0,
      message: "",
      errorMessage: "",
      successMessage: "",
    };
  },
  created() {
    this.eventBus.on("set-base-dialog", (payload) => {
      this.setTrainerReviewDialog(payload);
    });
  },
  unmounted() {
    this.eventBus.off("set-base-dialog");
  },
  computed: {
    ...mapGetters({
      trainerReviewDialog: "reviews/trainerReviewDialog",
    }),
    ratingErrors() {
      const errors = [];
      this.v$.$errors.forEach((error) => {
        if (error.$property === "rating") {
          errors.push("Select a rating between 1 and 5 stars");
        }
      });
      return errors;
    },
  },
  methods: {
    ...mapActions({
      setTrainerReviewDialog: "reviews/setTrainerReviewDialog",
    }),
    async submit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        this.eventBus.emit(this.eventName, {
          message: this.message,
          rating: this.rating,
          reviewFor: "trainer",
          sessionId: this.payload.sessionId,
          trainerId: this.payload.trainerId,
          userId: this.payload.userId,
        });
        this.setTrainerReviewDialog(false);
        this.clearData();
      }
      return;
    },
    cancel() {
      this.setTrainerReviewDialog(false);
      this.clearData();
    },
    clearData() {
      this.rating = 0;
    },
  },
};
</script>

<style scoped>
.trainer-review-dialog {
  font-size: 16px;
  padding-top: 50px;
}

.trainer-review-dialog-title {
  font-weight: 400;
  font-size: 18px;
  padding: 0px;
}
</style>
