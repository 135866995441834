<template>
  <div>
    <add-availability-dialog
      :trainerId="currentUserTrainerProfile.id"
      :trainerAvailability="trainerAvailability"
    ></add-availability-dialog>

    <base-card style="height: 100%">
      <p class="text-h4 font-weight-medium text-center my-10 mx-5">
        Upcoming Availability
      </p>

      <v-row class="ma-0 pa-0 px-5" align="end">
        <v-col class="ma-0 pa-0 py-2 text-caption">
          <base-tooltip-info
            text="Timezone can only be changed in the user settings page"
            :custom="true"
          >
            <v-icon size="small">mdi-earth</v-icon>
            {{ currentUser.timezone }} {{ currentUserTimezoneOffset }}
          </base-tooltip-info>
        </v-col>
        <v-col class="ma-0 pa-0" align="end">
          <base-button
            @click="setAddAvailabilityDialog(true)"
            dark
            :disabled="false"
          >
            <v-icon size="small">mdi-plus</v-icon>
            Add Availability
          </base-button>
        </v-col>
      </v-row>

      <base-loader v-if="loading"></base-loader>

      <div
        v-else
        :class="{
          'mx-5': $vuetify.display.mdAndUp,
          'mx-2': $vuetify.display.smAndDown,
          'mb-10': true,
        }"
      >
        <availability-list
          v-if="
            !checkIfTrainerAvailabilityIsEmpty && currentUserTrainerProfile.id
          "
          :trainerId="currentUserTrainerProfile.id"
          :trainerAvailability="trainerAvailability"
        ></availability-list>

        <p v-else class="text-subtitle-1 ml-10 mt-10">
          No availability added...
        </p>
      </div>
    </base-card>
  </div>
</template>

<script>
import AddAvailabilityDialog from "./addavailabilitydialog.vue";
import AvailabilityList from "./availabilitylist.vue";
import { mapActions, mapGetters } from "vuex";
import { getUTCValueOfTimezone } from "@/utils/utils.js";

export default {
  components: {
    AddAvailabilityDialog,
    AvailabilityList,
  },
  props: {
    currentUserTrainerProfile: {
      required: true,
    },
    trainerAvailability: {
      required: true,
    },
    loading: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
    }),
    checkIfTrainerAvailabilityIsEmpty() {
      return this.trainerAvailability.length === 0;
    },
    currentUserTimezoneOffset() {
      return getUTCValueOfTimezone(this.currentUser.timezone);
    },
  },
  methods: {
    ...mapActions({
      setAddAvailabilityDialog: "trainerAvailability/setAddAvailabilityDialog",
    }),
  },
};
</script>

<style scoped></style>
