<template>
  <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col
        :class="{
          'pr-5': $vuetify.display.mdAndUp,
          'py-5': $vuetify.display.smAndDown,
          'pa-0': true,
        }"
        cols="12"
        sm="9"
        md="4"
        lg="3"
        xl="2"
      >
        <settings-sidemenu></settings-sidemenu>
      </v-col>
      <v-col class="pa-0" cols="12" sm="9" md="8" lg="6" xl="5">
        <base-card>
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            Trainer Profile
          </p>
          <base-loader v-if="loading"></base-loader>
          <div
            v-else
            :class="{
              'ma-10': $vuetify.display.mdAndUp,
              'ma-2': $vuetify.display.smAndDown,
            }"
          >
            <basic-settings
              v-if="$route.name === 'SettingsTrainerInformation'"
              @fetchTrainerProfile="getTrainerProfileDetails()"
              :trainerProfile="currentUserTrainerProfile"
            ></basic-settings>
            <profile-picture
              v-if="$route.name === 'SettingsTrainerProfilePicture'"
              @fetchTrainerProfile="getTrainerProfileDetails()"
              :trainerProfile="currentUserTrainerProfile"
            ></profile-picture>
            <communication-tools-settings
              v-if="$route.name === 'SettingsTrainerCommunicationTools'"
              @fetchTrainerProfile="getTrainerProfileDetails()"
              :trainerProfile="currentUserTrainerProfile"
            ></communication-tools-settings>
            <certifications-settings
              v-if="$route.name === 'SettingsTrainerCertifications'"
              @fetchTrainerProfile="getTrainerProfileDetails()"
              :trainerProfile="currentUserTrainerProfile"
            ></certifications-settings>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import basicSettings from "@/components/trainer/settings/profile/basic/basicsettings.vue";
import communicationToolsSettings from "@/components/trainer/settings/profile/communicationtools/communicationtoolssettings.vue";
import certificationsSettings from "@/components/trainer/settings/profile/certifications/certificationssettings.vue";
import settingsSidemenu from "@/components/trainer/settings/profile/settingssidemenu.vue";
import profilePicture from "@/components/trainer/settings/profile/picture/profilepicture.vue";
import { mapActions, mapGetters } from "vuex";
import { setMetaTags } from "../../../utils/utils.js";

export default {
  inject: ["eventBus"],
  data() {
    return {};
  },
  components: {
    basicSettings,
    communicationToolsSettings,
    certificationsSettings,
    profilePicture,
    settingsSidemenu,
  },
  created() {
    this.eventBus.on("new-certification-added", () => {
      setTimeout(() => {
        this.getTrainerProfileDetails();
      }, 1000);
    });
    this.eventBus.on("certification-removed", () => {
      setTimeout(() => {
        this.getTrainerProfileDetails();
      }, 1000);
    });
    setMetaTags(
      "NetworkTal - Trainer Profile Settings",
      "Trainer Profile Settings"
    );
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
      isTrainer: "auth/isTrainer",
      userId: "auth/userId",
    }),
    loading() {
      if (this.currentUserTrainerProfile) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions({
      getTrainerByUserId: "trainers/getTrainerByUserId",
    }),
    async getTrainerProfileDetails() {
      await this.getTrainerByUserId(this.userId);
    },
  },
};
</script>

<style scoped></style>
