<template>
  <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col
        :class="{
          'pr-5': $vuetify.display.mdAndUp,
          'py-5': $vuetify.display.smAndDown,
          'pa-0': true,
        }"
        cols="12"
        md="10"
        lg="6"
        xl="6"
      >
        <base-card class="card-style">
          <v-row class="text-h5 font-weight-medium mx-5" align="center">
            <v-col align="center">
              <v-avatar size="100" color="colorSpecial2" class="mb-10 mt-10">
                <v-icon size="60" color="white">mdi-check</v-icon>
              </v-avatar>
              <p class="mt-10">
                Your trainer registration request has been sent successfully!
              </p>
            </v-col>
          </v-row>
          <v-row class="text-body-1" justify="center">
            <v-col cols="11" md="10" lg="9" xl="9">
              <p class="font-weight-medium">What happens next?</p>
              <ul class="ml-5 mt-5">
                <li>
                  We will review your request and get back to you within 7 days.
                </li>
                <li>
                  You will receive an email notification once your request is
                  approved.
                </li>
                <li>
                  Once your request is approved, you will be able to manage your
                  trainer profile and start accepting bookings.
                </li>
                <li>
                  In case you need to update your profile, we will notify you
                  via email.
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center" class="my-10">
              <base-button
                customColor="colorSpecial1"
                toPath="/settings/trainer/dashboard"
              >
                Trainer Dashboard
              </base-button>
            </v-col>
          </v-row>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { setMetaTags } from "../../../utils/utils.js";

export default {
  computed: {
    containerWidth() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "100";
        case "sm":
          return "80";
        case "md":
          return "70";
        case "lg":
          return "60";
        case "xl":
          return "60";
        default:
          return "60";
      }
    },
  },
  created() {
    setMetaTags(
      "NetworkTal - Trainer Registration Success",
      "Trainer Registration Success"
    );
  },
};
</script>

<style scoped>
.card-style {
  min-height: 50vh;
  /* make all content align-tabs="center" vertifcally */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.row {
  margin: 0px;
}
</style>
