<template>
  <div>
    <base-card class="pa-5 pt-2" style="text-align: center">
      <base-loader v-if="!currentUserTrainerProfile"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0">
          <v-col
            class="ma-0 pa-0 pt-2 text-body-2 font-weight-medium"
            cols="10"
            align="start"
          >
            <v-icon size="small" class="pr-2">mdi-human-male-board</v-icon>
            My Sessions Overview</v-col
          >
          <v-col class="ma-0 pa-0" align="right">
            <router-link to="/settings/trainer/mysessions">
              <v-icon size="15" color="grey">mdi-eye</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 py-5" justify="space-around">
          <v-col class="ma-0 pa-0 my-2 sessions-view" cols="12" sm="10" md="5">
            <upcoming-session :upcomingSession="upcomingSession" />
          </v-col>
          <v-col class="ma-0 pa-0 my-2 sessions-view" cols="12" sm="10" md="5">
            <action-required-sessions
              :newSessions="newSessions"
              :disputedSessions="disputedSessions"
            />
          </v-col>
        </v-row>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ActionRequiredSessions from "./mysessionsoverview/actionrequiredsessions.vue";
import UpcomingSession from "./mysessionsoverview/upcomingsession.vue";
import {
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
} from "@/utils/utils.js";

export default {
  data() {
    return {
      upcomingSession: null,
      newSessions: [],
      disputedSessions: [],
    };
  },
  components: {
    ActionRequiredSessions,
    UpcomingSession,
  },
  async created() {
    await this.fetchSessions();
    this.upcomingSession = this.getUpcomingSession()[0];
    this.newSessions = this.getNewSessions();
    this.disputedSessions = this.getDisputedSessions();
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
      sessionsTrainer: "calendar/sessionsTrainer",
    }),
  },
  methods: {
    ...mapActions({
      loadTrainerEvents: "calendar/loadTrainerEvents",
    }),
    async fetchSessions() {
      if (this.currentUserTrainerProfile) {
        try {
          await this.loadTrainerEvents({
            trainerId: this.currentUserTrainerProfile.id,
            loadAvailability: false,
          });
        } catch (error) {
          // console.error(error);
        }
      }
    },
    getUpcomingSession() {
      let upcomingAcceptedSessions = [];

      upcomingAcceptedSessions = this.sessionsTrainer
        .filter(
          (session) =>
            session.statusLogs[session.statusLogs.length - 1].status ===
              "accepted" &&
            convertUTCDateTimeToUserTimezoneNoFormat(
              session.start,
              this.currentUser.timezone
            ).isAfter(getCurrentTimeInUserTimezone(this.currentUser.timezone))
        )
        .sort((a, b) => new Date(a.start) - new Date(b.start));

      return upcomingAcceptedSessions.slice(0, 1);
    },
    getNewSessions() {
      let newSessions = [];
      newSessions = this.sessionsTrainer.filter(
        (session) =>
          session.statusLogs[session.statusLogs.length - 1].status === "new"
      );
      return newSessions;
    },
    getDisputedSessions() {
      let disputedSessions = [];
      disputedSessions = this.sessionsTrainer.filter(
        (session) =>
          session.statusLogs[session.statusLogs.length - 1].status ===
          "disputed"
      );
      return disputedSessions;
    },
  },
};
</script>

<style scoped>
.sessions-view {
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  min-height: 100px;
}
</style>
