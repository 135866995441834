<template>
  <v-container>
    <base-error-success-message
      :errorMessage="errorMessage"
      :successMessage="successMessage"
    ></base-error-success-message>
    <v-row>
      <v-col class="font-weight-medium" cols="2">Enabled</v-col>
      <v-col class="font-weight-medium" cols="4">Name</v-col>
      <v-col class="font-weight-medium" cols="5">Id / Meeting URL</v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <communication-tool-with-tool-id
      :communicationTool="trainerProfile.communicationTools[0]"
    ></communication-tool-with-tool-id>
    <communication-tool-with-tool-id
      :communicationTool="trainerProfile.communicationTools[1]"
    ></communication-tool-with-tool-id>
    <communication-tool-with-tool-id
      :communicationTool="trainerProfile.communicationTools[2]"
    ></communication-tool-with-tool-id>
    <communication-tool-with-custom-tool-id
      :communicationTool="trainerProfile.communicationTools[3]"
    ></communication-tool-with-custom-tool-id>
    <v-row>
      <base-button @click="submit">
        Save
        <base-icon>mdi-content-save</base-icon>
      </base-button>
      <base-button customColor="error" @click="cancel">
        Cancel
        <base-icon>mdi-close</base-icon>
      </base-button>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import CommunicationToolWithToolId from "../../../register/communicationtools/communicationtoolwithtoolid.vue";
import CommunicationToolWithCustomToolId from "../../../register/communicationtools/communicationtoolwithcustomtoolid.vue";

export default {
  inject: ["eventBus"],
  data() {
    return {
      errorMessage: "",
      successMessage: "",
    };
  },
  props: {
    trainerProfile: {
      type: Object,
      required: true,
    },
  },
  components: {
    CommunicationToolWithToolId,
    CommunicationToolWithCustomToolId,
  },
  methods: {
    ...mapActions({
      createOrUpdateTrainer: "trainers/createOrUpdateTrainer",
      getEditedTrainerCommunicationToolsStatus:
        "trainers/getEditedTrainerCommunicationToolsStatus",
    }),
    async submit() {
      this.eventBus.emit("validate-communication-tools");

      let statuses = await this.getEditedTrainerCommunicationToolsStatus({
        communicationTools: this.trainerProfile.communicationTools,
      });

      // if valid, create trainer account, else show error message
      if (statuses.includes("valid") && !statuses.includes("invalid")) {
        try {
          let id = this.trainerProfile.id;
          let trainerProfileComplete = this.trainerProfile;
          delete trainerProfileComplete.id;

          await this.createOrUpdateTrainer({
            trainerId: id,
            data: {
              ...trainerProfileComplete,
            },
          });
          this.$emit("editCommunicationToolsSuccessful");
        } catch (error) {
          this.errorMessage = "Unknown error occurred. Please try again later!";
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        }
      } else {
        this.errorMessage =
          "Please select at least one communication tool and fill in the required fields";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    },
    cancel() {
      this.$emit("cancelEdit");
    },
  },
};
</script>

<style scoped></style>
