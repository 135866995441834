<template>
  <div class="text-subtitle-1">
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col cols="7" class="text-h5 font-weight-medium pl-5"
        >Basic Information</v-col
      >
      <v-col align="end">
        <base-button
          v-if="!inEditMode"
          @click="toggleInEditMode"
          :disabled="isTrainerProfileInformationUpdated"
        >
          Edit
          <base-icon>mdi-pencil</base-icon>
        </base-button>
      </v-col>
    </v-row>
    <base-card
      outlined
      :class="{
        'px-10 py-5': $vuetify.display.mdAndUp,
        'pa-2': $vuetify.display.smAndDown,
      }"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <base-error-success-message
        :successMessage="successMessage"
      ></base-error-success-message>
      <base-notification-card
        v-if="displayTrainerProfileUpdatesInfoNotification"
        :notification="notifications[0]"
      ></base-notification-card>
      <base-notification-card
        v-if="displayTrainerProfileUpdatedNotification"
        :notification="notifications[1]"
      ></base-notification-card>
      <basic-settings-data
        v-if="!inEditMode && trainerProfile"
        :trainerProfile="trainerProfile"
      ></basic-settings-data>
      <edit-basic-settings-data
        v-if="inEditMode"
        @cancelEdit="toggleInEditMode()"
        @editBasicInformationSuccessful="editBasicInformationSuccessful()"
        :trainerProfile="trainerProfile"
      ></edit-basic-settings-data>
    </base-card>
  </div>
</template>

<script>
import basicSettingsData from "../basic/basicsettingsdata.vue";
import editBasicSettingsData from "../basic/editbasicsettingsdata.vue";

export default {
  data() {
    return {
      inEditMode: false,
      successMessage: "",
      notifications: [
        {
          id: 1,
          color: "info",
          icon: "mdi-information-outline",
          message:
            "Any changes you make to your trainer profile information will not be visible directly in your public profile. They will be reviewed by our team first, and published once approved. This process may take up to 7 days.",
          title: "Profile Information Update",
        },
        {
          id: 2,
          color: "warning",
          icon: "mdi-alert-outline",
          message:
            "You have submitted changes to your trainer profile information. These changes will be reviewed by our team first, and published once approved. This process may take up to 7 days.",
          title: "Profile Information Updated",
        },
      ],
    };
  },
  components: {
    basicSettingsData,
    editBasicSettingsData,
  },
  props: {
    trainerProfile: {
      required: true,
    },
  },
  computed: {
    displayTrainerProfileUpdatesInfoNotification() {
      let displayTrainerProfileUpdatesInfoNotification = false;

      if (!this.isTrainerProfileInformationUpdated) {
        displayTrainerProfileUpdatesInfoNotification = true;
      }

      // If the trainer profile is not approved, do not show the notification
      if (this.trainerProfile.registrationRequestStatus !== "approved") {
        displayTrainerProfileUpdatesInfoNotification = false;
      }

      return displayTrainerProfileUpdatesInfoNotification;
    },
    displayTrainerProfileUpdatedNotification() {
      let displayTrainerProfileUpdatedNotification = false;

      if (this.isTrainerProfileInformationUpdated) {
        displayTrainerProfileUpdatedNotification = true;
      }

      // If the trainer profile is not approved, do not show the notification
      if (this.trainerProfile.registrationRequestStatus !== "approved") {
        displayTrainerProfileUpdatedNotification = false;
      }

      return displayTrainerProfileUpdatedNotification;
    },
    isTrainerProfileInformationUpdated() {
      let isTrainerProfileInformationUpdated = false;

      if (
        this.trainerProfile &&
        this.trainerProfile.trainerProfileUpdateRequests &&
        this.trainerProfile.trainerProfileUpdateRequests.length > 0
      ) {
        // check if any trainer profile Update Requests are of type profile-information and status is pending
        this.trainerProfile.trainerProfileUpdateRequests.forEach(
          (updateRequest) => {
            if (
              updateRequest.type === "profile-information" &&
              updateRequest.status === "pending"
            ) {
              isTrainerProfileInformationUpdated = true;
            }
          }
        );
      }

      // If the trainer profile is not approved, do not show the notification
      if (this.trainerProfile.registrationRequestStatus !== "approved") {
        isTrainerProfileInformationUpdated = false;
      }

      return isTrainerProfileInformationUpdated;
    },
  },
  methods: {
    toggleInEditMode() {
      this.inEditMode = !this.inEditMode;
    },
    editBasicInformationSuccessful() {
      this.$emit("fetchTrainerProfile");
      this.toggleInEditMode();
      this.successMessage = "Operation successful!";
      setTimeout(() => {
        this.successMessage = "";
      }, 5000);
    },
  },
};
</script>

<style scoped></style>
