<template>
  <div>
    <base-card class="pa-5 pt-2" style="text-align: center">
      <base-loader v-if="!currentUser"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0">
          <v-col
            class="ma-0 pa-0 pt-2 text-body-2 font-weight-medium"
            cols="10"
            align="start"
          >
            <v-icon size="small" class="pr-2">mdi-human-male-board</v-icon>
            My Explorer Sessions Overview</v-col
          >
          <v-col class="ma-0 pa-0" align="right">
            <router-link to="/settings/user/mysessions">
              <v-icon size="15" color="grey">mdi-eye</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 py-5" justify="space-around">
          <v-col class="ma-0 pa-0 my-2 sessions-view" cols="12" sm="10" md="5">
            <upcoming-session :upcomingSession="upcomingSession" />
          </v-col>
          <v-col class="ma-0 pa-0 my-2 sessions-view" cols="12" sm="10" md="5">
            <action-required-sessions
              :toCompleteSessions="toCompleteSessions"
              :disputedSessions="disputedSessions"
            />
          </v-col>
        </v-row>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ActionRequiredSessions from "./myusersessionsoverview/actionrequiredsessions.vue";
import UpcomingSession from "./myusersessionsoverview/upcomingsession.vue";
import {
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
} from "@/utils/utils.js";

export default {
  data() {
    return {
      upcomingSession: null,
      toCompleteSessions: [],
      disputedSessions: [],
    };
  },
  components: {
    ActionRequiredSessions,
    UpcomingSession,
  },
  async created() {
    await this.loadEvents();
    this.upcomingSession = this.getUpcomingSession()[0];
    this.toCompleteSessions = this.getToCompleteSessions();
    this.disputedSessions = this.getDisputedSessions();
  },
  computed: {
    ...mapGetters({
      sessionsUser: "calendar/sessionsUser",
      currentUser: "users/currentUser",
    }),
  },
  methods: {
    ...mapActions({
      loadUserEvents: "calendar/loadUserEvents",
    }),
    async loadEvents() {
      try {
        await this.loadUserEvents({
          userId: this.currentUser.userId,
        });
      } catch (error) {
        // console.error("Something went wrong! Please try again later.");
      }
    },
    getUpcomingSession() {
      let upcomingAcceptedSessions = [];
      upcomingAcceptedSessions = this.sessionsUser
        .filter(
          (session) =>
            session.statusLogs[session.statusLogs.length - 1].status ===
              "accepted" &&
            convertUTCDateTimeToUserTimezoneNoFormat(
              session.start,
              this.currentUser.timezone
            ).isAfter(getCurrentTimeInUserTimezone(this.currentUser.timezone))
        )
        .sort((a, b) => new Date(a.start) - new Date(b.start));

      return upcomingAcceptedSessions.slice(0, 1);
    },
    getToCompleteSessions() {
      let toCompleteSessions = [];
      toCompleteSessions = this.sessionsUser.filter(
        (session) =>
          session.statusLogs[session.statusLogs.length - 1].status ===
            "accepted" &&
          convertUTCDateTimeToUserTimezoneNoFormat(
            session.end,
            this.currentUser.timezone
          ).isBefore(getCurrentTimeInUserTimezone(this.currentUser.timezone))
      );
      return toCompleteSessions;
    },
    getDisputedSessions() {
      let disputedSessions = [];
      disputedSessions = this.sessionsUser.filter(
        (session) =>
          session.statusLogs[session.statusLogs.length - 1].status ===
          "disputed"
      );
      return disputedSessions;
    },
  },
};
</script>

<style scoped>
.sessions-view {
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  min-height: 100px;
}
</style>
