<template>
  <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col
        :class="{
          'pr-5': $vuetify.display.mdAndUp,
          'py-5': $vuetify.display.smAndDown,
          'pa-0': true,
        }"
        cols="12"
        md="10"
        lg="6"
        xl="6"
      >
        <base-card :loading="loading" style="min-height: 300px">
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            My Trainers
          </p>
          <base-loader v-if="loading"></base-loader>
          <div
            :class="{
              'ma-10': $vuetify.display.mdAndUp,
              'ma-2': $vuetify.display.smAndDown,
            }"
            v-else
          >
            <v-row v-if="currentUser.myTrainers.length > 0">
              <v-col>
                <user-trainers
                  :trainers="currentUser.myTrainers"
                ></user-trainers>
              </v-col>
            </v-row>
            <div v-else>
              <v-row class="ma-10" style="font-size: 16px">
                <v-col>
                  You did not meet with any trainer yet. Get started now!
                </v-col>
              </v-row>
              <v-row class="ma-10">
                <v-col>
                  <base-button toPath="/trainers">Find trainers</base-button>
                </v-col>
              </v-row>
            </div>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userTrainers from "@/components/user/mytrainers/usertrainers.vue";
import { mapGetters } from "vuex";
import { setMetaTags } from "../../../utils/utils.js";

export default {
  data() {
    return {};
  },
  components: {
    userTrainers,
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
      userId: "auth/userId",
    }),
    loading() {
      if (this.currentUser) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    setMetaTags("NetworkTal - My Trainers", "My Trainers");
  },
};
</script>

<style scoped></style>
