import db from "../../../firebase-init";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";

export default {
  async createOrUpdateUser(_, payload) {
    await setDoc(doc(db, "users", payload.id), payload.data);
  },

  async getUserByUserId(context, payload) {
    let userDetails = null;
    const q = query(collection(db, "users"), where("userId", "==", payload));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.size === 0) {
      // console.log('Warning: No user has been found with the user id: ' + payload)
    } else if (querySnapshot.size === 1) {
      querySnapshot.forEach((doc) => {
        userDetails = {
          id: doc.id,
          ...doc.data(),
        };
        // console.log('User details: ' + JSON.stringify(userDetails))
      });
    } else {
      // console.log('Warning: Multiple users been found with the user id: ' + payload)
    }

    let currentUserId = context.rootGetters["auth/userId"];
    if (userDetails && currentUserId === userDetails.userId) {
      context.dispatch("setCurrentUser", userDetails);
    }

    return userDetails;
  },

  setCurrentUser(context, payload) {
    context.commit("setCurrentUser", payload);
  },
};
