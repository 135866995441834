<template>
  <div class="pa-2">
    <v-row justify="center">
      <v-col cols="6" md="8" lg="8" xl="8">
        <v-select
          :disabled="disableFilterAndSearch"
          v-model="selectedAreas"
          :items="areasFiltered"
          chips
          label="Filter by skill areas"
          clearable
          closable-chips=""
          multiple
          @update:modelValue="filterTrainersByAreas"
          style="z-index: 100; position: relative"
          variant="underlined"
        >
          <template v-slot:prepend-item>
            <v-text-field
              class="mx-5"
              v-model="searchAreaNameInput"
              placeholder="Search Area"
              @update:modelValue="searchAreas"
            ></v-text-field>
          </template>
        </v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="ma-0 pa-0" cols="6" md="4" lg="4" xl="4">
        <v-text-field
          :disabled="disableFilterAndSearch"
          class="mt-3"
          v-model="searchByNameInput"
          append-icon="mdi-magnify"
          label="Search by name"
          single-line
          hide-details
          clearable
          @update:modelValue="filterTrainersByName"
          variant="underlined"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  inject: ["eventBus"],
  data() {
    return {
      searchByNameInput: "",
      searchAreaNameInput: "",
      selectedAreas: [],
      areasFiltered: [],
    };
  },
  props: {
    disableFilterAndSearch: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.areasFiltered = this.areas;
  },
  computed: {
    ...mapGetters({
      areas: "trainers/areas",
    }),
  },
  methods: {
    filterTrainersByAreas() {
      this.eventBus.emit("filter-trainers-by-areas", this.selectedAreas);
    },
    filterTrainersByName() {
      this.eventBus.emit("filter-trainers-by-name", this.searchByNameInput);
    },
    searchAreas() {
      this.areasFiltered = this.areas.filter((area) => {
        return area
          .toLowerCase()
          .includes(this.searchAreaNameInput.toLowerCase());
      });
    },
  },
};
</script>

<style scoped>
.dropdown-list {
  max-width: 400px;
  padding: 10px;
  background-color: white;
  border-radius: 20px;
}
</style>
