<template>
  <div>
    <router-link
      :to="
        '/trainer/' +
        createTrainerProfileDisplayNamePath(trainer.displayName) +
        '-' +
        trainer.id
      "
      target="_blank"
    >
      <base-card
        class="trainer-preview-card my-5"
        :style="'min-height:' + cardMinHeight + '; width:' + cardMinWidth"
      >
        <base-loader v-if="!trainer"></base-loader>
        <div v-else>
          <v-row class="ma-0 pa-0">
            <v-col
              align="end"
              class="ma-0 pa-0 font-weight-regular text-grey pr-4 pt-2 text-subtitle-1"
              ><v-chip variant="outlined" label>Trainer</v-chip>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0 pt-5 pb-10" justify="center">
            <v-col class="ma-0 pa-0">
              <v-avatar :size="avatarSize">
                <v-img :src="trainer.photoURL"></v-img>
              </v-avatar>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0" style="min-height: 35px" align="center">
            <v-col class="ma-0 pa-0 px-1 text-h5 font-weight-medium">
              {{ trainer.displayName }}
            </v-col>
          </v-row>
          <v-row
            class="ma-0 pa-0 mt-3 px-8"
            align="center"
            style="min-height: 100px"
          >
            <v-col
              v-if="trainer.sessions.length === 1"
              class="ma-0 pa-0 text-subtitle-1 font-weight-medium py-2"
            >
              <v-icon class="mr-1">mdi-message-video</v-icon>
              {{ trainer.sessions[0].title }}
            </v-col>
            <v-col v-else class="ma-0 pa-0 text-subtitle-1 py-2">
              <v-icon class="mr-1">mdi-message-video</v-icon>
              <strong>{{ trainer.sessions[0].title }}</strong> and more
            </v-col>
          </v-row>
        </div>
      </base-card>
    </router-link>
  </div>
</template>

<script>
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";

export default {
  setup() {
    const { createTrainerProfileDisplayNamePath } = useGlobalFunctions();
    return { createTrainerProfileDisplayNamePath };
  },
  data() {
    return {
      model: null,
    };
  },
  props: {
    trainer: {
      required: true,
    },
  },
  computed: {
    cardMinHeight() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "450px";
        case "sm":
          return "450px";
        case "md":
          return "500px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    cardMinWidth() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "320px";
        case "sm":
          return "320px";
        case "md":
          return "350px";
        case "lg":
          return "350px";
        case "xl":
          return "400px";
        default:
          return "400px";
      }
    },
    avatarSize() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "150";
        case "sm":
          return "150";
        case "md":
          return "200";
        case "lg":
          return "200";
        case "xl":
          return "220";
        default:
          return "220";
      }
    },
  },
};
</script>

<style scoped>
.trainer-preview-card {
  background-color: white !important;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Initial shadow */
}

.trainer-preview-card:hover {
  /* transform: translateY(-5px);  */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important; /* Enhanced shadow on hover */
}

a {
  text-decoration: none;
  color: rgb(var(--v-theme-primary));
}
</style>
