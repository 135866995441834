<template>
  <div style="max-height: 200vh; overflow: auto">
    <v-row
      justify="space-between"
      class="ma-0 pa-0 mt-10 mb-5"
      style="width: 100%"
    >
      <v-col>
        <v-tabs align-tabs="center" v-model="sessionStatusId">
          <v-tab v-for="status in mySessionsCategories" :key="status.value">
            {{ status }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row
      v-if="sessions.length === 0 && sessionStatusId === 0"
      class="sessions-not-found-text my-5 ma-0 pa-0"
    >
      <v-col>No sessions found.</v-col>
    </v-row>

    <!-- To verify what sessions need to go in Action required -->
    <sessions-list
      v-if="
        (sessionStatusId == 0 || sessionStatusId == 1) &&
        getActionRequiredSessions().length > 0
      "
      :sessions="getActionRequiredSessions()"
      listTitle="Action required"
      sessionCardMessage="TO COMPLETE"
    ></sessions-list>
    <v-row
      v-if="getActionRequiredSessions().length === 0 && sessionStatusId == 1"
      class="sessions-not-found-text my-5 ma-0 pa-0"
    >
      <v-col>No action required.</v-col>
    </v-row>

    <sessions-list
      class="mt-7"
      v-if="
        (sessionStatusId == 0 || sessionStatusId == 2) &&
        getUpcomingSessions().length > 0
      "
      :sessions="getUpcomingSessions()"
      listTitle="Upcoming sessions"
      sessionCardMessage="UPCOMING"
    ></sessions-list>
    <v-row
      v-if="getUpcomingSessions().length === 0 && sessionStatusId == 2"
      class="sessions-not-found-text my-5 ma-0 pa-0"
    >
      <v-col>No upcoming sessions.</v-col>
    </v-row>

    <sessions-list
      class="mt-7"
      v-if="
        (sessionStatusId == 0 || sessionStatusId == 3) &&
        getSessionsWithStatuses([sessionStatuses[3].value]).length > 0
      "
      :sessions="getSessionsWithStatuses([sessionStatuses[3].value])"
      listTitle="Completed sessions"
      sessionCardMessage="COMPLETED"
    ></sessions-list>
    <v-row
      v-if="
        getSessionsWithStatuses([sessionStatuses[3].value]).length === 0 &&
        sessionStatusId == 3
      "
      class="sessions-not-found-text my-5 ma-0 pa-0"
    >
      <v-col>No completed sessions.</v-col>
    </v-row>

    <sessions-list
      class="mt-7"
      v-if="
        (sessionStatusId == 0 || sessionStatusId == 4) &&
        getSessionsWithStatuses([sessionStatuses[4].value]).length > 0
      "
      :sessions="getSessionsWithStatuses([sessionStatuses[4].value])"
      listTitle="Declined sessions"
      sessionCardMessage="DECLINED"
    ></sessions-list>
    <v-row
      v-if="
        getSessionsWithStatuses([sessionStatuses[4].value]).length === 0 &&
        sessionStatusId == 4
      "
      class="sessions-not-found-text my-5 ma-0 pa-0"
    >
      <v-col>No declined sessions.</v-col>
    </v-row>

    <sessions-list
      class="mt-7"
      v-if="
        (sessionStatusId == 0 || sessionStatusId == 5) &&
        getSessionsWithStatuses([sessionStatuses[5].value]).length > 0
      "
      :sessions="getSessionsWithStatuses([sessionStatuses[5].value])"
      listTitle="Cancelled sessions"
      sessionCardMessage="CANCELLED"
    ></sessions-list>
    <v-row
      v-if="
        getSessionsWithStatuses([sessionStatuses[5].value]).length === 0 &&
        sessionStatusId == 5
      "
      class="sessions-not-found-text my-5 ma-0 pa-0"
    >
      <v-col>No cancelled sessions.</v-col>
    </v-row>

    <sessions-list
      class="mt-7"
      v-if="
        (sessionStatusId == 0 || sessionStatusId == 6) &&
        getSessionsWithStatuses([sessionStatuses[6].value]).length > 0
      "
      :sessions="getSessionsWithStatuses([sessionStatuses[6].value])"
      listTitle="Disputed sessions"
      sessionCardMessage="DISPUTED"
    ></sessions-list>
    <v-row
      v-if="
        getSessionsWithStatuses([sessionStatuses[6].value]).length === 0 &&
        sessionStatusId == 6
      "
      class="sessions-not-found-text my-5 ma-0 pa-0"
    >
      <v-col>No disputed sessions.</v-col>
    </v-row>
  </div>
</template>

<script>
import SessionsList from "./sessionslistcard.vue";
import { mapGetters } from "vuex";
import {
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
} from "@/utils/utils.js";

export default {
  inject: ["eventBus"],
  data() {
    return {
      sessionStatusId: 0,
      mySessionsCategories: [
        "All",
        "Action required",
        "Upcoming",
        "Completed",
        "Declined",
        "Cancelled",
        "Disputed",
      ],
      sessionStatuses: [
        {
          id: 0,
          text: "All",
          value: "all",
        },
        {
          id: 1,
          text: "New",
          value: "new",
        },
        {
          id: 2,
          text: "Accepted",
          value: "accepted",
        },
        {
          id: 3,
          text: "Completed",
          value: "completed",
        },
        {
          id: 4,
          text: "Declined",
          value: "declined",
        },
        {
          id: 5,
          text: "Cancelled",
          value: "cancelled",
        },
        {
          id: 6,
          text: "Disputed",
          value: "disputed",
        },
      ],
    };
  },
  components: {
    SessionsList,
  },
  props: {
    sessions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      addEventDialog: "calendar/addEventDialog",
      currentUser: "users/currentUser",
    }),
  },
  methods: {
    getSessionsWithStatuses(statuses) {
      return this.sessions.filter((session) => {
        if (session.statusLogs.length > 0) {
          const latestStatus =
            session.statusLogs[session.statusLogs.length - 1];
          if (statuses.includes(latestStatus.status)) {
            return session;
          }
        }
      });
    },
    getActionRequiredSessions() {
      let sessions = [];
      sessions = this.getSessionsWithStatuses([this.sessionStatuses[2].value]);
      sessions = sessions.filter((session) => {
        if (
          convertUTCDateTimeToUserTimezoneNoFormat(
            session.end,
            this.currentUser.timezone
          ).isBefore(getCurrentTimeInUserTimezone(this.currentUser.timezone))
        ) {
          return session;
        }
      });
      return sessions;
    },
    getUpcomingSessions() {
      let sessions = [];
      sessions = this.getSessionsWithStatuses([
        this.sessionStatuses[1].value,
        this.sessionStatuses[2].value,
        this.sessionStatuses[6].value,
      ]);
      sessions = sessions.filter((session) => {
        if (
          convertUTCDateTimeToUserTimezoneNoFormat(
            session.start,
            this.currentUser.timezone
          ).isAfter(getCurrentTimeInUserTimezone(this.currentUser.timezone))
        ) {
          return session;
        }
      });
      return sessions;
    },
  },
};
</script>

<style scoped>
.sessions-not-found-text {
  font-size: 1.2rem;
  font-weight: 450;
}
.active-chip {
  background-color: rgb(var(--v-theme-colorSpecial1)) !important;
  color: rgb(var(--v-theme-white)) !important;
}
.session-page-title {
  font-size: 1.6rem;
  font-weight: 600;
}
</style>
