<template>
  <div>
    <v-row class="communication-tools" justify="center">
      <v-col cols="12" md="10" lg="6" xl="6">
        <p class="text-subtitle-1 font-weight-medium mb-2">
          Select Communication Tool
        </p>
        <v-card class="communication-tools-card mb-5" elevation="0">
          <communication-tool-card
            v-for="communicationTool in communicationTools"
            :key="communicationTool.id"
            :communicationTool="communicationTool"
            :selectedCommunicationTool="selectedCommunicationTool"
          ></communication-tool-card>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommunicationToolCard from "./communicationtoolcard.vue";

export default {
  props: {
    communicationTools: {
      type: Array,
      required: true,
    },
    selectedCommunicationTool: {
      type: Object,
      required: false,
    },
  },
  components: {
    CommunicationToolCard,
  },
  computed: {},
};
</script>

<style scoped>
.communication-tools {
  margin: 0px;
  height: 45vh !important;
  overflow: auto;
}

.communication-tools-card {
  background-color: rgb(var(--v-theme-card));
  border: 1px solid rgb(var(--v-theme-background)) !important;
}
</style>
