<template>
  <div>
    <base-card class="pa-5 pt-4" style="text-align: center">
      <v-row class="ma-0 pa-0" align="center">
        <v-col
          class="ma-0 pa-0 text-body-2 font-weight-medium"
          cols="10"
          align="start"
        >
          <v-icon size="small" class="pr-2">mdi-account-search</v-icon>
          Looking for a trainer?</v-col
        >
      </v-row>
      <v-row class="ma-0 pa-0 py-5">
        <v-col align="center" class="ma-0 pa-0 py-3 text-caption">
          <base-button
            customHeight="27px"
            customFontSize="10px"
            customWidth="95px"
            customColor="colorSpecial1"
            toPath="/trainers"
            >Find trainers</base-button
          >
        </v-col>
      </v-row>
    </base-card>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
