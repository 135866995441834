<template>
  <div>
    <base-card class="pa-5 pt-2" style="text-align: center">
      <base-loader v-if="!currentUser"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0" align="center">
          <v-col
            class="ma-0 pa-0 text-body-2 font-weight-medium"
            cols="10"
            align="start"
          >
            <v-icon size="small" class="pr-2">mdi-star-circle-outline</v-icon>
            My Interest Areas</v-col
          >
          <v-col class="ma-0 pa-0" align="right">
            <router-link to="/settings/user/profile/interestareas">
              <v-icon size="15" color="grey">mdi-pencil</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <v-row
          v-if="
            currentUser.interestAreas === '' ||
            currentUser.interestAreas.length === 0
          "
          class="ma-0 pa-0 py-5"
        >
          <v-col
            align="center"
            class="ma-0 pa-0 py-3 text-caption text-grey"
            style="font-style: italic"
          >
            No interest areas added yet
          </v-col>
        </v-row>
        <div v-else>
          <v-row class="ma-0 pa-0 py-5">
            <v-col class="ma-0 pa-0">
              <v-chip
                class="ma-1"
                style="font-size: 10px"
                v-for="interestArea in currentUser.interestAreas"
                :key="interestArea"
                :ripple="false"
              >
                {{ interestArea }}
              </v-chip>
            </v-col>
          </v-row>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
    }),
  },
};
</script>

<style scoped></style>
