<template>
  <div>
    <base-card class="pa-5" style="text-align: center">
      <base-loader v-if="!session"></base-loader>
      <div v-else>
        <v-row class="ma-0 pa-0">
          <v-col align="center" class="ma-0 pa-0">
            <div v-if="newSessionCardMessage !== null">
              <v-chip
                size="small"
                :color="chipColorNewCardMessage"
                class="text-white"
                variant="outlined"
                label
              >
                <v-icon size="small" start>mdi-label</v-icon>
                {{ newSessionCardMessage }}
              </v-chip>
            </div>
            <div v-else>
              <v-chip
                size="small"
                :color="chipColor"
                class="text-white"
                variant="outlined"
                label
              >
                <v-icon size="small" start>mdi-label</v-icon>
                {{ sessionCardMessage }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </div>
    </base-card>
  </div>
</template>

<script>
import {
  convertUTCDateTimeToUserTimezoneNoFormat,
  getCurrentTimeInUserTimezone,
} from "@/utils/utils.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      sessionStatuses: [
        {
          id: 1,
          text: "New",
          value: "new",
        },
        {
          id: 2,
          text: "Accepted",
          value: "accepted",
        },
        {
          id: 3,
          text: "Completed",
          value: "completed",
        },
        {
          id: 4,
          text: "Declined",
          value: "declined",
        },
        {
          id: 5,
          text: "Cancelled",
          value: "cancelled",
        },
        {
          id: 6,
          text: "Disputed",
          value: "disputed",
        },
      ],
    };
  },
  props: {
    session: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
    }),
    chipColor() {
      switch (this.sessionCardMessage) {
        case "UPCOMING":
          return "info";
        case "COMPLETED":
          return "success";
        case "DECLINED":
          return "warning";
        case "PENDING SYSTEM ACTION":
          return "warning";
        case "CANCELLED":
          return "error";
        case "DISPUTED":
          return "warning";
        default:
          return "info";
      }
    },
    chipColorNewCardMessage() {
      switch (this.newSessionCardMessage) {
        case "PENDING CONFIRMATION":
          return "info";
        case "ACCEPT OR DECLINE":
          return "warning";
        case "DISPUTED BY TRAINER":
          return "warning";
        case "DISPUTED BY USER":
          return "warning";
        default:
          return "info";
      }
    },
    newSessionCardMessage() {
      let lastStatus =
        this.session.statusLogs[this.session.statusLogs.length - 1];
      let sessionStartDateInUserTimezone =
        convertUTCDateTimeToUserTimezoneNoFormat(
          this.session.start,
          this.currentUser.timezone
        );
      let currentTimeInUserTimezone = getCurrentTimeInUserTimezone(
        this.currentUser.timezone
      );

      if (lastStatus.status === "new") {
        if (sessionStartDateInUserTimezone.isAfter(currentTimeInUserTimezone)) {
          return "ACCEPT OR DECLINE";
        } else {
          return "PENDING SYSTEM ACTION";
        }
      } else if (lastStatus.status === "accepted") {
        if (
          sessionStartDateInUserTimezone.isBefore(currentTimeInUserTimezone)
        ) {
          return "PENDING EXPLORER COMPLETION";
        } else {
          return "UPCOMING";
        }
      } else if (lastStatus.status === "disputed") {
        if (lastStatus.initiator === "trainer") {
          return "DISPUTED BY TRAINER";
        } else {
          return "DISPUTED BY EXPLORER";
        }
      } else {
        return null;
      }
    },
    sessionCardMessage() {
      let lastStatus =
        this.session.statusLogs[this.session.statusLogs.length - 1];

      let lastStatusText = this.sessionStatuses.find(
        (status) => status.value === lastStatus.status
      ).text;

      return lastStatusText.toUpperCase();
    },
  },
};
</script>

<style scoped></style>
