<template>
  <v-container fluid class="ma-0 pa-0 px-2 pb-10" style="min-height: 70vh">
    <v-row class="my-15 ma-0 pa-0" justify="center">
      <v-col
        cols="12"
        md="10"
        lg="6"
        xl="6"
        :class="{
          'pr-5': $vuetify.display.mdAndUp,
          'py-5': $vuetify.display.smAndDown,
          'pa-0': true,
        }"
      >
        <base-card :loading="loading">
          <p class="text-h4 font-weight-medium text-center my-10 mx-5">
            My Explorers
          </p>
          <base-loader v-if="loading"></base-loader>
          <div v-else>
            <v-row
              :class="{
                'ma-10': $vuetify.display.mdAndUp,
                'ma-2': $vuetify.display.smAndDown,
              }"
              v-if="currentUserTrainerProfile.myStudents.length > 0"
            >
              <trainer-students
                :trainerProfile="currentUserTrainerProfile"
              ></trainer-students>
            </v-row>
            <div v-else>
              <v-row class="ma-10" style="font-size: 16px">
                <p>You did not meet with any explorer yet.</p>
              </v-row>
            </div>
          </div>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TrainerStudents from "@/components/trainer/settings/mystudents/trainerstudents.vue";
import { mapGetters } from "vuex";
import { setMetaTags } from "../../../utils/utils.js";

export default {
  data() {
    return {};
  },
  created() {
    setMetaTags("NetworkTal - My Explorers", "My Explorers");
  },
  components: {
    TrainerStudents,
  },
  computed: {
    ...mapGetters({
      currentUserTrainerProfile: "trainers/currentUserTrainerProfile",
    }),
    loading() {
      if (this.currentUserTrainerProfile !== null) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped></style>
