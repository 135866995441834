<template>
  <v-container class="my-10" style="min-height: 70vh">
    <v-row v-if="selectedTrainer" justify="center">
      <v-col cols="12" md="10" lg="6" xl="4">
        <trainer-overview
          class="mb-6"
          :trainer="selectedTrainer"
        ></trainer-overview>
        <trainer-session-list
          v-if="selectedTrainer.sessions"
          class="mb-6"
          :trainer="selectedTrainer"
        ></trainer-session-list>
        <trainer-video-card
          v-if="selectedTrainer.videoPresentationURL"
          :class="{ 'mb-6': selectedTrainer.certifications.length > 0 }"
          :videoURL="selectedTrainer.videoPresentationURL"
        ></trainer-video-card>
        <trainer-certifications
          v-if="selectedTrainer.certifications.length > 0"
          :trainer="selectedTrainer"
        ></trainer-certifications>
      </v-col>
      <v-col cols="12" md="10" lg="6" xl="4">
        <trainer-description
          class="mb-6"
          :trainer="selectedTrainer"
        ></trainer-description>
        <trainer-calendar
          :trainerId="getTrainerIdFromTrainerProfileSubPath(id)"
        ></trainer-calendar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { setMetaTags } from "../../../utils/utils.js";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";
import TrainerCalendar from "../../../components/trainer/profile/calendar/calendar.vue";
import TrainerVideoCard from "../../../components/trainer/profile/trainervideocard/trainervideocard.vue";
import TrainerDescription from "../../../components/trainer/profile/description/description.vue";
import TrainerSessionList from "../../../components/trainer/profile/sessionlist/sessionlist.vue";
import TrainerOverview from "../../../components/trainer/profile/overview/overview.vue";
import TrainerCertifications from "../../../components/trainer/profile/certifications/certifications.vue";

export default {
  setup() {
    const { getTrainerIdFromTrainerProfileSubPath } = useGlobalFunctions();
    return { getTrainerIdFromTrainerProfileSubPath };
  },
  data() {
    return {
      selectedTrainer: "",
    };
  },
  components: {
    TrainerCalendar,
    TrainerVideoCard,
    TrainerDescription,
    TrainerSessionList,
    TrainerOverview,
    TrainerCertifications,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  async created() {
    await this.fetchTrainer();
    setMetaTags(
      `NetworkTal - Trainer ${this.selectedTrainer.displayName}`,
      this.selectedTrainer.aboutMe
    );
  },
  methods: {
    ...mapActions({
      getTrainerById: "trainers/getTrainerById",
    }),
    async fetchTrainer() {
      try {
        this.selectedTrainer = await this.getTrainerById({
          id: this.getTrainerIdFromTrainerProfileSubPath(this.id),
        });
      } catch (error) {
        // redirect to page not found
        this.$router.push({ name: "PageNotFound", params: { pathMatch: "*" } });
      }
    },
  },
};
</script>

<style scoped></style>
