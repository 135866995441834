<template>
  <div>
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col cols="7" class="text-h5 font-weight-medium pl-5"
        >Certifications</v-col
      >
      <v-col align="end">
        <base-button
          v-if="!addOrUpdateCertificationDialog"
          @click="toggleDialog"
        >
          Add
          <base-icon>mdi-plus</base-icon>
        </base-button>
      </v-col>
    </v-row>
    <base-error-success-message
      :successMessage="successMessage"
      :errorMessage="errorMessage"
    ></base-error-success-message>
    <base-card
      outlined
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <base-dialog-confirm
        v-if="confirmDialog"
        :displayText="certificationRemovalDisplayText"
        eventName="confirm-delete-certification"
        :payload="certificationRemovalPayload"
        toolbarText="Delete confirmation"
      ></base-dialog-confirm>
      <add-or-update-certification-dialog
        v-if="trainerProfile"
        :trainerProfile="trainerProfile"
      ></add-or-update-certification-dialog>
      <certifications-list
        v-if="trainerProfile"
        :trainerProfile="trainerProfile"
        @cancelEdit="toggleDialog()"
        @editCertificationsSuccessful="editCertificationsSuccessful()"
      ></certifications-list>
    </base-card>
  </div>
</template>

<script>
import certificationsList from "./certificationslist.vue";
import addOrUpdateCertificationDialog from "./addorupdatecertificationdialog.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  inject: ["eventBus"],
  data() {
    return {
      successMessage: "",
      errorMessage: "",
      certificationRemovalPayload: null,
      certificationRemovalDisplayText: "",
    };
  },
  components: {
    certificationsList,
    addOrUpdateCertificationDialog,
  },
  props: {
    trainerProfile: {
      required: true,
    },
  },
  created() {
    this.eventBus.on("new-certification-added", () => {
      this.editCertificationsSuccessful(
        "Certification added or updated successfully"
      );
    });
    this.eventBus.on("confirm-delete-certification", (payload) => {
      // console.log(
      //     'confirm-delete-certification triggered with payload:',
      //     JSON.stringify(payload)
      // )
      try {
        this.removeCertification({
          ...payload,
        })
          .then(() => {
            this.successMessage = "Certification removed successfully";
            setTimeout(() => {
              this.successMessage = "";
            }, 5000);
          })
          .catch(() => {
            // this.errorMessage = error
            this.errorMessage =
              "An unexpected error occurred. Please try again later.";
            setTimeout(() => {
              this.errorMessage = "";
            }, 5000);
          });
      } catch (error) {
        // this.errorMessage = error
        this.errorMessage =
          "An unexpected error occurred. Please try again later.";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
      this.eventBus.emit("certification-removed");
    });
    this.eventBus.on("trigger-remove-certification-confirmation", (payload) => {
      this.certificationRemovalPayload = {
        id: payload.id,
        trainerId: payload.trainerId,
      };
      this.certificationRemovalDisplayText = `delete the certification with the name ${payload.certificationName}`;
      this.setConfirmDialog(true);
    });
  },
  computed: {
    ...mapGetters({
      addOrUpdateCertificationDialog: "trainers/addOrUpdateCertificationDialog",
      confirmDialog: "websiteData/confirmDialog",
    }),
  },
  methods: {
    ...mapActions({
      setAddOrUpdateCertificationDialog:
        "trainers/setAddOrUpdateCertificationDialog",
      setConfirmDialog: "websiteData/setConfirmDialog",
      removeCertification: "trainers/removeCertification",
    }),
    toggleDialog() {
      this.setAddOrUpdateCertificationDialog(
        !this.addOrUpdateCertificationDialog
      );
    },
    editCertificationsSuccessful(message) {
      this.successMessage = message;
      setTimeout(() => {
        this.successMessage = "";
      }, 5000);
    },
  },
};
</script>

<style scoped></style>
