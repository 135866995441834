<template>
  <div :loading="true" style="width: 100%">
    <v-skeleton-loader
      class="mx-auto"
      elevation="2"
      max-width="360"
      type="card, text, text, text, button, button"
      boilerplate
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
div :deep() .v-skeleton-loader__heading {
  min-height: 50px;
}

div :deep() .v-skeleton-loader__button {
  min-height: 40px;
  min-width: 100px !important;
}
</style>
