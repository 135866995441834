<template>
  <div style="width: 100%">
    <v-row justify="space-between" class="mt-10 mb-5">
      <v-col class="text-h5 font-weight-medium pl-5">Account Management</v-col>
    </v-row>
    <base-card
      :class="{
        'pa-10': $vuetify.display.mdAndUp,
        'pa-2': $vuetify.display.smAndDown,
      }"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <base-error-success-message
        :successMessage="successMessage"
        :errorMessage="errorMessage"
      ></base-error-success-message>

      <base-dialog-confirm
        v-if="confirmDialog"
        displayText="delete your user and trainer account permanently"
        displayTextExtra="This action cannot be undone."
        eventName="confirm-user-account-deletion"
        :payload="{
          userId: userId,
          email: userProfile.email,
          displayName: userProfile.displayName,
        }"
        toolbarText="Account Deletion Confirmation"
      ></base-dialog-confirm>

      <v-row class="ma-0 pa-0">
        <v-col align="center"
          ><base-button
            customColor="colorSpecial1"
            customVariant="outlined"
            @click="setConfirmDialog(true)"
            >Delete Account</base-button
          ></v-col
        >
      </v-row>
    </base-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  inject: ["eventBus"],
  data() {
    return {
      successMessage: "",
      errorMessage: "",
    };
  },
  props: {
    userProfile: {
      type: Object,
      default: null,
      required: true,
    },
  },
  created() {
    this.eventBus.on("confirm-user-account-deletion", async (payload) => {
      try {
        let noActiveSessions = await this.verifyNonActiveSessionsByUserId(
          payload.userId
        );
        if (noActiveSessions) {
          await this.deleteAccount(payload);
          setTimeout(() => {
            this.logout();
            this.$router.push("/");
          }, 1000);
        }
      } catch (error) {
        this.errorMessage =
          "An error occurred while deleting your account. Please try again later or contact us.";
        setTimeout(() => {
          this.errorMessage = "";
        }, 5000);
      }
    });
  },
  unmounted() {
    this.eventBus.off("confirm-user-account-deletion");
  },
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      confirmDialog: "websiteData/confirmDialog",
    }),
  },
  methods: {
    ...mapActions({
      deleteAccount: "auth/deleteAccount",
      logout: "auth/logout",
      setConfirmDialog: "websiteData/setConfirmDialog",
      verifyNonActiveSessionsByUserId:
        "sessions/verifyNonActiveSessionsByUserId",
    }),
  },
};
</script>

<style scoped></style>
