import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import db from "../../../firebase-init";
import { setUpNotifications } from "../../../firebase-init";

export default {
  async deleteAccount(_, payload) {
    // console.log("Deleting account: " + JSON.stringify(payload));
    await db.collection("users_to_delete").add({
      userId: payload.userId,
      email: payload.email,
      displayName: payload.displayName,
      date: new Date().toISOString(),
      deleteStatus: "requested",
    });
  },

  async setCurrentUserByUserId(context, payload) {
    let customUserProfile = await context.dispatch(
      "users/getUserByUserId",
      payload.userId,
      {
        root: true,
      }
    );
    context.dispatch("setCurrentUser", {
      ...customUserProfile,
    });
    // added set currency here as it depends on current user data
    context.dispatch("setUserCurrencyOrDefault");
  },

  setCurrentUser(context, payload) {
    context.dispatch("users/setCurrentUser", { ...payload }, { root: true });
  },

  setUserCurrencyOrDefault(context) {
    context.dispatch(
      "websiteData/setUserCurrencyOrDefault",
      {},
      { root: true }
    );
  },

  async setUserTimezone(context) {
    context.dispatch("websiteData/setUserTimezone", {}, { root: true });
  },

  async createUser({ dispatch }, payload) {
    let displayName;
    let photoURL;

    if (payload.google) {
      displayName = payload.firstName.trim();
      photoURL = payload.photoURL;
    } else {
      displayName = payload.firstName.trim() + " " + payload.lastName.trim();
      photoURL = "";
    }

    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!timezone || timezone === undefined) {
      // set default timezone to UTC
      timezone = "UTC";
    }

    // capitalize first letter of each word in display name and convert to lowercase
    displayName = displayName
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    await dispatch(
      "users/createOrUpdateUser",
      {
        id: payload.id,
        data: {
          termsAndConditionsAccepted: true,
          dateOfBirth: "",
          displayName: displayName,
          email: payload.email,
          emailVerified: "",
          from: {
            country: "",
            region: "",
          },
          gender: "",
          interestAreas: [],
          livingIn: {
            country: "",
            region: "",
          },
          photoURL: photoURL,
          timezone: timezone,
          userId: payload.userId,
          myTrainers: [],
          currency: {
            symbol: "$",
            name: "US Dollar",
            code: "USD",
            name_plural: "US dollars",
          },
        },
      },
      { root: true }
    );
  },

  async isUserTrainer(context, payload) {
    // retrieve trainer profile
    let trainerProfile = await context.dispatch(
      "trainers/getTrainerByUserId",
      payload.userId,
      {
        root: true,
      }
    );

    if (trainerProfile) {
      // set is trainer to true
      context.commit("setIsTrainer", true);

      // set is trainer registration approved
      trainerProfile.registrationRequestStatus === "approved"
        ? context.commit("setIsTrainerRegistrationApproved", true)
        : context.commit("setIsTrainerRegistrationApproved", false);

      // set current user trainer profile
      context.dispatch(
        "trainers/setCurrentUserTrainerProfile",
        { ...trainerProfile },
        { root: true }
      );
    } else {
      context.commit("setIsTrainer", false);
    }
  },

  async resetpassword(_, payload) {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, payload.email, {
        url: payload.continueUrl,
      });
    } catch (error) {
      throw Error(error.message);
    }
  },

  async verifyemail(_, payload) {
    const auth = getAuth();
    let user = auth.currentUser;
    let counter = 0;

    while (!user && counter < 10) {
      user = auth.currentUser;
      counter++;
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    if (user) {
      try {
        await sendEmailVerification(user, {
          url: payload.continueUrl,
        });
      } catch (error) {
        throw Error(error);
      }
    } else {
      throw new Error("No user is signed in!");
    }
  },

  async signingoogle(context) {
    return await context.dispatch("auth", {
      mode: "google",
    });
  },

  async login(context, payload) {
    return await context.dispatch("auth", {
      ...payload,
      mode: "login",
    });
  },

  async signup(context, payload) {
    return await context.dispatch("auth", {
      ...payload,
      mode: "signup",
    });
  },

  async auth(context, payload) {
    // console.log("Root Getter: " + JSON.stringify(context.rootGetters["trainers/areas"]))
    // console.log("Root Actions: " + JSON.stringify(context))
    let userData;
    let id1;
    let user;

    switch (payload.mode) {
      case "login":
        userData = await signInWithEmailAndPassword(
          getAuth(),
          payload.email,
          payload.password
        );
        break;
      case "signup":
        userData = await createUserWithEmailAndPassword(
          getAuth(),
          payload.email,
          payload.password
        );

        sendEmailVerification(getAuth().currentUser, {
          url: process.env.VUE_APP_NETWORKTAL_PLATFORM_URL,
        });

        id1 = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
          .toString()
          .slice(0, 9);

        await context.dispatch("createUser", {
          id: id1,
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          userId: userData.user.uid,
          google: false,
        });

        // send welcome email
        await context.dispatch(
          "email/sendNewUserWelcomeEmail",
          {
            userEmail: payload.email,
            userDisplayName: payload.firstName + " " + payload.lastName,
          },
          { root: true }
        );
        break;
      case "google":
        userData = await signInWithPopup(getAuth(), new GoogleAuthProvider());

        user = await context.dispatch(
          "users/getUserByUserId",
          userData.user.uid,
          {
            root: true,
          }
        );

        if (user === null) {
          let id2 = Math.floor(
            Math.random() * Math.floor(Math.random() * Date.now())
          )
            .toString()
            .slice(0, 9);

          await context.dispatch("createUser", {
            id: id2,
            firstName: userData.user.displayName,
            email: userData.user.email,
            userId: userData.user.uid,
            photoURL: userData.user.photoURL,
            google: true,
          });

          // send welcome email
          await context.dispatch(
            "email/sendNewUserWelcomeEmail",
            {
              userEmail: userData.user.email,
              userDisplayName: userData.user.displayName,
            },
            { root: true }
          );
        }

        break;
      default:
        throw Error("Invalid request!");
    }
    context.commit("setUserId", userData.user.uid);
    context.commit("setEmailVerified", userData.user.emailVerified);
    context.commit("setIsAuthenticated", true);

    context.dispatch("isUserTrainer", {
      userId: userData.user.uid,
    });

    if (userData.user.emailVerified) {
      await context.dispatch("setCurrentUserByUserId", {
        userId: userData.user.uid,
      });
    }

    // set up notifications
    setTimeout(() => {
      setUpNotifications();
    }, 3000);
  },

  async autoLogin(context) {
    context.dispatch("setUserCurrencyOrDefault");
    context.dispatch("setUserTimezone");

    const auth = getAuth();
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        // Set current user information
        await context.dispatch("setCurrentUserByUserId", {
          userId: user.uid,
        });
        // Check if user is a trainer
        context.dispatch("isUserTrainer", {
          userId: user.uid,
        });
        // Set up userId, emailVerified and isAuthenticated
        context.commit("setUserId", user.uid);
        context.commit("setEmailVerified", user.emailVerified);
        context.commit("setIsAuthenticated", true);
      }
    });

    setTimeout(() => {
      context.commit("setAutoLoginCompleted", true);
    }, 1500);
  },

  logout(context) {
    getAuth()
      .signOut()
      .then(() => {
        context.commit("setUserId", null);
        context.commit("setEmailVerified", false);
        context.commit("setIsAuthenticated", false);
        context.commit("setIsTrainer", false);
        context.commit("setIsTrainerRegistrationApproved", false);
      })
      .catch((error) => {
        throw Error(error.message);
      });
  },
};
