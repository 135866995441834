<template>
  <div>
    <v-row class="session-types ma-0 pa-0" justify="center">
      <v-col cols="12" md="10" lg="6" xl="6">
        <p class="text-subtitle-1 font-weight-medium mb-2">Session Types</p>
        <v-card class="session-type-card mb-5" elevation="0">
          <session
            v-for="session in regularSessions"
            :key="session.id"
            :session="session"
            :selectedSession="selectedSession"
          ></session>
        </v-card>
      </v-col>
      <v-col v-if="trialSessions.length > 0" cols="12" md="10" lg="6" xl="6">
        <p class="text-subtitle-1 font-weight-medium">Trial Session</p>
        <v-card class="session-type-card" elevation="0">
          <session
            v-for="session in trialSessions"
            :key="session.id"
            :session="session"
            :selectedSession="selectedSession"
          ></session>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Session from "./sessiontypecard.vue";

export default {
  props: {
    sessions: {
      type: Array,
      required: true,
    },
    selectedSession: {
      type: Object,
      required: false,
    },
  },
  components: {
    Session,
  },
  computed: {
    trialSessions() {
      return this.sessions.filter((session) => session.isTrial);
    },
    regularSessions() {
      return this.sessions.filter((session) => !session.isTrial);
    },
  },
};
</script>

<style scoped>
.session-types {
  min-height: 45vh !important;
  overflow: auto;
}

.session-type-card {
  background-color: rgb(var(--v-theme-card));
  border: 1px solid rgb(var(--v-theme-background)) !important;
}
</style>
