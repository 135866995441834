<template>
  <div>
    <base-card>
      <v-btn
        :class="{ 'settings-sidebar-button active': true }"
        color="card"
        elevation="0"
        to="/settings/trainer/profile/information"
      >
        <v-icon class="mr-5">mdi-account-settings-outline</v-icon>
        Profile Information
      </v-btn>
      <v-btn
        class="settings-sidebar-button"
        color="card"
        elevation="0"
        to="/settings/trainer/profile/picture"
      >
        <v-icon class="mr-5">mdi-image-outline</v-icon>
        Profile Picture
      </v-btn>
      <v-btn
        class="settings-sidebar-button active"
        color="card"
        elevation="0"
        to="/settings/trainer/profile/communicationtools"
      >
        <v-icon class="mr-5">mdi-message-cog-outline</v-icon>
        Communication Tools
      </v-btn>
      <v-btn
        class="settings-sidebar-button"
        color="card"
        elevation="0"
        to="/settings/trainer/profile/certifications"
      >
        <v-icon class="mr-5">mdi-star-box-multiple-outline</v-icon>
        Certifications
      </v-btn>
    </base-card>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.settings-sidebar-button {
  width: 100%;
  min-width: 300px;
  height: 70px !important;
  font-size: 18px;
  font-weight: 410;
  cursor: pointer;
  text-transform: none;
  justify-content: flex-start;
  padding-left: 10% !important;
}

.settings-sidebar-title {
  text-align: center;
  font-size: 30px !important;
  font-weight: 450;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
