<template>
  <div class="register-trainer-form-section">
    <base-error-success-message
      :errorMessage="errorMessage"
      :successMessage="successMessage"
    ></base-error-success-message>
    <communication-tool-with-tool-id
      :communicationTool="trainer.communicationTools[0]"
    ></communication-tool-with-tool-id>
    <communication-tool-with-tool-id
      :communicationTool="trainer.communicationTools[1]"
    ></communication-tool-with-tool-id>
    <communication-tool-with-tool-id
      :communicationTool="trainer.communicationTools[2]"
    ></communication-tool-with-tool-id>
    <communication-tool-with-custom-tool-id
      :communicationTool="trainer.communicationTools[3]"
    ></communication-tool-with-custom-tool-id>
  </div>
</template>

<script>
import CommunicationToolWithToolId from "./communicationtoolwithtoolid.vue";
import CommunicationToolWithCustomToolId from "./communicationtoolwithcustomtoolid.vue";

export default {
  data: () => ({}),
  components: {
    CommunicationToolWithToolId,
    CommunicationToolWithCustomToolId,
  },
  props: {
    trainer: {
      type: Object,
      required: true,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    successMessage: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.register-trainer-form-section {
  border: 1px solid rgb(var(--v-theme-background));
  padding: 40px;
  margin-bottom: 40px;
}

@media (max-width: 600px) {
  .register-trainer-form-section {
    padding: 10px;
  }
}
</style>
