<template>
  <div id="chat" class="my-1">
    <v-row v-if="!continuation && !isUserMessage()" class="ma-0 pa-0">
      <v-col class="ml-2 pa-0" cols="1" align="center">
        <v-avatar size="40"><v-img :src="avatar"></v-img></v-avatar>
      </v-col>
      <!-- <v-col cols="10" class="pa-0 mt-1 name">{{ senderName }}</v-col> -->
    </v-row>
    <v-row v-if="!continuation && isUserMessage()" class="ma-0 pa-0">
      <v-spacer></v-spacer>
      <!-- <v-col cols="10" class="pa-0 mt-1 name" align="right" style="border: 2px solid black">
                {{ senderName }}
            </v-col> -->
      <v-col class="mr-2 pa-0" cols="1" align="center">
        <v-avatar size="40"><v-img :src="avatar"></v-img></v-avatar>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" v-if="!isUserMessage()">
      <v-spacer></v-spacer>
      <v-col
        cols="8"
        :class="{
          'message-continuation': continuation,
          'ma-0': true,
          'pa-0': true,
        }"
        style="padding-bottom: 0px"
      >
        <div
          :class="{
            message: !isUserMessage(),
            'user-message': isUserMessage(),
          }"
          v-html="chat.message"
        ></div>
      </v-col>
      <v-col cols="3" class="datetime ma-0 pa-0" style="text-align: left">
        {{ getFormattedDateTimeInUserTimezone(chat.timestamp) }}
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" v-else>
      <v-col cols="3" class="datetime ma-0 pa-0">{{
        getFormattedDateTimeInUserTimezone(chat.timestamp)
      }}</v-col>
      <v-col
        cols="8"
        :class="{
          'message-continuation': continuation,
          'ma-0': true,
          'pa-0': true,
        }"
        style="padding-bottom: 0px; text-align: right"
      >
        <div
          :class="{
            message: !isUserMessage(),
            'user-message': isUserMessage(),
          }"
          v-html="chat.message"
        ></div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { convertUTCDateTimeToUserTimezoneNoFormat } from "@/utils/utils.js";

export default {
  name: "Chat",
  props: {
    chat: {
      type: Object,
      required: true,
    },
    continuation: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      sender: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "users/currentUser",
      userId: "auth/userId",
      userPhotoDefault: "websiteData/userPhotoDefault",
    }),
    avatar: function () {
      return this.sender.photoURL
        ? this.sender.photoURL
        : this.userPhotoDefault;
    },
    senderName: function () {
      return this.sender ? this.sender.displayName : "Unknown";
    },
  },
  methods: {
    ...mapActions({
      getUserByUserId: "users/getUserByUserId",
    }),
    async setSenderAsync(userId) {
      let senderUserProfile = await this.getUserByUserId(userId);
      this.sender = senderUserProfile;
    },
    isUserMessage() {
      return this.userId == this.chat.senderID;
    },
    getFormattedDateTimeInUserTimezone(dateTimeUTC) {
      let dateTimeUserTimezone = convertUTCDateTimeToUserTimezoneNoFormat(
        dateTimeUTC,
        this.currentUser.timezone
      );
      return dateTimeUserTimezone.format("hh:mm A, MMM DD, YYYY");
    },
    scrollToBottom() {
      var element = document.getElementById("chat-list-container");
      if (typeof element != "undefined" && element != null) {
        element.scrollTop = element.scrollHeight;
      }
    },
  },
  mounted() {
    this.setSenderAsync(this.chat.senderID);
    this.scrollToBottom();
  },
  watch: {
    chat() {
      this.scrollToBottom();
    },
  },
};
</script>

<style scoped>
#chat {
  color: gray;
}

.name {
  font-size: 16px;
  font-weight: 400;
  color: black;
  /* color: rgb(129, 129, 129); */
}

.datetime {
  font-size: 9px;
  text-align: right;
  color: rgb(164, 164, 164);
}
.message {
  /* background-color: #dfe1e6; */
  background-color: rgb(var(--v-theme-chatSenderBackground));
  color: black;
  font-size: 15px;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  display: inline-block;
  max-width: 100%;
}
.user-message {
  /* background-color: #0584fb; */
  background-color: rgb(var(--v-theme-colorSpecial3));
  color: white;
  font-size: 15px;
  border-radius: 10px 0px 10px 10px;
  padding: 10px;
  display: inline-block;
  max-width: 100%;
}
.message-continuation {
  padding-top: 0px;
}
</style>
