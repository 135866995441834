<template>
  <v-container class="text-subtitle-1">
    <v-row>
      <v-col cols="5" class="font-weight-medium">Full Name:</v-col>
      <v-col>{{ userProfile.displayName }}</v-col>
    </v-row>

    <v-row>
      <v-col cols="5" class="font-weight-medium">Email Address:</v-col>
      <v-col>{{ userProfile.email }}</v-col>
    </v-row>

    <v-row>
      <v-col cols="5" class="font-weight-medium">From:</v-col>
      <v-col v-if="userProfile.from.country"
        >{{ userProfile.from.country }}, {{ userProfile.from.region }}</v-col
      >
    </v-row>

    <v-row>
      <v-col cols="5" class="font-weight-medium">Living in:</v-col>
      <v-col v-if="userProfile.livingIn.country"
        >{{ userProfile.livingIn.country }},
        {{ userProfile.livingIn.region }}</v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
