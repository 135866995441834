<template>
  <div style="width: 100%">
    <base-card
      class="students-card"
      style="
        border: 2px solid rgb(var(--v-theme-borderSettingsCard)) !important;
      "
    >
      <message-trainer-dialog
        v-if="trainerProfile"
        :trainerId="trainerProfile.id"
        :targetUserId="targetUserId"
      ></message-trainer-dialog>
      <student-card
        v-for="student in trainerProfile.myStudents"
        :key="student.id"
        :student="student"
      ></student-card>
    </base-card>
  </div>
</template>

<script>
import StudentCard from "./studentcard.vue";
import MessageTrainerDialog from "@/components/trainer/profile/overview/messagetrainerdialog/messagetrainerdialog.vue";
import { mapActions } from "vuex";

export default {
  inject: ["eventBus"],
  data() {
    return {
      targetUserId: null,
    };
  },
  components: {
    StudentCard,
    MessageTrainerDialog,
  },
  props: {
    trainerProfile: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.eventBus.on("send-message-to-user", (payload) => {
      this.targetUserId = payload.userId;
      this.setMessageTrainerDialog(true);
    });
  },
  methods: {
    ...mapActions({
      setMessageTrainerDialog: "trainers/setMessageTrainerDialog",
    }),
  },
};
</script>

<style scoped>
.students-card {
  max-height: 500px;
  overflow-y: auto;
}
</style>
