<template>
  <base-card class="session-card">
    <div class="free-session-tag" v-if="session.price === 0">Free Session</div>
    <v-row class="ma-0 pa-0" align="center" style="width: 100%">
      <v-col class="ma-0 pa-0 py-5" cols="12" sm="3" align="center">
        <v-row class="ma-0 pa-0 px-2">
          <v-col class="ma-0 pa-0" align="center">
            <v-avatar :size="trainerPhotoSize">
              <v-img :src="getPhotoURL"></v-img>
            </v-avatar>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="ma-0 pa-0 py-2 px-4" cols="12" sm="9">
        <div class="text-h6 font-weight-medium">{{ session.title }}</div>
        <div class="text-subtitle-2 font-weight-regular font-italic my-2">
          Offered by
          <router-link class="font-weight-medium" :to="trainerURL">{{
            session.trainerProfile.displayName
          }}</router-link>
        </div>
        <div class="text-subtitle-2 font-weight-light">
          {{ session.description }}
        </div>
        <v-row class="ma-0 pa-0 my-8 text-subtitle-1 font-weight-medium">
          <v-col class="ma-0 pa-0" align="center">
            <v-icon size="20">mdi-clock-outline</v-icon>
            {{ session.duration }} minutes
          </v-col>
          <v-divider vertical></v-divider>
          <v-col class="ma-0 pa-0" align="center">
            <v-icon size="20">mdi-cash</v-icon>
            {{
              session.price === 0
                ? "Free"
                : session.price + " " + currency.symbol
            }}
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 text-subtitle-1 font-weight-regular">
          <v-col class="ma-0 pa-0">
            <base-button
              customColor="colorSpecial1"
              :toPath="bookNowToPath()"
              target="_blank"
              >Book Now
            </base-button>
            <base-button :toPath="trainerURL" target="_blank"
              >View Profile</base-button
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
import { mapGetters } from "vuex";
import { useGlobalFunctions } from "@/composables/useGlobalFunctions.js";

export default {
  setup() {
    const { createTrainerProfileDisplayNamePath } = useGlobalFunctions();
    return { createTrainerProfileDisplayNamePath };
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      userPhotoDefault: "websiteData/userPhotoDefault",
      currency: "websiteData/currency",
    }),
    getPhotoURL() {
      if (this.session.trainerProfile.photoURL) {
        return this.session.trainerProfile.photoURL;
      } else {
        return this.userPhotoDefault;
      }
    },
    trainerPhotoSize() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "150";
        case "sm":
          return "125";
        case "md":
          return "90";
        case "lg":
          return "100";
        case "xl":
          return "120";
        default:
          return "80";
      }
    },
    trainerURL() {
      return (
        "/trainer/" +
        this.createTrainerProfileDisplayNamePath(
          this.session.trainerProfile.displayName
        ) +
        "-" +
        this.session.trainerProfile.id
      );
    },
  },
  methods: {
    bookNowToPath() {
      let bookNowToPath = this.trainerURL + "?bookSession=" + this.session.id;

      if (this.isAuthenticated) {
        return bookNowToPath;
      } else {
        return "/login?from=" + bookNowToPath;
      }
    },
  },
};
</script>

<style scoped>
.session-card {
  width: 100%;
  border-radius: 20px !important;
  min-height: 400px;
  /* align vertically centered */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
  color: inherit;
}

.free-session-tag {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #fed757; /* Vuetify's default error color */
  color: rgb(var(--v-theme-textPrimary));
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 10px;
  z-index: 10;
}
</style>
