<template>
  <div>
    <base-card
      class="trainer-preview-card my-5"
      :style="'min-height:' + cardMinHeight + '; width:' + cardMinWidth"
    >
      <v-skeleton-loader type="avatar"></v-skeleton-loader>
      <v-skeleton-loader class="mt-6" type="heading"></v-skeleton-loader>
      <v-skeleton-loader class="mt-3" type="text"></v-skeleton-loader>
      <v-skeleton-loader class="my-0" type="text"></v-skeleton-loader>
    </base-card>
  </div>
</template>

<script>
export default {
  computed: {
    cardMinHeight() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "450px";
        case "sm":
          return "450px";
        case "md":
          return "500px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    cardMinWidth() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "320px";
        case "sm":
          return "320px";
        case "md":
          return "350px";
        case "lg":
          return "350px";
        case "xl":
          return "400px";
        default:
          return "400px";
      }
    },
    avatarSize() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "150";
        case "sm":
          return "150";
        case "md":
          return "200";
        case "lg":
          return "200";
        case "xl":
          return "220";
        default:
          return "220";
      }
    },
  },
};
</script>

<style scoped>
.trainer-preview-card {
  background-color: white !important;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Initial shadow */
}

.trainer-preview-card:hover {
  /* transform: translateY(-5px);  */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important; /* Enhanced shadow on hover */
}

a {
  text-decoration: none;
  color: rgb(var(--v-theme-primary));
}

div :deep() .v-skeleton-loader__avatar {
  margin-top: 50px;
  min-height: 200px;
  min-width: 200px;
  /* align center horizontally */
  margin-left: auto;
  margin-right: auto;
}

div :deep() .v-skeleton-loader__heading {
  min-height: 50px;
}
</style>
