import db from "../../../firebase-init";
import { collection, getDocs, query, where } from "firebase/firestore";

export default {
  async createEvent(_, payload) {
    // console.log("Create Event triggered with payload: " + JSON.stringify(payload))
    let event = "";

    await db
      .collection("sessions")
      .add({
        ...payload,
      })
      .then((docRef) => {
        event = {
          ...payload,
          id: docRef.id,
        };
      });

    return event;
  },

  async deleteEvent(_, payload) {
    // console.log('Deleting session with id: ' + payload.id)
    await db.collection("sessions").doc(payload.id).delete();
  },

  async getEventById(_, payload) {
    // console.log("Getting session with id: " + payload);
    let event = "";

    const doc = await db.collection("sessions").doc(payload).get();

    if (doc.exists) {
      event = doc.data();
      event.id = doc.id;
    }

    return event;
  },

  async updateEvent(_, payload) {
    // console.log("Update Event triggered with payload: " + JSON.stringify(payload))
    await db
      .collection("sessions")
      .doc(payload.id)
      .update({
        ...payload.data,
      });
  },

  async getTrainerSessions(_, payload) {
    let sessions = [];
    const q = query(
      collection(db, "sessions"),
      where("trainerId", "==", payload.trainerId)
    );
    const queryResult = await getDocs(q);

    queryResult.forEach((doc) => {
      const session = doc.data();
      session.id = doc.id;
      sessions.push(session);
    });

    return sessions;
  },

  async loadTrainerAvailability(context, payload) {
    let sessions = payload.sessions;
    let trainerAvailability = [];

    trainerAvailability = await context.dispatch(
      "trainerAvailability/getTrainerAvailability",
      {
        trainerId: payload.trainerId,
      },
      { root: true }
    );

    if (trainerAvailability.length > 0) {
      trainerAvailability.forEach((timeframe) => {
        let session = {
          start: timeframe.startTime,
          end: timeframe.endTime,
          color: context.getters.availabilityColor,
        };
        sessions.push(session);
      });
    }

    return sessions;
  },

  async loadUserEvents(context, payload) {
    // console.log('Called loadUserEvents for user with id: ' + payload.userId)

    let userEvents = [];
    const q = query(
      collection(db, "sessions"),
      where("userId", "==", payload.userId)
    );
    const queryResult = await getDocs(q);

    queryResult.forEach((doc) => {
      const userEvent = doc.data();
      userEvent.id = doc.id;
      userEvents.push(userEvent);
    });

    userEvents.sort((a, b) => {
      return new Date(a.start) - new Date(b.start);
    });

    context.commit("setSessionsUser", userEvents);
  },

  async loadTrainerEvents(context, payload) {
    // console.log('Called loadTrainerEvents for trainer with id: ' + JSON.stringify(payload))

    let sessions = [];
    const q = query(
      collection(db, "sessions"),
      where("trainerId", "==", payload.trainerId)
    );
    const queryResult = await getDocs(q);

    queryResult.forEach((doc) => {
      const session = doc.data();
      session.id = doc.id;
      sessions.push(session);
    });

    if (payload.loadAvailability) {
      sessions = await context.dispatch("loadTrainerAvailability", {
        trainerId: payload.trainerId,
        sessions: sessions,
      });
    }

    context.commit("setSessionsTrainer", sessions);
  },

  setAddEventDialog(context, payload) {
    context.commit("setAddEventDialog", payload);
  },
};
